import React from 'react';
import { ISimulation } from 'modules/coach/entities/Simulation';

interface Props {
  simulation: ISimulation;
}

const SimulationMainInfo: React.FC<Props> = ({ simulation }) => (
  <div className="flex flex-col space-y-8">
    <div className="flex flex-col space-y-3">
      <p className="text-current text-2xl text-left">Категория</p>
      <p className="text-current text-base">{simulation.categoryName}</p>
    </div>

    <div className="flex flex-col space-y-3">
      <p className="text-current text-2xl text-left">Цель</p>
      <p className="text-current text-base">{simulation.target}</p>
    </div>

    <div className="flex flex-col space-y-3">
      <p className="text-current text-2xl text-left">Описание</p>
      <p className="text-current text-base">{simulation.description}</p>
    </div>

    <div className="flex flex-col space-y-3">
      <p className="text-current text-2xl text-left">Инструкция</p>
      <p className="text-current text-base">{simulation.instruction}</p>
    </div>

    <div className="flex flex-col space-y-3">
      <p className="text-current text-2xl text-left">Описание категории</p>
      <p className="text-current text-base">{simulation.categoryDescription}</p>
    </div>

    <div className="flex flex-col space-y-3">
      <p className="text-current text-2xl text-left">Вопросы</p>
      <p className="text-current text-base">{simulation.questions}</p>
    </div>

    <div className="flex flex-col space-y-3">
      <p className="text-current text-2xl text-left">Описание таймера</p>
      <p className="text-current text-base">{simulation.timerDescription}</p>
    </div>

    <div className="flex flex-col space-y-3">
      <p className="text-current text-2xl text-left">Описание количества попыток</p>
      <p className="text-current text-base">{simulation.tryDescription}</p>
    </div>

    <div className="flex flex-col space-y-3">
      <p className="text-current text-2xl text-left">Описание результатов</p>
      <p className="text-current text-base">{simulation.resultsDescription}</p>
    </div>

    <div className="flex flex-col space-y-3">
      <p className="text-current text-2xl text-left">Общее время на симуляцию в секундах</p>
      <p className="text-current text-base">{simulation.seconds}</p>
    </div>
  </div>
);

export default SimulationMainInfo;
