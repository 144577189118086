import React, { useState } from 'react';

import { Coach } from '../../entities/Lesson';

interface Props {
  trainerInfo: Coach;
}

const TrainerCard: React.FC<Props> = ({ trainerInfo }) => {
  return (
    <aside className="flex gap-3 border theme-border--disabled rounded-[44px] w-full">
      <img
        className="w-10 h-10 rounded-full object-cover"
        src={trainerInfo?.avatar || require('assets/images/empty-avatar.png')}
        alt={trainerInfo?.fullName}
      />
      <span className="text-[16px] theme-text--black max-w-full truncate self-center pr-4">
        {' '}
        {trainerInfo?.fullName}{' '}
      </span>
    </aside>
  );
};

export default TrainerCard;
