import React from 'react';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
interface Props {
  checked: boolean;
}

const LMSRadioButton: React.FC<Props> = ({ checked }) => {
  return (
    <div className="relative shrink-0 flex items-center justify-center w-6 h-6 rounded-full border border-solid theme-border">
      {checked ? (
        <div className="theme-background--green flex items-center justify-center  w-5 h-5 rounded-full">
          <CheckIcon className="w-3 h-3 text-black" />
        </div>
      ) : null}
    </div>
  );
};

export default LMSRadioButton;
