import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { fetchCoaches } from '../api/repository/coaches';
import { Coach } from '../entities/Coaches';

export const useCoaches = () => {
  const [query, setQuery] = useSearchParams();
  const courseId = query.get('course_id');
  const coachType = query.get('type');
  const page = query.get('page');

  const [lastPage, setLastPage] = useState(1);

  const [isLoading, setLoading] = useState(false);
  const [coaches, setGroups] = useState<Coach[]>([]);

  useEffect(() => {
    const params = {
      ...(courseId && { course_id: courseId || '' }),
      ...(coachType && { type: coachType || '' }),
      page: page ? page : '1',
    };

    setLoading(true);
    fetchCoaches(params)
      .then((res) => {
        setGroups(res.coaches);
        setLastPage(res.lastPage);
      })
      .catch((e) => console.warn(e))
      .finally(() => {
        setLoading(false);
      });
  }, [courseId, coachType, page]);

  const setCurrentPage = (current: number) => {
    query.set('page', current.toString());
    setQuery(query);
  };

  return {
    isLoading,
    coaches,
    currentPage: page ? +page : 1,
    lastPage,
    setCurrentPage,
  };
};
