import request from 'api/axios-instance';

export const createModule = (params: Record<string, string | number | null>) =>
  request.post('v1/management/modules', params).then((res) => res.data.data);

export const editModule = (params: Record<string, string | number | null>) =>
  request.post(`v1/management/modules/${params.id}`, params).then((res) => res.data.data);

export const deleteModule = (id: number) =>
  request.delete(`v1/management/modules/${id}`).then((res) => res.data.data);

export const changeOrderModules = (id: number, params: { order: { id: number }[] }) =>
  request.post(`v1/management/courses/${id}/order`, params).then((res) => res.data.data);
