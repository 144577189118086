import React, { useEffect, useMemo, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useSearchParams } from 'react-router-dom';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';

import { fetchAcademicPerformance } from '../../api/repository/dashboard';
import { DashboardAcademicPerformance } from '../../entities/DashboardAcademicPerformance';

ChartJS.register(ArcElement, Tooltip, Legend);

interface LocalPerformance extends DashboardAcademicPerformance {
  color: string;
}

const colors: Record<string, string> = {
  '0_25': '#26A7EF1A',
  '25_50': '#26A7EF66',
  '50_75': '#26A7EFC2',
  '75_100': '#26A7EF',
};

const AcademicPerformanceChart = () => {
  const [query] = useSearchParams();
  const course = query.get('course');

  const [performances, setPerformances] = useState<LocalPerformance[]>([]);

  useEffect(() => {
    const params = { ...(course && { course_ids: course }) };
    fetchAcademicPerformance(params).then((res) =>
      setPerformances(res.reverse().map((p) => ({ ...p, color: colors[`${p.min}_${p.max}`] })))
    );
  }, [course]);

  const chartData = useMemo(() => {
    return {
      datasets: [
        {
          label: 'Процент',
          data: performances.map((p) => p.percentage),
          backgroundColor: performances.map((p) => p.color),
          hoverOffset: 4,
          borderWidth: 0,
          cutout: '85%',
          borderRadius: 20,
          offset: 10,
        },
      ],
    };
  }, [performances]);

  const options = {
    maintainAspectRatio: false,
    tooltips: {
      enabled: false,
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div className="flex-1 p-5 rounded-xl bg-studentCardBg">
      <p className="text-base md:text-lg">Успеваемость</p>
      <div className="flex justify-center">
        <div className="w-[190px] h-[190px] mt-5 mb-10">
          <Doughnut data={chartData} options={options} />
        </div>
      </div>
      {performances.map((performance) => (
        <div
          key={`${performance.max}_${performance.min}`}
          className="flex items-center justify-between space-x-3 mb-1 text-sm"
        >
          <span className="block w-4 h-1 rounded" style={{ backgroundColor: performance.color }} />
          <span className="flex-1 text-white/50">
            от {performance.min} до {performance.max} баллов
          </span>
          <span>{performance.percentage}%</span>
        </div>
      ))}
    </div>
  );
};

export default AcademicPerformanceChart;
