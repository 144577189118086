import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowBack } from 'assets/icons/chevron-left.svg';
import AddNewSchedule from 'modules/coach/components/AddNewSchedule';

const NewSchedule = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate('/coach/schedule');
  };

  return (
    <div className="w-full h-full flex flex-col mt-20 lg:mt-6">
      <button
        type="button"
        onClick={() => goBack()}
        className="w-fit flex flex-row items-center justify-center gap-x-2 py-3 pl-3 pr-5 text-white text-[14px] font-medium theme-default-button--active rounded-[36px] border-none outline-none uppercase"
      >
        <ArrowBack />
        назад
      </button>
      <div className="flex flex-col mt-10">
        <AddNewSchedule />
      </div>
    </div>
  );
};

export default NewSchedule;
