import React, { useEffect, useMemo, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';
import LMSButton from 'components/LMSButton';
import LMSInput from 'components/LMSInput';
import ru from 'date-fns/locale/ru';
import DropdownSelect from 'modules/coach/components/DropdownSelect';
import { useHandbooks } from 'modules/coach/hooks/useHandbooks';
import { useHomeworksCheckFilter } from 'modules/coach/hooks/useHomeworksCheckFilter';
import { useOutsideClick } from 'hooks/outside-click';
import { useWindowSize } from 'hooks/window-size';

registerLocale('ru', ru);

const defaultSelectValue = {
  id: null,
  name: '',
};

const CheckedHomeworksFilter = () => {
  const device = useWindowSize();
  const { setFilter, onReset, onSubmit } = useHomeworksCheckFilter();
  const {
    homeworkStatuses,
    homeworkCourses,
    homeworkGroups,
    onFetchHandbooks,
    onFetchHomeworkCourses,
    onFetchHomeworkGroups,
  } = useHandbooks();

  const [isOpen, setOpen] = useState(false);
  const [student, setStudent] = useState<string>('');
  const [deadline, setDeadline] = useState<Date | undefined | null>(null);
  const [status, setStatus] = useState<{ id: number | null; name: string }>(defaultSelectValue);
  const [course, setCourse] = useState<{ id: number | null; name: string }>(defaultSelectValue);
  const [group, setGroup] = useState<{ id: number | null; name: string }>(defaultSelectValue);

  const filterRef = useOutsideClick(() => {
    setOpen(false);
  });

  const filterClass = useMemo(() => {
    if (device === 'desktop') {
      return 'relative w-full h-fit flex flex-col';
    }

    if (!isOpen) return 'hidden';

    return 'fixed bottom-0 right-0 left-0 top-24 w-full bg-[#3D436B] px-8 py-5 border border-solid border-[#3D436B] rounded-t-[20px] overflow-auto z-10';
  }, [device, isOpen]);

  const onToggleOpen = () => {
    if (isOpen) {
      document.body.classList.remove('modal-open');
    } else {
      document.body.classList.add('modal-open');
    }
    setOpen((prev) => !prev);
  };

  const resetFilters = () => {
    setCourse(defaultSelectValue);
    setStudent('');
    setGroup(defaultSelectValue);
    setDeadline(null);
    setStatus(defaultSelectValue);
    onToggleOpen();
    onReset();
  };

  const submitFilters = () => {
    onToggleOpen();
    onSubmit();
  };

  const handleStatusChange = (id: number, name: string) => {
    setStatus({ id, name });
  };

  const handleCourseChange = (id: number, name: string) => {
    setCourse({ id, name });
    if (course?.id) {
      setGroup(defaultSelectValue);
      onFetchHomeworkGroups(course.id);
    }
  };

  const handleGroupChange = (id: number, name: string) => {
    setGroup({ id, name });
    if (group?.id) {
      setCourse(defaultSelectValue);
      onFetchHomeworkCourses(group.id);
    }
  };

  useEffect(() => {
    setFilter({
      course: course.id || null,
      student: student || null,
      group: group.id || null,
      deadline: deadline || null,
      status: status.id || null,
    });
  }, [course, student, group, deadline, status]);

  useEffect(() => {
    onFetchHandbooks();
    onFetchHomeworkCourses();
    onFetchHomeworkGroups();
  }, []);

  return (
    <div>
      <button
        className="flex lg:hidden items-center justify-center w-full p-2 space-x-2 border text-current border-white border-opacity-30 rounded-xl"
        onClick={onToggleOpen}
      >
        <FilterIcon />
        <span>Фильтр</span>
      </button>

      <div ref={filterRef} className={filterClass}>
        <div className="w-full flex flex-col gap-2 md:flex-row md:flex-wrap">
          <div className="w-full md:w-fit md:max-w-[290px]">
            <LMSInput
              name="student"
              placeholder="ФИО"
              variant="rounded"
              value={student}
              onChange={(e: string) => setStudent(e)}
            />
          </div>
          <div className="w-full md:w-fit md:max-w-[290px]">
            <DropdownSelect
              notRounded={false}
              title={'Курс'}
              value={course.name}
              list={homeworkCourses.map(({ value, label }) => ({ id: value, name: label }))}
              onCourseClick={handleCourseChange}
            />
          </div>
          <div className="w-full md:w-fit md:max-w-[290px]">
            <DropdownSelect
              notRounded={false}
              title={'Группа'}
              value={group.name}
              list={homeworkGroups.map(({ value, label }) => ({ id: value, name: label }))}
              onCourseClick={handleGroupChange}
            />
          </div>
          <div className="training-base-datepicker w-full flex flex-row md:w-fit md:max-w-[290px]">
            <DatePicker
              selected={deadline}
              placeholderText="Дата дедлайна"
              locale="ru"
              onChange={(date) => setDeadline(date)}
              className="text-black"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
            <CalendarIcon className="ml-[-44px] mt-[14px] text-current lg:ml-[-30px]" />
          </div>
          <div className="w-full md:w-fit md:max-w-[290px]">
            <DropdownSelect
              notRounded={false}
              title={'Cтатус'}
              value={status.name}
              list={homeworkStatuses.map(({ value, label }) => ({ id: value, name: label }))}
              onCourseClick={handleStatusChange}
            />
          </div>
        </div>
        <div className="w-full flex flex-col gap-4 mt-4 md:flex-row">
          {device === 'desktop' ? (
            <>
              <button
                type="button"
                className="w-fit flex-row gap-x-2 text-current md:flex"
                onClick={submitFilters}
              >
                &#10003; <span className="underline underline-offset-4">Применить</span>
              </button>
              <button
                type="button"
                className="w-fit flex-row gap-x-2 text-current md:flex"
                onClick={resetFilters}
              >
                x <span className="underline underline-offset-4">Сбросить фильтры</span>
              </button>
            </>
          ) : (
            <>
              <LMSButton className="w-full mt-5" colorType="light" onClick={submitFilters}>
                Применить
              </LMSButton>
              <LMSButton
                className="w-full"
                variant="text"
                prefix={<CloseIcon className="w-5 h-5" />}
                onClick={resetFilters}
              >
                Сбросить
              </LMSButton>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CheckedHomeworksFilter;
