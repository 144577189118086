import { useSearchParams } from 'react-router-dom';
import { usePartialState } from 'hooks/partial-state';

interface Filter {
  course_id: number | null;
  student_type: string | number | null;
}

export const useStudentFilter = () => {
  const [query, setQuery] = useSearchParams();

  const courseId = query.get('course_id');
  const studentType = query.get('student_type');

  const [filter, setFilter] = usePartialState<Filter>({
    course_id: courseId ? +courseId : null,
    student_type: studentType === 'external' ? 0 : studentType === 'internal' ? 1 : null,
  });

  const onSubmit = () => {
    Object.entries(filter).forEach(([key, value]) => {
      if (key && value !== null) {
        if (key === 'student_type') {
          query.set(key, value ? 'internal' : 'external');
        } else {
          query.set(key, value.toString());
        }
      } else {
        query.delete(key);
      }
    });

    query.set('page', '1');

    setQuery(query);
  };

  const onReset = () => {
    setFilter({
      course_id: null,
      student_type: null,
    });

    Object.entries(filter).forEach(([key]) => {
      query.delete(key);
    });

    query.set('page', '1');

    setQuery(query);
  };

  return {
    filter,
    setFilter,
    onSubmit,
    onReset,
  };
};
