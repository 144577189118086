import React from 'react';
import Accordion from 'components/Accordion';
import { UserDetail } from 'modules/hr/entities/User';

interface Props {
  user: UserDetail;
}

const COACH_TYPES: { [key: string]: string } = {
  inner: 'Внутренний',
  outer: 'Внешний',
};

const UserProfessionalData: React.FC<Props> = ({ user }) => (
  <Accordion title="Профессиональные данные">
    <div className="flex flex-col space-y-3">
      {user.type && (
        <div className="flex flex-row rounded-2xl bg-white/10 p-5">
          <p className="w-1/2 text-white">Тип тренера:</p>
          <p className="w-1/2 text-white font-medium truncate">{COACH_TYPES[user.type]}</p>
        </div>
      )}
      {user.status && (
        <div className="flex flex-row rounded-2xl bg-white/10 p-5">
          <p className="w-1/2 text-white">Статус:</p>
          <p className="w-1/2 text-white font-medium truncate">{user.status}</p>
        </div>
      )}
      {user.specialization && (
        <div className="flex flex-row rounded-2xl bg-white/10 p-5">
          <p className="w-1/2 text-white">Специализация:</p>
          <p className="w-1/2 text-white font-medium truncate">{user.specialization}</p>
        </div>
      )}
      {user.salary && (
        <div className="flex flex-row rounded-2xl bg-white/10 p-5">
          <p className="w-1/2 text-white">Гонорар:</p>
          <p className="w-1/2 text-white font-medium truncate">{(+user.salary).toLocaleString()}</p>
        </div>
      )}
    </div>
  </Accordion>
);

export default UserProfessionalData;
