import { useTranslation } from 'react-i18next';
import { toast, ToastOptions } from 'react-toastify';

export const useNotification = () => {
  const { t } = useTranslation();

  const toastOptions: ToastOptions = {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
  };

  const success = (message?: string) =>
    toast.success(message || (t('message.success') as string), toastOptions);

  const error = (message: string) => toast.error(message, toastOptions);

  return {
    success,
    error,
  };
};
