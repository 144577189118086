import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { ReactComponent as AddIcon } from 'assets/icons/add.svg';
import { ReactComponent as ArrowBack } from 'assets/icons/chevron-left.svg';
import LMSTitle from 'components/LMSTitle';
import DetailHomeWorkCard from 'modules/coach/components/HomeWork/DetailHomeWorkCard';
import DetailLessonWithStudents from 'modules/coach/components/HomeWork/DetailLessonWithStudents';
import GroupStudentsTable from 'modules/coach/components/HomeWork/GroupStudentsTable';
import { IHomeWorkGroups } from 'modules/coach/entities/HomeWorksGroups';

import { fetchHomeWorkGroupsLesson } from '../../api/repository/homework-groups-lesson';

const HomeWorksGroups = () => {
  const { id } = useParams();
  const [homeWorkGroups, setHomeWorkGroups] = useState<IHomeWorkGroups>();
  const navigate = useNavigate();

  useEffect(() => {
    fetchHomeWorkGroupsLesson(Number(id)).then((res) => {
      setHomeWorkGroups(res);
    });
  }, [id]);

  const goBack = () => {
    navigate('/coach/homework');
  };

  return (
    <div className="relative w-full mt-20 lg:mt-0">
      <div className="flex flex-col pt-6 overflow-y-auto">
        <button
          type="button"
          onClick={() => goBack()}
          className="w-fit flex flex-row items-center justify-center gap-x-2 py-3  mt-4 pl-3 pr-5 text-white text-[14px] font-medium bg-white bg-opacity-20 rounded-[36px] border-none outline-none uppercase"
        >
          <ArrowBack />
          назад
        </button>
        <div className="flex flex-col gap-4 justify-between pt-4 md:flex-row 2xl:max-w-[80%]">
          <LMSTitle tag="h4" className="max-w-[600px] mt-auto text-white text-3xl">
            {homeWorkGroups?.courseName}
          </LMSTitle>
          <NavLink
            to="/coach/homework/new-homework"
            className="h-fit w-fit flex flex-row items-center justify-center gap-x-2 py-3 px-5 text-white text-[12px] font-bold uppercase bg-[#26A7EF] rounded-[30px] hover:text-white"
          >
            <AddIcon />
            НОВОЕ ЗАДАНИЕ
          </NavLink>
        </div>
        <div className="flex flex-col gap-y-6 pt-10 2xl:max-w-[70%]">
          {homeWorkGroups && (
            <DetailHomeWorkCard title={homeWorkGroups.name}>
              {homeWorkGroups.lessons &&
                homeWorkGroups.lessons
                  .filter((lessons) => !lessons.isTest)
                  .map((lesson, idx) => {
                    return (
                      <DetailLessonWithStudents
                        key={idx}
                        idx={idx}
                        name={lesson.name}
                        passed={Number(lesson.studentsPassed)}
                        studentsCount={Number(lesson.studentsNumber)}
                      >
                        <GroupStudentsTable students={lesson.students} lessonId={lesson.id} />
                      </DetailLessonWithStudents>
                    );
                  })}
            </DetailHomeWorkCard>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomeWorksGroups;
