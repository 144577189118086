export default {
  change: 'Изменить',
  delete: 'Удалить',
  find: 'Найти',
  reset: 'Сбросить',
  save: 'Сохранить',
  edit: 'Редактировать',
  cancel: 'Отменить',
  accept: 'Согласен',
  login: 'Вход',
  logout: 'Выйти',
  signup: 'Создать аккаунт',
  signin: 'Авторизоваться',
  next: 'Далее',
  seekManufacturer: 'Ищу производителя',
  seekProduct: 'Ищу товар',
  back: 'Назад',
  forward: 'Вперед',
  becomeAPartner: 'Стать партнёром',
  contactWithManufacturer: 'Связаться с производителем',
  uploadFile: 'Загрузить файл',
  sendRequest: 'Отправить заявку',
  importProducts: 'Импорт товаров',
  addProduct: 'Добавить товар',
  continue: 'Продолжить',
  addPrice: 'Добавить цену',
  removePrice: 'Удалить цену',
  saveChanges: 'Сохранить изменения',
  leaveAsDraft: 'Оставить как черновик',
  close: 'Закрыть',
  ready: 'Готово',
  addBranches: 'Добавить филиалы',
  resendActivationCode: 'Отправить код повторно',
  sendForVerification: 'Отправить на проверку',
  deleteAccount: 'Удалить аккаунт',
  changePassword: 'Сменить пароль',
  hide: 'Скрыть',
  expand: 'Раскрыть',
  orderProduct: 'Заказать товар',
  writeToManufacturer: 'Написать производителю',
  goToCatalog: 'Перейти в каталог',
  confirm: 'Согласен',
  yes: 'Да',
  no: 'Нет',
  selectChat: 'Выберите чат',
  signIn: 'Войти',
  recover: 'Восстановить',
  create: 'Создать',
  uploadNewPhoto: 'Загрузить новое фото',
  verifyCompany: 'Верифицировать компанию',
};
