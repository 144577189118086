import request from 'api/axios-instance';

import { CoachTest } from '../../entities/Test';
import { CoachTestDao } from '../dao/Test.dao';
import { CoachCurrentTestDao } from '../dao/Test.dao';

export const fetchCoachTests = () =>
  request.get('v1/coach-tests').then((res) => {
    const tests: CoachCurrentTestDao[] = res.data.data;
    return tests;
  });

interface TestDto {
  deadline: string;
  lesson_id: number;
  is_last: boolean;
  questions: {
    name: string;
    answers: {
      name: string;
      is_correct: boolean;
    }[];
  }[];
}

export const createCoachTests = (data: TestDto) => request.post('v1/coach-tests', data);

// function mapCoachTestDaoToEntity(test: CoachTestDao): CoachTest {
//   return {
//     id: test.id,
//     name: test.name,
//     courseId: test.course_id,
//     modules: test.modules.map((module) => ({
//       id: module.id,
//       title: module.title,
//       courseId: module.course_id,
//       description: module.description,
//       kineScopeModuleId: module.kine_scope_module_id,
//       slug: module.slug,
//       lessons: module.lessons.map((lesson) => ({
//         id: lesson.id,
//         name: lesson.name,
//         description: lesson.description,
//         position: lesson.position,
//         canAddTest: lesson.can_add_test,
//         isTest: lesson.is_test,
//       })),
//     })),
//     testsNumber: test.tests_number,
//     tests: test.tests.map((t) => ({
//       id: t.id,
//       name: t?.name,
//       deadline: t.deadline,
//       timeAmount: t.time_amount,
//     })),
//   };
// }
