import React, { useEffect, useState } from 'react';

interface IProps {
  duration: number;
  onCallback: () => void;
}

const TimerProgressBar = ({ duration, onCallback }: IProps) => {
  const [progress, setProgress] = useState(100);

  useEffect(() => {
    const decrement = 100 / duration;
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress <= 0) {
          clearInterval(interval);
          setTimeout(() => onCallback(), 300);
          return 0;
        }
        return prevProgress - decrement;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="w-full bg-[#F4F4F4] rounded">
      <div
        className="h-[14px] bg-[#26A7EF] rounded"
        style={{
          width: `${progress}%`,
          transition: 'width 1s linear',
        }}
      ></div>
    </div>
  );
};

export default TimerProgressBar;
