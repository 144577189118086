import React from 'react';
import CoachCard from 'modules/hr/components/CoachCard';

import { Coach } from '../../entities/Coaches';

interface Props {
  coaches: Coach[];
}

const CoachTable: React.FC<Props> = ({ coaches }) => {
  return (
    <table className="bg-coachTableGradient w-full rounded-[20px] backdrop-blur-[60px] min-w-[980px]">
      <thead className="block px-8 pt-4 pb-[10px] w-full bg-darkGray">
        <tr className="grid grid-cols-coachTable w-full gap-x-8">
          <td>ТРЕНЕР</td>
          <td>ЛОГИН</td>
          <td>E-MAIL</td>
          <td>ТЕЛЕФОН</td>
        </tr>
      </thead>

      <tbody>
        {coaches.length > 0 &&
          coaches.map((coach) => (
            <tr
              className="grid grid-cols-coachTable w-full px-8 pt-4 pb-[10px] gap-x-8 border-t-[1px] border-coachTableBorder "
              key={coach.id}
            >
              <td>
                <CoachCard info={coach} />
              </td>
              <td className="w-full flex items-center">
                <span className="w-full max-w-full truncate">{coach.login}</span>
              </td>
              <td className="w-full flex items-center">
                <a
                  className="w-full max-w-full truncate text-white cursor-pointer"
                  href={'mailto:' + coach.email}
                >
                  {coach.email}
                </a>
              </td>
              <td className="w-full flex items-center">
                <a className="w-full text-white cursor-pointer" href={'tel:' + coach.phoneNumber}>
                  {coach.phoneNumber}
                </a>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default CoachTable;
