import React, { useState } from 'react';
import { FC } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import { ReactComponent as ArrowBack } from 'assets/icons/chevron-left.svg';
import { ReactComponent as RightIcon } from 'assets/icons/chevron-right.svg';

type Props = {
  fileLink: string;
};

const PDFView: FC<Props> = ({ fileLink }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState<null | number>(null);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const goToPrevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };
  const goToNextPage = () => {
    if (numPages && pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  return (
    <div className="relative max-w-[1000px] w-full">
      <div className="absolute top-2/4 left-0 flex flex-row w-full justify-between gap-4 z-10">
        <button
          disabled={pageNumber === 1}
          onClick={() => goToPrevPage()}
          className="w-[32px] h-[32px] flex flex-row justify-center items-center cursor-pointer theme-background--grey"
        >
          <ArrowBack />
        </button>
        <button
          disabled={pageNumber === numPages}
          onClick={() => goToNextPage()}
          className="w-[32px] h-[32px] flex flex-row justify-center items-center cursor-pointer theme-background--grey"
        >
          <RightIcon />
        </button>
      </div>

      <div className="w-full h-[560px]">
        <Document file={fileLink} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} width={1000} height={800} />
        </Document>
      </div>

      <span className="absolute bottom-[-30px] left-[42%] z-10 theme-text--default">
        Страница {pageNumber} из {numPages}
      </span>
    </div>
  );
};

export default PDFView;
