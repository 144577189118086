import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';

import { fetchBookById } from '../../api/repository/library';
import Header from '../../components/Header';
import Loading from '../../components/Loading';
import Title from '../../components/Title';
import { Book } from '../../entities/Book';

const BookDetail = () => {
  const { id } = useParams();
  const [book, setBook] = useState<Book | null>(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (!id) return;
    setLoading(true);
    fetchBookById(+id)
      .then((res) => {
        setBook(res);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  const badgeClass =
    'flex items-center mb-2 px-5 py-2 theme-background--grey text-xs md:text-base rounded-full truncate overflow-hidden';

  return (
    <>
      <Header />
      {isLoading ? (
        <div className="mt-20">
          <Loading theme="dark" />
        </div>
      ) : null}
      {book && !isLoading ? (
        <div className="w-full px-4 pt-12 md:pt-20">
          <div className="grid grid-cols-1 md:grid-cols-[1.5fr_1fr] gap-5">
            <div className="flex flex-col p-4 md:p-10 rounded-[30px] theme-student-card">
              <div className="flex justify-between items-center">
                {book.category?.label && (
                  <div className="flex space-x-2">
                    <p className={badgeClass}>{book.category.label}</p>
                  </div>
                )}
                <p className="text-base md:text-xl theme-light-text">{book.publishedAt}</p>
              </div>

              <h2 className="mt-16 lg:mt-20 text-[40px] lg:text-[50px] leading-[53px] lg:leading-[67px]">
                {book.name}
              </h2>

              <div className="flex-1 flex items-end mt-8">
                {book.filesUrl.map((file) => (
                  <a
                    key={file.extension}
                    href={file.downloadLink}
                    className="flex items-center space-x-2 py-2 px-4 border border-solid border-white rounded-full theme-text--default cursor-pointer theme-text-hover-effect"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <UploadIcon /> <span>{file.extension}</span>
                  </a>
                ))}
              </div>
            </div>
            <div className="flex flex-col space-y-5">
              <div className="max-h-[340px] md:max-h-[450px] rounded-3xl theme-student-card overflow-hidden">
                <img className="w-full h-full object-contain" src={book.imageUrl} alt={book.name} />
              </div>

              {(book.author || book.publisher) && (
                <div className="px-6 py-7 rounded-2xl theme-student-card">
                  {book.author && (
                    <p className="font-semibold text-xl md:text-2xl">{book.author.label}</p>
                  )}
                  {book.publisher && (
                    <>
                      <p className="mt-4 md:mt-5 md:text-xl theme-light-text">Издательство:</p>
                      <p className="md:text-xl">{book.publisher.label}</p>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="mt-16 md:mt-32">
            <Title>Описание книги</Title>
            <div className="mt-5 md:mt-12 p-5 md:p-10 rounded-2xl theme-student-card">
              <p className="md:text-2xl">{book.description}</p>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default BookDetail;
