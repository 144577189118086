import React from 'react';
import { NavLink } from 'react-router-dom';

const UserTabs = () => {
  return (
    <div className="flex justify-center items-end">
      <NavLink
        to="/hr/users/coaches"
        className={({ isActive }) => {
          return `
            flex-1 hover:text-white border-b-2 text-xl text-center py-4
            ${isActive ? 'text-white border-white' : 'text-white/50 border-white/30'}
          `;
        }}
        end
      >
        База тренеров
      </NavLink>
      <NavLink
        to="/hr/users/students"
        className={({ isActive }) => {
          return `
            flex-1 hover:text-white border-b-2 text-xl text-center py-4
            ${isActive ? 'text-white border-white' : 'text-white/50 border-white/30'}
          `;
        }}
        end
      >
        База студентов
      </NavLink>
      <NavLink
        to="/hr/users/managers"
        className={({ isActive }) => {
          return `
            flex-1 hover:text-white border-b-2 text-xl text-center py-4
            ${isActive ? 'text-white border-white' : 'text-white/50 border-white/30'}
          `;
        }}
        end
      >
        Менеджеры
      </NavLink>
    </div>
  );
};

export default UserTabs;
