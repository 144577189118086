import React, { useMemo, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const SatisfactionChart = () => {
  const [satisfactions, setSatisfactions] = useState([
    { id: 1, title: 'Отлично!', percentage: 0, color: '#58DB66' },
    { id: 2, title: 'Хорошо', percentage: 0, color: '#26A7EF' },
    { id: 3, title: 'Средне', percentage: 0, color: '#EFB826' },
    { id: 4, title: 'Тек себе', percentage: 0, color: '#FF498F' },
    { id: 5, title: 'Очень плохо', percentage: 0, color: '#FF5449' },
  ]);

  const chartData = useMemo(() => {
    const data = {
      datasets: [
        {
          label: 'Удовлетворенность',
          data: satisfactions.map((p) => p.percentage),
          backgroundColor: satisfactions.map((p) => p.color),
          hoverOffset: 4,
          borderWidth: 0,
          cutout: '85%',
          borderRadius: 20,
          offset: 10,
        },
      ],
    };
    return data;
  }, [satisfactions]);

  const options = {
    maintainAspectRatio: false,
    tooltips: {
      enabled: false,
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div className="flex-1 p-5 rounded-xl bg-studentCardBg">
      <p className="text-base md:text-lg">Удовлетворенность</p>
      <div className="flex justify-center">
        <div className="w-[190px] h-[190px] mt-5 mb-10">
          <Doughnut data={chartData} options={options} />
        </div>
      </div>
      {satisfactions.map((item) => (
        <div key={item.id} className="flex items-center justify-between space-x-3 mb-1 text-sm">
          <span className="block w-4 h-1 rounded" style={{ backgroundColor: item.color }} />
          <span className="flex-1 text-white/50">{item.title}</span>
          <span>{item.percentage}%</span>
        </div>
      ))}
    </div>
  );
};

export default SatisfactionChart;
