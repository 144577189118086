import { useState } from 'react';
import { useNotification } from 'hooks/notification';
import { Option } from 'entities/Option';

import {
  fetchHandbookCalendarCourses,
  fetchHandbookCalendarGroups,
  fetchHandbooks,
  fetchHomeworkCourses,
  fetchHomeworkGroups,
} from '../api/repository/handbooks';
import { IHandbooks } from '../entities/Handbooks';

export const useHandbooks = () => {
  const notify = useNotification();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [roles, setRoles] = useState<IHandbooks['roles']>([]);
  const [genders, setGenders] = useState<IHandbooks['genders']>([]);
  const [studyTypes, setStudyTypes] = useState<IHandbooks['studyTypes']>([]);
  const [languages, setLanguages] = useState<IHandbooks['languages']>([]);
  const [coachStatuses, setCoachStatuses] = useState<IHandbooks['coachStatuses']>([]);
  const [homeworkStatuses, setHomeworkStatuses] = useState<IHandbooks['homeworkStatuses']>([]);
  const [homeworkCourses, setHomeworkCourses] = useState<Option[]>([]);
  const [homeworkGroups, setHomeworkGroups] = useState<Option[]>([]);
  const [calendarCourses, setCalendarCourses] = useState<Option[]>([]);
  const [calendarGroups, setCalendarGroups] = useState<Option[]>([]);

  const onFetchHandbooks = () => {
    setLoading(true);
    fetchHandbooks()
      .then((res) => {
        setRoles(res.roles);
        setGenders(res.genders);
        setStudyTypes(res.studyTypes);
        setLanguages(res.languages);
        setCoachStatuses(res.coachStatuses);
        setHomeworkStatuses(res.homeworkStatuses);
      })
      .catch((e) => notify.error(e.message))
      .finally(() => setLoading(false));
  };

  const onFetchHomeworkCourses = (groupId?: number) => {
    setLoading(true);
    fetchHomeworkCourses(groupId)
      .then((res) => setHomeworkCourses(res))
      .catch((e) => notify.error(e.message))
      .finally(() => setLoading(false));
  };

  const onFetchHomeworkGroups = (courseId?: number) => {
    setLoading(true);
    fetchHomeworkGroups(courseId)
      .then((res) => setHomeworkGroups(res))
      .catch((e) => notify.error(e.message))
      .finally(() => setLoading(false));
  };

  const onFetchCalendarCourses = (params?: { [key: string]: string | number }) => {
    setLoading(true);
    fetchHandbookCalendarCourses(params)
      .then((res) => setCalendarCourses(res))
      .catch((e) => notify.error(e.message))
      .finally(() => setLoading(false));
  };

  const onFetchCalendarGroups = (params?: { [key: string]: string | number }) => {
    setLoading(true);
    fetchHandbookCalendarGroups(params)
      .then((res) => setCalendarGroups(res))
      .catch((e) => notify.error(e.message))
      .finally(() => setLoading(false));
  };

  return {
    isLoading,
    roles,
    genders,
    studyTypes,
    languages,
    coachStatuses,
    homeworkStatuses,
    homeworkCourses,
    homeworkGroups,
    calendarCourses,
    calendarGroups,
    onFetchHandbooks,
    onFetchHomeworkCourses,
    onFetchHomeworkGroups,
    onFetchCalendarCourses,
    onFetchCalendarGroups,
  };
};
