import { IHomeTaskStudentInfo } from 'modules/coach/entities/HomeWorkTaskStudent';
import request from 'api/axios-instance';

import { IHomeTaskStudentInfoDao } from '../dao/HomeWorkTaskStudentDao';

export const fetchStudentInfo = (studentId: number, lessonId: number) =>
  request
    .get(`v1/coach/homeworks/mark?lesson_id=${lessonId}&student_id=${studentId}`)
    .then((res) => {
      const studentInfo: IHomeTaskStudentInfo = mapHomeTaskStudentInfoDaoToEntity(res.data.data);

      return studentInfo;
    });

function mapHomeTaskStudentInfoDaoToEntity(info: IHomeTaskStudentInfoDao): IHomeTaskStudentInfo {
  return {
    mark: info.mark,
    testId: info.test_id,
    testScore: info.test_score,
    student: {
      avatar: info.student.avatar,
      id: info.student.id,
      fio: info.student.fio,
      name: info.student.name,
    },
  };
}
