import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import BaseImage from 'components/BaseImage';
import LMSButton from 'components/LMSButton';
import LMSPagination from 'components/LMSPagination';

import AllCoursesFilter from '../../components/AllCoursesFilter';
import Header from '../../components/Header';
import Loading from '../../components/Loading';
import Title from '../../components/Title';
import { useAllCourses } from '../../hooks/all-courses';

const Courses = () => {
  const navigate = useNavigate();
  const { isLoading, courses, lastPage, currentPage, setCurrentPage } = useAllCourses();

  const goToCourseDetail = (id: number) => {
    navigate(`/student/courses/${id}`);
  };

  const badgeClass =
    'flex items-center mb-2 px-5 py-2 theme-badge text-current text-xs md:text-base rounded-full truncate overflow-hidden';

  return (
    <>
      <Header />
      <div className="w-full  px-4 pt-12 md:pt-20">
        <Title className="text-center">Каталог курсов</Title>

        <div className="flex flex-col lg:flex-row lg:space-x-6 mt-10 md:mt-16">
          <div className="hidden">
            <AllCoursesFilter />
          </div>

          <div className="flex-1 flex flex-col space-y-5">
            {isLoading && courses.length === 0 ? <Loading /> : null}
            {!isLoading && courses.length === 0 ? (
              <div className="flex flex-col items-center justify-center h-[460px] rounded-[20px] theme-student-card">
                <p className="text-[30px] text-center leading-10 max-w-[420px] mb-14">
                  Курсы не найдены. Продолжайте поиск, чтобы найти нужный курс
                </p>
                <LMSButton>Продолжить обучение</LMSButton>
              </div>
            ) : null}

            {courses.map((course) => (
              <div
                key={course.id}
                className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-9 p-4 md:p-8 rounded-3xl theme-student-card"
              >
                <div className="relative w-full md:w-[300px] lg:w-[266px] h-[210px] lg:h-[266px] shrink-0 theme-background--grey rounded-2xl overflow-hidden">
                  {/* <img
                    className="w-full h-full object-cover"
                    src={course.imageUrl}
                    alt={course.name}
                  /> */}
                  <NavLink to={`/student/courses/${course.id}`}>
                    <BaseImage image={course.imageUrl} alt={course.name} />
                  </NavLink>
                  {course.studyType === 'inner' ? (
                    <div className="absolute top-3 py-3 pl-4 pr-2 bg-green text-current text-sm rounded-r-full">
                      <p>внутренний курс</p>
                    </div>
                  ) : null}
                </div>
                <div className="flex flex-col flex-1">
                  <div className="flex flex-wrap space-x-2">
                    <p className={badgeClass}>{course.dateFrom || 'Не указан'}</p>
                    <p className={badgeClass}>{course.direction || 'Не указан'}</p>
                    <p className={badgeClass}>{course.courseType || 'Не указан'}</p>
                    {/* <p className={badgeClass}>10 групп</p> */}
                  </div>
                  <NavLink
                    to={`/student/courses/${course.id}`}
                    className=" flex-1 mt-5 mb-5 lg:mt-10 text-2xl theme-text--default"
                  >
                    {course.name}
                  </NavLink>
                  <div className="flex flex-nowrap md:flex-wrap w-full mt-5 md:mt-0 md:mb-2 md:w-auto space-x-2">
                    <LMSButton
                      className="pl-2 pr-2 md:pl-4 md:pr-4"
                      onClick={() => goToCourseDetail(course.id)}
                    >
                      Перейти к курсу
                    </LMSButton>
                    {/* <div className="flex items-center space-x-4 pr-2 theme-background--grey rounded-full overflow-hidden">
                      <div className="w-7 md:w-14 h-7 md:h-14 rounded-full overflow-hidden">
                        <img className="w-full h-full object-cover" src="" alt="" />
                      </div>
                      <p className="max-w-[130px] text-xs md:text-base truncate">
                        Лебакина Алтынай
                      </p>
                    </div> */}
                  </div>
                </div>
              </div>
            ))}

            <LMSPagination
              current={currentPage}
              lastPage={lastPage}
              onChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Courses;
