import React, { useMemo, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const EfficiencyChart = () => {
  const [efficiencies, setEfficiencies] = useState([
    { id: 1, title: 'Высокая эффективность', percentage: 0, color: '#58DB66' },
    { id: 2, title: 'Средняя эффективность', percentage: 0, color: '#58DB6675' },
    { id: 3, title: 'Низкая эффективность', percentage: 0, color: '#58DB6614' },
  ]);

  const chartData = useMemo(() => {
    return {
      datasets: [
        {
          label: 'Эффективность',
          data: efficiencies.map((p) => p.percentage),
          backgroundColor: efficiencies.map((p) => p.color),
          hoverOffset: 4,
          borderWidth: 0,
          cutout: '85%',
          borderRadius: 20,
          offset: 10,
        },
      ],
    };
  }, [efficiencies]);

  const options = {
    maintainAspectRatio: false,
    tooltips: {
      enabled: false,
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  return (
    <div className="flex-1 p-5 rounded-xl bg-studentCardBg">
      <p className="text-base md:text-lg">Эффективность</p>
      <div className="flex justify-center">
        <div className="w-[190px] h-[190px] mt-5 mb-10">
          <Doughnut data={chartData} options={options} />
        </div>
      </div>
      {efficiencies.map((item) => (
        <div key={item.id} className="flex items-center justify-between space-x-3 mb-1 text-sm">
          <span className="block w-4 h-1 rounded" style={{ backgroundColor: item.color }} />
          <span className="flex-1 text-white/50">{item.title}</span>
          <span>{item.percentage}%</span>
        </div>
      ))}
    </div>
  );
};

export default EfficiencyChart;
