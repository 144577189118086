import React from 'react';
import LMSPagination from 'components/LMSPagination';
import Loader from 'components/Loader';
import CheckTestsFilter from 'modules/coach/components/CheckTestsFilter';
import TestsTable from 'modules/coach/components/TestsTable';
import { useHomeworksForCheck } from 'modules/coach/hooks/useHomeworksForCheck';

const CheckTests = () => {
  const { homeworks, isLoading, currentPage, lastPage, setCurrentPage } = useHomeworksForCheck();

  return (
    <div className="flex flex-col pt-6">
      <div className="hidden lg:flex flex-row mb-6 lg:mb-10">
        <CheckTestsFilter />
      </div>

      <div className="block mb-6 lg:hidden">
        <CheckTestsFilter />
      </div>

      {isLoading ? (
        <div className="my-10">
          <Loader />
        </div>
      ) : (
        <>
          {!homeworks || homeworks.length === 0 ? (
            <div className="flex flex-col items-center justify-center h-[360px] rounded-[20px] bg-studentCardBg">
              <p className="text-[30px] text-center leading-10 max-w-[420px] text-white">
                Тестов не найдено. Продолжайте поиск, чтобы найти нужный тест.
              </p>
            </div>
          ) : (
            <>
              <div className="overflow-x-auto">
                <TestsTable tests={homeworks} />
              </div>

              <div className={lastPage > 1 ? 'mt-6' : ''}>
                <LMSPagination
                  current={currentPage}
                  lastPage={lastPage}
                  onChange={setCurrentPage}
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default CheckTests;
