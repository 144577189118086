import React from 'react';
import { ReactComponent as SpinnerIcon } from 'assets/icons/spinner.svg';

type ButtonProps = {
  children: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  loading?: boolean;
};

const CouchButton: React.FC<ButtonProps> = ({
  onClick = () => {},
  disabled = false,
  loading = false,
  children,
}) => {
  return (
    <button
      className="w-full relative inline-flex items-center justify-center mt-[36px] gap-2 py-4 text-white text-base uppercase bg-blue rounded-[55px] cursor-pointer"
      type="submit"
      disabled={disabled || loading}
      onClick={onClick}
    >
      {loading ? (
        <span className="absolute inset-0 flex items-center justify-center z-10">
          <SpinnerIcon />
        </span>
      ) : null}
      {children}
    </button>
  );
};

export default CouchButton;
