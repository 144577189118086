import React, { useEffect, useMemo, useState } from 'react';
import { ReactComponent as ArrowRight } from 'assets/icons/chevron-right.svg';
import { ReactComponent as Finger } from 'assets/icons/finger.svg';
import LMSButton from 'components/LMSButton';
import { sendGameResults, TData } from 'modules/student/api/repository/games';
import { useNotification } from 'hooks/notification';

import { QUESTIONS_EASY, QUESTIONS_HARD, QUESTIONS_MEDIUM } from '../../constants';
import { DifficultLevelType, ICoords, IQuestion } from '../../entities/Pathfinder';
import Timer from '../Timer';

interface Props {
  level: DifficultLevelType;
  startTime: Date;
  nextStep: () => void;
}

const PathfinderGame = ({ level, startTime, nextStep }: Props) => {
  const notify = useNotification();

  const [disabledImage, setDisabledImage] = useState<boolean>(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(1);
  const [foundDifferences, setFoundDifferences] = useState<ICoords[]>([]);
  const [wrongClicks, setWrongClicks] = useState<Record<string, number>[]>([]);
  const [countCorrectAnswers, setCountCorrectAnswers] = useState<number>(0);
  const [clickCount, setClickCount] = useState<number>(0);
  const [failedQuestions, setFailedQuestions] = useState<IQuestion[]>([]);

  const MAX_CLICKS = 10;
  const timer = level === 'easy' ? 300 : level === 'medium' ? 180 : 120;
  const questionsByLevel =
    level === 'easy' ? QUESTIONS_EASY : level === 'medium' ? QUESTIONS_MEDIUM : QUESTIONS_HARD;

  const questions = useMemo(() => {
    return [...questionsByLevel, ...failedQuestions];
  }, [failedQuestions]);

  const sendGameStats = () => {
    const endTime: Date = new Date();
    const time = endTime.getTime() - startTime.getTime();
    const result: TData = {
      game: 'images',
      difficulty: level,
      score: countCorrectAnswers / 5 + 1,
      time,
    };

    sendGameResults(result)
      .then(() => {
        nextStep();
      })
      .catch(() => {
        notify.error('Произошла ошибка. Обратитесь в службу поддержки');
      });
  };

  const handleNextQuestion = (nextQuestionIndex: number, isPassed: boolean) => {
    if (questions.length < nextQuestionIndex && isPassed) {
      sendGameStats();
    } else {
      if (isPassed) {
        setCountCorrectAnswers((prevState) => prevState + foundDifferences.length);
      }
      setDisabledImage(false);
      setFoundDifferences([]);
      setWrongClicks([]);
      setClickCount(0);
      setCurrentQuestionIndex(nextQuestionIndex);
    }
  };

  const handleClickImage = (
    e: React.MouseEvent<HTMLImageElement, MouseEvent>,
    differences: ICoords[]
  ) => {
    if (disabledImage) return;

    if (clickCount >= MAX_CLICKS) {
      notify.error(
        'Вы достигли максимального количества кликов! Вы будете перенесены на следующий уровень.'
      );
      setFailedQuestions((prev) => [...prev, questions[currentQuestionIndex - 1]]);
      setTimeout(() => handleNextQuestion(currentQuestionIndex + 1, false), 2000);
      return;
    }

    const imgElement = e.target as HTMLImageElement;
    const rect = imgElement.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    let isCorrect = false;

    differences.forEach((diff) => {
      if (x >= diff.x - 40 && x <= diff.x + 40 && y >= diff.y - 40 && y <= diff.y + 40) {
        isCorrect = true;
        if (!foundDifferences.some((fd) => fd.x === diff.x && fd.y === diff.y)) {
          setFoundDifferences([...foundDifferences, diff]);
        }
      }
    });

    if (!isCorrect) {
      setWrongClicks([...wrongClicks, { x, y }]);
      setClickCount(clickCount + 1);
    }
  };

  const handleFinishTimer = () => {
    if (foundDifferences.length < 5) {
      setFailedQuestions((prev) => [...prev, questions[currentQuestionIndex - 1]]);
    }
    notify.error('Время вышло! Вы переходите на следующий уровень.');
    setTimeout(() => handleNextQuestion(currentQuestionIndex + 1, false), 2000);
  };

  useEffect(() => {
    if (foundDifferences.length > 4) {
      setDisabledImage(true);
      notify.success('Вы прошли уровень! Чтобы перейти на следующий уровень, нажмите "Далее".');
    }
  }, [foundDifferences]);

  return (
    <div className="flex flex-col w-[80%] max-w-[960px] h-max rounded-[20px] theme-student-card pt-10 px-14 pb-20 m-auto">
      {questions.map((question, index) => {
        return currentQuestionIndex === index + 1 ? (
          <div key={index} className="flex flex-col space-y-8">
            <div className="w-full flex flex-row justify-between items-center">
              <p className="font-base opacity-50">Задание №{question.id}</p>
              <Timer time={timer} className="!text-[16px]" onClose={handleFinishTimer} />
            </div>
            <div className="flex flex-col items-center max-w-[800px] w-full mx-auto">
              <p className="max-w-[450px] text-[24px] font-semibold text-center leading-8 mb-4">
                Внимательно сравните обе картинки и найдите все отличия между ними
              </p>
              <p className="text-[18px] text-center mb-7">
                Картинка слева правильная, а на картинке справа найдите 5 отличий.
              </p>
              <div className="mb-4 relative">
                <img
                  className="w-full h-auto object-cover object-center"
                  alt={`pathfinder-${question.id}`}
                  src={question.image}
                  onClick={(e) => handleClickImage(e, question.coords)}
                />
                {foundDifferences.map((diff, index) => (
                  <div
                    key={index}
                    className="bg-transparent absolute w-[60px] h-[60px] border-4 border-green rounded-full"
                    style={{ top: diff.y - 30, left: diff.x - 30 }}
                  />
                ))}
                {wrongClicks.map((diff, index) => (
                  <div
                    key={index}
                    className="bg-transparent absolute w-[60px] h-[60px] border-4 border-red-500 rounded-full"
                    style={{ top: diff.y - 30, left: diff.x - 30 }}
                  />
                ))}
              </div>
              <div className="flex flex-row items-center justify-center theme-text--active space-x-2 mb-10">
                <Finger />
                <p className="text-[18px]">Нажмите на точку на картинке, где вы нашли отличие.</p>
              </div>
              <LMSButton
                className="self-center"
                suffix={<ArrowRight />}
                disabled={foundDifferences.length < 5}
                onClick={() => handleNextQuestion(index + 1 + 1, true)}
              >
                Далее
              </LMSButton>
            </div>
          </div>
        ) : null;
      })}
    </div>
  );
};

export default PathfinderGame;
