import React from 'react';

interface Props {
  theme?: 'dark' | 'light';
}

const Loader: React.FC<Props> = ({ theme = 'light' }) => {
  return (
    <div className="flex justify-center">
      <div
        className={`animate-spin w-10 h-10 border-4 rounded-full border-l-transparent border-r-transparent ${
          theme === 'light' ? 'border-t-white border-b-white' : 'border-t-black border-b-black'
        }`}
      />
    </div>
  );
};

export default Loader;
