export const formatDate = (inputDate: Date, divide: string) => {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}${divide}${month}${divide}${day}`;
};

// export const getFormattedDateWithTime = (date: string): string => {
//   const formattedDate = new Date(date).toLocaleString('ru-RU', {
//     year: 'numeric',
//     month: '2-digit',
//     day: '2-digit',
//     hour: '2-digit',
//     minute: '2-digit',
//     second: '2-digit',
//   });

//   return formattedDate.replace(',', '');
// };
export const getFormattedDateWithTime = (date: string): string => {
  const dateObject = new Date(date);
  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Month is zero-based
  const day = String(dateObject.getDate()).padStart(2, '0');
  const hours = String(dateObject.getHours()).padStart(2, '0');
  const minutes = String(dateObject.getMinutes()).padStart(2, '0');
  const sec = String(dateObject.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${sec}`;
};

export function getDateTime(strDate: string, time: string) {
  const date = parseDate(strDate, 'dd.mm.yyyy');
  if (date != null) {
    return `${parseDateToString(date, 'yyyy-mm-dd')} ${time.slice(0, 5)}`;
  }
}

export function getWeekDay(strDate: string): number | null {
  const date = parseDate(strDate, 'yyyy-mm-dd');
  return date ? date.getDate() : null;
}

export const parseDate = (input: string, format: string): Date | null => {
  format = format || 'yyyy.mm.dd';
  const parts = input.match(/(\d+)/g);
  const fmt: { [key: string]: number } = {};
  let i = 0;

  if (parts) {
    format.replace(/(yyyy|dd|mm)/g, (part) => {
      fmt[part] = i++;
      return part;
    });

    if (
      typeof fmt.yyyy !== 'undefined' &&
      typeof fmt.mm !== 'undefined' &&
      typeof fmt.dd !== 'undefined'
    ) {
      const year = Number(parts[fmt.yyyy]);
      const month = Number(parts[fmt.mm]) - 1;
      const day = Number(parts[fmt.dd]);

      if (!isNaN(year) && !isNaN(month) && !isNaN(day)) {
        return new Date(year, month, day);
      }
    }
  }

  return null;
};

export function parseDateToString(date: Date, format: string) {
  return format.replace(/yyyy|mm|dd/g, (part) => {
    const dateObj: { [key: string]: string } = {
      yyyy: String(date.getFullYear()),
      mm: String(date.getMonth() + 1).padStart(2, '0'),
      dd: String(date.getDate()).padStart(2, '0'),
    };
    return dateObj[part] || part;
  });
}
