import { IQuestion } from '../entities/Pathfinder';

export const QUESTIONS_EASY: IQuestion[] = [
  {
    id: 1,
    image: require('assets/images/pathfinder/pathfinder1.png'),
    coords: [
      { x: 665, y: 30 },
      { x: 597, y: 246 },
      { x: 723, y: 325 },
      { x: 648, y: 140 },
      { x: 631, y: 91 },
    ],
  },
  {
    id: 2,
    image: require('assets/images/pathfinder/pathfinder2.png'),
    coords: [
      { x: 654, y: 28 },
      { x: 423, y: 207 },
      { x: 523, y: 226 },
      { x: 541, y: 327 },
      { x: 646, y: 384 },
      { x: 648, y: 242 },
    ],
  },
  {
    id: 3,
    image: require('assets/images/pathfinder/pathfinder3.png'),
    coords: [
      { x: 466, y: 101 },
      { x: 711, y: 157 },
      { x: 574, y: 148 },
      { x: 440, y: 325 },
      { x: 684, y: 363 },
    ],
  },
  {
    id: 4,
    image: require('assets/images/pathfinder/pathfinder5.png'),
    coords: [
      { x: 731, y: 56 },
      { x: 609, y: 171 },
      { x: 778, y: 211 },
      { x: 619, y: 316 },
      { x: 548, y: 315 },
      { x: 567, y: 78 },
    ],
  },
  {
    id: 5,
    image: require('assets/images/pathfinder/pathfinder7.png'),
    coords: [
      { x: 603, y: 46 },
      { x: 639, y: 198 },
      { x: 657, y: 310 },
      { x: 683, y: 369 },
      { x: 579, y: 265 },
    ],
  },
  {
    id: 6,
    image: require('assets/images/pathfinder/pathfinder8.png'),
    coords: [
      { x: 570, y: 119 },
      { x: 739, y: 178 },
      { x: 621, y: 277 },
      { x: 715, y: 361 },
      { x: 531, y: 313 },
      { x: 576, y: 160 },
    ],
  },
  {
    id: 7,
    image: require('assets/images/pathfinder/pathfinder6.png'),
    coords: [
      { x: 450, y: 58 },
      { x: 575, y: 25 },
      { x: 471, y: 185 },
      { x: 717, y: 361 },
      { x: 761, y: 147 },
      { x: 598, y: 236 },
      { x: 620, y: 209 },
      { x: 429, y: 199 },
      { x: 681, y: 372 },
    ],
  },
  {
    id: 8,
    image: require('assets/images/pathfinder/pathfinder9.png'),
    coords: [
      { x: 460, y: 55 },
      { x: 465, y: 153 },
      { x: 645, y: 155 },
      { x: 542, y: 248 },
      { x: 759, y: 341 },
      { x: 645, y: 333 },
    ],
  },
  {
    id: 9,
    image: require('assets/images/pathfinder/pathfinder10.png'),
    coords: [
      { x: 458, y: 186 },
      { x: 565, y: 43 },
      { x: 641, y: 121 },
      { x: 708, y: 123 },
      { x: 782, y: 276 },
    ],
  },
  {
    id: 10,
    image: require('assets/images/pathfinder/pathfinder16.png'),
    coords: [
      { x: 602, y: 104 },
      { x: 568, y: 208 },
      { x: 776, y: 385 },
      { x: 461, y: 301 },
      { x: 569, y: 269 },
      { x: 636, y: 357 },
    ],
  },
  {
    id: 11,
    image: require('assets/images/pathfinder/pathfinder21.jpg'),
    coords: [
      { x: 658, y: 54 },
      { x: 517, y: 40 },
      { x: 603, y: 152 },
      { x: 564, y: 218 },
      { x: 592, y: 270 },
    ],
  },
  {
    id: 12,
    image: require('assets/images/pathfinder/pathfinder24.png'),
    coords: [
      { x: 476, y: 221 },
      { x: 747, y: 30 },
      { x: 702, y: 272 },
      { x: 465, y: 516 },
      { x: 447, y: 426 },
    ],
  },
  {
    id: 13,
    image: require('assets/images/pathfinder/pathfinder30.jpg'),
    coords: [
      { x: 745, y: 68 },
      { x: 704, y: 191 },
      { x: 752, y: 324 },
      { x: 589, y: 280 },
      { x: 507, y: 152 },
    ],
  },
  {
    id: 14,
    image: require('assets/images/pathfinder/pathfinder31.jpg'),
    coords: [
      { x: 663, y: 69 },
      { x: 557, y: 124 },
      { x: 602, y: 205 },
      { x: 669, y: 273 },
      { x: 539, y: 283 },
    ],
  },
  {
    id: 15,
    image: require('assets/images/pathfinder/pathfinder40.png'),
    coords: [
      { x: 522, y: 340 },
      { x: 672, y: 252 },
      { x: 584, y: 206 },
      { x: 533, y: 67 },
      { x: 541, y: 166 },
    ],
  },
  {
    id: 16,
    image: require('assets/images/pathfinder/pathfinder32.jpg'),
    coords: [
      { x: 750, y: 202 },
      { x: 601, y: 377 },
      { x: 603, y: 155 },
      { x: 523, y: 151 },
      { x: 447, y: 341 },
      { x: 663, y: 29 },
    ],
  },
  {
    id: 17,
    image: require('assets/images/pathfinder/pathfinder33.jpg'),
    coords: [
      { x: 603, y: 49 },
      { x: 655, y: 193 },
      { x: 672, y: 323 },
      { x: 584, y: 335 },
      { x: 534, y: 323 },
      { x: 724, y: 135 },
      { x: 636, y: 41 },
      { x: 558, y: 296 },
    ],
  },
  {
    id: 18,
    image: require('assets/images/pathfinder/pathfinder34.jpg'),
    coords: [
      { x: 664, y: 142 },
      { x: 542, y: 52 },
      { x: 469, y: 257 },
      { x: 496, y: 315 },
      { x: 568, y: 255 },
    ],
  },
  {
    id: 19,
    image: require('assets/images/pathfinder/pathfinder35.jpg'),
    coords: [
      { x: 575, y: 116 },
      { x: 725, y: 167 },
      { x: 442, y: 224 },
      { x: 649, y: 201 },
      { x: 632, y: 137 },
    ],
  },
  {
    id: 20,
    image: require('assets/images/pathfinder/pathfinder36.jpg'),
    coords: [
      { x: 599, y: 151 },
      { x: 724, y: 211 },
      { x: 462, y: 210 },
      { x: 597, y: 370 },
      { x: 568, y: 123 },
      { x: 626, y: 122 },
      { x: 699, y: 370 },
    ],
  },
];

export const QUESTIONS_MEDIUM: IQuestion[] = [
  {
    id: 1,
    image: require('assets/images/pathfinder/pathfinder4.jpeg'),
    coords: [
      { x: 450, y: 378 },
      { x: 690, y: 452 },
      { x: 631, y: 25 },
      { x: 537, y: 65 },
      { x: 474, y: 227 },
    ],
  },
  {
    id: 2,
    image: require('assets/images/pathfinder/pathfinder17.png'),
    coords: [
      { x: 609, y: 283 },
      { x: 758, y: 200 },
      { x: 556, y: 118 },
      { x: 526, y: 263 },
      { x: 582, y: 236 },
    ],
  },
  {
    id: 3,
    image: require('assets/images/pathfinder/pathfinder18.png'),
    coords: [
      { x: 668, y: 197 },
      { x: 449, y: 354 },
      { x: 640, y: 87 },
      { x: 543, y: 91 },
      { x: 541, y: 239 },
    ],
  },
  {
    id: 4,
    image: require('assets/images/pathfinder/pathfinder23.jpeg'),
    coords: [
      { x: 533, y: 202 },
      { x: 620, y: 225 },
      { x: 543, y: 317 },
      { x: 622, y: 292 },
      { x: 739, y: 280 },
    ],
  },
  {
    id: 5,
    image: require('assets/images/pathfinder/pathfinder26.webp'),
    coords: [
      { x: 588, y: 24 },
      { x: 479, y: 140 },
      { x: 477, y: 211 },
      { x: 600, y: 330 },
      { x: 790, y: 173 },
    ],
  },
  {
    id: 6,
    image: require('assets/images/pathfinder/pathfinder27.jpeg'),
    coords: [
      { x: 519, y: 112 },
      { x: 587, y: 166 },
      { x: 555, y: 226 },
      { x: 497, y: 391 },
      { x: 765, y: 409 },
    ],
  },
  {
    id: 7,
    image: require('assets/images/pathfinder/pathfinder28.png'),
    coords: [
      { x: 519, y: 112 },
      { x: 612, y: 159 },
      { x: 634, y: 311 },
      { x: 708, y: 298 },
      { x: 614, y: 117 },
    ],
  },
  {
    id: 8,
    image: require('assets/images/pathfinder/pathfinder37.jpeg'),
    coords: [
      { x: 716, y: 88 },
      { x: 732, y: 153 },
      { x: 637, y: 149 },
      { x: 512, y: 197 },
      { x: 431, y: 277 },
      { x: 523, y: 361 },
      { x: 771, y: 309 },
      { x: 750, y: 361 },
    ],
  },
  {
    id: 9,
    image: require('assets/images/pathfinder/pathfinder38.png'),
    coords: [
      { x: 685, y: 102 },
      { x: 500, y: 161 },
      { x: 487, y: 306 },
      { x: 650, y: 290 },
      { x: 636, y: 175 },
    ],
  },
  {
    id: 10,
    image: require('assets/images/pathfinder/pathfinder39.png'),
    coords: [
      { x: 546, y: 185 },
      { x: 660, y: 229 },
      { x: 551, y: 372 },
      { x: 784, y: 271 },
      { x: 465, y: 276 },
    ],
  },
];

export const QUESTIONS_HARD: IQuestion[] = [
  {
    id: 1,
    image: require('assets/images/pathfinder/pathfinder11.jpeg'),
    coords: [
      { x: 727, y: 156 },
      { x: 751, y: 233 },
      { x: 443, y: 500 },
      { x: 768, y: 377 },
      { x: 500, y: 284 },
    ],
  },
  {
    id: 2,
    image: require('assets/images/pathfinder/pathfinder12.jpeg'),
    coords: [
      { x: 661, y: 60 },
      { x: 604, y: 422 },
      { x: 690, y: 443 },
      { x: 477, y: 425 },
      { x: 490, y: 157 },
    ],
  },
  {
    id: 3,
    image: require('assets/images/pathfinder/pathfinder13.png'),
    coords: [
      { x: 565, y: 295 },
      { x: 596, y: 178 },
      { x: 459, y: 25 },
      { x: 640, y: 307 },
      { x: 722, y: 187 },
    ],
  },
  {
    id: 4,
    image: require('assets/images/pathfinder/pathfinder14.png'),
    coords: [
      { x: 610, y: 298 },
      { x: 546, y: 301 },
      { x: 616, y: 57 },
      { x: 691, y: 229 },
      { x: 561, y: 101 },
    ],
  },
  {
    id: 5,
    image: require('assets/images/pathfinder/pathfinder15.png'),
    coords: [
      { x: 532, y: 92 },
      { x: 706, y: 18 },
      { x: 431, y: 391 },
      { x: 696, y: 427 },
      { x: 674, y: 347 },
    ],
  },
  {
    id: 6,
    image: require('assets/images/pathfinder/pathfinder19.png'),
    coords: [
      { x: 677, y: 235 },
      { x: 494, y: 206 },
      { x: 629, y: 172 },
      { x: 619, y: 119 },
      { x: 680, y: 122 },
    ],
  },
  {
    id: 7,
    image: require('assets/images/pathfinder/pathfinder20.jpg'),
    coords: [
      { x: 738, y: 49 },
      { x: 417, y: 273 },
      { x: 647, y: 405 },
      { x: 740, y: 296 },
      { x: 462, y: 332 },
    ],
  },
  {
    id: 8,
    image: require('assets/images/pathfinder/pathfinder22.jpeg'),
    coords: [
      { x: 773, y: 40 },
      { x: 703, y: 132 },
      { x: 741, y: 206 },
      { x: 553, y: 241 },
      { x: 652, y: 293 },
    ],
  },
  {
    id: 9,
    image: require('assets/images/pathfinder/pathfinder25.png'),
    coords: [
      { x: 670, y: 280 },
      { x: 655, y: 143 },
      { x: 675, y: 232 },
      { x: 643, y: 373 },
      { x: 509, y: 69 },
    ],
  },
  {
    id: 10,
    image: require('assets/images/pathfinder/pathfinder29.png'),
    coords: [
      { x: 750, y: 179 },
      { x: 511, y: 288 },
      { x: 554, y: 244 },
      { x: 591, y: 236 },
      { x: 533, y: 166 },
    ],
  },
];

export const COUNT_QUICKLY_QUESTIONS = [
  {
    id: 1,
    title: 'Сколько коробок на паллете? Все коробки установленные на паллете одинакового размера.',
    image: require('assets/images/countQuickly/countQuickly1.webp'),
    answer: '8',
  },
  {
    id: 2,
    title: 'Сколько коробок вы видите на данной картинке?',
    image: require('assets/images/countQuickly/countQuickly2.png'),
    answer: '5',
  },
  // {
  //   id: 3,
  //   title: 'Сколько бутылок в коробке?',
  //   image: require('assets/images/countQuickly/countQuickly3.png'),
  //   answer: '11',
  // },
  {
    id: 4,
    title: 'Посчитайте все видимые коробки на стеллаже',
    image: require('assets/images/countQuickly/countQuickly4.png'),
    answer: '42',
  },
  {
    id: 5,
    title: 'Сколько коробок вы видите на этом паллете?',
    image: require('assets/images/countQuickly/countQuickly5.png'),
    answer: '4',
  },
  {
    id: 6,
    title:
      'На паллете находятся одинаковые по размеру коробки и три нижних ряда заполнены полностью. Сколько коробок находится на данной паллете?',
    image: require('assets/images/countQuickly/countQuickly6.png'),
    answer: '32',
  },
  {
    id: 7,
    title: 'Сколько бутылок в данной коробке?',
    image: require('assets/images/countQuickly/countQuickly7.png'),
    answer: '10',
  },
  {
    id: 8,
    title: 'Сколько коробок нужно добавить, чтобы на паллете стало 17 коробок?',
    image: require('assets/images/countQuickly/countQuickly8.png'),
    answer: '9',
  },
  // {
  //   id: 9,
  //   title: 'Сколько необходимо таких же коробок, такого же размера чтобы заполнить полную паллету?',
  //   image: require('assets/images/countQuickly/countQuickly9.png'),
  //   answer: '4',
  // },
  {
    id: 10,
    title:
      'Для полного заполнения площади паллеты в один ряд нужно 6 коробок, как на картинке. Сколько коробок на паллете будет, если паллета будет заполнена в 4 ряда?',
    image: require('assets/images/countQuickly/countQuickly10.png'),
    answer: '24',
  },
  {
    id: 11,
    title:
      'Сколько коробок на паллете, если предположить что на картинке 1 и 2 ряд заполнены одинаковыми по размеру коробками полностью?',
    image: require('assets/images/countQuickly/countQuickly11.png'),
    answer: '16',
  },
  {
    id: 12,
    title:
      'В коробку помещается только два ряда бутылок. Сколько бутылок нужно, чтобы заполнить коробку?',
    image: require('assets/images/countQuickly/countQuickly12.png'),
    answer: '4',
  },
];
