export default {
  signTo: 'Вход в систему',
  signUp: 'Создание личного кабинета',
  passwordHelper: 'Минимум 8 знаков, 1 заглавная буква, 1 цифра.',
  lastUpdate: 'Последнее обновление: {{date}}',
  tradingAgency:
    'Представитель который берёт на себя обязательство выстраивать дистрибуцию в конкретном регионе по заранее определенным правилам.',
  saleOfPoint: 'Территориальное присутствие товара у конкретного продавца.',
  tradingHouse:
    'Уполномоченная компания от производителя по представлению товара и выстраиванию правил дистрибуции конкретном регионе.',
  uploadPresentationOrFile:
    'При необходимости загрузите дополнительные документы, презентацию или файл',
  usingForSEO: 'Используются для SEO и помогают повысить видимость товара в поисковых системах',
  noBranchesYet: 'У вас пока нет добавленных филиалов',
  branchBenefits:
    'Список филиалов позволит повысить доверие и улучшить видимость на сайте, а также предоставляет другим компаниям возможность видеть и контактировать с филиалами.',
  activationCodeSend: 'На вашу почту отправлен код, для завершения регистрации введите код в поле',
  resendCodeAfter: 'Отправить код повторно через {{seconds}} секунд',
  codeSent: 'Код отправлен',
  allDataWillBeDeleted: 'Все ваши данные будут удалены вместе с учётной записью',
  changePasswordImproveAccount:
    'Обновление кода доступа к учетной записи с целью повышения безопасности аккаунта',
  writeEmailForRecovery:
    'Пожалуйста, введите электронную почту, которая привязана к вашему аккаунту.',
  codeForRecovery: 'Код для сброса пароля был отправлен на вашу почту',
  enterCodeToAccessAccount: 'Введите код ниже для восстановления доступа к аккаунту',
  createStrongPassword: 'Придумайте надежный пароль для вашего аккаунта',
  infoAboutYourCompany: 'Укажите информацию о вашей компании',
  iWantCatalogAndMakeSale: 'Я хочу выставлять свой каталог и продавать товары',
  iWantSearchManufacturers: 'Я ищу производителей для заключения партнёрства',
  tradingAgencyDescription:
    'Торговые представительства: это организации, которые представляют интересы и продукцию других компаний на определенной территории. Они могут осуществлять поиск клиентов, продвижение товаров, проведение переговоров и заключение сделок от имени представляемой компании. Примеры торговых представительств: агентства по продаже недвижимости, агентства по продаже автомобилей и т.д.',
  tradingHouseDescription:
    'Торговые дома: это крупные торговые организации, которые объединяют несколько торговых точек или магазинов под одной управляющей компанией. Торговый дом может включать в себя различные отделы и специализированные секции, предлагающие широкий ассортимент товаров или услуг. Примеры торговых домов: универмаги, торговые центры и т.д.',
  tradingPointDescription:
    'Торговые точки: это места, где товары или услуги продаются напрямую потребителям. Это могут быть магазины, супермаркеты, рынки, торговые павильоны и т.д. Торговые точки обычно имеют физическое пространство, где клиенты могут приобрести товары или получить услуги.',
};
