import request from 'api/axios-instance';

interface ProfileEditDto {
  location_city: string;
  phone: string;
}

export const editCouchProfile = (data: ProfileEditDto) => request.patch('v1/auth/profile', data);

export const changeCouchAvatar = (data: FormData) => request.post('v1/auth/profile/avatar', data);
