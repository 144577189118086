import React from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as StoryIcon } from 'assets/icons/story.svg';
import BaseImage from 'components/BaseImage';

import { Group } from '../../entities/Group';

interface Props {
  group: Group;
  isList?: boolean;
}

const CourseGroupCard: React.FC<Props> = ({ group, isList = true }) => {
  const badgeClass =
    'flex items-center space-x-2 w-fit mb-2 px-5 py-2 bg-white/20 text-xs md:text-base rounded-full truncate overflow-hidden';
  return (
    <div className="flex flex-col md:flex-row md:space-x-5 xl:space-x-10 space-y-3 md:space-y-0 p-4 md:p-8 rounded-2xl bg-studentCardBg">
      <div className="md:w-[210px] xl:w-[260px] h-[260px] rounded-xl overflow-hidden shrink-0">
        <NavLink to={`/hr/groups/${group.id}`}>
          <BaseImage image={group.course.imageUrl} />
        </NavLink>
      </div>
      <div className="flex-1 flex flex-col justify-between space-y-4">
        <div className="flex items-center space-x-2 flex-wrap">
          <div className={badgeClass}>
            <StoryIcon className={group.status === 'finished' ? 'text-green' : 'text-blue'} />
            <span>{group.status === 'finished' ? 'Завершенный' : 'Активный'}</span>
          </div>
          {group.course.courseType ? (
            <div className={badgeClass}>
              <span
                className={`w-1 h-1 rounded-full ${
                  group.course.courseType === 'online' ? 'bg-green' : 'bg-error'
                }`}
              />
              <p>{group.course.courseType === 'online' ? 'Онлайн' : 'Оффлайн'}</p>
            </div>
          ) : null}
        </div>

        <div>
          <NavLink
            to={`/hr/groups/${group.id}`}
            className={`text-white text-xl md:text-2xl text-bold ${
              !isList ? 'pointer-events-none' : ''
            }`}
          >
            {group.name}
          </NavLink>

          <p className="mt-4 text-white/50">Группа: {group.students.length} человек</p>
        </div>

        <div className="flex flex-col md:flex-row justify-between md:space-x-5 space-y-3 md:space-y-0">
          {group.course.price ? (
            <div className="flex flex-row md:flex-col justify-between md:justify-start">
              <p className="text-white/60 text-sm">Стоимость курса:</p>
              <p className="text-xl">
                {(+group.course.price).toLocaleString()} {group.course.currency}
              </p>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default CourseGroupCard;
