import React from 'react';
import { ReactComponent as DocumentIcon } from 'assets/icons/document-green.svg';
import Accordion from 'components/Accordion';
import { UserDetail } from 'modules/hr/entities/User';

interface Props {
  user: UserDetail;
}

const UserDisciplines: React.FC<Props> = ({ user }) => (
  <Accordion title="Дисциплины">
    <div className="flex flex-col space-y-3">
      {/* <div className="flex flex-row rounded-2xl bg-white/10 p-5">
          <p className="w-1/2 text-white">Преподаваемые модули:</p>
          <p className="w-1/2 text-white font-medium truncate">
            Разработка ПСД, Прохождение экспертизы
          </p>
        </div> */}
      {user.scoring && (
        <div className="flex flex-row rounded-2xl bg-white/10 p-5">
          <p className="w-1/2 text-white">Скоринг:</p>
          <p className="w-1/2 text-white font-medium truncate">{user.scoring}</p>
        </div>
      )}
      {/* <div className="flex flex-row rounded-2xl bg-white/10 p-5">
          <p className="w-1/2 text-white">РУПы:</p>
          <p className="w-1/2 text-white font-medium truncate">500 000</p>
        </div> */}
      {user.scoringFile && (
        <div className="flex flex-row rounded-2xl bg-white/10 p-5">
          <p className="w-1/2 text-white">Скоринг файл:</p>
          <div className="w-1/2 flex flex-col space-y-2">
            {user.scoringFile.map((file, index) => (
              <a
                key={index}
                href={file.download_link}
                className="flex flex-row items-center space-x-4 text-green font-medium"
                download
              >
                <DocumentIcon /> <span className="truncate">{file.original_name}</span>
              </a>
            ))}
          </div>
        </div>
      )}
    </div>
  </Accordion>
);

export default UserDisciplines;
