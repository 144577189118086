import { useSearchParams } from 'react-router-dom';
import { usePartialState } from 'hooks/partial-state';

interface Filter {
  student: string | null;
  course: number | null;
  group: number | null;
  status: number | null;
  deadline: Date | null;
}

export const useHomeworksCheckFilter = () => {
  const [query, setQuery] = useSearchParams();

  const student = query.get('student');
  const course = query.get('course');
  const group = query.get('group');
  const status = query.get('status');
  const deadline = query.get('deadline');

  const [filter, setFilter] = usePartialState<Filter>({
    student: student || null,
    course: course ? +course : null,
    group: group ? +group : null,
    status: status ? +status : null,
    deadline: deadline ? new Date(deadline) : null,
  });

  const onSubmit = () => {
    Object.entries(filter).forEach(([key, value]) => {
      if (value) {
        query.set(key, value.toString());
      } else {
        query.delete(key);
      }
    });

    query.set('page', '1');

    setQuery(query);
  };

  const onReset = () => {
    setFilter({
      student: null,
      course: null,
      group: null,
      status: null,
      deadline: null,
    });

    Object.entries(filter).forEach(([key]) => {
      query.delete(key);
    });

    query.set('page', '1');

    setQuery(query);
  };

  return {
    filter,
    setFilter,
    onSubmit,
    onReset,
  };
};
