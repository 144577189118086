import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { fetchCourseStatuses } from '../../api/repository/dashboard';
import { DashboardCourseStatus } from '../../entities/DashboardCourseStatus';

const TrainingFormatProgress = () => {
  const [query] = useSearchParams();
  const course = query.get('course');

  const [statuses, setStatuses] = useState<DashboardCourseStatus>({
    inProgress: 0,
    inProgressPercent: 0,
    finished: 0,
    finishedPercent: 0,
  });

  useEffect(() => {
    const params = { ...(course && { course_ids: course }) };
    fetchCourseStatuses(params).then((res) => setStatuses(res));
  }, [course]);

  return (
    <div className="p-4 md:p-6 rounded-2xl bg-studentCardBg">
      <p className="text-lg">Формат обучения</p>

      <div className="mt-4 mb-5 px-4 py-5 rounded-lg bg-white/10">
        <div className="flex items-center bg-white/30 rounded-full overflow-hidden">
          <div
            className="h-[5px] bg-studentProgressBg"
            style={{ width: `${statuses.finishedPercent}%` }}
          />
        </div>
      </div>

      <div className="flex items-center justify-between space-x-3 mb-1 text-sm">
        <span className="block w-4 h-1 rounded bg-blue" />
        <span className="flex-1 text-white">Пройден</span>
        <p>
          <span>{statuses.finished}</span>
          <span className="ml-2 text-white/50">{statuses.finishedPercent}%</span>
        </p>
      </div>
      <div className="flex items-center justify-between space-x-3 mb-1 text-sm">
        <span className="block w-4 h-1 rounded bg-blue/20" />
        <span className="flex-1 text-white">В процессе</span>
        <p>
          <span>{statuses.inProgress}</span>
          <span className="ml-2 text-white/50">{statuses.inProgressPercent}%</span>
        </p>
      </div>
    </div>
  );
};

export default TrainingFormatProgress;
