import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { fetchGroups } from '../api/repository/groups';
import { Group } from '../entities/Group';

export const useGroups = () => {
  const [query, setQuery] = useSearchParams();
  const courseStatus = query.get('course_status');
  const studyType = query.get('study_type');
  const courseType = query.get('course_type');
  const page = query.get('page');

  const [lastPage, setLastPage] = useState(1);

  const [isLoading, setLoading] = useState(false);
  const [groups, setGroups] = useState<Group[]>([]);

  useEffect(() => {
    const params = {
      ...(courseStatus && { course_status: courseStatus === '1' ? 'active' : 'finished' }),
      ...(studyType && { study_type: studyType === '1' ? 'inner' : 'external' }),
      ...(courseType && { course_type: courseType === '1' ? 'online' : 'offline' }),
      page: page ? page : '1',
    };

    setLoading(true);
    fetchGroups(params)
      .then((res) => {
        setGroups(res.groups);
        setLastPage(res.lastPage);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [courseStatus, studyType, courseType, page]);

  const setCurrentPage = (current: number) => {
    query.set('page', current.toString());
    setQuery(query);
  };

  return {
    isLoading,
    groups,
    currentPage: page ? +page : 1,
    lastPage,
    setCurrentPage,
  };
};
