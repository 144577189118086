import { FC } from 'react';
import { ReactComponent as DownloadIcon } from 'assets/icons/download-icon-blue.svg';
import useFileDownload from 'modules/coach/hooks/downloadFile';

type Props = {
  fileLink: string;
  name: string;
  withoutWord?: boolean;
};

const FileDownloadButton: FC<Props> = ({ fileLink, name, withoutWord }) => {
  const { downloadFile, isDownloading, error } = useFileDownload(fileLink, name);

  return (
    <div>
      {isDownloading ? (
        <div className="">
          <p>Скачивание...</p>
          <progress className="w-full h-3" value={+isDownloading} />
        </div>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <button
          type="button"
          onClick={downloadFile}
          className={`${
            withoutWord ? 'flex-row gap-3  hover:underline hover:underline-offset-4' : 'flex-col'
          } max-w-[200px] flex justify-center theme-text--default items-center gap-1 ml-auto text-left hyphens-auto sm:max-w-full theme-background-files theme-icon-stroke--blue`}
        >
          <DownloadIcon className="w-5 h-5 fill-white" />
          <span className="text-current"> {withoutWord ? name : 'Скачать'}</span>
        </button>
      )}
    </div>
  );
};

export default FileDownloadButton;
