import { useEffect, useState } from 'react';
import {
  fetchNewCoursesList,
  fetchNewGroupList,
  fetchNewLessonsList,
  fetchNewModuleList,
} from 'modules/coach/api/repository/courses-group-list';
import useHandleError from 'hooks/handleError';
import { ErrorResponse } from 'entities/Error';

import { ICourseMetaData } from '../entities/CourseGroupList';

export const useCoursesListData = (selectedCourseId?: number) => {
  const [coursesList, setCoursesList] = useState<ICourseMetaData[]>([]);
  const [modules, setModules] = useState<ICourseMetaData[]>([]);
  const { handleCommonError } = useHandleError();

  useEffect(() => {
    const params = {
      by_this_coach: 1,
      course_id: selectedCourseId ? selectedCourseId : null,
    };

    fetchNewCoursesList(params)
      .then((res) => {
        setCoursesList(res);
      })
      .catch((err: unknown) => {
        const error = err as ErrorResponse;
        handleCommonError(error);
      });
  }, []);

  useEffect(() => {
    if (!selectedCourseId) return;

    fetchNewModuleList(selectedCourseId)
      .then((res) => {
        setModules(res);
      })
      .catch((err: unknown) => {
        const error = err as ErrorResponse;
        handleCommonError(error);
      });
  }, [selectedCourseId]);

  return { coursesList, modules };
};
