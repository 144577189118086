import React from 'react';
import StudentCard from 'modules/hr/components/StudentCard';

import { StudentInList } from '../../entities/Student';

interface Props {
  students: StudentInList[];
}

const StudentsTable: React.FC<Props> = ({ students }) => {
  return (
    <table className="bg-coachTableGradient w-full rounded-[20px] backdrop-blur-[60px] min-w-[980px]">
      <thead className="block px-8 pt-4 pb-[10px] w-full bg-darkGray">
        <tr className="grid grid-cols-coachTable w-full gap-x-8">
          <td>СЛУШАТЕЛЬ</td>
          <td>ЛОГИН</td>
          <td>E-MAIL</td>
          <td>ТЕЛЕФОН</td>
        </tr>
      </thead>

      <tbody>
        {students.length > 0 &&
          students.map((student) => (
            <tr
              className="grid grid-cols-coachTable w-full px-8 pt-4 pb-[10px] gap-x-8 border-t-[1px] border-coachTableBorder "
              key={student.id}
            >
              <td>
                <StudentCard info={student} />
              </td>
              <td className="w-full flex items-center">
                <span className="w-full max-w-full truncate">{student.login}</span>
              </td>
              <td className="w-full flex items-center">
                <a
                  className="w-full max-w-full truncate text-white cursor-pointer"
                  href={'mailto:' + student.email}
                >
                  {student.email}
                </a>
              </td>
              <td className="w-full flex items-center">
                <a className="w-full text-white cursor-pointer" href={'tel:' + student.phoneNumber}>
                  {student.phoneNumber}
                </a>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default StudentsTable;
