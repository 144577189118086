import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';

import ProfileHeader from '../../components/ProfileHeader';
import ProfileSidebar from '../../components/ProfileSidebar';

const ProfileLayout = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div className="flex w-full h-full p-4 md:p-7">
      <ProfileSidebar isOpen={isSidebarOpen} onClose={setSidebarOpen} />
      <div className="flex-1 lg:ml-5 relative z-0">
        <ProfileHeader isSidebarOpen={isSidebarOpen} onSidebarOpen={setSidebarOpen} />

        <Outlet />
      </div>
    </div>
  );
};

export default ProfileLayout;
