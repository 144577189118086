import React, { useState } from 'react';
import { ReactComponent as DownLoadIcon } from 'assets/icons/download-icon-blue.svg';
import { uploadFiles } from 'modules/coach/api/repository/upload-file';
import { useNotification } from 'hooks/notification';

interface Props {
  initialAudio?: string | null;
  onChange: (audio: string | null) => void;
}

const UploadAudio: React.FC<Props> = ({ initialAudio, onChange }) => {
  const notify = useNotification();

  const [isAudioUploading, setIsAudioUploading] = useState<boolean>(false);
  const [audio, setAudio] = useState<string | null>(initialAudio || null);

  const uploadAudio = (file: File) => {
    const form = new FormData();
    form.append('file', file);
    setIsAudioUploading(true);
    uploadFiles(form)
      .then((res) => {
        setAudio(res);
        onChange(res);
      })
      .catch(() => {
        notify.error('Произошла ошибка. Обратитесь в службу поддержки');
      })
      .finally(() => {
        setIsAudioUploading(false);
      });
  };

  const removeAudio = () => {
    setAudio(null);
    onChange(null);
  };

  return (
    <div className="flex flex-col space-y-2">
      <label
        htmlFor="audio"
        className="w-full h-[50px] py-3 px-6 text-black bg-[#F2F2F2] pr-6 rounded-[14px] outline-none cursor-pointer"
      >
        <div className="flex flex-row gap-x-3 items-center">
          <DownLoadIcon />
          <span className="text-current text-base">Загрузить аудио</span>
        </div>
      </label>
      <input
        id="audio"
        type="file"
        accept=".mp3,audio/*"
        className="hidden"
        onChange={(event) => {
          const target = event.target as HTMLInputElement;
          if (target.files && target.files.length) {
            uploadAudio(target.files[0]);
          }
        }}
      />
      {isAudioUploading ? (
        <div className="text-current flex flex-row gap-1 items-baseline mt-2">
          Загрузка
          {Array(3)
            .fill(null)
            .map((_, index) => (
              <p key={index} className="w-1 h-1 rounded-full bg-[#58DB66] animate-pulse" />
            ))}
        </div>
      ) : (
        audio && (
          <div className="flex flex-row">
            <div className="text-current h-fit flex flex-row items-center gap-x-2 py-2 px-4 text-xs bg-[#F2F2F2] rounded-[55px]">
              <button
                onClick={removeAudio}
                className="text-current w-[20px] h-[20px] flex items-center justify-center bg-white rounded-full"
              >
                x
              </button>
              <span>{audio}</span>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default UploadAudio;
