import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowDown } from 'assets/icons/chevron-up.svg';
import BaseImage from 'components/BaseImage';
import LMSButton from 'components/LMSButton';
import Loader from 'components/Loader';
import { useNotification } from 'hooks/notification';

import { CoachCurrentTestDao } from '../../api/dao/Test.dao';
import { fetchCoachTests } from '../../api/repository/tests';
import { CoachTest, Module } from '../../entities/Test';

interface LocalModuleCoachTest extends Module {
  isExpanded: boolean;
}

interface LocalCoachTest extends CoachTest {
  isExpanded: boolean;
  modules: LocalModuleCoachTest[];
}

const CoachTests = () => {
  const navigate = useNavigate();
  const notify = useNotification();

  const [tests, setTests] = useState<CoachCurrentTestDao[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    fetchCoachTests()
      .then((res) => {
        const tests = [] as CoachCurrentTestDao[];

        res.forEach((item) => {
          if (item?.id) tests.push(item);
        });

        setTests(tests);
      })
      .catch(() => {
        notify.error('Произошла ошибка. Обратитесь в службу поддержки');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  // const toggleTestExpand = (id: number) => {
  //   setTests((prev) => prev.map((t) => (t.id === id ? { ...t, isExpanded: !t.isExpanded } : t)));
  // };

  // const toggleModuleExpand = (testId: number, moduleId: number) => {
  //   setTests((prev) =>
  //     prev.map((t) =>
  //       t.id === testId
  //         ? {
  //             ...t,
  //             modules: t.modules.map((m) =>
  //               m.id === moduleId ? { ...m, isExpanded: !m.isExpanded } : m
  //             ),
  //           }
  //         : t
  //     )
  //   );
  // };

  const onTestCreate = () => {
    navigate(`/coach/tests/create-test`);
  };

  const jumpToTest = (id: number) => {
    navigate(`/coach/tests/test/${id}`);
  };

  return (
    <div className="mt-20 lg:mt-0 text-current">
      <section className="flex items-center justify-between mb-6">
        <h2 className="mt-6 mb-6 text-[32px] font-semibold">Тесты</h2>
        <LMSButton
          className="py-0 md:py-[3px]"
          prefix={<span className="text-xl md:text-2xl">+</span>}
          onClick={() => onTestCreate()}
        >
          Создать тест
        </LMSButton>
      </section>
      {isLoading && <Loader theme="dark" />}
      {!isLoading && tests.length ? (
        // TODO: старая реализация
        // tests.map((test) => (
        //   <div key={test.id} className="mb-5 bg-studentCardBg rounded-2xl overflow-hidden">
        //     <div
        //       className="flex items-center justify-between p-4 md:p-8 cursor-pointer"
        //       onClick={() => toggleTestExpand(test.id)}
        //     >
        //       <div className="flex items-center md:space-x-10">
        //         <div className="hidden md:block w-[160px] h-[100px] rounded-xl overflow-hidden">
        //           <BaseImage />
        //         </div>
        //         <p className="max-w-[65%] md:text-2xl">{test.name}</p>
        //       </div>
        //       <div className="flex items-center space-x-5">
        //         {test.testsNumber ? (
        //           <div className="flex items-center shrink-0 px-3 py-1.5 rounded-full bg-white/20">
        //             <span className="block w-1 h-1 mr-2 rounded-full bg-green" />
        //             <span className="text-[10px] md:text-base">{test.testsNumber} тестов</span>
        //           </div>
        //         ) : null}

        //         <ArrowDown
        //           className={`transition duration-500 ${test.isExpanded ? '' : 'rotate-180'}`}
        //         />
        //       </div>
        //     </div>
        //     {test.isExpanded &&
        //       test.modules.map((module, index) => (
        //         <div
        //           key={module.id}
        //           onClick={() =>
        //             module.lessons &&
        //             module.lessons.length &&
        //             toggleModuleExpand(test.id, module.id)
        //           }
        //         >
        //           <div className="flex items-center justify-between py-4 px-4 md:px-8 border-t border-white/10 bg-[#2d3051]">
        //             <div className="flex flex-col md:flex-row md:items-center md:space-x-6 text-base md:text-lg">
        //               <p className="text-xs md:text-lg text-blue">Модуль {index + 1}</p>
        //               <p>{module.title}</p>
        //             </div>
        //             {module.lessons && module.lessons.length ? (
        //               <ArrowDown
        //                 className={`transition duration-500 ${
        //                   module.isExpanded ? '' : 'rotate-180'
        //                 }`}
        //               />
        //             ) : null}
        //           </div>

        //           {module.lessons.map((lesson, index) => (
        //             <div
        //               key={index}
        //               className="flex items-center justify-between py-4 pl-8 pr-4 md:pl-16 md:pr-8 border-t border-white/10"
        //             >
        //               <div className="flex flex-col md:flex-row md:items-center md:space-x-6 text-base md:text-lg">
        //                 <p className="text-xs md:text-lg text-blue">Урок {index + 1}</p>
        //                 <p>{lesson.name}</p>
        //               </div>
        //             </div>
        //           ))}
        //         </div>
        //       ))}
        //   </div>
        // ))}
        tests.map((test) => (
          <div
            key={test.id}
            className="mb-5 theme-student-card rounded-2xl overflow-hidden"
            onClick={() => jumpToTest(test.id)}
          >
            <div className="p-4 md:p-8 cursor-pointer w-full">
              <div className="flex items-center md:space-x-10">
                <div className="hidden md:block w-[160px] h-[100px] rounded-xl overflow-hidden">
                  <BaseImage />
                </div>
                <div className="flex flex-col gap-y-2 w-full">
                  {(test?.name || test?.id) && (
                    <p className="max-w-[65%] md:text-2xl">{`Тест: ${
                      test.name || ' № ' + test.id
                    }`}</p>
                  )}
                  <p className="max-w-[65%] md:text-2xl">{`Урок: ${test.lesson_name}`}</p>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="text-current">Созданные тесты отсутствуют</div>
      )}
    </div>
  );
};

export default CoachTests;
