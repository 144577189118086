import request from 'api/axios-instance';

import { DashboardAcademicPerformance } from '../../entities/DashboardAcademicPerformance';
import { DashboardAttendance } from '../../entities/DashboardAttendance';
import { DashboardCourseStatus } from '../../entities/DashboardCourseStatus';
import { DashboardCourseType } from '../../entities/DashboardCourseType';
import { DashboardStudentType } from '../../entities/DashboardStudentType';
import { AcademicPerformanceDashboardDao } from '../dao/academic-performance-dashboard.dao';
import { AttendanceDashboardDao } from '../dao/attendance-dashboard.dao';
import { CourseStatusDashboardDao } from '../dao/course-status-dashboard.dao';
import { CourseTypeDashboardDao } from '../dao/course-type-dashboard.dao';
import { StudentTypeDashboardDao } from '../dao/student-type-dashboard.dao';

export const fetchCourseStatuses = (params: Record<string, string | number>) =>
  request
    .get('v1/dashboards/course-status', { params })
    .then((res) => mapCourseStatusDaoToEntity(res.data.data));

export const fetchStudentTypes = (params: Record<string, string | number>) =>
  request
    .get('v1/dashboards/student-type', { params })
    .then((res) => mapStudentTypeDaoToEnity(res.data.data));

export const fetchCourseTypes = (params: Record<string, string | number>) =>
  request
    .get('v1/dashboards/course-type', { params })
    .then((res) => mapCourseTypeDaoToEntity(res.data.data));

export const fetchAcademicPerformance = (params: Record<string, string | number>) =>
  request.get('v1/dashboards/learning-performance', { params }).then((res) => {
    const performances: DashboardAcademicPerformance[] = res.data.data.map(
      mapAcademicPerformanceDaoToEntity
    );
    return performances;
  });

export const fetchAttendance = (params?: Record<string, string | number>) =>
  request.get('v1/dashboards/attendance', { params }).then((res) => {
    const attendances: DashboardAttendance[] = res.data.data.map(mapAttendanceDaoToEntity);
    return attendances;
  });

function mapCourseStatusDaoToEntity(status: CourseStatusDashboardDao): DashboardCourseStatus {
  return {
    inProgress: status.in_progress_count,
    inProgressPercent: status.in_progress_share,
    finished: status.finished_count,
    finishedPercent: status.finished_share,
  };
}

function mapStudentTypeDaoToEnity(type: StudentTypeDashboardDao): DashboardStudentType {
  return {
    inner: type.inner_count,
    innerPercent: type.inner_share,
    external: type.external_count,
    externalPercent: type.external_share,
  };
}

function mapCourseTypeDaoToEntity(type: CourseTypeDashboardDao): DashboardCourseType {
  return {
    online: type.online_count,
    onlinePercent: type.online_share,
    offline: type.offline_count,
    offlinePercent: type.offline_share,
  };
}

function mapAcademicPerformanceDaoToEntity(
  performance: AcademicPerformanceDashboardDao
): DashboardAcademicPerformance {
  return {
    min: performance.min,
    max: performance.max,
    percentage: performance.percentage,
  };
}

function mapAttendanceDaoToEntity(attendance: AttendanceDashboardDao): DashboardAttendance {
  return {
    start: attendance.start_date,
    end: attendance.end_date,
    count: attendance.count,
    month: attendance.month_name,
  };
}
