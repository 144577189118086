import React, { useEffect, useState } from 'react';
import LMSButton from 'components/LMSButton';
import LMSPagination from 'components/LMSPagination';
import ProgressBar from 'components/ProgressBar';
import { fetchGameResults, TData } from 'modules/student/api/repository/games';
import { useNotification } from 'hooks/notification';
import { useAppSelector } from 'hooks/redux';

import { DifficultLevelType } from '../../entities/Pathfinder';

interface Props {
  setStep: (level: number) => void;
}

const PathfinderResult: React.FC<Props> = ({ setStep }) => {
  const { user } = useAppSelector((state) => state.auth);
  const notify = useNotification();

  const [stats, setStats] = useState<TData[]>([]);
  const [page, setPage] = useState<number>(1);
  const [lastPage, setLastPage] = useState<number>(1);

  const handleTryAgain = () => setStep(5);

  const handleSelectLevel = () => setStep(4);

  const parseTime = (milliseconds: number): string => {
    const seconds = Math.trunc(milliseconds / 1000);
    const minutes = Math.trunc(seconds / 60);
    const leftSeconds = seconds % 60;
    return `${minutes}:${leftSeconds.toString().padStart(2, '0')}`;
  };

  const getScore = (score: number, difficulty: DifficultLevelType) => {
    const maxScore = difficulty === 'easy' ? 20 : 10;
    return score > maxScore ? maxScore : score;
  };

  useEffect(() => {
    fetchGameResults('images', page, 10)
      .then((res) => {
        setStats(res.result);
        setLastPage(res.lastPage);
      })
      .catch(() => {
        notify.error('Произошла ошибка. Обратитесь в службу поддержки');
      });
  }, [page]);

  return (
    <div className="flex flex-col space-y-8 w-[80%] max-w-[960px] min-h-[500px] rounded-[20px] theme-student-card py-10 px-14 m-auto">
      <div className="w-full h-full flex flex-col space-y-4">
        <p className="text-[32px] font-semibold leading-10 max-w-[512px]">Результаты</p>
        <div>
          <table className="table-auto w-full">
            <thead>
              <tr className="h-12 theme-text--default text-sm font-normal text-opacity-40">
                <th className="w-[5%] text-left">№</th>
                <th className="w-[30%] text-left">ИМЯ</th>
                <th className="w-[30%] text-left">Время</th>
                <th className="w-[20%] text-left">РЕЗУЛЬТАТЫ</th>
              </tr>
            </thead>
            <tbody className="w-full">
              {stats.map((el, index) => (
                <tr
                  key={index}
                  className="w-full theme-text--default text-base font-normal tracking-wide"
                >
                  <td className="h-16 theme-text--default text-lg font-medium">{index + 1}</td>
                  <td className="h-16 flex flex-row items-center gap-x-5">{user?.fio}</td>
                  <td className="h-16">{parseTime(el.time)}</td>
                  <td className="h-16">
                    <ProgressBar
                      progress={getScore(el.score, el.difficulty)}
                      maxProgress={el.difficulty === 'easy' ? 20 : 10}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex justify-start pb-4">
          <LMSPagination current={page} lastPage={lastPage} onChange={(page) => setPage(page)} />
        </div>
        <div className="flex flex-col items-center space-y-4 !mt-auto">
          <button
            type="button"
            className="min-w-[270px] flex items-center flex-2 justify-center text-sm md:text-base px-3 md:px-[25px] py-2 md:py-3.5 rounded-full border theme-border--primary theme-active-text"
            onClick={handleSelectLevel}
          >
            Выбрать уровень сложности
          </button>
          <LMSButton className="min-w-[270px]" onClick={handleTryAgain}>
            Попробовать еще раз
          </LMSButton>
        </div>
      </div>
    </div>
  );
};

export default PathfinderResult;
