import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNotification } from 'hooks/notification';

import { ErrorResponse } from '../entities/Error';

const useHandleError = () => {
  const { t } = useTranslation();
  const notify = useNotification();

  const handleCommonError = useCallback(
    ({ response }: ErrorResponse) => {
      if (!response?.status) {
        notify.error(t('error.notConnection'));
      } else if (response.status === 500) {
        notify.error(t('error.server'));
      } else {
        notify.error(t('error.unknown'));
      }
    },
    [notify]
  );

  return {
    handleCommonError,
  };
};

export default useHandleError;
