import React, { PropsWithChildren } from 'react';

interface Props {
  className?: string;
}

const Title: React.FC<PropsWithChildren & Props> = ({ className, children }) => {
  return <h1 className={`text-4xl md:text-6xl text-current ${className}`}>{children}</h1>;
};

export default Title;
