import LMSTitle from 'components/LMSTitle';
import UpdatePasswordForm from 'modules/coach/components/UpdatePasswordForm';

const Password = () => {
  return (
    <div className="w-full h-full flex flex-col">
      <LMSTitle
        tag="h4"
        className="pt-[86px] pb-[36px] text-current text-center text-3xl md:pt-[50px] md:text-start"
      >
        Смена пароля
      </LMSTitle>
      <UpdatePasswordForm />
    </div>
  );
};

export default Password;
