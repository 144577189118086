import { FC, useState } from 'react';
import { ReactComponent as DropdownIcon } from 'assets/icons/chevron-down.svg';

type Props = {
  title: string;
  children?: React.ReactNode;
};

const DetailHomeWorkCard: FC<Props> = (props) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const toggleDropdown = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <div className="h-fit w-full rounded-[20px] bg-[#58DB66]">
      <div
        className={`${
          isOpen ? 'mr-0' : 'mr-[2px]'
        } h-full flex flex-col bg-homeworkgradient rounded-[18px] cursor-pointer`}
      >
        <div
          onClick={() => toggleDropdown()}
          className="flex flex-row justify-between items-center p-10"
        >
          <div className="flex flex-col gap-y-3 gap-x-5 text-white text-lg sm:flex-row">
            <span className="text-[#26A7EF]">Группа {''}</span>
            <span className="max-w-[200px] sm:max-w-[400px]">{props.title}</span>
          </div>
          <DropdownIcon
            className={`${
              isOpen ? 'rotate-180 ease-out duration-700' : 'rotate-360 ease-in duration-500'
            } text-white cursor-pointer`}
          />
        </div>
        {isOpen && props.children}
      </div>
    </div>
  );
};

export default DetailHomeWorkCard;
