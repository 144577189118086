import { FC, useState } from 'react';
import BaseModal from 'components/BaseModal';
import { sendHomeworkMark } from 'modules/coach/api/repository/homework-mark';
import { useNotification } from 'hooks/notification';

type StudentMessage = {
  studentId: number;
  calendarId: number;
  hometaskId: number;
  onClose: () => void;
};

const HomeWorkMarkModal: FC<StudentMessage> = ({ studentId, calendarId, hometaskId, onClose }) => {
  const [mark, setMark] = useState<number>(0);
  const notify = useNotification();

  const sendOnMark = async () => {
    const params = {
      student_id: studentId,
      hometask_id: hometaskId,
      calendar_id: calendarId,
      status_id: '3',
      mark: mark,
    };

    try {
      await sendHomeworkMark(params);
    } catch (error) {
      notify.error(String(error));
    } finally {
      notify.success('Данные успешно отправлены');
      onClose();
    }
  };

  return (
    <BaseModal onClose={onClose}>
      <div className="p-6 sm:min-w-[500px] z-100">
        <h4 className="text-center text-lg font-medium">Оценить работу</h4>
        <div className="flex flex-col gap-y-3 pt-10">
          <div className="w-full h-full flex flex-col items-center gap-6">
            <span className="text-lg">{mark} из 100</span>
            <input
              type="range"
              value={mark}
              min="0"
              max="100"
              className="w-full"
              onChange={(data) => setMark(Number(data.target.value))}
            />
          </div>
        </div>
        <div className="flex flex-col justify-between pt-5 sm:flex-row">
          <button
            onClick={() => sendOnMark()}
            className="w-fit h-fit text-white px-6 py-3 bg-[#26A7EF] rounded-full sm:ml-auto"
          >
            Отправить
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default HomeWorkMarkModal;
