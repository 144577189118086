import React, { useState } from 'react';
import LMSButton from 'components/LMSButton';
import { fetchSimulation } from 'modules/coach/api/repository/simulations';
import { ISimulation, ISimulationDetail } from 'modules/coach/entities/Simulation';
import { useNotification } from 'hooks/notification';

interface Props {
  simulations: ISimulation[];
  selectSimulation: (value: ISimulationDetail) => void;
}

const LectioSelectCategory: React.FC<Props> = ({ simulations, selectSimulation }) => {
  const notify = useNotification();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedSimulation, setSelectedSimulation] = useState<ISimulation | null>(null);

  const handleFetchSimulation = (id: number) => {
    setIsLoading(true);
    fetchSimulation(id)
      .then((res) => {
        selectSimulation(res);
      })
      .catch(() => {
        notify.error('Произошла ошибка. Обратитесь в службу поддержки');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="flex flex-col space-y-14 items-center justify-center w-[80%] max-w-[960px] h-max min-h-[500px] rounded-[20px] theme-student-card m-auto p-10">
      <div className="flex flex-col space-y-6 items-center">
        <p className="text-lg font-semibold text-center leading-10 max-w-[512px]">
          Выберите категорию:
        </p>
        <div className="flex flex-row space-x-2">
          {simulations.map((simulation) => (
            <button
              key={simulation.id}
              type="button"
              className={`w-full h-[63px] bg-transparent justify-center items-center rounded-2xl border px-10 text-current text-base md:text-lg outline-none ${
                selectedSimulation && simulation.id === selectedSimulation.id
                  ? 'theme-border--primary'
                  : 'border-[#E7E7E7]'
              }`}
              onClick={() => setSelectedSimulation(simulation)}
            >
              {simulation.categoryName}
            </button>
          ))}
        </div>
      </div>
      <LMSButton
        type="button"
        disabled={!selectedSimulation}
        loading={isLoading}
        onClick={() => selectedSimulation && handleFetchSimulation(selectedSimulation.id)}
      >
        Начать игру
      </LMSButton>
    </div>
  );
};

export default LectioSelectCategory;
