import React from 'react';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { ReactComponent as ReloadIcon } from 'assets/icons/reload.svg';
import BaseImage from 'components/BaseImage';

import LMSButton from '../../../../components/LMSButton';
import { CompletedCourse } from '../../entities/CompletedCourse';

interface Props {
  course: CompletedCourse;
}

const CompletedCourseCard: React.FC<Props> = ({ course }) => {
  const badgeClass =
    'flex items-center mb-2 px-5 py-2 bg-white/20 text-xs md:text-base rounded-full truncate overflow-hidden';

  return (
    <div className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-9 p-4 md:p-8 rounded-3xl bg-studentCardBg">
      <div className="w-full md:w-[300px] lg:w-[364px] h-[210px] lg:h-[266px] shrink-0 bg-white/20 rounded-2xl overflow-hidden">
        <BaseImage image={course.name} />
        {/* <img src={} alt="" /> */}
      </div>
      <div className="flex flex-col flex-1 flex-wrap">
        <div className="flex flex-wrap space-x-2">
          <p className={badgeClass}>Старт: {course.dateFrom}</p>
          <p className={badgeClass}>{course.dateTo}</p>
          <p className={badgeClass}>{course.courseType}</p>
          <p className={badgeClass}>{course.groupName}</p>
        </div>
        <p className=" flex-1 mt-5 mb-5 lg:mt-10 text-2xl">{course.name}</p>
        <div className="flex items-center justify-between flex-wrap mt-5">
          <div className="flex items-center space-x-3 mr-4 md:mb-2">
            <button className="shrink-0 flex items-center justify-center w-12 h-12 bg-white rounded-full text-green">
              <CheckIcon />
            </button>
            <div>
              <p className="text-green font-semibold">Курс пройден успешно</p>
              <p className="text-xs font-medium text-grey">Результат {course.result}/100</p>
            </div>
          </div>
          <div className="flex items-center flex-row-reverse md:flex-row md:mb-2 mt-5 md:mt-0">
            <button className="shrink-0 flex items-center justify-center w-11 md:w-12 h-11 md:h-12 bg-white/25 rounded-full text-white ml-2 md:ml-0 md:mr-2">
              <ReloadIcon />
            </button>
            <LMSButton>Скачать сертификат</LMSButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompletedCourseCard;
