import request from 'api/axios-instance';

export type TData = {
  game: 'vortex' | 'lectio' | 'images';
  difficulty: 'easy' | 'medium' | 'hard';
  score: number;
  time: number;
};

export const sendGameResults = (data: TData) => request.post('v2/game', data);

export const fetchGameResults = (game: TData['game'], page = 1, perPage = 30) =>
  request.get(`v2/game?game=${game}&perPage=${perPage}&page=${page}`).then((res) => {
    return { result: res.data.data, lastPage: (res?.data?.meta?.last_page as number) || 1 };
  });
