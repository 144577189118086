import React, { useMemo, useState } from 'react';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';
import FilterAccordion from 'components/FilterAccordion';
import LMSButton from 'components/LMSButton';
import { useOutsideClick } from 'hooks/outside-click';
import { useWindowSize } from 'hooks/window-size';

import {
  fetchLibraryAuthors,
  fetchLibraryCategories,
  fetchLibraryPublishers,
  fetchLibrarySubcategories,
} from '../../api/repository/handbooks';
import { useLibraryFilter } from '../../hooks/library-filter';

const LibraryFilter = () => {
  const device = useWindowSize();
  const { filter, setFilter, onReset, onSubmit } = useLibraryFilter();
  const [isOpen, setOpen] = useState(false);

  const filterRef = useOutsideClick(() => {
    if (device !== 'desktop') {
      setOpen(false);
    }
  });

  const filterClass = useMemo(() => {
    if (device === 'desktop') {
      return 'w-[380px] h-fit theme-student-card px-8 py-5 border border-solid border-[#3D436B] rounded-[20px]';
    }

    if (!isOpen) return 'hidden';

    return 'fixed bottom-0 right-0 left-0 top-24 w-full bg-[#3D436B] px-8 py-5 border border-solid border-[#3D436B] rounded-t-[20px] overflow-auto';
  }, [device, isOpen]);

  const onToggleOpen = () => {
    if (device === 'desktop') return;

    if (isOpen) {
      document.body.classList.remove('modal-open');
    } else {
      document.body.classList.add('modal-open');
    }
    setOpen((prev) => !prev);
  };

  const onSubmitFilter = () => {
    onToggleOpen();
    onSubmit();
  };

  const onResetFilter = () => {
    onToggleOpen();
    onReset();
  };

  return (
    <div>
      <button
        className="flex lg:hidden items-center justify-center w-full mb-6 p-2 space-x-2 border border-white border-opacity-30 rounded-xl"
        onClick={onToggleOpen}
      >
        <FilterIcon />
        <span>Фильтр</span>
      </button>
      <div ref={filterRef} className={filterClass}>
        <FilterAccordion
          title="Категория"
          selected={filter.category}
          fetchOptions={fetchLibraryCategories}
          setSelected={(val) => setFilter({ category: val })}
        />
        <FilterAccordion
          title="Подкатегория"
          selected={filter.subcategory}
          fetchOptions={fetchLibrarySubcategories}
          setSelected={(val) => setFilter({ subcategory: val })}
        />
        <FilterAccordion
          title="Автор"
          selected={filter.author}
          fetchOptions={fetchLibraryAuthors}
          setSelected={(val) => setFilter({ author: val })}
        />
        <FilterAccordion
          title="Издательство"
          selected={filter.publisher}
          fetchOptions={fetchLibraryPublishers}
          setSelected={(val) => setFilter({ publisher: val })}
        />

        <div>
          <LMSButton className="w-full mt-5" colorType="light" onClick={onSubmitFilter}>
            Применить
          </LMSButton>
          <LMSButton
            className="w-full mt-2"
            variant="text"
            prefix={<CloseIcon className="w-5 h-5" />}
            onClick={onResetFilter}
          >
            Сбросить
          </LMSButton>
        </div>
      </div>
    </div>
  );
};

export default LibraryFilter;
