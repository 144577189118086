import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { createLessonComment, fetchLessonComments } from '../api/repository/lesson-comments';
import { LessonComment } from '../entities/LessonComments';

export const useLessonComments = () => {
  const { lessonId } = useParams();

  const [isLoading, setLoading] = useState(false);
  const [lessonComments, setLessonComments] = useState<LessonComment[]>([]);

  useEffect(() => {
    if (!lessonId) return;

    setLoading(true);
    fetchLessonComments(+lessonId)
      .then((res) => setLessonComments(res))
      .finally(() => setLoading(false));
  }, [lessonId]);

  const onCreateLessonComment = (
    {
      text,
      parent_id,
    }: {
      text: string;
      parent_id?: number;
    },
    calendar_id: number
  ) => {
    if (!lessonId || !calendar_id) return;

    const params = {
      calendar_id: +calendar_id,
      text,
      parent_id,
    };

    createLessonComment(calendar_id, params).then(() =>
      fetchLessonComments(+calendar_id).then((res) => setLessonComments(res))
    );
  };

  return {
    isLoading,
    lessonComments,
    onCreateLessonComment,
  };
};
