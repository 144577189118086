import React from 'react';
import LMSButton from 'components/LMSButton';

interface Props {
  title: string;
  desc: string;
  nextStep: () => void;
}

const PathfinderWelcome = ({ title, desc, nextStep }: Props) => {
  return (
    <div className="flex flex-col space-y-8 items-center justify-center w-[80%] max-w-[960px] h-[500px] rounded-[20px] theme-student-card m-auto">
      <div className="flex flex-col space-y-4 items-center">
        <p className="text-[32px] font-semibold text-center leading-10 max-w-[512px]">{title}</p>
        <p className="text-[22px] text-center leading-6 max-w-[670px] whitespace-pre-wrap">
          {desc}
        </p>
      </div>
      <LMSButton onClick={nextStep}>Далее</LMSButton>
    </div>
  );
};

export default PathfinderWelcome;
