import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowRight } from 'assets/icons/chevron-right.svg';
import LMSButton from 'components/LMSButton';
import { useNotification } from 'hooks/notification';

import CountQuicklyCongrats from '../../components/CountQuicklyCongrats';
import MathVortexWelcome from '../../components/MathVortexWelcome';
import Timer from '../../components/Timer';
import { COUNT_QUICKLY_QUESTIONS } from '../../constants';

const CountQuickly = () => {
  const notify = useNotification();
  const navigate = useNavigate();

  const [step, setStep] = useState<1 | 2 | 3 | 4>(1);
  const [answer, setAnswer] = useState<string>('');
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const [countCorrectAnswers, setCountCorrectAnswers] = useState<number>(0);
  const [startTime, setStartTime] = useState<number>(0);
  const [endTime, setEndTime] = useState<number>(0);
  const timer = 60;

  const handleNextQuestion = () => {
    const currentQuestion = COUNT_QUICKLY_QUESTIONS[currentQuestionIndex];
    const isCorrect = currentQuestion.answer.trim().toLowerCase() === answer.trim().toLowerCase();

    if (isCorrect) {
      setCountCorrectAnswers(countCorrectAnswers + 1);
    }

    if (COUNT_QUICKLY_QUESTIONS.length <= currentQuestionIndex + 1) {
      setAnswer('');
      setStep(4);
    } else {
      setAnswer('');
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const handleFinishTimer = () => {
    notify.error('Время вышло! Вы переходите на следующий уровень.');
    setTimeout(() => handleNextQuestion(), 2000);
  };

  const restartGame = () => {
    setCurrentQuestionIndex(0);
    setCountCorrectAnswers(0);
    setStep(3);
    const start = new Date();
    setStartTime(start.getTime());
    setEndTime(0);
  };

  const handleExit = () => navigate('/student');

  useEffect(() => {
    if (step === 3) {
      const start = new Date();
      setStartTime(start.getTime());
    }
    if (step === 4) {
      const end: Date = new Date();
      setEndTime(end.getTime() - startTime);
    }
  }, [step]);

  return (
    <div className="flex w-full h-full min-h-[100vh] relative">
      <div className="w-full h-full p-10 m-auto">
        {step === 1 ? (
          <MathVortexWelcome
            title='Добро пожаловать в игру "Считай быстро"'
            desc="Цель игры: помочь участникам развить навык быстро и точно выполнять в уме простые арифметические действия: сложение, вычитание, умножение и деление. Это помогает ни только повысить общую продуктивность при работе с цифрами, но и увеличивает производительность при приемке, отпуске и инвентаризации товаров."
            buttonLabel="Далее"
            nextStep={() => setStep(2)}
          />
        ) : step === 2 ? (
          <MathVortexWelcome title="Инструкция" nextStep={() => setStep(3)}>
            <div className="flex flex-col space-y-2 text-[18px] max-w-[700px] leading-6 text-center">
              <div className="mb-4">
                <p className="mb-4">1. Начало игры:</p>
                <p>Нажмите кнопку &ldquo;Начать&ldquo;, чтобы начать игру</p>
              </div>
              <div className="!mb-4">
                <p className="mb-4">2. Вопросы:</p>
                <p>
                  На экране будут появляться разные картинки и задания к данной картинке, вам нужно
                  быстро посчитать кол-во, и написать ответ.
                </p>
              </div>
              <div className="!mb-4">
                <p className="mb-4">3. Таймер:</p>
                <p>
                  На выполнение каждого задания отведено определенное время. Таймер с отчетом
                  времени показывается сколько времени у Вас есть. Убедитесь, что Вы выполнили
                  задание до истечения времени.
                </p>
              </div>
              <div className="!mb-4">
                <p className="mb-4">4. Результаты:</p>
                <p>
                  По окончании игры вы увидите свои результаты, включая количество правильных
                  ответов и время, затраченное на их выполнение.
                </p>
              </div>
              <div>
                <p className="mb-4">5. Повторение:</p>
                <p>
                  Если вы хотите улучшить свои результаты, вы можете пройти игру заново (до 50 раз)
                </p>
              </div>
            </div>
          </MathVortexWelcome>
        ) : step === 3 ? (
          <div className="flex flex-col w-[80%] max-w-[960px] h-max rounded-[20px] theme-student-card pt-10 px-14 pb-20 m-auto">
            {COUNT_QUICKLY_QUESTIONS.map((question, index) => {
              return index === currentQuestionIndex ? (
                <div key={index} className="flex flex-col space-y-8">
                  <div className="w-full flex flex-row justify-between items-center">
                    <p className="font-base opacity-50">Задание №{index + 1}</p>
                    <Timer time={timer} className="!text-[16px]" onClose={handleFinishTimer} />
                  </div>
                  <div className="flex flex-col items-center max-w-[800px] w-full mx-auto">
                    <p className="max-w-[450px] text-[24px] font-semibold text-center leading-8 mb-8">
                      {question.title}
                    </p>
                    <img
                      className=" max-w-[600px] w-full max-h-[400px] h-auto object-contain object-center mb-8"
                      alt={`count-quickly-${question.id}`}
                      src={question.image}
                    />
                    <input
                      type="number"
                      className="max-h-[52px] w-full max-w-[400px] py-3 px-6 text-current theme-input border border-0.5 border-opacity-50 rounded-[14px] outline-none border-white mb-10"
                      value={answer}
                      placeholder="Введите количество"
                      onChange={(e) => setAnswer(e.target.value)}
                    />
                    <LMSButton
                      className="self-center"
                      suffix={<ArrowRight />}
                      disabled={!answer}
                      onClick={handleNextQuestion}
                    >
                      Далее
                    </LMSButton>
                  </div>
                </div>
              ) : null;
            })}
          </div>
        ) : (
          <CountQuicklyCongrats
            countCorrectAnswers={countCorrectAnswers}
            countWrongAnswers={COUNT_QUICKLY_QUESTIONS.length - countCorrectAnswers}
            time={endTime}
            tryAgain={restartGame}
            onFinish={handleExit}
          />
        )}
      </div>
      <button
        type="button"
        className="h-[40px] px-4 flex flex-row justify-between items-center bg-[#F6F6F6]/50 rounded-[14px] outline-none text-black absolute top-5 right-5"
        onClick={handleExit}
      >
        Выйти из игры
      </button>
    </div>
  );
};

export default CountQuickly;
