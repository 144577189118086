import React from 'react';

import { TrainingBaseInList } from '../../entities/TrainingBase';

interface Props {
  trainingBase: TrainingBaseInList[];
}

const TrainingBaseTable: React.FC<Props> = ({ trainingBase }) => {
  return (
    <table className="bg-coachTableGradient w-full rounded-[20px] backdrop-blur-[60px] min-w-[980px] overflow-x-scroll">
      <thead className="block px-8 pt-4 pb-[10px] w-full bg-darkGray">
        <tr className="grid grid-cols-trainingBaseTable w-full gap-x-8">
          <td>ФИО ученика</td>
          <td>Компания</td>
          <td>ТОО</td>
          <td>ПРОЕКТ</td>
          <td>Должность</td>
          <td>программа</td>
          <td className="text-right">количество часов</td>
          <td>группа</td>
          <td className="text-right">Дата обучения</td>
          <td className="text-right">Дата завершения</td>
          <td className="text-right">Стоимость обучения, ₸ на 1 человека</td>
        </tr>
      </thead>

      <tbody>
        {trainingBase.length > 0 &&
          trainingBase.map((item) => (
            <tr
              className="grid grid-cols-trainingBaseTable w-full px-8 pt-4 pb-[10px] gap-x-8 border-t-[1px] border-coachTableBorder"
              key={item.id}
            >
              <td className="w-full flex items-center">
                <span className="w-full max-w-full truncate">{item.fullName}</span>
              </td>
              <td className="w-full flex items-center">
                <span className="w-full max-w-full truncate">{item.company}</span>
              </td>
              <td className="w-full flex items-center">
                <span className="w-full max-w-full truncate">{item.too}</span>
              </td>
              <td className="w-full flex items-center">
                <span className="w-full">{item.project}</span>
              </td>
              <td className="w-full flex items-center">
                <span className="w-full">{item.position}</span>
              </td>
              <td className="w-full flex items-center">
                <span className="w-full">{item.program}</span>
              </td>
              <td className="w-full flex items-center">
                <span className="w-full flex justify-end">{item.quantityHours}</span>
              </td>
              <td className="w-full flex items-center">
                <span className="w-full">{item.group}</span>
              </td>
              <td className="w-full flex items-center">
                <span className="w-full flex justify-end">
                  {new Date(item.dateTraining).toLocaleDateString()}
                </span>
              </td>
              <td className="w-full flex items-center">
                <span className="w-full flex justify-end">
                  {new Date(item.dateTraining).toLocaleDateString()}
                </span>
              </td>
              <td className="w-full flex items-center">
                <span className="w-full flex justify-end">{item.priceTraining}</span>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default TrainingBaseTable;
