import { useSearchParams } from 'react-router-dom';
import { usePartialState } from 'hooks/partial-state';

interface Filter {
  company: string | null;
  project: string | null;
  position: string | null;
  program: string | null;
  full_name: string | null;
  start_date: Date | string | null;
  end_date: Date | string | null;
}

export const useTrainingBaseFilter = () => {
  const [query, setQuery] = useSearchParams();

  const company = query.get('company');
  const project = query.get('project');
  const position = query.get('position');
  const program = query.get('program');
  const fullName = query.get('full_name');
  const startDate = query.get('start_date');
  const endDate = query.get('end_date');

  const [filter, setFilter] = usePartialState<Filter>({
    company: company || null,
    project: project || null,
    position: position || null,
    program: program || null,
    full_name: fullName || null,
    start_date: startDate || null,
    end_date: endDate || null,
  });

  const onSubmit = () => {
    Object.entries(filter).forEach(([key, value]) => {
      if (key && value !== null) {
        query.set(key, value.toString());
      } else {
        query.delete(key);
      }
    });

    query.set('page', '1');

    setQuery(query);
  };

  const onReset = () => {
    setFilter({
      company: null,
      project: null,
      position: null,
      program: null,
      full_name: null,
      start_date: null,
      end_date: null,
    });

    Object.entries(filter).forEach(([key]) => {
      query.delete(key);
    });

    query.set('page', '1');

    setQuery(query);
  };

  return {
    filter,
    setFilter,
    onSubmit,
    onReset,
  };
};
