import { useSearchParams } from 'react-router-dom';
import { usePartialState } from 'hooks/partial-state';

interface Filter {
  direction: number | null;
  format: number | null;
  priceStart: number;
  priceEnd: number;
}

export const useCoursesFilter = () => {
  const [query, setQuery] = useSearchParams();

  const direction = query.get('direction');
  const format = query.get('format');
  const priceStart = query.get('priceStart');
  const priceEnd = query.get('priceEnd');

  const [filter, setFilter] = usePartialState<Filter>({
    direction: direction ? +direction : null,
    format: format ? +format : null,
    priceStart: priceStart ? +priceStart : 0,
    priceEnd: priceEnd ? +priceEnd : 400000,
  });

  const onSubmit = () => {
    Object.entries(filter).forEach(([key, value]) => {
      if (value) {
        query.set(key, value.toString());
      } else {
        query.delete(key);
      }
    });

    query.set('page', '1');

    setQuery(query);
  };

  const onReset = () => {
    setFilter({
      direction: null,
      format: null,
      priceStart: 0,
      priceEnd: 400000,
    });

    Object.entries(filter).forEach(([key]) => {
      query.delete(key);
    });

    query.set('page', '1');

    setQuery(query);
  };

  return {
    filter,
    setFilter,
    onSubmit,
    onReset,
  };
};
