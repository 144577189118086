import React from 'react';
import { ReactComponent as ArrowIcon } from 'assets/icons/chevron-right.svg';
import { ReactComponent as ReloadIcon } from 'assets/icons/reload.svg';
import LMSButton from 'components/LMSButton';

import { Result } from '../../entities/Result';
import Speedometer from '../Speedometer';

interface Props {
  result: Result;
  reTest: () => void;
}

const TestResult: React.FC<Props> = ({ result, reTest }) => {
  const jumpToNextLesson = () => {
    console.log('jump');
  };
  return (
    <div className="flex flex-col md:flex-row items-center md:items-start md:space-x-10 p-4 md:p-10 rounded-2xl theme-student-card">
      <Speedometer result={result} />

      <div className="flex flex-col">
        <p className="mb-6 text-3xl md:text-4xl text-center md:text-left font-semibold uppercase">
          {result.isPassed ? 'Тест пройден' : 'Тест не пройден '}
        </p>
        <p className="flex-1 text-center md:text-left theme-light-text">
          Вы можете перейти к следующему уроку или улучшить свой результат, пройдя тест заново
        </p>
        <div className="flex flex-col md:flex-row space-y-2.5 md:space-y-0 md:space-x-2.5 mt-10">
          <LMSButton className="theme-background--grey" prefix={<ReloadIcon />} onClick={reTest}>
            ПРОЙТИ ЗАНОВО
          </LMSButton>
          {/* {result.isPassed ? (
            <LMSButton onClick={jumpToNextLesson} suffix={<ArrowIcon />}>
              ПРОДОЛЖИТЬ
            </LMSButton>
          ) : null} */}
        </div>
      </div>
    </div>
  );
};

export default TestResult;
