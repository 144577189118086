import { INewCourseCoach } from 'modules/hr/entities/Course';
import request from 'api/axios-instance';

export const createCourse = (data: Record<string, string | number | INewCourseCoach[]>) => {
  return request.post('v1/management/courses', data).then((res) => res.data);
};

export const editCourse = (
  id: number,
  data: Record<string, string | number | INewCourseCoach[]>
) => {
  return request.post(`v1/management/courses/${id}`, data).then((res) => res.data);
};
