import { useEffect, useState } from 'react';
import { registerLocale } from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import ReactQuill from 'react-quill';
import { useNavigate } from 'react-router-dom';
import LMSButton from 'components/LMSButton';
import LMSTitle from 'components/LMSTitle';
import ru from 'date-fns/locale/ru';
import { fetchLessons } from 'modules/coach/api/repository/lessons';
import { sendHomeWork } from 'modules/coach/api/repository/send-homework';
import { INewLesson } from 'modules/coach/entities/Lessons';
import { ISendHomeWork } from 'modules/coach/entities/SendHomework';
import useFileControl from 'modules/coach/hooks/useFileControl';
import useNewFileUploader from 'modules/coach/hooks/useNewFileUploader';
import useNewVideoUploader from 'modules/coach/hooks/useNewVideoUploader';
import useHandleError from 'hooks/handleError';
import { useNotification } from 'hooks/notification';
import { ErrorResponse } from 'entities/Error';

import DropdownSelect from '../DropdownSelect';
import UploadFile from '../UploadFile';

import { fileTypes, toolbarOptions, videoTypes } from './../../constants/index';

registerLocale('ru', ru);

const AddNewHomework = () => {
  const { control, handleSubmit, setValue } = useForm<ISendHomeWork>();
  const [loading, setLoading] = useState<boolean>(false);
  const [lessons, setLessons] = useState<INewLesson[]>([]);
  const [lessonName, setLessonName] = useState('');
  const { newFiles, newVideo, controlFiles, controlVideo, deleteNewVideo, deleteNewFiles } =
    useFileControl();
  const { uploadedFilePaths, upload } = useNewFileUploader();
  const { uploadedVideoPaths, uploadVideo } = useNewVideoUploader();
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [isVideoUploading, setIsVideoUploading] = useState(false);
  const navigate = useNavigate();
  const notify = useNotification();
  const { handleCommonError } = useHandleError();

  const handleChangeVideos = async (files: File[], isDelete?: false) => {
    if (isDelete) {
      deleteNewVideo(files);
      return;
    }

    controlVideo(files);
    setIsVideoUploading(true);

    try {
      await uploadVideo(files);
      setIsVideoUploading(false);
    } catch (error) {
      setIsVideoUploading(false);
    }
  };

  const handleChangeFiles = async (files: File[], isDelete?: false) => {
    if (isDelete) {
      deleteNewFiles(files);
      return;
    }

    controlFiles(files);
    setIsFileUploading(true);

    try {
      await upload(files);
      setIsFileUploading(false);
    } catch (error) {
      setIsFileUploading(false);
    }
  };

  useEffect(() => {
    fetchLessons()
      .then((res) => {
        setLessons(res);
      })
      .catch((err: unknown) => {
        const error = err as ErrorResponse;
        handleCommonError(error);
      });
  }, []);

  const onSubmit = async (formData: ISendHomeWork) => {
    setLoading(true);

    const files = newFiles.map((f) => f.name);
    const finalFiles = uploadedFilePaths
      .filter((f) => {
        return files.includes(f.file.name);
      })
      .map((f) => f.path);

    const videos = newVideo.map((f) => f.name);
    const finalVideos = uploadedVideoPaths
      .filter((f) => {
        return videos.includes(f.file.name);
      })
      .map((f) => f.path);

    const updatedFormData = {
      name: formData.name,
      lesson_id: formData.lesson_id,
      description: '<div style="color: white;">' + formData.description + '</div>',
      file_url: finalFiles,
      video_url: finalVideos,
    };

    try {
      await sendHomeWork(updatedFormData);
      notify.success('Домашнее задание успешно создано');
      setTimeout(() => navigate('/coach/homeworks'), 600);
    } catch (err) {
      const error = err as ErrorResponse;
      if (error?.response?.status === 422) {
        notify.error('У этого урока уже есть домашнее задание');
      } else {
        notify.error(
          'Не удалось создать урок, повторите еще раз или обратитесь в службу поддержки'
        );
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-row justify-between items-end">
        <LMSTitle tag="h4" className="text-current text-3xl">
          Новое домашнее задание
        </LMSTitle>
        <LMSButton
          loading={loading}
          onClick={handleSubmit(onSubmit)}
          className="!fixed bottom-0 left-0 right-0 py-4 rounded-none sm:!relative sm:rounded-full"
        >
          Создать задание
        </LMSButton>
      </div>
      <form
        onSubmit={(e) => e.preventDefault()}
        className="w-full h-full flex flex-col gap-y-5 mt-10 mb-20 sm:mb-0"
      >
        <div className="w-full flex flex-col gap-5 p-5 theme-coach-background--gn rounded-3xl sm:flex-row sm:p-10">
          <div className="w-full rounded-[14px]">
            <Controller
              name="lesson_id"
              control={control}
              rules={{
                required: { message: 'Выберите урок', value: true },
              }}
              render={({ fieldState: { error } }) => (
                <DropdownSelect
                  notRounded={true}
                  title="Выберите урок"
                  value={lessonName}
                  list={
                    lessons.length > 0 ? lessons.map(({ id, name }) => ({ id, name: name })) : []
                  }
                  error={error?.message}
                  onCourseClick={(id, name) => {
                    setLessonName(name);
                    setValue('lesson_id', id);
                  }}
                />
              )}
            />
          </div>
          <div className="w-full rounded-[14px]">
            <Controller
              name="name"
              control={control}
              rules={{
                required: { message: 'Введите название домашнего задания', value: true },
              }}
              render={({ field, fieldState: { error } }) => (
                <div className="w-full rounded-[14px]">
                  <input
                    type="text"
                    placeholder="Введите название домашнего задания"
                    {...field}
                    className={`w-full max-h-[52px] py-3 px-6 text-current theme-input border border-0.5 border-opacity-50 rounded-[14px] outline-none ${
                      error ? 'border-red-500' : 'border-white'
                    }`}
                    // onChange={(data) => setValue('name', data)}
                  />
                  {error && <p className="text-red-500">{error.message}</p>}{' '}
                </div>
              )}
            />
          </div>
        </div>

        <div className="flex flex-col gap-y-5 p-5 theme-coach-background--gn rounded-3xl sm:p-10">
          <div className="flex flex-row justify-between">
            <p className="text-current text-2xl">Описание задания</p>
          </div>
          <div className="mt-7 couch-editor">
            <Controller
              name="description"
              control={control}
              render={() => (
                <ReactQuill
                  placeholder={
                    'Опишите, что нужно сделать и какой результат вы ждете от студента. Прикрепите дополнительные материалы, если необходимо'
                  }
                  style={{ color: '#fff' }}
                  modules={{
                    toolbar: toolbarOptions,
                  }}
                  onChange={(data) => setValue('description', data)}
                />
              )}
            />
          </div>
        </div>

        <div className="flex flex-col gap-y-5 p-5 theme-coach-background--gn rounded-3xl sm:p-10">
          <span className="text-current text-xl font-medium">Загрузка файлов и видео</span>
          <div className="w-full flex flex-col gap-5 sm:flex-row">
            <div className="w-full sm:w-1/2">
              <UploadFile
                uploading={isFileUploading}
                title="Загрузить файл"
                hint="PNG, DOC, EXCEL, PDF, JPEG"
                types={fileTypes}
                isMultiple={true}
                files={newFiles}
                handleChange={handleChangeFiles}
              />
            </div>
            <div className="w-full sm:w-1/2">
              <UploadFile
                uploading={isVideoUploading}
                title="Загрузите видео"
                hint="Ограничение до 1 гб"
                types={videoTypes}
                isMultiple={true}
                files={newVideo}
                handleChange={handleChangeVideos}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddNewHomework;
