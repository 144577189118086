import { Navigate, Outlet } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import ChangeRoleButton from 'components/ChangeRoleButton';
import SideBar from 'modules/coach/components/SideBar';
import SideBarMobile from 'modules/coach/components/SideBar/SideBarMobile';
import { useAppSelector } from 'hooks/redux';

const CoachLayout = () => {
  const { user } = useAppSelector((state) => state.auth);
  const [query, setQuery] = useSearchParams();

  const THEME = query.get('theme') === 'light';
  const isTestIT =
    window.location.href.includes('test-it') || window.location.href.includes('cte-platform');

  const isLight = isTestIT || THEME;

  if (!user) {
    return <Navigate to="/auth/login" />;
  }

  if (user.currentRole === 'student') {
    return <Navigate to="/student" />;
  }

  if (user.currentRole === 'hr') {
    return <Navigate to="/hr/dashboard" />;
  }

  return (
    <div className={`relative min-h-[100dvh] w-full theme ${isLight && 'light-theme'}`}>
      <div className="w-full flex flex-row">
        <div className="hidden fixed lg:inset-0 lg:h-[100vh] lg:block lg:w-[36%] lg:p-10 xl:w-[30%] 2xl:w-[26%]">
          <SideBar />
        </div>
        <div className="w-full relative z-10 lg:hidden">
          <SideBarMobile />
        </div>
        <div
          className="flex flex-col fixed right-0 w-full p-0 lg:w-[68%] xl:w-[73%] 2xl:w-[76%] overflow-scroll"
          style={{ maxHeight: '100vh' }}
        >
          <div className="p-6 md:p-10">
            <div className="hidden lg:block">
              <ChangeRoleButton />
            </div>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoachLayout;
