import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as AddIcon } from 'assets/icons/add.svg';
import { ReactComponent as ArrowBack } from 'assets/icons/chevron-left.svg';
// import { ReactComponent as SettingsIcon } from 'assets/icons/settings.svg';
import Loader from 'components/Loader';
import { fetchModules } from 'modules/coach/api/repository/courses/modules';
import { fetchStudents } from 'modules/coach/api/repository/courses/students';
import Modules from 'modules/coach/components/courses/Modules';
import Users from 'modules/coach/components/courses/Users';
import CreateModuleModal from 'modules/coach/components/CreateModuleModal';
import { ICoursesModules, IModules } from 'modules/coach/entities/coach-courses/Modules';
import { ICourse, ICourseStudent } from 'modules/coach/entities/coach-courses/Students';
import { useNotification } from 'hooks/notification';

const CoachCourseDetail = () => {
  const navigate = useNavigate();
  const notify = useNotification();
  const { id, groupId } = useParams();

  const [activeTab, setActiveTab] = useState<number>(0);
  const [modules, setModules] = useState<ICoursesModules>();
  const [students, setStudents] = useState<ICourse>();
  const [isLoading, setLoading] = useState(true);
  const [isShowCreateModuleModal, setIsShowCreateModuleModal] = useState<boolean>(false);

  const switchTab = (tab: number) => {
    setActiveTab(tab);
  };

  const onFetchCourseModules = () => {
    setLoading(true);
    fetchModules(Number(groupId), Number(id))
      .then((res) => setModules(res))
      .catch(() => notify.error('Произошла ошибка. Обратитесь в службу поддержки'))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    Promise.all([
      fetchModules(Number(groupId), Number(id)),
      fetchStudents(Number(groupId), Number(id)),
    ])
      .then(([resModules, resStudents]) => {
        setModules(resModules);
        setStudents(resStudents);
      })
      .catch(() => notify.error('Произошла ошибка. Обратитесь в службу поддержки'))
      .finally(() => setLoading(false));
  }, [id, groupId]);

  return (
    <div className="w-full h-full flex flex-col pt-10 lg:pt-6">
      <button
        type="button"
        onClick={() => navigate('/coach/courses')}
        className="w-fit flex flex-row items-center justify-center gap-x-2 py-3 pl-3 pr-5 theme-text--default text-[14px] font-medium theme-default-button rounded-[36px] border-none outline-none uppercase"
      >
        <ArrowBack />
        назад
      </button>
      <div className="flex flex-col pt-10">
        <div className="grid grid-cols-2 grid-rows-2 gap-7">
          <div className="grid place-content-start">
            <span className="text-base font-medium theme-text--default opacity-40">
              Название курса
            </span>
            <h5 className="text-2xl font-semibold theme-text--default">{modules?.name}</h5>
          </div>
          {modules && modules.dateFrom && modules.dateTo && (
            <div className="grid place-content-end">
              <span className="text-base font-medium theme-text--default opacity-40">
                Период обучения
              </span>
              <span className="text-xl font-normal theme-text--default">
                {new Date(modules.dateFrom).toLocaleDateString()}-
                {new Date(modules.dateTo).toLocaleDateString()}
              </span>
            </div>
          )}
          <div className="grid place-content-start">
            <span className="text-base font-medium theme-text--default opacity-40">
              Название группы
            </span>
            <h5 className="text-2xl font-semibold theme-text--default">{modules?.groupName}</h5>
          </div>
          {/* <div className="grid place-content-end">
            <div className="flex flex-row justify-between items-center gap-x-4 py-1.5 px-3.5 text-base font-medium theme-text--default border rounded-full cursor-pointer">
              Статистика курса
              <SettingsIcon />
            </div>
          </div> */}
        </div>
        <div className="pt-16">
          <div className="flex flex-row">
            {['Модули', 'Пользователи'].map((el, idx) => (
              <div
                key={idx}
                className={`${
                  activeTab === idx
                    ? 'theme-coach-background-courses-tab'
                    : 'bg-transparent border-t border-l border-r'
                } py-4 px-7 text-lg theme-text--default rounded-tr-xl rounded-tl-xl cursor-pointer`}
                onClick={() => switchTab(idx)}
              >
                {el.toString()}
              </div>
            ))}
          </div>
          <div className="w-full h-full flex flex-col">
            {isLoading ? (
              <Loader />
            ) : modules?.modules.length === 0 ? (
              <p className="pt-10 theme-text--default text-xl">Модулей не найдено</p>
            ) : activeTab === 0 ? (
              <Modules
                modules={modules?.modules as IModules[]}
                onFetchCourseModules={onFetchCourseModules}
              />
            ) : (
              <Users students={students?.students as ICourseStudent[]} />
            )}
          </div>
          {activeTab === 0 && (
            <button
              type="button"
              className="w-fit h-fit flex flex-row items-center gap-3 mt-9 px-5 py-3 theme-text--default text-base border theme-outline-button rounded-full"
              onClick={() => setIsShowCreateModuleModal(true)}
            >
              <AddIcon />
              Создать модуль
            </button>
          )}
        </div>
      </div>

      {isShowCreateModuleModal ? (
        <CreateModuleModal
          onClose={() => setIsShowCreateModuleModal(false)}
          onCallback={onFetchCourseModules}
        />
      ) : null}
    </div>
  );
};

export default CoachCourseDetail;
