import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as NoAvatar } from 'assets/icons/no-avatar.svg';
import { IStudent } from 'modules/coach/entities/Students';

type Props = {
  lessonId: number;
  students: IStudent[];
};

type SortDirection = 'asc' | 'desc';
type SortField = 'firstLastName' | 'passed' | 'passDate';

const GroupStudentsTable: FC<Props> = ({ students, lessonId }) => {
  const [sortedStudents, setSortedStudents] = useState<IStudent[]>(students);
  const [sortField, setSortField] = useState<SortField | undefined>();
  const [sortDirection, setSortDirection] = useState<SortDirection>('asc');
  const navigate = useNavigate();

  const compareName = (a: IStudent, b: IStudent, direction: SortDirection) => {
    return direction === 'asc'
      ? a.firstLastName.localeCompare(b.firstLastName)
      : b.firstLastName.localeCompare(a.firstLastName);
  };

  const comparePassed = (a: IStudent, b: IStudent, direction: SortDirection) => {
    if (a.passed === b.passed) {
      return 0;
    }
    if (direction === 'asc') {
      return a.passed ? -1 : 1;
    }
    return b.passed ? -1 : 1;
  };

  const comparePassDate = (a: IStudent, b: IStudent, direction: SortDirection) => {
    if (!a.passDate && !b.passDate) return 0;
    if (!a.passDate) return direction === 'asc' ? -1 : 1;
    if (!b.passDate) return direction === 'asc' ? 1 : -1;

    const dateA = parseInt(a.passDate.slice(0, 2), 10);
    const dateB = parseInt(b.passDate.slice(0, 2), 10);

    return direction === 'asc' ? dateA - dateB : dateB - dateA;
  };

  const sortData = (field: SortField) => {
    const newDirection = sortField === field && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortField(field);
    setSortDirection(newDirection);

    const sorted = [...sortedStudents].sort((a: IStudent, b: IStudent) => {
      switch (field) {
        case 'firstLastName':
          return compareName(a, b, newDirection);
        case 'passed':
          return comparePassed(a, b, newDirection);
        case 'passDate':
          return comparePassDate(a, b, newDirection);
        default:
          return 0;
      }
    });

    setSortedStudents(sorted);
  };

  const redirectToHomeWork = (studentId: number, lessonId: number) => {
    navigate(`/coach/homework/hometask/${studentId}/${lessonId}`);
  };

  return (
    <table className="table-auto w-full mb-10">
      <thead className="bg-white bg-opacity-30">
        <tr className="h-10 text-white text-base">
          <th className="w-[10%]">№</th>
          <th onClick={() => sortData('firstLastName')} className="w-[50%] text-left">
            Студент
            {sortField === 'firstLastName' && sortDirection === 'asc' ? '▲' : '▼'}
          </th>
          <th onClick={() => sortData('passed')} className="w-[20%] text-left">
            Статус сдачи
            {sortField === 'passed' && sortDirection === 'asc' ? '▲' : '▼'}
          </th>
          <th onClick={() => sortData('passDate')} className="w-[20%] text-left">
            Дата сдачи ДЗ
            {sortField === 'passDate' && sortDirection === 'asc' ? '▲' : '▼'}
          </th>
        </tr>
      </thead>
      <tbody>
        {sortedStudents &&
          sortedStudents.map((el, index) => {
            return (
              <tr
                key={index}
                onClick={() => redirectToHomeWork(el.id, lessonId)}
                className="py-4 border-b border-opacity-20 border-b-white hover:bg-white hover:bg-opacity-10 last-of-type:border-b-0"
              >
                <td className="text-center text-white">{index + 1}</td>
                <td className="flex flex-row items-center gap-x-4 py-2 text-white text-lg">
                  {el.avatar ? (
                    <img
                      src={el.avatar}
                      alt="Student"
                      className="w-10 h-10 rounded-full object-fill"
                    />
                  ) : (
                    <NoAvatar />
                  )}
                  {el.firstLastName}
                </td>
                <td className="text-left text-white">{el.passed ? 'сдал' : 'не сдал'}</td>
                <td className="text-left text-white">{el.passDate?.slice(0, 10)}</td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

export default GroupStudentsTable;
