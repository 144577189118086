import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as ArrowBack } from 'assets/icons/chevron-left.svg';
import Loader from 'components/Loader';
import { useNotification } from 'hooks/notification';
import { Option } from 'entities/Option';

import { fetchHandbookSimulationCatalog } from '../../api/repository/handbooks';
import { fetchSimulation } from '../../api/repository/simulations';
import AddOrEditSimulation from '../../components/AddOrEditSimulation';
import { ISimulationDetail } from '../../entities/Simulation';

const EditSimulation = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const notify = useNotification();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [categories, setCategories] = useState<Option[]>([]);
  const [simulation, setSimulation] = useState<ISimulationDetail | null>(null);

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    setIsLoading(true);
    Promise.all([fetchHandbookSimulationCatalog(), fetchSimulation(Number(id))])
      .then(([resCatalog, resSimulation]) => {
        setCategories(resCatalog.categories);
        setSimulation(resSimulation);
      })
      .catch(() => {
        notify.error('Произошла ошибка. Обратитесь в службу поддержки');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <div className="w-full h-full flex flex-col mt-20 lg:mt-6">
      <button
        type="button"
        onClick={() => goBack()}
        className="w-fit flex flex-row items-center justify-center gap-x-2 py-3 pl-3 pr-5 text-current text-[14px] font-medium theme-default-button rounded-[36px] border-none outline-none uppercase"
      >
        <ArrowBack />
        назад
      </button>
      <div className="flex flex-col mt-10">
        {isLoading ? (
          <Loader />
        ) : (
          <AddOrEditSimulation categories={categories} simulation={simulation} />
        )}
      </div>
    </div>
  );
};

export default EditSimulation;
