import React, { useState } from 'react';
import { ReactComponent as ArrowUpIcon } from 'assets/icons/chevron-up.svg';

interface Props {
  children: React.ReactNode;
  title: string;
}

const CourseProgress: React.FC<Props> = ({ children, title }) => {
  const [isExpanded, setExpanded] = useState(true);

  return (
    <div className="w-full rounded-2xl bg-studentCardBg">
      <button
        className="flex items-center justify-between w-full p-4 md:p-10"
        onClick={() => setExpanded((prev) => !prev)}
      >
        <span className="text-xl md:text-2xl">{title}</span>
        <ArrowUpIcon className={`transition duration-500 ${isExpanded ? '' : 'rotate-180'}`} />
      </button>

      {isExpanded && <div className="w-full px-4 pb-4 md:px-10 md:pb-10">{children}</div>}
    </div>
  );
};

export default CourseProgress;
