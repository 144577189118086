import { useEffect, useState } from 'react';
import { Option } from 'entities/Option';

import { fetchStudents } from '../api/repository/handbooks';

export const useCatalogStudents = () => {
  const [isLoading, setLoading] = useState(false);
  const [students, setStudents] = useState<Option[]>([]);

  useEffect(() => {
    setLoading(true);
    fetchStudents()
      .then((res) => setStudents(res))
      .catch((e) => console.warn(e))
      .finally(() => setLoading(false));
  }, []);

  return {
    isLoading,
    students,
  };
};
