import request from 'api/axios-instance';

import { Faq } from '../../entities/Faq';
import { FaqDao } from '../dao/Faq.dao';

export const fetchFaqs = () =>
  request.get('v1/faqs').then((res) => {
    const faqs: Faq[] = res.data.data.map(mapFaqDaoToEntity);
    return faqs;
  });

export const createQuestion = (content: string) => request.post('v1/questions', { content });

function mapFaqDaoToEntity(faq: FaqDao): Faq {
  return {
    id: faq.id,
    title: faq.title,
    description: faq.description,
  };
}
