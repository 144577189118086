import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseEyeIcon } from 'assets/icons/close-eye.svg';
import { ReactComponent as OpenEyeIcon } from 'assets/icons/open-eye.svg';
import LMSInput from 'components/LMSInput';

import CouchButton from '../CouchButton';
// import PasswordFormInput from '../PasswordFormInput';

type Visibility = {
  password: boolean;
  newPassword: boolean;
  repeatPassword: boolean;
};

const UpdatePasswordForm = () => {
  const { t } = useTranslation();
  const [visibility, setVisibility] = useState<Visibility>({
    password: false,
    newPassword: false,
    repeatPassword: false,
  });

  const [passwordValue, setPasswordValue] = useState('');
  const [newPasswordValue, setNewPasswordValue] = useState('');
  const [repeatPasswordValue, setRepeatPasswordValue] = useState('');

  const {
    formState: { isSubmitting },
    handleSubmit,
    control,
  } = useForm({
    defaultValues: {
      password: '',
    },
  });

  const createVisibilitySuffix = (field: keyof Visibility) => {
    const IconComponent = visibility[field] ? CloseEyeIcon : OpenEyeIcon;
    return (
      <IconComponent
        className="cursor-pointer"
        onClick={() => setVisibility((prevState) => ({ ...prevState, [field]: !prevState[field] }))}
      />
    );
  };

  const checkLabel = (label: string) => {
    switch (label) {
      case 'password':
        return 'Текущий пароль';
      case 'newPassword':
        return 'Новый пароль';
      default:
        return 'Повторите пароль';
    }
  };

  const checkValue = (value: string) => {
    switch (value) {
      case 'password':
        return passwordValue;
      case 'newPassword':
        return newPasswordValue;
      default:
        return repeatPasswordValue;
    }
  };

  const checkPlaceholder = (hint: string) => {
    switch (hint) {
      case 'password':
        return '';
      case 'newPassword':
        return 'Введите пароль (мин 6 символов)';
      default:
        return 'Введите пароль повторно';
    }
  };

  // Пока не понятно, есть ли апишка и будет ли этот функционал задизейблин (функционал смены пароля)
  const onSubmit = async (data: Record<string, string>) => {};

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-full px-5 py-7 theme-coach-background--gn rounded-[20px] md:max-w-[450px] md:p-10"
    >
      <div className="flex flex-col gap-y-8">
        {['password', 'newPassword', 'repeatPassword'].map((field: string) => (
          <Controller
            key={field}
            control={control}
            name={'password'}
            rules={{
              required: { message: t('error.required'), value: true },
              minLength: { message: t('error.minLength', { len: 6 }), value: 6 },
            }}
            render={({ field: { onChange, onBlur, name, ref }, fieldState: { error } }) => (
              <LMSInput
                label={checkLabel(field)}
                type={visibility[field as keyof Visibility] ? 'text' : 'password'}
                ref={ref}
                name={name}
                value={checkValue(field)}
                placeholder={checkPlaceholder(field)}
                required
                suffix={createVisibilitySuffix(field as keyof Visibility)}
                onChange={(val) => {
                  if (field === 'password') setPasswordValue(val);
                  else if (field === 'newPassword') setNewPasswordValue(val);
                  else if (field === 'repeatPassword') setRepeatPasswordValue(val);
                  onChange(val);
                }}
                onBlur={onBlur}
                error={error?.message}
              />
            )}
          />
        ))}
      </div>

      <CouchButton loading={isSubmitting}>ПОМЕНЯТЬ ПАРОЛЬ</CouchButton>
    </form>
  );
};

export default UpdatePasswordForm;
