import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as AddIcon } from 'assets/icons/add.svg';
import LMSTitle from 'components/LMSTitle';
import Loader from 'components/Loader';
import { fetchHomeworks } from 'modules/coach/api/repository/homeworks';
import HomeWorkCard from 'modules/coach/components/HomeWorkCard';
import { IHomeWork } from 'modules/coach/entities/HomeWorks';
import { useNotification } from 'hooks/notification';

export const emptyIcon = 'assets/images/empty-image.png';

const HomeWorks = () => {
  const [homeWorks, setHomeWorks] = useState<IHomeWork[]>([]);
  const [isLoading, setLoading] = useState(true);
  const notify = useNotification();

  useEffect(() => {
    fetchHomeworks()
      .then((res) => setHomeWorks(res))
      .catch(() => notify.error('Произошла ошибка. Обратитесь в службу поддержки'))
      .finally(() => setLoading(false));
  }, []);

  return (
    <div className="w-full mt-20 lg:mt-0">
      <div className="flex flex-col pt-6">
        <div className="flex flex-row justify-between items-center">
          <LMSTitle tag="h4" className="mt-auto text-current text-3xl">
            Домашние задания
          </LMSTitle>
          <NavLink
            to="/coach/homeworks/new-homework"
            className="h-fit w-fit flex flex-row items-center justify-center gap-x-2 py-3 px-6 theme-default-button--active text-[12px] font-bold uppercase  rounded-[12px] md:relative md:rounded-[30px]"
          >
            <AddIcon />
            <span className="hidden md:block">НОВОЕ ЗАДАНИЕ</span>
          </NavLink>
        </div>

        <div className="w-full flex flex-col gap-y-4 pt-9">
          {isLoading && <Loader theme="dark" />}
          {homeWorks ? (
            homeWorks?.map((el) => <HomeWorkCard key={el.id} data={el} />)
          ) : (
            <div className="text-current">Домашние задания отстуствуют</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomeWorks;
