import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as CloseEyeIcon } from 'assets/icons/close-eye.svg';
import { ReactComponent as CupIcon } from 'assets/icons/cup-blue.svg';
import { ReactComponent as WairlessIcon } from 'assets/icons/hairless.svg';
import { ReactComponent as OpenEyeIcon } from 'assets/icons/open-eye.svg';
import { ReactComponent as HRIcon } from 'assets/icons/user-blue.svg';
import LMSTitle from 'components/LMSTitle';
import { authSlice } from 'store/auth';
import useHandleError from 'hooks/handleError';
import { useNotification } from 'hooks/notification';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { login } from 'api/repositories/users.repository';
import { sendRole } from 'api/repositories/users.repository';
import { ErrorResponse } from 'entities/Error';
import { LoginPayload } from 'entities/User';

import AuthButton from '../components/AuthButton';
import AuthInput from '../components/AuthInput';

const Login = () => {
  const { user } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const notify = useNotification();
  const { handleCommonError } = useHandleError();
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [isNewStep, setIsNewStep] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { handleSubmit, control } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  useEffect(() => {
    if (!user) return;
    if (user.roles.length > 1) {
      setIsNewStep(true);
    } else {
      setIsNewStep(false);
      const path = user.roles[0].name === 'coach' ? 'coach/schedule' : `${user.roles[0].name}`;
      navigate(location.state?.from ?? `/${path}`);
    }
  }, [user]);

  const passwordSuffix = useMemo(() => {
    if (passwordVisible) {
      return <CloseEyeIcon className="cursor-pointer" onClick={() => setPasswordVisible(false)} />;
    }
    return <OpenEyeIcon className="cursor-pointer" onClick={() => setPasswordVisible(true)} />;
  }, [passwordVisible]);

  const onSubmit = async (data: Record<string, string>) => {
    try {
      setIsLoading(true);
      const response = await login(data.email, data.password);
      dispatch(authSlice.actions.login(response));
    } catch (err: unknown) {
      const error = err as ErrorResponse;
      if (error.response?.status === 400) {
        notify.error(t('error.wrongAuth'));
      } else {
        handleCommonError(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const modifiedRolesName: Record<string, { name: string; icon: React.ReactNode }> = {
    coach: { name: 'Тренер', icon: <CupIcon /> },
    student: { name: 'Слушатель', icon: <WairlessIcon /> },
    hr: { name: 'HR', icon: <HRIcon /> },
  };

  const handleRoleSelection = (role: string) => {
    if (!user) return;

    const id = user.roles.find((r) => r.name === role)?.id;
    sendRole(id || user.roles[0].id).then((res) => {
      dispatch(authSlice.actions.updateUser(res));
    });

    // dispatch(authSlice.actions.userFetchingSuccess({ ...user, currentRole: role }));

    navigate(location.state?.from ?? role === 'coach' ? `/${role}/schedule` : `/${role}`);
  };

  return (
    <div className="w-full flex flex-col justify-center items-center gap-y-8">
      <LMSTitle tag="h2" className="text-current text-4xl font-normal sm:text-44px">
        {isNewStep ? 'Выберите роль' : 'Авторизация'}
      </LMSTitle>

      {!isNewStep && (
        <form
          className="max-w-[500px] w-full p-8 rounded-[20px] flex flex-col gap-y-[36px] theme-student-card sm:p-[40px] md:p-[60px]"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Controller
            control={control}
            name="email"
            rules={{
              required: { message: t('error.required'), value: true },
            }}
            render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
              <AuthInput
                label="Имя пользователя"
                ref={ref}
                name={name}
                value={value}
                required
                onChange={onChange}
                onBlur={onBlur}
                error={error?.message}
              />
            )}
          />
          <Controller
            control={control}
            name="password"
            rules={{
              required: { message: t('error.required'), value: true },
              minLength: { message: t('error.minLength', { len: 6 }), value: 6 },
            }}
            render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
              <AuthInput
                label="Пароль"
                type={passwordVisible ? 'text' : 'password'}
                ref={ref}
                name={name}
                value={value}
                required
                suffix={passwordSuffix}
                onChange={onChange}
                onBlur={onBlur}
                error={error?.message}
              />
            )}
          />
          {/* <p className="-mt-5">
            <Link to="/auth/reset-password" className="text-sm text-blue">
              Забыли пароль?
            </Link>
          </p> */}
          <AuthButton loading={isLoading}>{t('action.signIn')}</AuthButton>

          {/* <p className="-mt-5 text-white text-base text-center font-normal">
            У вас нет аккаунта?
            <Link to="/auth/registration" className="pl-1 text-base font-semibold text-blue">
              Регистрация
            </Link>
          </p> */}
        </form>
      )}

      {isNewStep ? (
        <div className="bg-white rounded-[20px] overflow-hidden">
          <div className="flex flex-row overflow-hidden">
            {user?.roles.map((role, idx) => {
              const translatedRole = modifiedRolesName[role.name] || {
                name: role.display_name,
                icon: null,
              };
              return (
                <div
                  key={idx}
                  onClick={() => handleRoleSelection(role.name)}
                  className="cursor-pointer border-b last-of-type:border-none"
                >
                  <div className="flex flex-row items-center gap-x-3 p-4 text-black hover:bg-gray-200">
                    {translatedRole.icon} {translatedRole.name}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Login;
