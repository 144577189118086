import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LMSButton from 'components/LMSButton';
import LMSPagination from 'components/LMSPagination';
import ProgressBar from 'components/ProgressBar';
import { ISimulationDetail } from 'modules/coach/entities/Simulation';
import { fetchGameResults, TData } from 'modules/student/api/repository/games';
import { useNotification } from 'hooks/notification';
import { useAppSelector } from 'hooks/redux';

interface Props {
  simulation: ISimulationDetail;
}

const LectioResult = ({ simulation }: Props) => {
  const { user } = useAppSelector((state) => state.auth);
  const notify = useNotification();
  const navigate = useNavigate();

  const [stats, setStats] = useState<TData[]>([]);
  const [page, setPage] = useState<number>(1);
  const [lastPage, setLastPage] = useState<number>(1);

  const parseTime = (milliseconds: number): string => {
    const minutes = Math.floor(milliseconds / 60);
    const seconds = Math.floor(milliseconds % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const handleExit = () => navigate('/student');

  useEffect(() => {
    fetchGameResults('lectio', page, 10)
      .then((res) => {
        setStats(res.result);
        setLastPage(res.lastPage);
      })
      .catch(() => {
        notify.error('Произошла ошибка. Обратитесь в службу поддержки');
      });
  }, [page]);

  return (
    <div className="flex flex-col space-y-8 items-center justify-center w-[80%] max-w-[960px] min-h-[500px] h-max rounded-[20px] theme-student-card py-10 px-14 m-auto">
      <div className="w-full h-full flex flex-col space-y-4">
        <p className="text-[32px] font-semibold leading-10 max-w-[512px]">Результаты</p>
        <div>
          <table className="table-auto w-full">
            <thead>
              <tr className="h-12 theme-text--default text-sm font-normal text-opacity-40">
                <th className="w-[5%] text-left">№</th>
                <th className="w-[30%] text-left">ИМЯ</th>
                <th className="w-[30%] text-left">Время</th>
                <th className="w-[20%] text-left">РЕЗУЛЬТАТЫ</th>
              </tr>
            </thead>
            <tbody className="w-full">
              {stats.map((el, index) => (
                <tr
                  key={index}
                  className="w-full theme-text--default text-base font-normal tracking-wide"
                >
                  <td className="h-16 theme-text--default text-lg font-medium">{index + 1}</td>
                  <td className="h-16 flex flex-row items-center gap-x-5">{user?.fio}</td>
                  <td className="h-16">{parseTime(el.time)}</td>
                  <td className="h-16">
                    <ProgressBar
                      progress={el.score}
                      maxProgress={simulation.lectioQuestions.length}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex justify-start pb-4">
          <LMSPagination current={page} lastPage={lastPage} onChange={(page) => setPage(page)} />
        </div>
        <div className="flex flex-col items-center !mt-auto">
          <LMSButton onClick={handleExit}>Выйти</LMSButton>
        </div>
      </div>
    </div>
  );
};

export default LectioResult;
