import { useState } from 'react';

export const usePartialState = <T>(payload: T): [T, (newState: Partial<T>) => void] => {
  const [state, setState] = useState(payload);

  const setCustomState = (newState: Partial<T>) => {
    setState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  return [state, setCustomState];
};
