import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { fetchStudentTypes } from '../../api/repository/dashboard';
import { DashboardStudentType } from '../../entities/DashboardStudentType';

const StudentTypeProgress = () => {
  const [query] = useSearchParams();
  const course = query.get('course');

  const [types, setTypes] = useState<DashboardStudentType>({
    inner: 0,
    innerPercent: 0,
    external: 0,
    externalPercent: 0,
  });

  useEffect(() => {
    const params = { ...(course && { course_ids: course }) };
    fetchStudentTypes(params).then((res) => setTypes(res));
  }, [course]);

  return (
    <div className="p-4 md:p-6 rounded-2xl bg-studentCardBg">
      <p className="text-lg">Тип слушателя</p>

      <div className="mt-4 mb-5 px-4 py-5 rounded-lg bg-white/10">
        <div className="flex items-center bg-white/30 rounded-full overflow-hidden">
          <div
            className="h-[5px] bg-studentProgressBg"
            style={{ width: `${types.innerPercent}%` }}
          />
        </div>
      </div>

      <div className="flex items-center justify-between space-x-3 mb-1 text-sm">
        <span className="block w-4 h-1 rounded bg-blue" />
        <span className="flex-1 text-white">Внутренний</span>
        <p>
          <span>{types.inner}</span>
          <span className="ml-2 text-white/50">{types.innerPercent}%</span>
        </p>
      </div>
      <div className="flex items-center justify-between space-x-3 mb-1 text-sm">
        <span className="block w-4 h-1 rounded bg-blue/20" />
        <span className="flex-1 text-white">Внешний</span>
        <p>
          <span>{types.external}</span>
          <span className="ml-2 text-white/50">{types.externalPercent}%</span>
        </p>
      </div>
    </div>
  );
};

export default StudentTypeProgress;
