import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useNotification } from 'hooks/notification';

import { fetchStudents } from '../api/repository/students';
import { StudentInList } from '../entities/Student';

export const useStudents = () => {
  const [query, setQuery] = useSearchParams();
  const courseId = query.get('course_id');
  const studentType = query.get('student_type');
  const page = query.get('page');

  const notify = useNotification();

  const [lastPage, setLastPage] = useState(1);

  const [isLoading, setLoading] = useState(false);
  const [students, setStudents] = useState<StudentInList[]>([]);

  useEffect(() => {
    const params = {
      ...(courseId && { course_id: courseId || '' }),
      ...(studentType && { student_type: studentType || null }),
      page: page ? page : '1',
    };

    setLoading(true);
    fetchStudents(params)
      .then((res) => {
        setStudents(res.students);
        setLastPage(res.lastPage);
      })
      .catch(() => {
        notify.error('Произошла ошибка. Обратитесь в службу поддержки');
        return;
      })
      .finally(() => {
        setLoading(false);
      });
  }, [courseId, studentType, page]);

  const setCurrentPage = (current: number) => {
    query.set('page', current.toString());
    setQuery(query);
  };

  return {
    isLoading,
    students,
    currentPage: page ? +page : 1,
    lastPage,
    setCurrentPage,
  };
};
