import React, { useState } from 'react';
import ReactSlider from 'react-slider';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/chevron-down.svg';
import { ReactComponent as ArrowUpIcon } from 'assets/icons/chevron-up.svg';

interface Props {
  startPrice: number;
  endPrice: number;
  expanded?: boolean;
  onChange: (price: [number, number]) => void;
}

const FilterPriceAccordion: React.FC<Props> = ({
  startPrice,
  endPrice,
  onChange,
  expanded = false,
}) => {
  const [isExpanded, setExpanded] = useState(expanded);

  const onRangeChange = (val: number[]) => {
    onChange([val[0], val[1]]);
  };

  return (
    <div className="w-full py-5 border-b border-solid border-white/20 last:border-b-0">
      <button
        className="flex justify-between items-center w-full mb-4"
        onClick={() => setExpanded((prev) => !prev)}
      >
        <p className="text-[22px]">Стоимость</p>
        {isExpanded ? <ArrowUpIcon /> : <ArrowDownIcon />}
      </button>
      <div className={`${isExpanded ? 'block max-h-[500px]' : 'hidden max-h-0'} transition-all`}>
        <div className="flex items-center space-x-6">
          <div className="flex items-center justify-between w-full p-2.5 bg-white/20 rounded overflow-hidden">
            <input
              className="w-20 border-none outline-none bg-transparent"
              value={startPrice}
              type="text"
              onChange={(ev) => onChange([+ev.target.value, endPrice])}
            />
            <span>₸</span>
          </div>
          <div className="flex items-center justify-between w-full p-2.5 bg-white/20 rounded overflow-hidden">
            <input
              className="w-20 border-none outline-none bg-transparent"
              value={endPrice}
              type="text"
              onChange={(ev) => onChange([startPrice, +ev.target.value])}
            />
            <span>₸</span>
          </div>
        </div>
        <div className="pt-8 pb-6">
          <ReactSlider
            className="horizontal-slider"
            thumbClassName="horizontal-slider__thumb"
            trackClassName="horizontal-slider__track"
            value={[startPrice, endPrice]}
            min={0}
            max={400000}
            pearling
            minDistance={1}
            onChange={onRangeChange}
          />
        </div>
      </div>
    </div>
  );
};

export default FilterPriceAccordion;
