import { useEffect, useState } from 'react';
import { Option } from 'entities/Option';

import { fetchCourses } from '../api/repository/handbooks';

export const useCatalogCourses = () => {
  const [isLoading, setLoading] = useState(false);
  const [courses, setCourses] = useState<Option[]>([]);

  useEffect(() => {
    setLoading(true);
    fetchCourses()
      .then((res) => setCourses(res))
      .catch((e) => console.warn(e))
      .finally(() => setLoading(false));
  }, []);

  return {
    isLoading,
    courses,
  };
};
