import { useState } from 'react';
import { uploadFiles } from 'modules/coach/api/repository/upload-file';
import { useNotification } from 'hooks/notification';

const useFileUpload = () => {
  const [uploadedFilePaths, setUploadedFilePaths] = useState<string[]>([]);
  const notify = useNotification();

  const upload = async (files: File[]) => {
    try {
      const uploadedFiles = [];

      for (const file of files) {
        const form = new FormData();
        form.append('file', file);
        const res = await uploadFiles(form);
        uploadedFiles.push(res);
      }

      setUploadedFilePaths(uploadedFiles);
      return uploadedFiles;
    } catch (error) {
      notify.error('Не удалось загрузить файлы, попробуйте снова');
      throw error;
    }
  };

  return { uploadedFilePaths, upload };
};

export default useFileUpload;
