import { IFiles } from 'modules/coach/entities/Files';
import { IsendEditingTask } from 'modules/coach/entities/HomeTaskEditing';
import { IHomeTask, ITaskFiles } from 'modules/coach/entities/HomeTaskEntity';
import { IStudentMessages } from 'modules/coach/entities/StudentMessages';
import request from 'api/axios-instance';

import { IHomeTaskDao, ITaskFilesDao } from '../dao/HomeTaskDao';
import { IStudentMessagesDao, IStudentMessagesFilesDao } from '../dao/StudentMessagesDao';

export const editHomeworkTask = (params: IsendEditingTask) =>
  request.patch(`v1/coach/homeworks`, params).then((res) => res.data);

export const fetchHomeTask = (lessonId: number, studentId: number, calendarId: number) =>
  request
    .get(`v2/coach/homeworks/${lessonId}`, {
      params: {
        student_id: studentId,
        calendar_id: calendarId,
      },
    })
    .then((res) => {
      const homeTask: IHomeTask = mapHomeTaskDaoToEntity(res.data.data);
      console.log(homeTask);
      return homeTask;
    });

function mapHomeTaskDaoToEntity(homeTaskDao: IHomeTaskDao): IHomeTask {
  return {
    id: homeTaskDao.id,
    name: homeTaskDao.name,
    description: homeTaskDao.description,
    position: homeTaskDao.position,
    videoUrl: homeTaskDao.video_url && homeTaskDao.video_url.map(mapHomeTaskFileDaoToEntity),
    fileUrl: homeTaskDao.file_url && homeTaskDao.file_url.map(mapHomeTaskFileDaoToEntity),
    deadline: homeTaskDao.deadline,
    courseName: homeTaskDao.course_name,
    moduleName: homeTaskDao.module_name,
    lessonId: homeTaskDao.lesson_id,
    lessonName: homeTaskDao.lesson_name,
    groupName: homeTaskDao.group_name,
    student: homeTaskDao.student,
    mark: homeTaskDao.mark,
    markDate: homeTaskDao.mark_date,
    calendarId: homeTaskDao.calendar_id,
    statusId: homeTaskDao.status.id,
    hometaskMessages: homeTaskDao.hometask_messages.map(mapHomeTaskMessageDaoToEntity),
  };
}

function mapHomeTaskMessageDaoToEntity(message: IStudentMessagesDao): IStudentMessages {
  return {
    id: message.id,
    content: message.content,
    statusId: message.status.id,
    author: message.user,
    createdAt: message.created_at,
    files: message.files.map(mapHomeTaskMessageFileDaoToEntity),
  };
}

function mapHomeTaskMessageFileDaoToEntity(file: IStudentMessagesFilesDao): IFiles {
  return {
    downloadLink: file.download_link,
    originalName: file.original_name,
    size: file.size,
    extension: file.extension,
  };
}

function mapHomeTaskFileDaoToEntity(file: ITaskFilesDao): ITaskFiles {
  return {
    downloadLink: file.download_link,
    hash: file.hash,
    name: file.name,
    size: file.size,
  };
}
