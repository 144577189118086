import request from 'api/axios-instance';

import { Notification, NotificationCount } from '../../entities/Notification';
import { NotificationCountDao, NotificationDao } from '../dao/Notification.dao';

export const fetchNotifications = (params?: Record<string, string>) =>
  request.get('v1/auth/profile/notifications', { params }).then((res) => {
    const notifications: Notification[] = res.data.data.map(mapNotificationDaoToEntity);
    return notifications;
  });

export const fetchNotificationsCount = () =>
  request
    .get('v1/auth/profile/notifications-count')
    .then((res) => mapNotificationsCountToEntity(res.data.data));

export const setNotificationRead = (id: string) =>
  request.get(`v1/auth/profile/notifications/read/${id}`);

function mapNotificationDaoToEntity(notification: NotificationDao): Notification {
  return {
    id: notification.id,
    name: notification.name,
    description: notification.description,
    survey: notification.survey
      ? {
          id: notification.survey.id,
          name: notification.survey.name,
          surveyNumber: notification.survey.survey_number,
          courseId: notification.survey.course_id,
        }
      : null,
    notificationType: notification.notification_type,
    publishedAt: notification.published_at,
    isRead: notification.is_read,
  };
}

function mapNotificationsCountToEntity(count: NotificationCountDao): NotificationCount {
  return {
    survey: count.survey,
    total: count.total,
    unread: count.unread,
  };
}
