import request from 'api/axios-instance';
import { LoginPayload, User } from 'entities/User';

import { mapUserDaoToEntity } from '../mappers/user.mappers';

export const login = (email: string, password: string) =>
  request
    .post('v1/auth/login', {
      name: email,
      password,
    })
    .then((response) => response?.data as LoginPayload);

export const fetchMe = () =>
  request.get('v1/auth/profile').then((response) => {
    const user: User = mapUserDaoToEntity(response.data.data);
    return user;
  });

export const sendRole = (id: number) =>
  request.post(`v1/auth/profile/select-role/${id}`).then((response) => {
    const user: User = mapUserDaoToEntity(response.data.data);
    return user;
  });

export const loginStudent = (email: string, password: string, token: string) =>
  request
    .post('v1/auth/student/login', {
      name: email,
      password,
      token,
    })
    .then((response) => response?.data as LoginPayload);

export const registrationStudent = (payload: Record<string, string>) =>
  request
    .post('v1/auth/student/register', payload)
    .then((response) => response?.data as LoginPayload);

export const checkStudentToken = (token: string) =>
  request.get(`v2/groups/exists/${token}`).then((res) => res.data);

export const loginCoach = (email: string, password: string, token: string) =>
  request
    .post('v1/auth/coach/login', {
      name: email,
      password,
      token,
    })
    .then((response) => response?.data as LoginPayload);

export const registrationCoach = (payload: Record<string, string>) =>
  request
    .post('v1/auth/coach/register', payload)
    .then((response) => response?.data as LoginPayload);

export const checkCoachToken = (token: string) =>
  request.get(`v2/course/exists/${token}`).then((res) => res.data);
