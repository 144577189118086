import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { fetchCourseTypes } from '../../api/repository/dashboard';
import { DashboardCourseType } from '../../entities/DashboardCourseType';

const LearningTypeProgress = () => {
  const [query] = useSearchParams();
  const course = query.get('course');

  const [types, setTypes] = useState<DashboardCourseType>({
    online: 0,
    onlinePercent: 0,
    offline: 0,
    offlinePercent: 0,
  });

  useEffect(() => {
    const params = { ...(course && { course_ids: course }) };
    fetchCourseTypes(params).then((res) => setTypes(res));
  }, [course]);

  return (
    <div className="p-4 md:p-6 rounded-2xl bg-studentCardBg">
      <p className="text-lg">Форматы проведения курсов</p>

      <div className="mt-4 mb-5 px-4 py-5 rounded-lg bg-white/10">
        <div className="flex items-center bg-white/30 rounded-full overflow-hidden">
          <div
            className="h-[5px] bg-studentProgressBg"
            style={{ width: `${types.onlinePercent}%` }}
          />
        </div>
      </div>

      <div className="flex items-center justify-between space-x-3 mb-1 text-sm">
        <span className="block w-4 h-1 rounded bg-blue" />
        <span className="flex-1 text-white">Онлайн</span>
        <p>
          <span>{types.online}</span>
          <span className="ml-2 text-white/50">{types.onlinePercent}%</span>
        </p>
      </div>
      <div className="flex items-center justify-between space-x-3 mb-1 text-sm">
        <span className="block w-4 h-1 rounded bg-blue/20" />
        <span className="flex-1 text-white">Оффлайн</span>
        <p>
          <span>{types.offline}</span>
          <span className="ml-2 text-white/50">{types.offlinePercent}%</span>
        </p>
      </div>
    </div>
  );
};

export default LearningTypeProgress;
