import React from 'react';
import { useNavigate } from 'react-router-dom';
import LMSButton from 'components/LMSButton';
import LMSPagination from 'components/LMSPagination';
import ManagerTable from 'modules/hr/components/ManagerTable';
import Loading from 'modules/student/components/Loading';

import ManagerFilter from '../../components/ManagerFilter';
import { useManagers } from '../../hooks/managers-list';

const HrManagers = () => {
  const navigate = useNavigate();
  const { isLoading, coaches, currentPage, lastPage, setCurrentPage } = useManagers();

  return (
    <div className="flex flex-col items-start w-full py-4 lg:py-10">
      <div className="w-full flex flex-col lg:flex-row justify-between space-y-4 lg:space-y-0 lg:space-x-4 mb-6 lg:mb-10">
        <h2 className="text-[32px] font-semibold">База кураторов</h2>
        <div className="flex flex-row items-center justify-between lg:justify-end space-x-4">
          <div className="hidden">
            <ManagerFilter />
          </div>

          <LMSButton onClick={() => navigate('/hr/users/managers/new-manager')}>
            Добавить менеджера
          </LMSButton>
        </div>
      </div>

      {isLoading ? (
        <div className="w-full p-20">
          <Loading />
        </div>
      ) : (
        <div className="w-full">
          {coaches.length === 0 ? (
            <h3 className="mb-10 text-[24px] font-semibold text-center mt-10">
              Нет данных <br /> Попробуйте изменить фильтрацию
            </h3>
          ) : (
            <>
              <div className="overflow-x-auto">
                <ManagerTable managers={coaches} />
              </div>

              <div className={lastPage > 1 ? 'mt-14' : ''}>
                <LMSPagination
                  current={currentPage}
                  lastPage={lastPage}
                  onChange={setCurrentPage}
                />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default HrManagers;
