import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useNotification } from 'hooks/notification';

import { fetchHomeworksForCheck } from '../api/repository/homeworks';
import { IHomeWorkForCheckParams } from '../entities/HomeWorks';

export const useHomeworksForCheck = () => {
  const [query, setQuery] = useSearchParams();

  const page = query.get('page');
  const student = query.get('student');
  const course = query.get('course');
  const group = query.get('group');
  const status = query.get('status');
  const deadline = query.get('deadline');

  const notify = useNotification();

  const [lastPage, setLastPage] = useState(1);

  const [isLoading, setLoading] = useState(false);
  const [homeworks, setHomeworks] = useState([]);

  useEffect(() => {
    const params: IHomeWorkForCheckParams = {
      type: 'new',
      page: page ? +page : 1,
      perPage: 30,
    };

    if (student) {
      params.search = student;
      params.searchBy = 'student';
    } else if (status) {
      params.search = status;
      params.searchBy = 'status';
    } else if (deadline) {
      params.search = new Date(deadline);
      params.searchBy = 'deadline';
    }

    if (course) params.course_id = +course;
    if (group) params.group_id = +group;

    setLoading(true);
    fetchHomeworksForCheck(params)
      .then((res) => {
        setHomeworks(res.homeworks);
        setLastPage(res.lastPage);
      })
      .catch((e) => notify.error(e.message))
      .finally(() => setLoading(false));
  }, [student, course, group, status, deadline, page]);

  const setCurrentPage = (current: number) => {
    query.set('page', current.toString());
    setQuery(query);
  };

  return {
    isLoading,
    homeworks,
    currentPage: page ? +page : 1,
    lastPage,
    setCurrentPage,
  };
};
