import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowBack } from 'assets/icons/chevron-left.svg';
import AddNewStudent from 'modules/hr/components/AddNewStudent';

const NewStudent = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate('/hr/users/students');
  };

  return (
    <div className="block lg:flex lg:flex-col items-start py-4 lg:py-10">
      <button
        type="button"
        onClick={() => goBack()}
        className="w-fit flex flex-row items-center justify-center gap-x-2 py-3 pl-3 pr-5 text-white text-[14px] font-medium bg-white bg-opacity-20 rounded-[36px] border-none outline-none uppercase mb-10"
      >
        <ArrowBack />
        назад
      </button>

      <div className="w-full flex flex-col !m-0">
        <AddNewStudent />
      </div>
    </div>
  );
};

export default NewStudent;
