import React from 'react';
import { NavLink } from 'react-router-dom';
import Avatar from 'components/Avatar';

import { StudentInList } from '../../entities/Student';

interface Props {
  info: StudentInList;
}

const TableUserCard: React.FC<Props> = ({ info }) => {
  return (
    <NavLink
      to={`/hr/students/${info.id}`}
      className="flex gap-x-4 bg-inherit text-white hover:text-blue"
    >
      <div className="w-10 h-10">
        <Avatar image={info?.photo ? info.photo : ''} alt={`Аватар ${info?.fullName}`} />
      </div>

      <section className="flex flex-col gap-[4px] max-w-[250px]">
        <span className="text-sm font-medium capitalize">{info?.fullName}</span>
      </section>
    </NavLink>
  );
};

export default TableUserCard;
