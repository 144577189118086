import { useState } from 'react';
import { uploadFiles } from 'modules/coach/api/repository/upload-file';
import { useNotification } from 'hooks/notification';

const useVideoUpload = () => {
  const [uploadedVideoFilePaths, setUploadedVideoFilePaths] = useState<string[]>([]);
  const notify = useNotification();

  const uploadVideo = async (files: File[]) => {
    try {
      const uploadedFiles = [];

      for (const file of files) {
        const form = new FormData();
        form.append('file', file);
        const res = await uploadFiles(form);
        uploadedFiles.push(res);
      }

      setUploadedVideoFilePaths(uploadedFiles);
      return uploadedFiles;
    } catch (error) {
      notify.error('Не удалось загрузить файлы, попробуйте снова');
      throw error;
    }
  };

  return { uploadedVideoFilePaths, uploadVideo };
};

export default useVideoUpload;
