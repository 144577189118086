import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowBack } from 'assets/icons/chevron-left.svg';
import AddNewGroup from 'modules/hr/components/AddNewGroup';
import Loading from 'modules/student/components/Loading';

import { useCatalogCoordinators } from '../../hooks/catalog-coordinators';
import { useCatalogCourses } from '../../hooks/catalog-courses';
import { useCatalogStudents } from '../../hooks/catalog-students';

const NewGroup = () => {
  const navigate = useNavigate();
  const { isLoading: isLoadingCourses, courses } = useCatalogCourses();
  const { isLoading: isLoadingCoordinators, coordinators } = useCatalogCoordinators();
  const { isLoading: isLoadingStudents, students } = useCatalogStudents();

  const goBack = () => {
    navigate('/hr/groups');
  };

  return (
    <div className="block lg:flex lg:flex-col items-start">
      <button
        type="button"
        onClick={() => goBack()}
        className="w-fit flex flex-row items-center justify-center gap-x-2 py-3 pl-3 pr-5 text-white text-[14px] font-medium bg-white bg-opacity-20 rounded-[36px] border-none outline-none uppercase mb-10"
      >
        <ArrowBack />
        назад
      </button>

      <div className="w-full flex flex-col !m-0">
        {isLoadingCourses || isLoadingCoordinators || isLoadingStudents ? (
          <Loading />
        ) : (
          <AddNewGroup courses={courses} coordinators={coordinators} students={students} />
        )}
      </div>
    </div>
  );
};

export default NewGroup;
