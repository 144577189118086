import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { NotificationCount } from '../../modules/student/entities/Notification';

const initialState: NotificationCount = {
  unread: 0,
  total: 0,
  survey: 0,
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotificationsCount(state, action: PayloadAction<NotificationCount>) {
      state.unread = action.payload.unread;
      state.total = action.payload.total;
      state.survey = action.payload.survey;
    },
  },
});

export const notificationsReducer = notificationsSlice.reducer;
