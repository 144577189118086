import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="w-full min-h-[100dvh] flex flex-col items-center justify-center p-10 bg-main bg-no-repeat bg-cover">
      <span className="text-white text-lg font-extrabold tracking-[4px] uppercase lg:text-2xl">
        Ошибка
      </span>
      <span className="text-white text-[148px] font-black leading-none md:text-[200px] lg:text-[288px]">
        404
      </span>
      <p className="text-white text-xl text-center font-normal text-opacity-30 lg:text-2xl">
        Увы, данной страницы не существует
      </p>
      <button
        type="button"
        onClick={() => goBack()}
        className="max-w-[258px] w-full py-4 mt-[40px] text-white uppercase bg-blue rounded-[10px] border-none outline-none lg:mt-[50px]"
      >
        вернуться назад
      </button>
    </div>
  );
};

export default NotFound;
