import request from 'api/axios-instance';
import { Option } from 'entities/Option';

import { Coach } from '../../entities/Coaches';
import { UserDetail } from '../../entities/User';
import { CoachDao } from '../dao/coaches';
import { ManagerDao } from '../dao/managers';

export const fetchFilterCourses = (params?: Record<string, string>) =>
  request.get('v1/courses-filter', { params }).then((res) => {
    const courses: Option[] = res.data.map(mapCoachesDaoToEntity);
    return courses;
  });

export const fetchManagers = (params: Record<string, string | number | null>) =>
  request.get('v1/managers', { params }).then((res) => {
    const coaches: Coach[] = res?.data?.data?.map(mapCoachesDaoToEntity) || [];
    return { coaches, lastPage: (res?.data?.meta?.last_page as number) || 1 };
  });

export const fetchManagerById = (id: number) =>
  request.get(`v1/managers/${id}`).then((res) => managerDaoToEntity(res.data.data));

function mapCoachesDaoToEntity(coach: CoachDao): Coach {
  return {
    id: coach.id,
    email: coach.email,
    fullName: coach.full_name,
    login: coach.login,
    phoneNumber: coach.phone_number,
    photo: coach.photo,
    specialization: coach.specialization,
    type: coach.type,
  };
}

function managerDaoToEntity(manager: ManagerDao): UserDetail {
  return {
    id: manager.id,
    fullName: manager.full_name,
    phone: manager.phone,
    email: manager.email,
    avatar: manager?.avatar || null,
  };
}
