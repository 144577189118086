import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import LMSPagination from 'components/LMSPagination';
import Loader from 'components/Loader';
import { fetchCourses } from 'modules/coach/api/repository/courses/courses';
import CourseCard from 'modules/coach/components/courses/CourseCard';
import CourseGroupTable from 'modules/coach/components/courses/CourseGroupTable';
import SwitchTabs from 'modules/coach/components/courses/SwitchTabs';
import { ICourses } from 'modules/coach/entities/coach-courses/Courses';
import { useNotification } from 'hooks/notification';

const CoachCoursesMain = () => {
  const notify = useNotification();
  const [query, setQuery] = useSearchParams();

  const [activeTab, setActiveTab] = useState<number>(1);
  const [courses, setCourses] = useState<ICourses[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [lastPage, setLastPage] = useState(1);

  const page = query.get('page') || 1;

  const setCurrentPage = (current: number) => {
    query.set('page', current.toString());
    setQuery(query);
  };

  const switchActiveTab = (tab: number) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    fetchCourses(activeTab, 10, Number(page))
      .then((res) => {
        setCourses(res.courses);
        setLastPage(res.lastPage);
      })
      .catch(() => notify.error('Произошла ошибка. Обратитесь в службу поддержки'))
      .finally(() => setLoading(false));
  }, [activeTab, page]);

  return (
    <div className="h-screen flex flex-col gap-10 pt-6">
      <SwitchTabs activeTab={Number(activeTab)} onTabChange={(value) => switchActiveTab(value)} />

      <div className="flex flex-col gap-5">
        {isLoading ? (
          <Loader />
        ) : courses.length === 0 ? (
          <div className="flex flex-col items-center justify-center h-[460px] rounded-[20px] bg-studentCardBg">
            <p className="text-[30px] text-center leading-10 max-w-[420px] mb-14">
              Курсы не найдены. Продолжайте поиск, чтобы найти нужный вам курс
            </p>
          </div>
        ) : (
          courses.map((course) => (
            <CourseCard
              key={course.id}
              count={course.groups.length}
              title={course.name}
              img={course.image}
            >
              <CourseGroupTable
                groups={course.groups}
                startDate={course.dateFrom}
                endDate={course.dateTo}
              />
            </CourseCard>
          ))
        )}
      </div>

      <LMSPagination
        current={Number(page)}
        lastPage={lastPage}
        onChange={(page) => setCurrentPage(page)}
      />
    </div>
  );
};

export default CoachCoursesMain;
