import React, { useEffect } from 'react';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

interface Props {
  children: React.ReactNode;
  wrapperClassName?: string;
  onClose?: () => void;
}

const BaseModal: React.FC<Props> = ({ children, wrapperClassName, onClose }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  });
  return (
    <div
      className="fixed inset-0 flex items-center justify-center text-black bg-black/70 z-50"
      onClick={() => onClose && onClose()}
    >
      <div
        className={`relative max-w-[90%] sm:max-w-[550px] p-4 sm: p-6 w-full bg-white rounded-2xl ${wrapperClassName}`}
        onClick={(e) => e.stopPropagation()}
      >
        <button
          className="absolute -top-9 right-0 flex items-center text-white"
          onClick={() => onClose && onClose()}
        >
          <span>Закрыть</span>
          <CloseIcon />
        </button>
        {children}
      </div>
    </div>
  );
};

export default BaseModal;
