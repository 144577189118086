import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { fetchBooks } from '../api/repository/library';
import { Book } from '../entities/Book';

export const useLibrary = () => {
  const [query, setQuery] = useSearchParams();
  const category = query.get('category');
  const subcategory = query.get('subcategory');
  const author = query.get('author');
  const publisher = query.get('publisher');
  const page = query.get('page');

  const [lastPage, setLastPage] = useState(1);

  const [isLoading, setLoading] = useState(false);
  const [books, setBooks] = useState<Book[]>([]);

  useEffect(() => {
    const params = {
      handbook_library_category_id: category,
      handbook_library_author_id: author,
      handbook_library_publisher_id: publisher,
      page: page ? page : '1',
    };

    setLoading(true);
    fetchBooks(params)
      .then(({ books, last }) => {
        setBooks(books);
        setLastPage(last);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [category, subcategory, author, publisher, page]);

  const setCurrentPage = (current: number) => {
    query.set('page', current.toString());
    setQuery(query);
  };

  return {
    isLoading,
    books,
    currentPage: page ? +page : 1,
    lastPage,
    setCurrentPage,
  };
};
