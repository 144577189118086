import React from 'react';

import cl from './toggle.module.scss';

interface Props {
  value: boolean;
  disabled?: boolean;
  onChange: (val: boolean) => void;
}

const LMSToggle: React.FC<Props> = ({ value, disabled = false, onChange }) => {
  return (
    <label className={`${cl['toggle']} ${disabled ? cl['toggle--disabled'] : ''}`}>
      <input type="checkbox" checked={value} onChange={(e) => onChange(e.target.checked)} />
      <div className={cl['toggle__slider']} />
    </label>
  );
};

export default LMSToggle;
