import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { fetchCourses } from '../api/repository/courses';
import { ICourseDetail } from '../entities/Course';

export const useCourses = () => {
  const [query, setQuery] = useSearchParams();
  const direction = query.get('direction');
  const format = query.get('format');
  const priceStart = query.get('priceStart');
  const priceEnd = query.get('priceEnd');
  const page = query.get('page');

  const [lastPage, setLastPage] = useState(1);

  const [isLoading, setLoading] = useState(false);
  const [courses, setCourses] = useState<ICourseDetail[]>([]);

  useEffect(() => {
    const params = {
      handbook_direction_type_id: direction,
      study_type: format ? (format === '1' ? 'inner' : 'external') : null,
      price:
        priceStart && +priceStart > 0 && priceEnd && +priceEnd < 400000
          ? `${priceStart},${priceEnd}`
          : null,
      page: page ? page : '1',
    };

    setLoading(true);
    fetchCourses(params)
      .then(({ courses, lastPage }) => {
        setCourses(courses);
        setLastPage(lastPage);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [direction, format, priceStart, priceEnd, page]);

  const setCurrentPage = (current: number) => {
    query.set('page', current.toString());
    setQuery(query);
  };

  return {
    isLoading,
    courses,
    currentPage: page ? +page : 1,
    lastPage,
    setCurrentPage,
  };
};
