import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { ReactComponent as PhoneIcon } from 'assets/icons/phone.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';
import Avatar from 'components/Avatar';
import LMSButton from 'components/LMSButton';
import LMSRadioButton from 'components/LMSRadioButton';

import { Course } from '../../entities/Course';

const CourseManager = () => {
  const course = useOutletContext<Course>();

  const badgeClass =
    'flex items-center px-5 py-2 space-x-2 bg-white/20 text-xs md:text-base rounded-full truncate overflow-hidden';

  return (
    <div className="flex flex-col-reverse md:grid grid-cols-2 theme-student-card rounded-2xl p-4 md:p-8">
      <div>
        <div className="flex items-center justify-center md:justify-start space-x-3">
          <div className="flex items-center space-x-4 pr-2 bg-white/20 rounded-full overflow-hidden">
            <div className="w-7 md:w-10 h-7 md:h-10 rounded-full overflow-hidden">
              <Avatar image={course.manager?.photo} alt="аватар координатора" />
            </div>
            <p className="max-w-[130px] text-xs md:text-base truncate">
              {course.manager?.fullName ?? ''}
            </p>
          </div>
          {course.manager.phoneNumber && (
            <div className={badgeClass}>
              <PhoneIcon />
              <a className="text-current" href={'tel:' + course.manager?.phoneNumber}>
                {course.manager?.phoneNumber}
              </a>
            </div>
          )}
        </div>
        <p className="mt-6 text-xl md:text-2xl">{course.manager?.fullName}</p>
        <p className="text-blue text-sm">{course.manager?.email}</p>

        {/* <div className="mt-7">
          <p className="text-white/60 text-xs">Документы для скачивания:</p>
          <div className="flex flex-col md:flex-row md:items-center md:space-x-9 space-y-3 md:space-y-0 mt-3">
            <div className="flex items-center space-x-2">
              <LMSRadioButton checked={true} />
              <p>
                Договор <span className="uppercase text-white/40">(pdf, 32 kb)</span>
              </p>
            </div>
            <div className="flex items-center space-x-2 text-white/40">
              <LMSRadioButton checked={false} />
              <p>Заявление</p>
            </div>
          </div>
        </div> */}
        {course.certificateUrl ? (
          <div className="mt-8">
            <LMSButton prefix={<UploadIcon />}>Скачать сертификат</LMSButton>
          </div>
        ) : null}
      </div>

      <div className="flex items-center justify-center">
        {course.certificateUrl ? (
          <img src="" alt="" />
        ) : (
          <p className="hidden md:block">Нет сертификата</p>
        )}
      </div>
    </div>
  );
};

export default CourseManager;
