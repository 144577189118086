import React, { useEffect, useMemo, useState } from 'react';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/chevron-down.svg';
import LMSDropdown from 'components/LMSDropdown';

import { changeStudentAttendance, fetchAttendanceStatuses } from '../../api/repository/journal';
import { AttendanceLesson } from '../../entities/Attendance';
import { AttendanceStatus } from '../../entities/AttendanceStatus';

interface LocalLesson extends AttendanceLesson {
  isExpanded: boolean;
}

interface Props {
  selectedLesson: LocalLesson;
}

const AttendanceLessonsTable: React.FC<Props> = ({ selectedLesson }) => {
  const [isLoading, setLoading] = useState(false);
  const [statuses, setStatuses] = useState<AttendanceStatus[]>([]);

  const attendanceOptions = [
    {
      id: 1,
      title: 'Посетил',
      onClick: (id?: number) => id && refreshStudentStatus(id, 'attended'),
    },
    {
      id: 2,
      title: 'Пропустил',
      onClick: (id?: number) => id && refreshStudentStatus(id, 'skipped'),
    },
    {
      id: 3,
      title: 'Пропустил, по уважительной причине',
      onClick: (id?: number) => id && refreshStudentStatus(id, 'skipped_with_reason'),
    },
  ];

  useEffect(() => {
    if (!selectedLesson) return;
    setLoading(true);
    setStatuses([]);
    fetchAttendanceStatuses(selectedLesson.id)
      .then((res) => {
        setStatuses(res);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [selectedLesson]);

  const memo = useMemo(() => {
    return statuses.reduce((acc, status) => {
      acc[status.studentId] = status.attendance;
      return acc;
    }, {} as Record<string, string>);
  }, [statuses]);

  const refreshStudentStatus = (studentId: number, attendance: string) => {
    if (!selectedLesson) return;

    const data = {
      attendance,
      lesson_id: selectedLesson.id,
      student_id: studentId,
    };
    changeStudentAttendance(data).then((res) => {
      setStatuses((prev) => [...prev, res]);
    });
  };

  return (
    <>
      {selectedLesson.students.map((student, index) => (
        <div
          key={student.id}
          className="flex flex-col md:grid grid-cols-[40px_1fr_170px] gap-2 items-start md:items-center space-y-2.5 md:space-y-0 p-4 md:px-7 md:py-5 border-b border-white/10 last:border-b-0"
        >
          <span className="hidden md:block">{index + 1}</span>
          <div className="flex items-center space-x-3">
            <div className="w-9 h-9 rounded-full overflow-hidden">
              <img src={student.avatar} className="w-full h-full object-cover" alt="" />
            </div>
            <p>{student.fio}</p>
          </div>
          <div className="flex justify-end md:justify-start w-full">
            {isLoading ? (
              <p className="text-current text-opacity-50">Загрузка...</p>
            ) : memo[student.id] ? (
              memo[student.id] === 'attended' ? (
                <div className="flex items-center space-x-2">
                  <CheckIcon className="w-5 h-5 text-[#58DB66]" />
                  <p className="text-sm">Посетил</p>
                </div>
              ) : memo[student.id] === 'skipped' ? (
                <div className="flex items-center space-x-3">
                  <p className="text-2xl text-[#FF5449] font-light">&times;</p>
                  <p className="text-sm">Пропустил</p>
                </div>
              ) : (
                <div className="flex items-center space-x-3 overflow-hidden">
                  <p className="text-2xl text-[#FF5449] font-light">&times;</p>
                  <p className="truncate text-sm">Пропустил, по уважительной причине</p>
                </div>
              )
            ) : (
              <LMSDropdown targetId={student.id} options={attendanceOptions}>
                <div className="flex items-center space-x-4 w-full py-3 px-4 rounded-full border border-white/10">
                  <span>Выберите</span>
                  <ArrowDownIcon />
                </div>
              </LMSDropdown>
            )}
          </div>
        </div>
      ))}
    </>
  );
};

export default AttendanceLessonsTable;
