import React, { useEffect, useState } from 'react';
import dayGridPlugin from '@fullcalendar/daygrid';
import FullCalendar from '@fullcalendar/react';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/chevron-down.svg';
import Loader from 'components/Loader';
import { useNotification } from 'hooks/notification';

import { fetchAttendance } from '../../api/repository/journal';
import AttendanceLessonsTable from '../../components/AttendanceLessonsTable';
import { AttendanceLesson } from '../../entities/Attendance';

interface LocalLesson extends AttendanceLesson {
  isExpanded: boolean;
}

const CoachJournal = () => {
  const [isLoading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [lessons, setLessons] = useState<LocalLesson[]>([]);
  const [selectedLesson, setSelectedLesson] = useState<LocalLesson | null>(null);
  const notify = useNotification();

  useEffect(() => {
    setLoading(true);
    setSelectedLesson(null);
    fetchAttendance(formatDate(selectedDate))
      .then((res) => {
        setLessons(res.map((l) => ({ ...l, isExpanded: false })));
      })
      .catch(() => {
        notify.error('Произошла ошибка. Обратитесь в службу поддержки');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [selectedDate]);

  const toggleLesson = (id: number) => {
    setLessons((prev) => prev.map((l) => (l.id === id ? { ...l, isExpanded: !l.isExpanded } : l)));
  };

  const formatDate = (date: Date) => {
    const year = date.getFullYear().toString().padStart(4, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  return (
    <div className="mt-24 lg:mt-0 text-current pb-40">
      <h2 className="mt-5 mb-10 text-2xl md:text-[32px] font-semibold">Журнал посещаемости</h2>
      <div className="flex flex-col md:flex-row items-start md:space-x-4 space-y-4 md:space-y-0">
        <div className="w-full md:w-[300px] rounded-2xl theme-student-card overflow-hidden">
          <p className="p-5 text-xl md:text-2xl">Мои курсы</p>
          {isLoading ? (
            <div className="py-2 px-4">
              <Loader theme="dark" />
            </div>
          ) : (
            <div className="w-full">
              {lessons.map((lesson) => (
                <div key={lesson.id} className="border-b border-white/20 last:border-b-0">
                  <button
                    className="flex items-center justify-between w-full px-4 py-5 bg-white/10 text-left"
                    onClick={() => toggleLesson(lesson.id)}
                  >
                    <span>{lesson.course}</span>
                    <ArrowDownIcon className="shrink-0" />
                  </button>
                  {lesson.isExpanded ? (
                    <div
                      className={`px-4 py-5 cursor-pointer ${
                        selectedLesson?.id === lesson.id ? 'bg-[#26A7EF1F]' : 'white/10'
                      }`}
                      onClick={() => setSelectedLesson(lesson)}
                    >
                      <p className="mb-2.5 text-xs">{lesson.name}</p>
                      <div className="flex items-center justify-between">
                        <p className="text-blue text-xs">
                          {lesson.timeFrom.substring(0, 5)}-{lesson.timeTo.substring(0, 5)}
                        </p>
                        <p></p>
                      </div>
                    </div>
                  ) : null}
                </div>
              ))}
              {lessons.length === 0 ? <p className="py-5 px-4">Список пуст</p> : null}
            </div>
          )}
        </div>

        <div className="flex-1 rounded-2xl theme-student-card">
          <div className="journal-calender border-b theme-border--light-black">
            <FullCalendar
              plugins={[dayGridPlugin]}
              initialView="dayGridWeek"
              headerToolbar={{
                left: 'prev',
                center: 'title',
                right: 'next',
              }}
              titleFormat={{ month: 'long', day: 'numeric' }}
              contentHeight="auto"
              locale="ru"
              selectable
              dayHeaderContent={(arg) => (
                <div
                  className={`${
                    selectedDate.toLocaleDateString() === arg.date.toLocaleDateString()
                      ? 'text-blue'
                      : 'theme-text--default'
                  } cursor-pointer px-4 py-2`}
                  onClick={() => setSelectedDate(arg.date)}
                >
                  <p
                    className={`${
                      selectedDate.toLocaleDateString() === arg.date.toLocaleDateString()
                        ? 'text-blue'
                        : 'theme-text--default text-opacity-30'
                    } text-xs text-left`}
                  >
                    {arg.text.split(' ')[0]}
                  </p>
                  <p className="text-lg md:text-[22px] font-medium text-left">
                    {arg.date.getDate()}
                  </p>
                </div>
              )}
            />
          </div>
          <div>
            <div className="hidden md:grid grid-cols-[40px_1fr_170px] gap-2 px-7 py-1 text-current text-opacity-50 bg-white/10">
              <span>№</span>
              <span>Студент</span>
              <span>Статус посещения</span>
            </div>
            {selectedLesson ? (
              <AttendanceLessonsTable selectedLesson={selectedLesson} />
            ) : (
              <p className="text-center py-10">Список пуст</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoachJournal;
