import React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowRight } from 'assets/icons/chevron-right.svg';
import LMSButton from 'components/LMSButton';

import { fetchGameResults } from '../../api/repository/games';
import MathTest from '../../components/MathTest';
import MathVortexWelcome from '../../components/MathVortexWelcome';
import MultiplicationTable from '../../components/MultiplicationTable';
import Title from '../../components/Title';

interface ITaskLevel {
  id: number;
  title: string;
  description: string;
  difficulty: 'easy' | 'medium' | 'hard';
  score: number;
  bestScore: number | null;
  time: number;
}

interface IGame {
  date: string;
  difficulty: 'easy' | 'medium' | 'hard';
  game: string;
  id: number;
  score: number;
  time: number;
}

const TASK_LEVELS: ITaskLevel[] = [
  {
    id: 1,
    title: 'Легкий',
    description: 'Сложение и вычитание чисел',
    difficulty: 'easy',
    score: 0,
    bestScore: null,
    time: 600,
  },
  {
    id: 2,
    title: 'Средний',
    description: 'Умножение и деление чисел',
    difficulty: 'medium',
    score: 0,
    bestScore: null,
    time: 360,
  },
  {
    id: 3,
    title: 'Сложный',
    description: 'Все виды математических операций',
    difficulty: 'hard',
    score: 0,
    bestScore: null,
    time: 240,
  },
];

const DEFAULT_TASK_LEVEL = TASK_LEVELS[1];

const MathVortex = () => {
  const navigate = useNavigate();

  const [step, setStep] = useState<number>(1);
  const [selectedLvl, setSelectedLvl] = useState<ITaskLevel>(DEFAULT_TASK_LEVEL);
  const [activeLvl, setActiveLvl] = useState<ITaskLevel | null>(null);
  const [gameResults, setGameResults] = useState<IGame[]>([]);

  const findBestScore = (difficulty: 'easy' | 'medium' | 'hard') => {
    return gameResults
      .filter((item) => item.difficulty === difficulty)
      .reduce((acc, item) => {
        return acc > item.score ? acc : item.score;
      }, 0);
  };

  const handleExit = () => navigate('/student');

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [activeLvl]);

  useEffect(() => {
    fetchGameResults('vortex').then((res) => {
      setGameResults(res.result);
    });
  }, []);

  return (
    <div className="flex w-full h-full min-h-[100vh] relative">
      <div className="w-full h-full py-10 m-auto">
        {step === 1 ? (
          <MathVortexWelcome
            title='Добро пожаловать в игру "Математический вихрь"'
            desc="Цель игры: помочь участникам развить навык быстро и точно выполнять в уме простые арифметические действия: сложение, вычитание, умножение и деление. Это помогает ни только повысить общую продуктивность при работе с цифрами, но и увеличивает производительность при приемке, отпуске и инвентаризации товаров."
            buttonLabel="Далее"
            nextStep={() => setStep(step + 1)}
          >
            <p className="text-[18px] text-center leading-6 max-w-[700px] opacity-50">
              Эта игра поможет вам развить навыки быстрого счета в уме. Следуйте инструкциям, чтобы
              улучшить свои математические способности. Удачи!
            </p>
          </MathVortexWelcome>
        ) : step === 2 ? (
          <MathVortexWelcome
            title="Правила игры"
            buttonLabel="Далее"
            nextStep={() => setStep(step + 1)}
          >
            <div className="flex flex-col space-y-2 text-[18px] max-w-[700px] leading-6 text-center">
              <div className="mb-4">
                <p className="mb-4">1. Выбор уровня сложности:</p>
                <p>Легкий</p>
                <p>Средний</p>
                <p>Сложный</p>
              </div>
              <div className="!mb-4">
                <p className="mb-4">2. Уровни игры:</p>
                <p>Легкий уровень: сложение и вычитание</p>
                <p>Средний уровень: умножение и деление</p>
                <p>Сложный уровень: сложение, вычитание, умножение и деление</p>
              </div>
              <div className="!mb-4">
                <p className="mb-4">3. Этапы игры:</p>
                <p>
                  На экране появится ряд математических примеров, Вам нужно будет решить их все пока
                  не закончится отведенное на это время. На каждом уровне время для выполнения
                  задания разное. Если ответ будет неверным, то Вы увидите сообщение об этом.
                </p>
                <p>
                  Важно, чтобы вы решали примеры исключительно в уме, НЕ пользуясь ручкой с бумагой,
                  а уже тем более НЕ используя калькулятор.
                </p>
              </div>
              <div className="!mb-4">
                <p className="mb-4">4. Отслеживание прогресса:</p>
                <p>Вы сможете видеть и отслеживать свой прогресс на каждом уровне</p>
                <p>После завершения каждого этапа вам будет показана статистика ваших успехов.</p>
              </div>
              <div>
                <p className="mb-4">Таблица умножения:</p>
                <p>
                  Таблица умножения предоставляется отдельно для изучения и повторения. Ваши
                  правильные ответы в таблице умножения не исчезают. Вы сможете их удалить
                  самостоятельно, если захотите
                </p>
              </div>
            </div>
          </MathVortexWelcome>
        ) : (
          <div className="w-full flex flex-col items-center px-4 pt-12 md:pt-20 space-y-10">
            {!activeLvl?.id && (
              <div className="theme-student-card flex flex-col w-full max-w-[1200px] rounded-[20px] p-16">
                <Title className="text-center">Математический вихрь</Title>
                <section className="flex flex-col gap-y-8 items-center pt-10">
                  <h2 className="text-2xl md:text-[32px]">Выберите уровень сложности</h2>
                  <div className="flex flex-row bg-[#f1f4ff] rounded-full p-1">
                    {TASK_LEVELS.map((item) => (
                      <LMSButton
                        key={item.id}
                        onClick={() => setSelectedLvl(item)}
                        className={selectedLvl.id === item.id ? '' : '!bg-transparent !text-black'}
                      >
                        {item.title}
                      </LMSButton>
                    ))}
                  </div>
                  {selectedLvl && (
                    <p>
                      {selectedLvl?.description} / {selectedLvl?.time} сек.
                    </p>
                  )}
                  {findBestScore(selectedLvl.difficulty) > 0 && (
                    <span>Ваш лучший результат - {findBestScore(selectedLvl.difficulty)}%</span>
                  )}
                  <LMSButton
                    className="bg-white text-black"
                    suffix={<ArrowRight />}
                    onClick={() => setActiveLvl(selectedLvl)}
                  >
                    Начать
                  </LMSButton>
                </section>
              </div>
            )}

            {!activeLvl?.id && (
              <section className="theme-student-card rounded-[20px] w-full max-w-[1200px] flex flex-col items-center gap-y-10 p-16">
                <Title className="text-center">Тренировка</Title>
                <div className="flex justify-between gap-x-10 w-full">
                  <MultiplicationTable />
                </div>
              </section>
            )}

            {activeLvl?.id && (
              <MathTest
                type={activeLvl.difficulty}
                time={activeLvl.time}
                resetActiveLevel={() => setActiveLvl(null)}
              />
            )}
          </div>
        )}
      </div>
      <button
        type="button"
        className="h-[40px] px-4 flex flex-row justify-between items-center bg-[#F6F6F6]/50 rounded-[14px] outline-none text-black absolute top-5 right-5"
        onClick={handleExit}
      >
        Выйти из игры
      </button>
    </div>
  );
};

export default MathVortex;
