import { SetStateAction, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactQuill from 'react-quill';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as LinkIcon } from 'assets/icons/link.svg';
import LMSButton from 'components/LMSButton';
import LMSTitle from 'components/LMSTitle';
import { editLesson } from 'modules/coach/api/repository/edit-lesson';
import { fetchLessonsDetail } from 'modules/coach/api/repository/lesson-detail';
import { ILessonDetail } from 'modules/coach/entities/LssonDetail';
import { ISendLesson } from 'modules/coach/entities/SendLesson';
import useFileUpload from 'modules/coach/hooks/multiFileUpload';
import useVideoUpload from 'modules/coach/hooks/multiVideoUpload';
import useFileControl from 'modules/coach/hooks/useFileControl';
import { useCoursesListData } from 'modules/coach/hooks/useNewCoursesListsData';
import useHandleError from 'hooks/handleError';
import { useNotification } from 'hooks/notification';
import { ErrorResponse } from 'entities/Error';

import CustomRadioButton from '../CustomRadioButton';
import FileDownloadButton from '../DownLoadButton';
import DropdownSelect from '../DropdownSelect';
import UploadFile from '../UploadFile';

import { fileTypes, toolbarOptions, videoTypes } from './../../constants/index';

const EditLesson = ({ id }: { id: number }) => {
  const { control, handleSubmit, setValue } = useForm<ISendLesson>();
  const [selectedOption, setSelectedOption] = useState<string>('offline');
  const [lessonDescription, setLessonDescription] = useState<string>('');
  const { handleCommonError } = useHandleError();
  const notify = useNotification();
  const navigate = useNavigate();
  const { uploadedFilePaths, upload } = useFileUpload();
  const { uploadedVideoFilePaths, uploadVideo } = useVideoUpload();
  const { newFiles, newVideo, controlFiles, controlVideo, deleteNewVideo, deleteNewFiles } =
    useFileControl();
  const [lessonDetail, setLessonDetail] = useState<ILessonDetail>();
  const [courses, setCourses] = useState<{ id: number | null; name: string }>({
    id: null,
    name: '',
  });
  const { coursesList, modules } = useCoursesListData(Number(courses.id));
  const [moduleName, setModuleName] = useState<string>();
  const [isEditingName, setIsEditingName] = useState(false);
  const [isEditingDesc, setIsEditingDesc] = useState(false);
  const [isEditingVideoUrl, setIsEditingVideoUrl] = useState(false);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [isVideoUploading, setIsVideoUploading] = useState(false);

  useEffect(() => {
    fetchLessonsDetail(id).then((res) => {
      setLessonDetail(res);
    });
  }, [id]);

  const handleOptionChange = (value: SetStateAction<string>) => {
    setSelectedOption(value);
    setValue('is_online', value === 'online' ? true : false);
  };

  const handleChangeVideos = async (files: File[], isDelete?: false) => {
    if (isDelete) deleteNewVideo(files);
    else {
      controlVideo(files);
    }

    setIsVideoUploading(true);

    try {
      await uploadVideo(files);
      setIsVideoUploading(false);
    } catch (error) {
      setIsVideoUploading(false);
    }
  };

  const deleteFile = (file: { hash: string; name: string }, typeName: string) => async () => {
    if (typeName === 'file' && lessonDetail?.fileUrl) {
      setLessonDetail({
        ...lessonDetail,
        fileUrl: lessonDetail.fileUrl.filter((el) => el.hash !== file.hash),
      });
    }

    if (typeName === 'video' && lessonDetail?.videoUrl) {
      setLessonDetail({
        ...lessonDetail,
        videoUrl: lessonDetail.videoUrl.filter((el) => el.hash !== file.hash),
      });
    }
  };

  const handleChangeFiles = async (files: File[], isDelete?: false) => {
    if (isDelete) deleteNewFiles(files);
    else {
      controlFiles(files);
    }

    setIsFileUploading(true);

    try {
      const existingFileNames = lessonDetail?.fileUrl?.map((fileInfo) => fileInfo.name) || [];

      const filesToUpload = files.filter((newFile) => {
        return !existingFileNames.includes(newFile.name);
      });

      if (filesToUpload.length === 0) {
        setIsFileUploading(false);
        return;
      }

      await upload(filesToUpload);
      setIsFileUploading(false);
    } catch (error) {
      notify.error('Ошибка загрузки файла');
      setIsFileUploading(false);
    }
  };

  const changeCourse = (id: number, name: string) => {
    if (id === courses.id) return;
    setValue('course_id', id);
    setCourses({ id, name });

    setValue('module_id', undefined);
    setModuleName('');
  };

  const onSubmit = async (formData: ISendLesson) => {
    const updatedFormData = {
      ...formData,
      // time_from: formData.time_from
      //   ? String(formData.time_from).slice(0, 5)
      //   : String(lessonDetail?.timeFrom).slice(0, 5),
      // time_to: formData.time_to
      //   ? String(formData.time_to).slice(0, 5)
      //   : String(lessonDetail?.timeTo).slice(0, 5),
      course_id: lessonDetail?.courseId,
      module_id: lessonDetail?.moduleId,
      name: isEditingName ? formData.name : lessonDetail?.name,
      // video_broadcast_link: isEditingBroadcast
      //   ? formData.video_broadcast_link
      //   : lessonDetail?.videoBroadcastUrl || undefined,
      video_youtube_link: isEditingVideoUrl
        ? formData.video_youtube_link
        : lessonDetail?.videoYoutubeUrl || undefined,
      lesson_description: '<div style="color: white;">' + lessonDescription + '</div>',
    };

    if (uploadedFilePaths.length > 0) {
      updatedFormData.new_file_url = uploadedFilePaths;
    }

    if (lessonDetail?.fileUrl && lessonDetail?.fileUrl?.length > 0) {
      const filePaths = lessonDetail?.fileUrl?.map((file) => file.hash);
      updatedFormData.file_url = filePaths;
    }

    if (uploadedVideoFilePaths.length > 0) {
      updatedFormData.new_video_url = uploadedVideoFilePaths;
    }

    if (lessonDetail?.videoUrl && lessonDetail?.videoUrl?.length > 0) {
      const videoPaths = lessonDetail?.videoUrl?.map((file) => file.hash);
      updatedFormData.video_url = videoPaths;
    }

    try {
      await editLesson(updatedFormData, id);
      notify.success('Данные обновлены');
      setTimeout(() => {
        navigate(`/coach/lessons/lesson/${id}`);
      }, 600);
    } catch (err: unknown) {
      const error = err as ErrorResponse;
      handleCommonError(error);
    }
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-row justify-between items-end">
        <LMSTitle tag="h4" className="text-current text-3xl">
          Редактирование урока
        </LMSTitle>
        <LMSButton
          onClick={handleSubmit(onSubmit)}
          className="absolute bottom-0 left-0 right-0 py-4 rounded-none sm:static sm:rounded-full"
        >
          Сохранить изменения
        </LMSButton>
      </div>
      <div className="w-full h-full flex flex-col gap-y-5 mt-10 mb-20 sm:mb-0">
        <div className="w-full flex flex-col gap-5 p-5 theme-background-homework-card rounded-3xl sm:p-10">
          <div className="w-full flex flex-col gap-5 sm:flex-row">
            <div className="w-full h-fit rounded-[14px] sm:max-w-[49%]">
              <Controller
                name="course_id"
                control={control}
                rules={{
                  required: { message: 'Выберите курс', value: true },
                }}
                render={({ fieldState: { error } }) => (
                  <DropdownSelect
                    notRounded={true}
                    title="Выберите курс"
                    value={courses.name}
                    list={coursesList}
                    error={error?.message}
                    onCourseClick={(id, name) => {
                      changeCourse(id, name);
                    }}
                  />
                )}
              />
            </div>
            <div className="w-full rounded-[14px] sm:max-w-[49%]">
              <Controller
                name="module_id"
                control={control}
                rules={{
                  required: { message: 'Выберите модуль', value: true },
                }}
                render={({ fieldState: { error } }) => (
                  <DropdownSelect
                    notRounded={true}
                    title="Выберите модуль"
                    value={moduleName}
                    list={courses?.id && modules.length ? modules : []}
                    error={error?.message}
                    onCourseClick={(id, name) => {
                      setValue('module_id', id);
                      setModuleName(name);
                    }}
                  />
                )}
              />
            </div>
          </div>
          <div className="w-full bg-inputbg rounded-[14px]">
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <input
                  type="text"
                  placeholder="Введите название урока"
                  value={isEditingName ? field.value : lessonDetail?.name}
                  onChange={(e) => {
                    if (!isEditingName) {
                      setIsEditingName(true);
                    }
                    setValue('name', e.target.value);
                  }}
                  className="w-full max-h-[52px] py-3 px-6 text-current bg-transparent border border-0.5 border-white border-opacity-50 rounded-[14px] outline-none theme-input"
                />
              )}
            />
          </div>
        </div>

        <div className="flex flex-col gap-y-5 p-5 theme-background-homework-card rounded-3xl sm:p-10">
          <div className="flex flex-row justify-between">
            <p className="text-current text-2xl">Описание урока</p>
            <div className="hidden flex-row gap-x-8 sm:flex">
              {/* there are fields not working yet with useForm */}
              {/* <CustomRadioButton
                label="Оффлайн"
                value="offline"
                selectedValue={selectedOption}
                onChange={handleOptionChange}
              />
              <CustomRadioButton
                label="Онлайн"
                value="online"
                selectedValue={selectedOption}
                onChange={handleOptionChange}
              /> */}
            </div>
          </div>
          <div className="w-full mt-7 couch-editor">
            <ReactQuill
              placeholder={
                'Опишите, что нужно сделать и какой результат вы ждете от студента. Прикрепите дополнительные материалы, если необходимо'
              }
              modules={{
                toolbar: toolbarOptions,
              }}
              style={{ color: '#fff' }}
              value={isEditingDesc ? lessonDescription : lessonDetail?.description}
              onChange={(value) => {
                if (!isEditingDesc) {
                  setIsEditingDesc(true);
                }
                setLessonDescription(value);
              }}
            />
          </div>
          <div className="w-full flex flex-col gap-5 rounded-3xl sm:flex-row">
            {/* TODO: ссылка на трансляцию вынесена в создание расписание */}
            {/* <div className="w-full flex flex-roe items-center gap-x-3.5 px-6 border border-0.5 border-white border-opacity-50 rounded-[14px] sm:w-1/2 hidden">
              <LinkIcon />
              <Controller
                name="video_broadcast_link"
                control={control}
                render={({ field }) => (
                  <input
                    type="text"
                    placeholder="Ссылку на трансляцию"
                    value={
                      isEditingBroadcast
                        ? field.value
                        : String(lessonDetail?.videoBroadcastUrl || '')
                    }
                    onChange={(e) => {
                      if (!isEditingBroadcast) {
                        setIsEditingBroadcast(true);
                      }
                      setValue('video_broadcast_link', e.target.value);
                    }}
                    className="w-full max-h-[52px] py-3 text-current bg-transparent border-none outline-none"
                  />
                )}
              />
            </div> */}
            <div className="w-full flex flex-row items-center gap-x-3.5 px-6 border border-0.5 border-white border-opacity-50 rounded-[14px] sm:w-full theme-input theme-icon-stroke">
              <LinkIcon />
              <Controller
                name="video_youtube_link"
                control={control}
                render={({ field }) => (
                  <input
                    type="text"
                    placeholder="Ссылка на видео"
                    value={isEditingVideoUrl ? field.value : String(lessonDetail?.videoYoutubeUrl)}
                    onChange={(e) => {
                      if (!isEditingVideoUrl) {
                        setIsEditingVideoUrl(true);
                      }
                      setValue('video_youtube_link', e.target.value);
                    }}
                    className="w-full max-h-[52px] py-3 theme-text--default bg-transparent border-none outline-none"
                  />
                )}
              />
            </div>
          </div>
          <div className="flex flex-row gap-x-8 sm:hidden">
            {/* there are fields not working yet with useForm */}
            <CustomRadioButton
              label="Оффлайн"
              value="offline"
              selectedValue={selectedOption}
              onChange={handleOptionChange}
            />
            <CustomRadioButton
              label="Онлайн"
              value="online"
              selectedValue={selectedOption}
              onChange={handleOptionChange}
            />
          </div>
        </div>

        <div className="flex flex-col gap-y-5 p-5 theme-background-homework-card rounded-3xl sm:p-10">
          <span className="text-current text-xl font-medium">Загрузка файлов и видео</span>
          <div className="w-full flex flex-col gap-5 sm:flex-row">
            <div className="w-full sm:w-1/2 flex flex-col gap-1">
              {lessonDetail?.fileUrl?.length &&
                lessonDetail?.fileUrl?.map((el, idx) => (
                  <div
                    key={idx}
                    className="w-full flex flex-row justify-between items-center p-7 theme-background--grey rounded-[14px] relative"
                  >
                    <div className="max-w-[400px] flex flex-col gap-2">
                      <p className="flex flex-row items-end gap-x-2 text-current text-ld">
                        {el.name}
                        <span className="text-current text-opacity-30 text-base">
                          {(Number(el.size) / (1024 * 1024)).toFixed(2)} мб
                        </span>
                      </p>
                    </div>
                    <FileDownloadButton fileLink={el.downloadLink} name={el.name} />
                    <CloseIcon
                      onClick={deleteFile(el, 'file')}
                      className="absolute right-[-8px] top-[-8px] w-5 h-5 bg-white rounded-full cursor-pointer z-10 theme-border--light-black border"
                    />
                  </div>
                ))}
              <UploadFile
                uploading={isFileUploading}
                title="Загрузить файл"
                hint="PNG, DOC, EXCEL, PDF, JPEG"
                types={fileTypes}
                isMultiple={true}
                files={newFiles}
                handleChange={handleChangeFiles}
              />
            </div>
            <div className="w-full sm:w-1/2 flex flex-col gap-1">
              {lessonDetail?.videoUrl?.length &&
                lessonDetail?.videoUrl?.map((el, idx) => (
                  <div
                    key={idx}
                    className="w-full flex flex-row justify-between theme-background--grey items-center p-7 rounded-[14px] relative"
                  >
                    <div className="max-w-[400px] flex flex-col gap-2">
                      <p className="flex flex-row items-end gap-x-2 text-current text-ld">
                        {el.name}
                        <span className="text-current text-opacity-30 text-base">
                          {(Number(el.size) / (1024 * 1024)).toFixed(2)} мб
                        </span>
                      </p>
                    </div>
                    <FileDownloadButton fileLink={el.downloadLink} name={el.name} />
                    <CloseIcon
                      onClick={deleteFile(el, 'video')}
                      className="absolute right-[-8px] top-[-8px] w-5 h-5 bg-white rounded-full theme-border---light-black border cursor-pointer z-10"
                    />
                  </div>
                ))}
              <UploadFile
                uploading={isVideoUploading}
                title="Загрузите видео"
                hint="Ограничение до 1 гб"
                types={videoTypes}
                isMultiple={true}
                files={newVideo}
                handleChange={handleChangeVideos}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditLesson;
