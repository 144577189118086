import request from 'api/axios-instance';
import { mapOptionDaoToEntity } from 'api/mappers/option.mappers';
import { Option } from 'entities/Option';

import { CoachDao } from '../dao/coaches';

export const fetchCourses = () =>
  request.get('v2/coach/catalog/courses?perPage=100&page=1').then((res) => {
    const types: Option[] = res.data.data.map(mapOptionDaoToEntity);
    return types;
  });

export const fetchCoordinators = () =>
  request.get('v2/coach/catalog/coordinators?perPage=100&page=1').then((res) => {
    const types: Option[] = res.data.data.map(mapOptionDaoToEntity);
    return types;
  });

export const fetchStudents = () =>
  request.get('v2/coach/catalog/students?perPage=100&page=1').then((res) => {
    const types: Option[] = res.data.data.map(mapOptionDaoToEntity);
    return types;
  });

export const fetchStudyTypes = () =>
  request.get('v1/management/study-types').then((res) => {
    const types: Option[] = res.data.data.map(mapOptionDaoToEntity);
    return types;
  });

export const fetchCoaches = (search?: string) => {
  const params: { [key: string]: string | number } = {
    perPage: 1000,
    page: 1,
  };
  if (search) params.search = search;
  return request
    .get(`v1/management/coaches`, { params })
    .then((res) => res.data.data.map(mapCoachDaoToEntity));
};

export const uploadFile = (data: FormData) =>
  request.post('v1/upload', data).then((res) => res.data.file_path as string);

function mapCoachDaoToEntity(coach: CoachDao): Option {
  return {
    value: coach.id,
    label: coach.full_name,
  };
}
