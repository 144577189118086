import React from 'react';
import Accordion from 'components/Accordion';
import { UserDetail } from 'modules/hr/entities/User';

interface Props {
  user: UserDetail;
}

const LANGUAGES: { [key: string]: string } = {
  ru: 'русский',
  kz: 'казахский',
  en: 'английский',
};

const UserPersonalData: React.FC<Props> = ({ user }) => {
  const langs = user.languages
    ? user.languages.split(',').reduce((acc, crr) => {
        const crrLang = LANGUAGES[crr.trim()];
        if (acc) {
          return `${acc}, ` + crrLang;
        }
        return acc + crrLang;
      }, '')
    : null;

  return (
    <Accordion title="Личные данные">
      <div className="flex flex-col space-y-3">
        {/* <div className="flex flex-row rounded-2xl bg-white/10 p-5">
          <p className="w-1/2 text-white">Гражданство:</p>
          <p className="w-1/2 text-white font-medium truncate">{user.value}</p>
        </div> */}
        {user.city && (
          <div className="flex flex-row rounded-2xl bg-white/10 p-5">
            <p className="w-1/2 text-white">Город:</p>
            <p className="w-1/2 text-white font-medium truncate">{user.city}</p>
          </div>
        )}
        {langs && (
          <div className="flex flex-row rounded-2xl bg-white/10 p-5">
            <p className="w-1/2 text-white">Языки:</p>
            <p className="w-1/2 text-white font-medium truncate">{langs}</p>
          </div>
        )}
        {user.status && (
          <div className="flex flex-row rounded-2xl bg-white/10 p-5">
            <p className="w-1/2 text-white">Статус:</p>
            <p className="w-1/2 text-white font-medium truncate">{user.status}</p>
          </div>
        )}
      </div>
    </Accordion>
  );
};

export default UserPersonalData;
