import { ICoursesModules, ILessons, IModules } from 'modules/coach/entities/coach-courses/Modules';
import request from 'api/axios-instance';

import { ICoursesModulesDao, ILessonsDao, IModulesDao } from '../../dao/courses/GetModules.dao';

export const fetchModules = (groupId: number, courseId: number) =>
  request
    .get(
      `/v2/coach/total-courses/modules?by_this_coach=1&group_id=${groupId}&course_id=${courseId}`
    )
    .then((res) => {
      const modules: ICoursesModules = mapCoursesModulesDaoToEntity(res.data.data);

      return modules;
    });

export const createModule = (params: Record<string, string | number>) =>
  request.post('/v2/coach/total-courses/modules', params);

export const editModule = (id: number, params: Record<string, string | number>) =>
  request.post(`/v2/coach/total-courses/modules/${id}`, params);

export const deleteModule = (id: number) => request.delete(`/v2/coach/total-courses/modules/${id}`);

function mapCoursesModulesDaoToEntity(courseModulesDao: ICoursesModulesDao): ICoursesModules {
  return {
    id: courseModulesDao.id,
    name: courseModulesDao.name,
    image: courseModulesDao.image,
    dateFrom: courseModulesDao.date_from,
    dateTo: courseModulesDao.date_to,
    groupId: courseModulesDao.group_id,
    groupName: courseModulesDao.group_name,
    modules: mapModulesDaoToEntity(courseModulesDao.modules),
  };
}

function mapModulesDaoToEntity(modulesDao: IModulesDao[]): IModules[] {
  return modulesDao.map((module) => ({
    id: module.id,
    title: module.title,
    courseId: module.course_id,
    slug: module.slug,
    position: module.position,
    lessons: mapLessonsDaoToEntity(module.lessons),
  }));
}

function mapLessonsDaoToEntity(lessonsDao: ILessonsDao[]): ILessons[] {
  return lessonsDao.map((lesson) => ({
    id: lesson.id,
    name: lesson.name,
    position: lesson.position,
    moduleId: lesson.module_id,
  }));
}
