import { useSearchParams } from 'react-router-dom';
import { usePartialState } from 'hooks/partial-state';

interface Filter {
  course_id: number | null;
  type: number | null;
}

export const useCoachFilters = () => {
  const [query, setQuery] = useSearchParams();

  const courseId = query.get('course_id');
  const coachType = query.get('type');

  const [filter, setFilter] = usePartialState<Filter>({
    course_id: courseId ? +courseId : null,
    type: coachType ? +coachType : null,
  });

  const onSubmit = () => {
    Object.entries(filter).forEach(([key, value]) => {
      if (value) {
        query.set(key, value.toString());
      } else {
        query.delete(key);
      }
    });

    query.set('page', '1');

    setQuery(query);
  };

  const onReset = () => {
    setFilter({
      course_id: null,
      type: null,
    });

    Object.entries(filter).forEach(([key]) => {
      query.delete(key);
    });

    query.set('page', '1');

    setQuery(query);
  };

  return {
    filter,
    setFilter,
    onSubmit,
    onReset,
  };
};
