import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import KinescopePlayer from '@kinescope/react-kinescope-player';

const Kinescope = () => {
  const { id } = useParams();

  return (
    <div className="h-full w-full flex justify-center items-center">
      {id ? (
        <KinescopePlayer className="w-full h-full max-w-[500px] max-h-[500px]" videoId={id} />
      ) : null}
    </div>
  );
};

export default Kinescope;
