import { useEffect, useState } from 'react';

type Size = 'phone' | 'tablet' | 'desktop';

export const useWindowSize = (): Size => {
  const [windowWidth, setWindowWidth] = useState<number | undefined>();

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (!windowWidth) return 'desktop';

  if (windowWidth < 640) return 'phone';

  if (windowWidth < 1024) return 'tablet';

  return 'desktop';
};
