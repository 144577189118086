import React from 'react';
import { ReactComponent as SpinnerIcon } from 'assets/icons/spinner.svg';

type ButtonProps = {
  children: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  loading?: boolean;
};

const AuthButton: React.FC<ButtonProps> = ({
  onClick = () => {},
  disabled = false,
  loading = false,
  children,
}) => {
  return (
    <button
      className={`${
        loading ? 'bg-transparent' : ''
      } relative inline-flex items-center justify-center gap-2 py-4 text-white text-base uppercase bg-blue rounded-[10px] cursor-pointer`}
      type="submit"
      disabled={disabled || loading}
      onClick={onClick}
    >
      {loading ? (
        <span className="absolute inset-0 flex items-center justify-center z-10">
          <SpinnerIcon />
        </span>
      ) : (
        children
      )}
    </button>
  );
};

export default AuthButton;
