import { useSearchParams } from 'react-router-dom';
import { usePartialState } from 'hooks/partial-state';

interface Filter {
  course: number | null;
  year: number | null;
}

export const useDashboardFilter = () => {
  const [query, setQuery] = useSearchParams();

  const course = query.get('course');
  const year = query.get('year');

  const [filter, setFilter] = usePartialState<Filter>({
    course: course ? +course : null,
    year: year ? +year : null,
  });

  const onSubmit = () => {
    Object.entries(filter).forEach(([key, value]) => {
      if (value) {
        query.set(key, value.toString());
      } else {
        query.delete(key);
      }
    });

    setQuery(query);
  };

  const onReset = () => {
    setFilter({
      course: null,
      year: null,
    });

    Object.entries(filter).forEach(([key]) => {
      query.delete(key);
    });

    setQuery(query);
  };

  return {
    filter,
    setFilter,
    onSubmit,
    onReset,
  };
};
