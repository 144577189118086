export default {
  notConnection: 'Интернет қосылымын тексеріңіз',
  unknown: 'Бірдеңе дұрыс болмады',
  server: 'Сервер қатесі. Тех. көмекке жүгініңіз',
  wrongAuth: 'Электрондық пошта немесе құпия сөз қате',
  emailTaken: 'Бұл электрондық пошта бос емес',
  required: 'Міндетті өріс',
  invalidEmail: 'Жарамсыз электрондық пошта',
  minLength: '{{len}} таңбадан ұзақ болуы керек',
  passwordsNotMatch: 'Құпия сөздер сәйкес келмеді',
  notCorrectPrice: 'Дұрыс емес баға',
};
