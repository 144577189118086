import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ReactComponent as StarIcon } from 'assets/icons/star-fill.svg';
import LMSButton from 'components/LMSButton';
import useHandleError from 'hooks/handleError';
import { useNotification } from 'hooks/notification';
import { ErrorResponse } from 'entities/Error';

import { fetchSurvey, sendSurvey } from '../../api/repository/profile';
import Loading from '../../components/Loading';
import { Survey } from '../../entities/Survey';

const StudentSurvey = () => {
  const navigate = useNavigate();
  const notify = useNotification();
  const { id } = useParams();
  const [query] = useSearchParams();
  const courseId = query.get('course_id');
  const { handleCommonError } = useHandleError();

  const [isLoading, setLoading] = useState(false);
  const [survey, setSurvey] = useState<Survey | null>(null);
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    if (!id || !courseId) return;

    setLoading(true);
    fetchSurvey(+id, +courseId)
      .then((res) => setSurvey(res))
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const question = useMemo(() => {
    if (!survey || !survey.questions.length) return null;

    return survey.questions[current];
  }, [current, survey]);

  const progressBarWidth = useMemo(() => {
    const percentage = Math.floor(((current + 1) * 100) / (survey?.questions.length || 1));
    return `${percentage}%`;
  }, [current, survey]);

  const setSelectedAnswer = (questionId: number, selected: string) => {
    setSurvey((prev) =>
      prev
        ? {
            ...prev,
            questions: prev.questions.map((q) => {
              if (q.id === questionId && q.answerType === 'rating') {
                const res = [];

                for (let i = 1; i <= +selected; i++) {
                  res.push(i.toString());
                }
                q.selected = res;
              }

              return q;
            }),
          }
        : null
    );
  };

  const writeFeedback = (questionId: number, feedBack: string) => {
    setSurvey((prev) =>
      prev
        ? {
            ...prev,
            questions: prev.questions.map((q) => {
              if (q.id === questionId && q.answerType === 'text') {
                q.selected = feedBack;
              }

              return q;
            }),
          }
        : null
    );
  };

  const goToNextQuestion = () => {
    if (!question || !question.selected) return;

    if (current + 1 === survey?.questions.length) {
      const answers = survey.questions.map((q) => ({
        question_id: q.id,
        answer: q.answerType === 'text' ? q.selected : +q.selected[q.selected.length - 1],
      }));
      sendSurvey(+id!, { answers, course_id: +(courseId || 0) })
        .then(() => {
          notify.success('Ваши ответы удачно отправлены');
          navigate('/student');
        })
        .catch((err: unknown) => {
          const error = err as ErrorResponse;
          if (error.response?.status === 400) {
            notify.error('Вы уже ответили на этот опрос.');
          } else {
            handleCommonError(error);
          }
        });
    } else {
      setCurrent((prev) => prev + 1);
    }
  };

  if (!survey && isLoading) return <Loading />;

  if (!survey) return <div />;

  return (
    <div className="mt-8 md:mt-12">
      <p className="theme-light-text">Опрос №295</p>
      <p className="mt-4 text-lg md:text-2xl">
        {survey.name} «{survey.course.name}»{' '}
      </p>

      {question ? (
        <div className="mt-10 rounded-2xl theme-student-card overflow-hidden">
          <div className="flex items-center space-x-6 py-5 px-4 md:px-10 theme-background--grey">
            <span>
              Вопрос {current + 1} из {survey.questions.length}
            </span>
            <div className="flex-1 flex items-center theme-background--grey rounded-full overflow-hidden">
              <div className="h-[3px] bg-studentProgressBg" style={{ width: progressBarWidth }} />
            </div>
          </div>
          <div className="p-4 md:p-10">
            <p className="max-w-[600px] text-base md:text-lg">{question.name}</p>

            <div className="flex items-center md:items-end md:justify-between flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 mt-5 md:mt-12">
              <div className="flex space-x-3 flex-1">
                {question.answerType === 'rating' &&
                  Object.keys(question.options).map((key) => (
                    <button
                      key={key}
                      className={`${
                        question.selected.includes(key) ? 'text-current' : 'text-transparent'
                      }`}
                      onClick={() => setSelectedAnswer(question.id, key)}
                    >
                      <StarIcon className="w-[48px] md:w-[58px] h-[48px] md:h-[58px]" />
                    </button>
                  ))}
                {question.answerType === 'text' ? (
                  <textarea
                    value={question.selected}
                    className="w-full p-4 bg-transparent outline-none border theme-border--grey rounded-lg"
                    onChange={(event) => writeFeedback(question.id, event.target.value)}
                  />
                ) : null}
              </div>
              <LMSButton className="w-full md:w-auto" onClick={goToNextQuestion}>
                {survey.questions.length === current + 1 ? 'ОТПАВИТЬ' : 'ДАЛЕЕ'}
              </LMSButton>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default StudentSurvey;
