import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ListIcon } from 'assets/icons/list.svg';
import LMSButton from 'components/LMSButton';

interface Props {
  countCorrectAnswers: number;
  countWrongAnswers: number;
  nextStep: () => void;
  tryAgain: () => void;
}

const LectioCongrats: React.FC<Props> = ({
  countCorrectAnswers,
  countWrongAnswers,
  nextStep,
  tryAgain,
}) => {
  const navigate = useNavigate();

  const handleExit = () => navigate('/student');

  return (
    <div className="flex flex-col space-y-14 items-center justify-center w-[80%] max-w-[960px] h-max rounded-[20px] theme-student-card m-auto px-10 py-20">
      <div className="flex flex-col space-y-8 items-center">
        <p className="text-[32px] font-semibold text-center leading-10 max-w-[512px]">
          Поздравляем! <br /> Вы завершили игру
        </p>
        <p className="text-[22px] text-center leading-6 max-w-[700px]">Вот ваши результаты:</p>
        <div className="flex flex-row space-x-4">
          <article className="flex flex-col items-center justify-center rounded-xl px-10 py-5 bg-[#F1FEF2]">
            <p className="text-3xl text-green">{countCorrectAnswers}</p>
            <p className="text-[#797979]">Правильных ответов</p>
          </article>
          <article className="flex flex-col items-center justify-center rounded-xl px-10 py-5 bg-[#FEF1F1]">
            <p className="text-3xl text-red-700">{countWrongAnswers}</p>
            <p className="text-[#797979]">Неправильных ответов</p>
          </article>
        </div>
        <button
          className="flex flex-row items-center justify-center bg-transparent outline-none theme-active-text"
          onClick={nextStep}
        >
          <ListIcon className="mr-2" />
          Показать прошлые результаты
        </button>
      </div>
      <div className="flex flex-row space-x-3">
        <LMSButton onClick={tryAgain}>Начать игру заново</LMSButton>
        <button
          type="button"
          className="flex items-center justify-center text-sm md:text-base px-3 md:px-[25px] py-2 md:py-3.5 rounded-full border theme-border--primary theme-active-text"
          onClick={handleExit}
        >
          Перейти на главную страницу
        </button>
      </div>
    </div>
  );
};

export default LectioCongrats;
