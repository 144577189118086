import { useState } from 'react';
import { uploadFiles } from 'modules/coach/api/repository/upload-file';
import { useNotification } from 'hooks/notification';

type TFIle = {
  downloadLink: string;
  hash: string;
  name: string;
  size: string;
};

const useFileControl = () => {
  const [controlOldFiles, setFiles] = useState<TFIle[]>([]);
  const [controlOldVideos, setVideo] = useState<TFIle[]>([]);
  const [newFiles, setNewFiles] = useState<File[]>([]);
  const [newVideo, setNewVideo] = useState<File[]>([]);
  const notify = useNotification();

  const deleteOldFile = (file: TFIle) => {
    const filterFiles = controlOldFiles.filter((f) => f.downloadLink !== file.downloadLink);
    setFiles(filterFiles);
  };

  const deleteOldVideo = (video: TFIle) => {
    const filterVideos = controlOldVideos.filter((f) => f.downloadLink !== video.downloadLink);
    setVideo(filterVideos);
  };

  // ----- new files control --------

  const deleteNewFiles = (files: File[]) => {
    setNewFiles(files);
  };

  const controlFiles = (files: File[]) => {
    const newFile = files.filter((file) => {
      const alreadyInclude = controlOldFiles.find((el) => el.name === file.name);
      const alreadyOldInclude = newFiles.find((el) => el.name === file.name);

      if (alreadyInclude || alreadyOldInclude) {
        notify.error('Файл с таким именем уже добавлен');
        return;
      }

      return true;
    });

    setNewFiles([...newFiles, ...newFile]);
  };

  // -----  new video control -------

  const deleteNewVideo = (videos: File[]) => {
    setNewVideo(videos);
  };

  const controlVideo = (files: File[]) => {
    const newVideos = files.filter((file) => {
      const alreadyInclude = controlOldVideos.find((el) => el.name === file.name);
      const alreadyOldInclude = newVideo.find((el) => el.name === file.name);

      if (alreadyInclude || alreadyOldInclude) {
        notify.error('Файл с таким именем уже добавлен');
        return;
      }

      return true;
    });

    setNewVideo([...newVideo, ...newVideos]);
  };

  return {
    controlOldFiles,
    controlOldVideos,
    newFiles,
    newVideo,
    controlFiles,
    controlVideo,
    deleteNewVideo,
    deleteNewFiles,
  };
};

export default useFileControl;
