import React, { createContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePartialState } from 'hooks/partial-state';

import BaseModal from '../BaseModal';
import LMSButton, { ButtonColorType } from '../LMSButton';

import cl from './confirm-box.module.scss';

interface ConfirmProps {
  title: string;
  message: string;
  okVariant: ButtonColorType;
  okText: string;
  cancelVariant: 'default' | 'red';
  cancelText: string;
  onAccept: () => void;
  onCancel?: () => void;
}

export interface ConfirmBoxContextProps {
  showConfirmBox: (payload: Partial<ConfirmProps>) => void;
}

export const ConfirmBoxContext = createContext<ConfirmBoxContextProps>({
  showConfirmBox: () => {},
});

interface Props {
  children: React.ReactNode;
}

const ConfirmBoxProvider: React.FC<Props> = ({ children }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const getInit = (): ConfirmProps => ({
    title: t('common.attention'),
    message: '',
    okText: t('action.confirm'),
    okVariant: 'main',
    cancelVariant: 'default',
    cancelText: t('action.cancel'),
    onAccept: () => {},
    onCancel: undefined,
  });

  const [state, setState] = usePartialState<ConfirmProps>(getInit());

  const showConfirmBox = (payload: Partial<ConfirmProps>) => {
    setIsOpen(true);
    setState(payload);
  };

  const handleConfirm = () => {
    setIsOpen(false);
    if (state.onAccept) {
      state.onAccept();
    }
    resetOnClose();
  };

  const handleCancel = () => {
    setIsOpen(false);
    if (state.onCancel) {
      state.onCancel();
    }
    resetOnClose();
  };

  const resetOnClose = () => {
    setState(getInit());
  };

  return (
    <ConfirmBoxContext.Provider
      value={{
        showConfirmBox,
      }}
    >
      {children}

      {isOpen ? (
        <BaseModal onClose={handleCancel}>
          <div className={cl['confirm-box__description']}>{state.message}</div>
          <div className={cl['confirm-box__footer']}>
            <LMSButton
              variant="solid"
              colorType="main"
              isRed={state.cancelVariant === 'red'}
              onClick={handleCancel}
            >
              {state.cancelText}
            </LMSButton>
            <LMSButton variant="solid" colorType={state.okVariant} onClick={handleConfirm}>
              {state.okText}
            </LMSButton>
          </div>
        </BaseModal>
      ) : null}
    </ConfirmBoxContext.Provider>
  );
};

export default ConfirmBoxProvider;
