import { usePartialState } from 'hooks/partial-state';

export const useCalendarFilter = () => {
  const today = new Date();

  const [calendar, setCalendar] = usePartialState({
    type: 'week',
    year: today.getFullYear(),
    month: today.getMonth(),
    week: getWeekNumber(today),
    monday: today.getDate() - today.getDay() + 1,
  });

  const onPeriodChange = (type: string) => {
    setCalendar({
      type,
      year: today.getFullYear(),
      month: today.getMonth(),
      week: getWeekNumber(today),
      monday: today.getDate() - today.getDay() + 1,
    });
  };

  const getWeekDateRange = () => {
    const monday = new Date(calendar.year, calendar.month, calendar.monday);
    const end = new Date(calendar.year, calendar.month, monday.getDate() + 6);
    return `${monday.getDate()}-${end.getDate()}`;
  };

  const onPrevPeriodChange = () => {
    if (calendar.type === 'year') {
      setCalendar({ year: calendar.year - 1 });
    } else if (calendar.type === 'month') {
      if (calendar.month === 1) {
        setCalendar({ year: calendar.year - 1, month: 12 });
      } else {
        setCalendar({ month: calendar.month - 1 });
      }
    } else if (calendar.type === 'week') {
      const prevMonday = new Date(calendar.year, calendar.month, calendar.monday - 7);
      setCalendar({
        year: prevMonday.getFullYear(),
        month: prevMonday.getMonth(),
        week: getWeekNumber(prevMonday),
        monday: prevMonday.getDate(),
      });
    }
  };

  const onNextPeriodChange = () => {
    if (calendar.type === 'year') {
      setCalendar({ year: calendar.year + 1 });
    } else if (calendar.type === 'month') {
      if (calendar.month === 12) {
        setCalendar({ year: calendar.year + 1, month: 1 });
      } else {
        setCalendar({ month: calendar.month + 1 });
      }
    } else if (calendar.type === 'week') {
      const nexMonday = new Date(calendar.year, calendar.month, calendar.monday + 7);
      setCalendar({
        year: nexMonday.getFullYear(),
        month: nexMonday.getMonth(),
        week: getWeekNumber(nexMonday),
        monday: nexMonday.getDate(),
      });
    }
  };

  function getWeekNumber(date: Date, startDay = 1) {
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const daysSinceStart = Math.floor(
      (date.getTime() - firstDayOfMonth.getTime()) / (24 * 60 * 60 * 1000)
    );

    return Math.floor((daysSinceStart + firstDayOfMonth.getDay() - startDay) / 7) + 1;
  }

  return {
    calendar,
    onPeriodChange,
    onPrevPeriodChange,
    onNextPeriodChange,
    getWeekDateRange,
  };
};
