import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Lesson } from 'modules/student/entities/Lesson';

import { fetchCalendar } from '../api/repository/calendar';
import { ICalendarLessonParams } from '../entities/CalendarLesson';

export const useCalendar = () => {
  const [query] = useSearchParams();

  const course = query.get('course');
  const group = query.get('group');
  const status = query.get('status');
  const dateFrom = query.get('dateFrom');
  const dateTo = query.get('dateTo');

  const [horizontalLessons, setHorizontalLessons] = useState<Lesson[]>([]);
  const [verticalLessons, setVerticalLessons] = useState<Lesson[]>([]);
  const [calendarLessons, setCalendarLessons] = useState<Lesson[]>([]);
  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    const params: ICalendarLessonParams = {
      course_id: course ? +course : null,
      group_id: group ? +group : null,
      filter: status || 'active',
      date_from: dateFrom ? new Date(dateFrom).toLocaleDateString('sv-SE') : null,
      date_to: dateTo ? new Date(dateTo).toLocaleDateString('sv-SE') : null,
      limit: 10,
    };
    fetchCalendar(params).then((res) => {
      setVerticalLessons(res);
    });
  }, [currentDate, course, group, status, dateFrom, dateTo]);

  useEffect(() => {
    const today = new Date();
    const closestLessonsDayFrom = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    const closestLessonsCurrentDay = new Date();
    const closestLessonDayTo = new Date(
      closestLessonsCurrentDay.setDate(closestLessonsCurrentDay.getDate() + 31)
    );

    Promise.all([
      fetchCalendar({
        date_from: formatDate(closestLessonsDayFrom),
        date_to: formatDate(closestLessonDayTo),
        limit: 2,
      }),
      fetchCalendar(),
    ]).then(([resHorizontalLessons, resClosestLessons]) => {
      setHorizontalLessons(resHorizontalLessons);
      setCalendarLessons(resClosestLessons);
    });
  }, []);

  const formatDate = (date: Date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  return {
    horizontalLessons,
    verticalLessons,
    calendarLessons,
    setCurrentDate,
  };
};
