export default {
  submit: 'Отправить',
  email: 'Эл. почта',
  password: 'Пароль',
  title: 'Название',
  workingArea: 'Вид деятельности',
  country: 'Страна',
  legalAddress: 'Юридический адрес',
  bin: 'БИН',
  website: 'Сайт',
  phoneNumber: 'Номер телефона',
  fullName: 'Полное имя',
  position: 'Должность',
  firstName: 'Имя',
  lastName: 'Фамилия',
  patronymic: 'Отчество',
  confirmPassword: 'Подтвердить',
  newPassword: 'Новый пароль',
  createAccount: 'Завести аккаунт',
  forgotPassword: 'Забыл пароль',
  city: 'Город',
  description: 'Описание',
  category: 'Категория',
  subcategory: 'Подкатегория',
  region: 'Регион',
  industry: 'Отрасль',
  showOtherCompanies: 'Показать компании, которых нет в моём регионе',
  companyName: 'Название компании',
  cooperationPurpose: 'Цель сотрудничества',
  offer: 'Предложение',
  expectedBenefits: 'Ожидаемые выгоды',
  searchByIdTitle: 'Поиск по названию, ID или артикулу товара',
  selectColumnName: 'Выберите название столбца',
  positionName: 'Название позиции',
  width: 'Ширина, {{unit}}',
  height: 'Высота, {{unit}}',
  length: 'Длина, {{unit}}',
  weight: 'Вес, {{unit}}',
  orderFrom: 'При заказе от',
  keyPhrases: 'Впишите ключевые фразы, по которым покупатели будут искать ваш товар',
  contactPerson: 'Контактное лицо',
  code: 'Код',
  street: 'Улица',
  writeMessage: 'Введите сообщение',
  branchType: 'Тип организации',
};
