import React, { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';

import { fetchActiveCourseById } from '../../api/repository/my-courses';
import ActiveCourseSidebar from '../../components/ActiveCourseSidebar';
import Loading from '../../components/Loading';
import ProfileHeader from '../../components/ProfileHeader';
import { ActiveCourse } from '../../entities/ActiveCourse';

const ActiveCourseLayout = () => {
  const { id } = useParams();
  const [query, setQuery] = useSearchParams();

  const groupId = query.get('groupId') || null;

  const [isLoading, setLoading] = useState(true);
  const [course, setCourse] = useState<ActiveCourse | null>(null);
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    if (!id) return;

    fetchActiveCourseById(+id, groupId)
      .then((res) => {
        setCourse(res);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  if (isLoading)
    return (
      <div className="flex p-8">
        <Loading theme="dark" />
      </div>
    );

  if (!course || !id) return <div></div>;
  return (
    <div className="flex w-full h-full p-4 md:p-7">
      <ActiveCourseSidebar isOpen={isSidebarOpen} course={course} onClose={setSidebarOpen} />
      <div className="w-full flex-1 lg:ml-5">
        <ProfileHeader isSidebarOpen={isSidebarOpen} onSidebarOpen={setSidebarOpen} />

        <Outlet context={course} />
      </div>
    </div>
  );
};

export default ActiveCourseLayout;
