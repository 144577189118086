import React from 'react';
// import { ReactComponent as HeartIcon } from 'assets/icons/heart.svg';
// import { ReactComponent as HeartBlueIcon } from 'assets/icons/heart-blue.svg';
import Avatar from 'components/Avatar';
import { LessonComment as ILessonComment } from 'modules/student/entities/LessonComments';

interface Props {
  comment: ILessonComment;
  onSetResponseComment: (comment: ILessonComment) => void;
}

const LessonComment: React.FC<Props> = ({ comment, onSetResponseComment }) => {
  const onSetResponse = (data: ILessonComment) => onSetResponseComment(data);

  return (
    <div className="flex flex-col space-y-6">
      <div className="flex flex-row space-x-4">
        <div className="w-[34px] h-[34px] min-w-[34px]">
          <Avatar image={comment.user.avatar} alt="avatar" />
        </div>
        <div className="flex flex-col items-start">
          <div className="flex flex-row space-x-2 mb-2">
            <p className="text-current">{comment.user.full_name}</p>
            {/* <p className="text-white/30">5 сен</p> */}
          </div>
          <p className="theme-light-text mb-4">{comment.text}</p>
          <button
            className="theme-active-text outline-none m-0 p-0 cursor-pointer"
            onClick={() => onSetResponse(comment)}
          >
            Ответить
          </button>
        </div>
        {/* <div className="h-full flex items-center justify-center self-center">
          <div className="flex flex-row items-center space-x-2">
            <button className="outline-none m-0 p-0 cursor-pointer" onClick={() => {}}>
              <HeartIcon />
            </button>
            <p>21</p>
          </div>
        </div> */}
      </div>

      {comment.replies && comment.replies.length ? (
        <div className="flex flex-col space-y-6 ml-12">
          {comment.replies.map((commentChild) => (
            <LessonComment
              key={commentChild.id}
              comment={commentChild}
              onSetResponseComment={onSetResponseComment}
            />
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default LessonComment;
