import React from 'react';
import { useOutsideClick } from 'hooks/outside-click';

interface Props {
  children: React.ReactNode;
  isOpen?: boolean;
  onClose?: () => void;
}

const BasePopup: React.FC<Props> = ({ children, isOpen = false, onClose }) => {
  const popupRef = useOutsideClick(() => {
    onClose && onClose();
  });

  return (
    <div ref={popupRef} id="base-popup" className="base-popup">
      {isOpen && children}
    </div>
  );
};

export default BasePopup;
