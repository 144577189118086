import React, { useEffect, useRef } from 'react';

import { Result } from '../../entities/Result';

interface Props {
  result: Result;
}

const Speedometer: React.FC<Props> = ({ result }) => {
  const canvas = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    if (!canvas.current) return;

    const ctx = canvas.current.getContext('2d');
    const x = 400;
    const y = 400;
    const radius = 300;
    const minRad = 0.75 * Math.PI;
    const maxRad = 2.25 * Math.PI;
    const valueRad = (maxRad - minRad) * (result.score / 100) + minRad;

    if (ctx) {
      ctx.beginPath();
      ctx.arc(x, y, radius, minRad, valueRad);
      ctx.lineWidth = 40;
      ctx.lineCap = 'round';
      ctx.strokeStyle = result.isPassed ? '#58DB66' : '#DB586A';
      ctx.stroke();
    }
  }, [canvas.current]);

  return (
    <div className="relative w-60 h-60 shrink-0">
      <div className="w-full h-full">
        <canvas
          ref={canvas}
          id="speedometerCanvas"
          width="800"
          height="700"
          className="w-full h-full object-contain"
        />
      </div>

      <div className="absolute top-2/4 left-2/4 -translate-y-1/2 -translate-x-1/2">
        <p className="text-center text-white/50">Результат</p>
        <p className="text-center text-[52px] leading-[52px]">{result.score}%</p>
        <p className="text-center text-white/50">
          {result.correctAnswersNumber} из {result.questionsNumber}
        </p>
      </div>
    </div>
  );
};

export default Speedometer;
