import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import { useOutsideClick } from 'hooks/outside-click';

import CalendarPopup from '../../../../components/CalendarPopup';
import { Lesson } from '../../entities/Lesson';

interface Props {
  studyDays: string[];
  setDate: (date: Date) => void;
  lessons: Lesson[];
}

const Calendar: React.FC<Props> = ({ studyDays, setDate, lessons }) => {
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [popupVisible, setPopupVisible] = useState(false);
  const [currentLesson, setCurrentLesson] = useState<Lesson[]>([] as Lesson[]);

  const dropdownRef = useOutsideClick(() => {
    setPopupVisible(false);
  });

  const findCurrentLesson = (date: Date): Lesson[] => {
    if (!date || !lessons.length) {
      return [] as Lesson[];
    }

    const filterLessons =
      lessons.filter((lesson: Lesson) => {
        return lesson.date === date.toLocaleDateString('ru-RU');
      }) || ({} as Lesson);

    return filterLessons;
  };

  const handleEventClick = (date: Date | null) => {
    if (date) {
      const lessons = findCurrentLesson(date);
      setCurrentLesson(lessons);

      if (lessons?.length) {
        setPopupVisible(true);
      }
    }
  };

  return (
    <div className="flex flex-col w-full h-full student-calendar relative">
      <DatePicker
        selected={startDate}
        locale={ru}
        onMonthChange={(month) => setDate(month)}
        onSelect={(date) => setStartDate(date)}
        inline
        onChange={(date) => handleEventClick(date)}
        renderDayContents={(day, date) => {
          if (studyDays.includes(date?.toLocaleDateString('ru-RU') || '')) {
            return (
              <div>
                <span className="student-calendar__study-day">{day}</span>

                {popupVisible &&
                currentLesson?.length &&
                startDate &&
                day === +startDate.toLocaleDateString('ru-RU').slice(0, 2) ? (
                  <div ref={dropdownRef}>
                    <CalendarPopup lessons={currentLesson} />
                  </div>
                ) : null}
              </div>
            );
          }
          return <span>{day}</span>;
        }}
      />
    </div>
  );
};

export default Calendar;
