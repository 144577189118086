import { useState } from 'react';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/chevron-down.svg';
import { useOutsideClick } from 'hooks/outside-click';

type listType = {
  id: number;
  name: number | string;
};

export type Props = {
  list: listType[];
  value?: number | string;
  onChangeClick: (id: number, name: number | string) => void;
};

const DropdownSelect: React.FC<Props> = ({ list, value, onChangeClick }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const dropdownRef = useOutsideClick(() => {
    setIsOpen(false);
  });

  const toggleDropdown = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleItemClick = (id: number, name: number | string) => {
    setIsOpen(false);
    if (onChangeClick) {
      onChangeClick(id, name);
    }
  };

  return (
    <div ref={dropdownRef} className="relative h-full">
      <button
        onClick={() => toggleDropdown()}
        className={`rounded-[4px] !bg-[#F1F4FF] theme-input relative w-full h-[36px] flex flex-row items-center justify-between gap-x-2 py-0 px-4 text-blue`}
      >
        <span className="my-auto text-[22px] whitespace-nowrap overflow-x-auto">{value}</span>
        <ArrowDownIcon
          className={`${
            isOpen ? 'rotate-180 ease-out duration-700' : 'ease-in duration-500'
          } w-fit`}
        />
      </button>
      {isOpen && (
        <div className="absolute top-14 left-0 h-fit min-w-[200px] max-w-[300px] mt-1 border theme-border rounded-lg theme-main-background z-50">
          <ul className="max-h-[168px] w-full flex flex-col overflow-y-auto">
            {list.length ? (
              list.map((el) => (
                <li
                  key={el.id}
                  onClick={() => handleItemClick(el.id, el.name)}
                  className="w-full py-2 px-5 text-blue text-opacity-50 text-start cursor-pointer hover:bg-inputbg hover:text-opacity-100"
                >
                  {el.name}
                </li>
              ))
            ) : (
              <li className="py-2 px-5 text-blue text-sm text-opacity-50 text-start">
                Нет подходящих элементов
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default DropdownSelect;
