import { IHomeWorkForCheckDao } from 'modules/coach/api/dao/HomeWorkForCheckDao';
import { IHomeWorkForCheck } from 'modules/coach/entities/HomeWorkForCheck';
import {
  IGroup,
  IHomeWork,
  IHomeWorkEditParams,
  IHomeWorkForCheckParams,
  IHomeWorksParams,
  INewAllHomeWork,
  INewHomeWork,
} from 'modules/coach/entities/HomeWorks';
import request from 'api/axios-instance';

import { IAllHomeWorkDao, IGroupDao, IHomeWorkDao, INewHomeWorkDao } from '../dao/HomeWorksDao';

export const fetchHomeWorksByGroups = () =>
  request.get('v2/coach/home-tasks?perPage=100&page=1').then((res) => {
    const homeWorksByGroups = res.data.data.map(mapHomeWorksDaoToEntity);

    return homeWorksByGroups;
  });

export const fetchHomeworks = (params?: IHomeWorksParams) => {
  return request.get('v2/coach/home-tasks', { params }).then((res) => {
    const homeworks = res.data.data.map(mapHomeWorksDaoToEntity);

    return homeworks;
  });
};

export const fetchHomeworksById = (lessonId: number) => {
  return request.get(`v2/coach/catalog/home-tasks/${lessonId}`).then((res) => {
    const homeworks = res.data.data;

    return homeworks;
  });
};

export const fetchHomework = (homeworkId: number) =>
  request.get(`v2/coach/home-tasks/${homeworkId}`).then((res) => {
    const homework = mapNewHomeWorksDaoToEntity(res.data.data);

    console.warn('homework', homework);
    return homework;
  });

export const editHomework = (params: IHomeWorkEditParams) => {
  const { id, ...rest } = params;
  return request.post(`v2/coach/home-tasks/${id}`, { ...rest });
};

export const deleteHomework = (homeworkId: number) =>
  request.delete(`v2/coach/home-tasks/${homeworkId}`);

export const fetchCatalogHomeworks = (id: string | number) => {
  return request.get(`v2/coach/catalog/home-tasks/${id}&search=`).then((res) => {
    const homeworks = res.data.data.map(mapHomeWorksDaoToEntity);

    return homeworks;
  });
};

export const fetchTests = (id: string | number) => {
  return request.get(`v2/coach/catalog/tests/${id}&search=`).then((res) => res.data.data);
};

export const fetchHomeworksForCheck = (params: IHomeWorkForCheckParams) =>
  request.get('v2/coach/homeworks', { params }).then((res) => {
    const homeworks = res.data.data.map(mapHomeworkForCheckDaoToEntity);
    return { homeworks, lastPage: (res?.data?.meta?.last_page as number) || 1 };
  });

function mapHomeWorksDaoToEntity(homeWorksDao: IHomeWorkDao): IHomeWork {
  return {
    id: homeWorksDao.id,
    name: homeWorksDao.name,
    imageUrl: homeWorksDao.image_url,
    videoUrl: homeWorksDao.video_url,
    description: homeWorksDao.description,
    // groups: homeWorksDao.groups.map(mapGroupDaoToEntity),
  };
}

function mapHomeNewWorksDaoToEntity(homeWorksDao: IAllHomeWorkDao): INewAllHomeWork {
  return {
    calendar_id: homeWorksDao.calendar_id,
    course_name: homeWorksDao.course_name,
    deadline: homeWorksDao.deadline,
    group_name: homeWorksDao.group_name,
    id: homeWorksDao.id,
    lesson_id: homeWorksDao.lesson_id,
    lesson_name: homeWorksDao.lesson_name,
    mark: homeWorksDao.mark,
    mark_date: homeWorksDao.mark_date,
    module_name: homeWorksDao.module_name,
    name: homeWorksDao.name,
    position: homeWorksDao.position,
    status: homeWorksDao.status,
    student_id: homeWorksDao.student_id,
    student_name: homeWorksDao.student_name,
  };
}

function mapNewHomeWorksDaoToEntity(homeWorksDao: INewHomeWorkDao): INewHomeWork {
  return {
    id: homeWorksDao.id,
    name: homeWorksDao.name,
    imageUrl: homeWorksDao.image_url,
    videoUrl: homeWorksDao.video_url
      ? homeWorksDao.video_url.map((f) => ({
          downloadLink: f.download_link,
          hash: f.hash,
          name: f.name,
          size: f.size,
        }))
      : null,
    fileUrl: homeWorksDao.file_url
      ? homeWorksDao.file_url.map((f) => ({
          downloadLink: f.download_link,
          hash: f.hash,
          name: f.name,
          size: f.size,
        }))
      : null,
    description: homeWorksDao.description,
    // groups: homeWorksDao.groups.map(mapGroupDaoToEntity),
  };
}

function mapGroupDaoToEntity(groupDao: IGroupDao): IGroup {
  return {
    id: groupDao.id,
    name: groupDao.name,
    dateFrom: groupDao.date_from,
  };
}

function mapHomeworkForCheckDaoToEntity(homework: IHomeWorkForCheckDao): IHomeWorkForCheck {
  return {
    calendarId: homework.calendar_id,
    courseName: homework.course_name,
    deadline: homework.deadline,
    groupName: homework.group_name,
    id: homework.id,
    lessonId: homework.lesson_id,
    lessonName: homework.lesson_name,
    mark: homework.mark,
    markDate: homework.mark_date,
    moduleName: homework.module_name,
    name: homework.name,
    position: homework.position,
    studentId: homework.student_id,
    studentName: homework.student_name,
    status: {
      id: homework.status.id,
      name: homework.status.name,
    },
  };
}

export const fetchCheckHomeworks = (type: string, params?: IHomeWorksParams) => {
  return request
    .get(`v2/coach/homeworks?type=${type}&perPage=100&page=1`, { params })
    .then((res) => {
      const homeworks = res.data.data.map(mapHomeNewWorksDaoToEntity);

      return homeworks;
    });
};
