import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowBack } from 'assets/icons/chevron-left.svg';
import Avatar from 'components/Avatar';
import { fetchManagerById } from 'modules/hr/api/repository/managers';
import Loading from 'modules/student/components/Loading';

import UserActiveCourses from '../../components/UserActiveCourses';
// import UserCompletedCourses from '../../components/UserCompletedCourses';
import UserDisciplines from '../../components/UserDisciplines';
import UserDocuments from '../../components/UserDocuments';
import UserPersonalData from '../../components/UserPersonalData';
import UserProfessionalData from '../../components/UserProfessionalData';
import { UserDetail } from '../../entities/User';

const ManagerDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(false);
  const [manager, setManager] = useState<UserDetail | null>(null);

  useEffect(() => {
    if (!id) return;

    setLoading(true);
    fetchManagerById(+id)
      .then((res) => {
        setManager(res);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  return (
    <>
      {isLoading ? (
        <div className="mt-20">
          <Loading />
        </div>
      ) : null}

      {!isLoading ? (
        <div className="block lg:flex lg:flex-col items-start">
          <button
            type="button"
            onClick={() => navigate('/hr/users/managers')}
            className="w-fit flex flex-row items-center justify-center gap-x-2 py-3 pl-3 pr-5 text-white text-[14px] font-medium bg-white bg-opacity-20 rounded-[36px] border-none outline-none uppercase mb-10"
          >
            <ArrowBack />
            назад
          </button>

          {manager ? (
            <div className="w-full flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-6">
              <div className="flex flex-col w-full lg:w-[360px] space-y-4">
                <div className="flex flex-col items-center space-y-5 h-fit py-10 rounded-2xl bg-studentCardBg">
                  <div className="w-20 h-20 rounded-full overflow-hidden">
                    <Avatar image={manager?.avatar} alt={manager?.fullName} />
                  </div>
                  <p className="max-w-[300px] text-2xl text-center">{manager?.fullName}</p>

                  <div className="flex flex-col">
                    {manager?.phone && (
                      <a href={`tel:${manager.phone}`} className="text-center text-blue">
                        {manager.phone}
                      </a>
                    )}
                    {manager?.email && (
                      <a href={`mailto:${manager.email}`} className="text-center text-blue">
                        {manager.email}
                      </a>
                    )}
                  </div>
                </div>

                {/* <div className="flex flex-col items-center space-y-2 h-fit py-6 rounded-2xl bg-studentCardBg">
              <p className="text-white/50 text-center">Менеджер:</p>
              <p className="text-center">Войченко Екатерина Владимировна</p>
            </div> */}
              </div>

              <div className="flex flex-col flex-1 space-y-4">
                {manager.city && <UserPersonalData user={manager} />}
                {(manager.agreementFiles ||
                  manager.document ||
                  manager.requisites ||
                  manager.resume) && <UserDocuments user={manager} />}
                {(manager.type || manager.status || manager.salary || manager.specialization) && (
                  <UserProfessionalData user={manager} />
                )}
                {(manager.scoring || manager.scoringFile) && <UserDisciplines user={manager} />}
                {(manager.type || manager.agreementFiles) && <UserActiveCourses user={manager} />}
                {/* <UserCompletedCourses user={coach} /> */}
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  );
};

export default ManagerDetail;
