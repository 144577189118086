import React, { useEffect, useMemo, useState } from 'react';
import { registerLocale } from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as PlusIcon } from 'assets/icons/add.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import LMSButton from 'components/LMSButton';
import LMSTitle from 'components/LMSTitle';
import ru from 'date-fns/locale/ru';
import { useNotification } from 'hooks/notification';
import { Option } from 'entities/Option';

import { editGroup } from '../../api/repository/groups';
import { GroupParams } from '../../entities/Group';
import { Group } from '../../entities/Group';
import DropdownSelect from '../DropdownSelect';

registerLocale('ru', ru);

interface Props {
  group: Group;
  courses: Option[];
  students: Option[];
  coordinators: Option[];
}

interface IUser extends Option {
  role: string;
}

const USER_COORDINATOR_ROLE_ID = '1';
const USER_STUDENT_ROLE_ID = '0';

const EditGroup: React.FC<Props> = ({ group, courses, students, coordinators }) => {
  const notify = useNotification();
  const navigate = useNavigate();
  const { control, handleSubmit, setValue } = useForm<GroupParams>({
    defaultValues: {
      name: group.name,
      course_id: group.course.id,
    },
  });

  const [course, setCourse] = useState('');
  const [search, setSearch] = useState('');
  const [selectedUsers, setSelectedUsers] = useState<IUser[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const users: IUser[] = useMemo(() => {
    const list = [
      ...coordinators.map((c) => ({ role: USER_COORDINATOR_ROLE_ID, ...c })),
      ...students.map((s) => ({ role: USER_STUDENT_ROLE_ID, ...s })),
    ];
    return list.filter((item: IUser) => item.label.toLowerCase().includes(search.toLowerCase()));
  }, [students, coordinators, search]);

  const checkUserAdded = (user: IUser) => {
    return !!selectedUsers.find((u: IUser) => u.value === user.value);
  };

  const addUser = (user: IUser) => {
    const isUserAdded = !!selectedUsers.find((u: IUser) => u.value === user.value);
    if (isUserAdded) {
      setSelectedUsers([...selectedUsers].filter((u) => u.value !== user.value));
    } else {
      setSelectedUsers([...selectedUsers, user]);
    }
  };

  const selectAll = () => {
    if (selectedUsers.length === users.length) {
      return setSelectedUsers([]);
    }
    return setSelectedUsers(users);
  };

  const onSubmit = (formData: GroupParams) => {
    const coordinatorSelected = selectedUsers.find(
      (user: IUser) => user.role === USER_COORDINATOR_ROLE_ID
    );
    const studentsSelected = selectedUsers.filter(
      (user: IUser) => user.role === USER_STUDENT_ROLE_ID
    );

    if (!(coordinatorSelected && coordinatorSelected.value)) {
      return notify.error('Необходимо выбрать координатора');
    }

    if (!(studentsSelected && studentsSelected.length)) {
      return notify.error('Необходимо выбрать студента');
    }

    setIsLoading(true);
    editGroup(group.id, {
      ...formData,
      coordinator_id: coordinatorSelected.value,
      students: studentsSelected.map((s: IUser) => s.value),
    })
      .then(() => {
        notify.success('Изменения группы сохранены');
        setTimeout(() => {
          navigate('/hr/groups');
        }, 600);
      })
      .catch(() =>
        notify.error(
          'Не удалось изменить группу, повторите еще раз или обратитесь в службу поддержки'
        )
      )
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    const initialSelectedUsers: IUser[] = [];
    if (group.coordinator) {
      initialSelectedUsers.push({
        role: USER_COORDINATOR_ROLE_ID,
        value: group.coordinator.id,
        label: group.coordinator.name,
      });
    }
    group.students.forEach((student) => {
      initialSelectedUsers.push({
        role: USER_STUDENT_ROLE_ID,
        value: student.id,
        label: student.name,
      });
    });
    setSelectedUsers(initialSelectedUsers);
  }, []);

  return (
    <div className="flex flex-col">
      <div className="flex flex-row justify-between items-end">
        <LMSTitle tag="h4" className="text-white text-3xl">
          Редактирование группы
        </LMSTitle>
        <LMSButton loading={isLoading} className="hidden sm:block" onClick={handleSubmit(onSubmit)}>
          Сохранить
        </LMSButton>
      </div>

      <div className="w-full h-full flex flex-col gap-y-5 mt-10 mb-20 sm:mb-0">
        <div className="w-full flex flex-col gap-10 p-5 bg-gradient rounded-3xl sm:p-10">
          <div className="flex flex-col">
            <p className="text-white text-2xl mb-5">Основные данные</p>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
              <Controller
                name="name"
                control={control}
                rules={{
                  required: { message: 'Введите название группы', value: true },
                }}
                render={({ field, fieldState: { error } }) => (
                  <div className="w-full rounded-[14px]">
                    <input
                      type="text"
                      placeholder="Название группы"
                      {...field}
                      className={`w-full h-[50px] py-3 px-6 text-white bg-transparent border border-0.5 border-opacity-50 rounded-[14px] outline-none ${
                        error ? 'border-red-500' : 'border-white'
                      }`}
                    />
                    {error && <p className="text-red-500 mt-2">{error.message}</p>}{' '}
                  </div>
                )}
              />
              <Controller
                name="course_id"
                control={control}
                rules={{
                  required: { message: 'Выберите курс', value: true },
                }}
                render={({ fieldState: { error } }) => (
                  <DropdownSelect
                    notRounded={true}
                    title="Выбор курса"
                    value={course || group.course.name}
                    list={courses}
                    error={error?.message}
                    onCourseClick={(id, name) => {
                      setValue('course_id', id);
                      setCourse(name);
                    }}
                  />
                )}
              />
            </div>
          </div>
        </div>

        <div className="w-full flex flex-col gap-10 p-5 bg-gradient rounded-3xl sm:p-10">
          <div className="flex flex-col space-y-6">
            <p className="text-white text-2xl">Добавить пользователей</p>

            <div className="flex flex-col space-x-0 space-y-6 sm:flex-row sm:space-x-6 sm:space-y-0">
              <div className="w-full flex items-center space-x-2 rounded-[14px] py-3 px-6 bg-inputbg border border-solid border-white/30">
                <input
                  value={search}
                  type="text"
                  className="flex-1 bg-transparent outline-none"
                  placeholder="Имя пользователя"
                  onChange={(ev) => setSearch(ev.target.value)}
                />
                <SearchIcon />
              </div>
              <button
                className="text-blue whitespace-nowrap p-0 m-0 outline-none"
                onClick={() => selectAll()}
              >
                Выбрать всех
              </button>
            </div>

            <div className="flex flex-col h-[412px] w-full overflow-y-auto">
              {users.map((user, index) => (
                <article
                  key={index}
                  className="flex flex-row p-5 rounded-xl bg-transparent space-x-4 transition hover:bg-[#C2E9FF14]"
                >
                  <div className="flex flex-col justify-center">
                    <p>
                      <span className="font-semibold">{user.label}</span> &#183;{' '}
                      <span className="opacity-60">
                        {user.role === USER_COORDINATOR_ROLE_ID ? 'координатор' : 'студент'}
                      </span>
                    </p>
                  </div>
                  {checkUserAdded(user) ? (
                    <button
                      type="button"
                      className="outline-none min-w-[48px] w-[48px] h-[48px] p-0 m-0 !ml-auto bg-blue rounded-full flex items-center justify-center"
                      onClick={() => addUser(user)}
                    >
                      <CheckIcon />
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="outline-none min-w-[48px] w-[48px] h-[48px] p-0 m-0 !ml-auto bg-[#C2E9FF14] rounded-full flex items-center justify-center transition hover:bg-blue"
                      onClick={() => addUser(user)}
                    >
                      <PlusIcon />
                    </button>
                  )}
                </article>
              ))}
            </div>

            <div className="flex flex-row justify-between items-center space-x-6">
              <p>Выбрано: {selectedUsers.length}</p>
            </div>
          </div>
        </div>
      </div>

      <LMSButton
        loading={isLoading}
        onClick={handleSubmit(onSubmit)}
        className="!fixed sm:hidden !bottom-0 left-0 right-0 py-4 rounded-none self-end sm:static sm:rounded-full"
      >
        СОХРАНИТЬ
      </LMSButton>
    </div>
  );
};

export default EditGroup;
