import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { ReactComponent as ArrowBack } from 'assets/icons/chevron-left.svg';
import EditGroup from 'modules/hr/components/EditGroup';
import Loading from 'modules/student/components/Loading';

import { fetchGroupById } from '../../api/repository/groups';
import { Group } from '../../entities/Group';
import { useCatalogCoordinators } from '../../hooks/catalog-coordinators';
import { useCatalogCourses } from '../../hooks/catalog-courses';
import { useCatalogStudents } from '../../hooks/catalog-students';

const HrEditGroupDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [group, setGroup] = useState<Group | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { isLoading: isLoadingCourses, courses } = useCatalogCourses();
  const { isLoading: isLoadingCoordinators, coordinators } = useCatalogCoordinators();
  const { isLoading: isLoadingStudents, students } = useCatalogStudents();

  const goBack = () => {
    navigate('/hr/groups');
  };

  useEffect(() => {
    if (!id) return;
    setIsLoading(true);
    fetchGroupById(+id)
      .then((res) => setGroup(res))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <div className="block lg:flex lg:flex-col items-start">
      <button
        type="button"
        onClick={() => goBack()}
        className="w-fit flex flex-row items-center justify-center gap-x-2 py-3 pl-3 pr-5 text-white text-[14px] font-medium bg-white bg-opacity-20 rounded-[36px] border-none outline-none uppercase mb-10"
      >
        <ArrowBack />
        назад
      </button>

      <div className="w-full flex flex-col !m-0">
        {isLoading || isLoadingCourses || isLoadingCoordinators || isLoadingStudents ? (
          <Loading />
        ) : id && group ? (
          <EditGroup
            group={group}
            courses={courses}
            coordinators={coordinators}
            students={students}
          />
        ) : (
          <div className="flex justify-center items-center text-white">
            Произошла ошибка... обратитесь в тех.поддержку
          </div>
        )}
      </div>
    </div>
  );
};

export default HrEditGroupDetail;
