import request from 'api/axios-instance';
import { mapOptionDaoToEntity } from 'api/mappers/option.mappers';

import { Book } from '../../entities/Book';
import { BookDao } from '../dao/Book.dao';

export const fetchBooks = (params: Record<string, string | null>) =>
  request.get('v1/books', { params }).then((res) => {
    const books: Book[] = res.data.data.map(mapBookDaoToEntity);
    return {
      books,
      last: res.data.meta.last_page as number,
    };
  });

export const fetchBookById = (id: number) =>
  request.get(`v1/books/${id}`).then((res) => mapBookDaoToEntity(res.data.data));

function mapBookDaoToEntity(book: BookDao): Book {
  return {
    id: book.id,
    name: book.name,
    description: book.description,
    pageNumber: book.page_number,
    imageUrl: book.image_url,
    filesUrl: book.files_url.map((f) => ({
      downloadLink: f.download_link,
      originalName: f.original_name,
      size: f.size,
      extension: f.extension,
    })),
    publishedAt: book.published_at,
    author: book.author ? mapOptionDaoToEntity(book.author) : null,
    category: book.category ? mapOptionDaoToEntity(book.category) : null,
    publisher: book.publisher ? mapOptionDaoToEntity(book.publisher) : null,
  };
}
