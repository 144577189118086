import React from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as AddIcon } from 'assets/icons/add.svg';
import LMSPagination from 'components/LMSPagination';
import Loading from 'modules/student/components/Loading';

import CourseGroupCard from '../../components/CourseGroupCard';
// import CourseGroupsFilter from '../../components/CourseGroupsFilter';
import { useGroups } from '../../hooks/groups';

const HrGroups = () => {
  const { isLoading, groups, currentPage, lastPage, setCurrentPage } = useGroups();

  return (
    <div className="block lg:flex items-start">
      <div className="flex-1">
        <div className="w-full flex flex-row justify-between lg:items-center mb-6 lg:mb-10">
          <h2 className="text-[32px] font-semibold">Группы обучения</h2>
          <div className="flex lg:flex-row items-center space-x-4">
            {/* <div className="hidden lg:block">
              <CourseGroupsFilter />
            </div> */}
            <NavLink
              to="/hr/groups/new"
              className="h-fit w-fit flex flex-row items-center justify-center gap-x-2 py-4 px-5 text-white text-[14px] font-bold uppercase bg-[#26A7EF] rounded-[12px] hover:text-white md:relative md:rounded-[30px]"
            >
              <AddIcon />
              <span className="hidden md:block">ДОБАВИТЬ ГРУППУ</span>
            </NavLink>
          </div>
        </div>

        {/* <div className="block mb-6 lg:hidden">
          <CourseGroupsFilter />
        </div> */}

        {isLoading ? <Loading /> : null}

        {!isLoading ? (
          <>
            <div className="flex flex-col space-y-5">
              {groups.map((group) => (
                <CourseGroupCard key={group.id} group={group} />
              ))}
            </div>

            <div className={lastPage > 1 ? 'mt-14' : ''}>
              <LMSPagination current={currentPage} lastPage={lastPage} onChange={setCurrentPage} />
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default HrGroups;
