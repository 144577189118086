import classes from './DropdownMenu.module.scss';

const DropdownMenu = ({
  isOpen,
  toggleDropdown,
  size,
}: {
  isOpen: boolean;
  size?: string;
  toggleDropdown: CallableFunction;
}) => {
  return (
    <div className={`flex h-[62px] w-[62px] items-center justify-center ${size || 'lg'}:hidden`}>
      <button className="relative h-[16px] w-[22px]" onClick={() => toggleDropdown()}>
        <span
          className={`h-[2px] w-[26px] bg-white ${classes.burgerLine as string} ${
            classes.topLine as string
          } ${isOpen ? `${classes.open as string} translate-y-[6px] rotate-[45deg]` : ''}`}
        ></span>
        <span
          className={`h-[2px] w-[18px] ml-[calc(100%-14px)] bg-white ${
            classes.burgerLine as string
          } ${classes.midLine as string} ${isOpen ? 'opacity-0' : ''}`}
        ></span>
        <span
          className={`h-[2px] w-[26px] bg-white ${classes.burgerLine as string} ${
            classes.bottomLine as string
          } ${isOpen ? `${classes.open as string} translate-y-[-8px] rotate-[-45deg]` : ''}`}
        ></span>
      </button>
    </div>
  );
};

export default DropdownMenu;
