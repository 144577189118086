import React, { useMemo, useState } from 'react';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';
import FilterAccordion from 'components/FilterAccordion';
import LMSButton from 'components/LMSButton';
import { useOutsideClick } from 'hooks/outside-click';
import { useWindowSize } from 'hooks/window-size';

import { fetchFilterCourses } from '../../api/repository/courses';
import { useStudentFilter } from '../../hooks/students-filter';

const StudentFilter = () => {
  const device = useWindowSize();
  const { filter, setFilter, onReset, onSubmit } = useStudentFilter();
  const [isOpen, setOpen] = useState(false);

  const filterRef = useOutsideClick(() => {
    setOpen(false);
  });

  const filterClass = useMemo(() => {
    if (!isOpen) return 'hidden';

    if (device === 'desktop') {
      return 'fixed right-0 left-auto top-10 bottom-10 w-full max-w-[400px] bg-[#3D436B] px-8 py-5 border border-solid border-[#3D436B] rounded-[20px] overflow-auto z-10';
    }

    return 'fixed bottom-0 right-0 left-0 top-24 w-full bg-[#3D436B] px-8 py-5 border border-solid border-[#3D436B] rounded-t-[20px] overflow-auto z-10';
  }, [device, isOpen]);

  const fetchStudentsTypes = () =>
    Promise.resolve([
      {
        value: 0,
        label: 'Внешний',
      },
      {
        value: 1,
        label: 'Внутренний',
      },
    ]);

  const onToggleOpen = () => {
    if (isOpen) {
      document.body.classList.remove('modal-open');
    } else {
      document.body.classList.add('modal-open');
    }
    setOpen((prev) => !prev);
  };

  const onSubmitFilter = () => {
    onToggleOpen();
    onSubmit();
  };

  const onResetFilter = () => {
    onToggleOpen();
    onReset();
  };

  return (
    <div>
      <button
        className="flex items-center justify-center w-full p-2 space-x-2 border border-white border-opacity-30 rounded-xl"
        onClick={onToggleOpen}
      >
        <FilterIcon />
        <span>Фильтр</span>
      </button>
      <div ref={filterRef} className={filterClass}>
        <FilterAccordion
          title="Курс"
          selected={filter.course_id}
          fetchOptions={fetchFilterCourses}
          expanded
          setSelected={(val) => setFilter({ course_id: val })}
        />
        <FilterAccordion
          title="Тип студента"
          selected={filter.student_type}
          hideSearch
          fetchOptions={fetchStudentsTypes}
          expanded
          setSelected={(val) => setFilter({ student_type: val })}
        />

        <LMSButton className="w-full mt-5" colorType="light" onClick={onSubmitFilter}>
          Применить
        </LMSButton>
        <LMSButton
          className="w-full"
          variant="text"
          prefix={<CloseIcon className="w-5 h-5" />}
          onClick={onResetFilter}
        >
          Сбросить
        </LMSButton>
      </div>
    </div>
  );
};

export default StudentFilter;
