import React, { useEffect, useState } from 'react';
import { ReactComponent as CopyIcon } from 'assets/icons/document-copy.svg';
import { ReactComponent as SpinnerIcon } from 'assets/icons/spinner.svg';
import BaseModal from 'components/BaseModal';
import LMSTitle from 'components/LMSTitle';
import { useNotification } from 'hooks/notification';
import { Option } from 'entities/Option';

import { getCoachRegistrationToken } from '../../api/repository/coaches';
import DropdownSelect from '../DropdownSelect';

interface Props {
  courses: Option[];
  onClose: () => void;
}

const AddUserByLinkModal: React.FC<Props> = ({ courses, onClose }) => {
  const notify = useNotification();

  const [course, setCourse] = useState<Option>({
    value: 0,
    label: '',
  });
  const [token, setToken] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const copyLink = () => {
    if (token) {
      const path = `${window.location.origin}/auth/coach-login?token=${token}`;
      navigator.clipboard
        .writeText(path)
        .then(() => notify.success('Ссылка скопирована в буфер обмена'))
        .catch((e) => notify.error(String(e)));
    } else {
      notify.error('Чтобы скопировать ссылку вам необходимо выбрать курс');
    }
  };

  useEffect(() => {
    if (course.value) {
      setIsLoading(true);
      getCoachRegistrationToken(course.value)
        .then((token) => {
          setToken(token);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [course.value]);

  return (
    <BaseModal wrapperClassName="sm:!max-w-max" onClose={onClose}>
      <div className="flex flex-col space-y-6 min-w-full lg:min-w-[436px] max-w-[436px] p-6 lg:p-10">
        <LMSTitle tag="h2">Доступ по ссылке</LMSTitle>
        <div className="flex flex-col space-y-4">
          <DropdownSelect
            notRounded={true}
            title="Добавить в курс"
            value={course.label}
            list={courses}
            onCourseClick={(id, name) => setCourse({ value: id, label: name })}
            className="bg-[#F2F2F2] border-none !text-black"
          />
          <div className="flex flex-col space-y-2">
            <p>Скопируйте ссылку и отправьте пользователю, которого хотите добавить</p>
            <div className="flex flex-row items-center space-x-2 w-full rounded-[14px] bg-[#F2F2F2] pr-6">
              <input
                type="text"
                placeholder={token || 'Тут должен быть токен'}
                disabled
                className="w-full h-[50px] py-3 px-6 text-white bg-transparent rounded-[14px] outline-none"
              />
              {isLoading ? (
                <span className="flex items-center justify-center ">
                  <SpinnerIcon className="animate-spin text-blue" />
                </span>
              ) : (
                <button onClick={copyLink}>
                  <CopyIcon />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

export default AddUserByLinkModal;
