import { IHomeWorkGroupsLesson } from 'modules/coach/entities/HomeWorkGroupsLesson';
import { IHomeWorkGroups } from 'modules/coach/entities/HomeWorksGroups';
import { IStudent } from 'modules/coach/entities/Students';
import request from 'api/axios-instance';

import { IHomeWorkGroupsLessonDao } from '../dao/HomeWorkGroupsLessonDao';
import { IHomeWorkGroupsDao } from '../dao/HomeWorksGroupsDao';
import { IStudentDao } from '../dao/StudentsDao';

export const fetchHomeWorkGroupsLesson = (id: number) =>
  request.get(`v1/coach/homeworks/groups/${id}`).then((res) => {
    const homeWorkGroupsLessons: IHomeWorkGroups = mapHomeWorkGroupsLessonsDaoToEntity(
      res.data.data
    );

    return homeWorkGroupsLessons;
  });

function mapHomeWorkGroupsLessonsDaoToEntity(groups: IHomeWorkGroupsDao): IHomeWorkGroups {
  return {
    id: groups.id,
    courseId: groups.course_id,
    name: groups.name,
    courseName: groups.course_name,
    lessons: groups.lessons.map(mapHomeWorkLessonsDaoToEntity),
  };
}

function mapHomeWorkLessonsDaoToEntity(lesson: IHomeWorkGroupsLessonDao): IHomeWorkGroupsLesson {
  return {
    id: lesson.id,
    isTest: lesson.is_test,
    name: lesson.name,
    position: lesson.position,
    studentsNumber: lesson.students_number,
    studentsPassed: lesson.students_passed,
    testId: lesson.test_id,
    students: lesson.students.map(mapHomeWorkLessonStudents),
  };
}

function mapHomeWorkLessonStudents(student: IStudentDao): IStudent {
  return {
    id: student.id,
    avatar: student.avatar,
    firstLastName: student.first_last_name,
    passDate: student.pass_date,
    passed: student.passed,
  };
}
