import { SetStateAction, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactQuill from 'react-quill';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as LinkIcon } from 'assets/icons/link.svg';
import LMSButton from 'components/LMSButton';
import LMSTitle from 'components/LMSTitle';
import { sendLesson } from 'modules/coach/api/repository/send-lesson';
import { ISendLesson } from 'modules/coach/entities/SendLesson';
import useVideoUpload from 'modules/coach/hooks/multiVideoUpload';
import useFileControl from 'modules/coach/hooks/useFileControl';
import { useCoursesListData } from 'modules/coach/hooks/useNewCoursesListsData';
import useNewFileUploader from 'modules/coach/hooks/useNewFileUploader';
import useNewVideoUploader from 'modules/coach/hooks/useNewVideoUploader';
import { useNotification } from 'hooks/notification';
import { ErrorResponse } from 'entities/Error';

import CustomRadioButton from '../CustomRadioButton';
import DropdownSelect from '../DropdownSelect';
import UploadFile from '../UploadFile';

import { fileTypes, toolbarOptions, videoTypes } from './../../constants/index';

const AddNewLesson = () => {
  const [query] = useSearchParams();
  const { control, handleSubmit, setValue } = useForm<ISendLesson>();
  const [courses, setCourses] = useState<{ id: number | null; name: string }>({
    id: null,
    name: '',
  });
  const [moduleName, setModuleName] = useState<string>();
  const [selectedOption, setSelectedOption] = useState<string>('offline');
  const notify = useNotification();
  const navigate = useNavigate();
  const { newFiles, newVideo, controlFiles, controlVideo, deleteNewVideo, deleteNewFiles } =
    useFileControl();
  const { coursesList, modules } = useCoursesListData(Number(courses.id));
  const { uploadedFilePaths, upload } = useNewFileUploader();
  const { uploadedVideoPaths, uploadVideo } = useNewVideoUploader();
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [isVideoUploading, setIsVideoUploading] = useState(false);

  const courseId = query.get('courseId');
  const moduleId = query.get('moduleId');

  const handleOptionChange = (value: SetStateAction<string>) => {
    setSelectedOption(value);
    setValue('is_online', value === 'online' ? true : false);
  };

  const changeCourse = (id: number, name: string) => {
    if (id === courses.id) return;
    setValue('course_id', id);
    setCourses({ id, name });

    setValue('module_id', undefined);
    setModuleName('');
  };

  const handleChangeVideos = async (files: File[], isDelete?: false) => {
    if (isDelete) {
      deleteNewVideo(files);
      return;
    }

    controlVideo(files);
    setIsVideoUploading(true);

    try {
      await uploadVideo(files);
      setIsVideoUploading(false);
    } catch (error) {
      setIsVideoUploading(false);
    }
  };

  const handleChangeFiles = async (files: File[], isDelete?: false) => {
    if (isDelete) {
      deleteNewFiles(files);
      return;
    }

    controlFiles(files);
    setIsFileUploading(true);

    try {
      await upload(files);
      setIsFileUploading(false);
    } catch (error) {
      setIsFileUploading(false);
    }
  };

  const onSubmit = async (formData: ISendLesson) => {
    const files = newFiles.map((f) => f.name);
    const finalFiles = uploadedFilePaths
      .filter((f) => {
        return files.includes(f.file.name);
      })
      .map((f) => f.path);

    const videos = newVideo.map((f) => f.name);
    const finalVideos = uploadedVideoPaths
      .filter((f) => {
        return videos.includes(f.file.name);
      })
      .map((f) => f.path);

    try {
      formData.file_url = finalFiles;
      formData.video_url = finalVideos;
      await sendLesson(formData);
      notify.success('Урок успешно создан');
      setTimeout(() => {
        navigate('/coach/lessons');
      }, 600);
    } catch (err) {
      const error = err as ErrorResponse;
      if (error.response?.status === 422) {
        notify.error('Урок уже запланирован в это время');
      } else {
        notify.error(
          'Не удалось создать урок, повторите еще раз или обратитесь в службу поддержки'
        );
      }
    }
  };

  useEffect(() => {
    if (courseId && moduleId) {
      const initialCourse = coursesList.find((course) => course.id === +courseId);
      if (initialCourse) {
        setValue('course_id', initialCourse.id);
        setCourses(initialCourse);
        const initialModule = modules.find((module) => module.id === +moduleId);
        if (initialModule) {
          setValue('module_id', initialModule.id);
          setModuleName(initialModule.name);
        }
      }
    }
  }, [coursesList, modules]);

  return (
    <div className="flex flex-col">
      <div className="flex flex-row justify-between items-end">
        <LMSTitle tag="h4" className="text-current text-3xl">
          Добавление урока
        </LMSTitle>
        <LMSButton
          onClick={handleSubmit(onSubmit)}
          className="fixed bottom-0 left-0 right-0 py-4 rounded-none sm:static sm:rounded-full"
        >
          Сохранить урок
        </LMSButton>
      </div>
      <div className="w-full h-full flex flex-col gap-y-5 mt-10 mb-20 sm:mb-0">
        <div className="w-full flex flex-col gap-5 p-5 theme-coach-background--gn rounded-3xl sm:p-10">
          <div className="w-full flex flex-col gap-5 sm:flex-row">
            <div className="w-full h-fit rounded-[14px] sm:max-w-[49%]">
              <Controller
                name="course_id"
                control={control}
                rules={{
                  required: { message: 'Выберите курс', value: true },
                }}
                render={({ fieldState: { error } }) => (
                  <DropdownSelect
                    notRounded={true}
                    title="Выберите курс"
                    value={courses.name}
                    list={coursesList}
                    error={error?.message}
                    onCourseClick={(id, name) => {
                      changeCourse(id, name);
                    }}
                  />
                )}
              />
            </div>
            <div className="w-full rounded-[14px] sm:max-w-[49%]">
              <Controller
                name="module_id"
                control={control}
                rules={{
                  required: { message: 'Выберите модуль', value: true },
                }}
                render={({ fieldState: { error } }) => (
                  <DropdownSelect
                    notRounded={true}
                    title="Выберите модуль"
                    value={moduleName}
                    list={courses?.id && modules.length ? modules : []}
                    error={error?.message}
                    onCourseClick={(id, name) => {
                      setValue('module_id', id);
                      setModuleName(name);
                    }}
                  />
                )}
              />
            </div>
          </div>
          <div className="w-full rounded-[14px]">
            <Controller
              name="name"
              control={control}
              rules={{
                required: { message: 'Введите название урока', value: true },
              }}
              render={({ field, fieldState: { error } }) => (
                <div className="w-full rounded-[14px]">
                  <input
                    type="text"
                    placeholder="Введите название урока"
                    {...field}
                    className={`w-full max-h-[52px] py-3 px-6 text-current theme-input border border-0.5 border-opacity-50 rounded-[14px] outline-none ${
                      error ? 'border-red-500' : 'border-white'
                    }`}
                  />
                  {error && <p className="text-red-500">{error.message}</p>}{' '}
                </div>
              )}
            />
          </div>
        </div>

        <div className="flex flex-col gap-y-5 p-5 theme-coach-background--gn rounded-3xl sm:p-10">
          <div className="flex flex-row justify-between">
            <p className="text-current text-2xl">Описание урока</p>
            <div className="hidden flex-row gap-x-8 sm:flex">
              {/* there are fields not working yet with useForm */}
              {/* <CustomRadioButton
                label="Оффлайн"
                value="offline"
                selectedValue={selectedOption}
                onChange={handleOptionChange}
              />
              <CustomRadioButton
                label="Онлайн"
                value="online"
                selectedValue={selectedOption}
                onChange={handleOptionChange}
              /> */}
            </div>
          </div>
          <div className="mt-7 couch-editor">
            <Controller
              name="lesson_description"
              control={control}
              render={() => (
                <ReactQuill
                  placeholder={
                    'Опишите, что нужно сделать и какой результат вы ждете от студента. Прикрепите дополнительные материалы, если необходимо'
                  }
                  style={{ color: '#fff' }}
                  modules={{
                    toolbar: toolbarOptions,
                  }}
                  onChange={(data) => setValue('lesson_description', data)}
                />
              )}
            />
          </div>
          <div className="w-full flex flex-col gap-5 rounded-3xl sm:flex-row">
            {/* TODO: ссылка на трансляцию вынесена в создание расписание */}
            <div className="w-full flex flex-roe items-center gap-x-3.5 px-6 border border-0.5 border-white border-opacity-50 rounded-[14px] sm:w-1/2 hidden">
              <LinkIcon />
              <Controller
                name="video_broadcast_link"
                control={control}
                render={({ field }) => (
                  <input
                    type="text"
                    placeholder="Ссылку на трансляцию"
                    value={field.value}
                    onChange={(e) => setValue('video_broadcast_link', e.target.value)}
                    className="w-full max-h-[52px] py-3 text-current theme-input border-none outline-none"
                  />
                )}
              />
            </div>
            <div className="w-full flex flex-row items-center gap-x-3.5 px-6 border border-0.5 theme-border--light-black rounded-[14px] sm:w-full theme-icon-stroke--blue">
              <LinkIcon />
              <Controller
                name="video_youtube_link"
                control={control}
                render={({ field }) => (
                  <input
                    type="text"
                    placeholder="Ссылка на видео"
                    value={field.value}
                    onChange={(e) => setValue('video_youtube_link', e.target.value)}
                    className="w-full max-h-[52px] py-3 text-current bg-transparent border-none outline-none"
                  />
                )}
              />
            </div>
          </div>
          <div className="flex flex-row gap-x-8 sm:hidden">
            {/* there are fields not working yet with useForm */}
            <CustomRadioButton
              label="Оффлайн"
              value="offline"
              selectedValue={selectedOption}
              onChange={handleOptionChange}
            />
            <CustomRadioButton
              label="Онлайн"
              value="online"
              selectedValue={selectedOption}
              onChange={handleOptionChange}
            />
          </div>
        </div>

        <div className="flex flex-col gap-y-5 p-5 theme-coach-background--gn rounded-3xl sm:p-10">
          <span className="text-current text-xl font-medium">Загрузка файлов и видео</span>
          <div className="w-full flex flex-col gap-5 sm:flex-row">
            <div className="w-full sm:w-1/2">
              <UploadFile
                uploading={isFileUploading}
                title="Загрузить файл"
                hint="PNG, DOC, EXCEL, PDF, JPEG"
                types={fileTypes}
                isMultiple={true}
                files={newFiles}
                handleChange={handleChangeFiles}
              />
            </div>
            <div className="w-full sm:w-1/2">
              <UploadFile
                uploading={isVideoUploading}
                title="Загрузите видео"
                hint="Ограничение до 1 гб"
                types={videoTypes}
                isMultiple={true}
                files={newVideo}
                handleChange={handleChangeVideos}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewLesson;
