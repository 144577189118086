import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import ReactQuill from 'react-quill';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as LinkIcon } from 'assets/icons/link.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import ConfirmBoxProvider, { ConfirmBoxContext } from 'components/ConfirmBox';
import LMSButton from 'components/LMSButton';
import LMSCheckbox from 'components/LMSCheckbox';
import LMSRadioButton from 'components/LMSRadioButton';
import LMSTitle from 'components/LMSTitle';
import ru from 'date-fns/locale/ru';
import { ISchedule } from 'modules/coach/api/dao/Schedule.dao';
import { fetchHomeworksById, fetchTests } from 'modules/coach/api/repository/homeworks';
import { fetchSchedule } from 'modules/coach/api/repository/schedule';
import { SendSchedule } from 'modules/coach/api/repository/schedule';
import { deleteSchedule, updateSchedule } from 'modules/coach/api/repository/schedule';
import { ISendSchedule } from 'modules/coach/entities/SendSchedule';
import { useCoursesListData } from 'modules/coach/hooks/useCoursesListsData';
import { useConfirmBox } from 'hooks/confirm-box';
import { useNotification } from 'hooks/notification';
import { ErrorResponse } from 'entities/Error';

import { formatDate } from '../../utils/dateUtils';
import CustomRadioButton from '../CustomRadioButton';
import DropdownSelect from '../DropdownSelect';

type TOption = {
  id: number;
  label: string;
  value: string;
};

type TValue = {
  id: number;
  name: string;
};

interface Props {
  scheduleId?: string;
}

const availabilityOptions = [
  { value: 'now', label: 'сразу', id: 0 },
  { value: 'then', label: 'по дате и времени', id: 1 },
];

const reviewOptions = [
  { value: 'no', label: 'Необязательно', id: 0 },
  { value: 'yes', label: 'Обязательно', id: 1 },
];

const aссessOptions = [
  { value: 'one', label: 'Пока не пройдут этот урок', id: 0 },
  { value: 'all', label: 'Пока не пройдут этот и все предыдущие обучающие уроки', id: 1 },
];

const AddNewSchedule: React.FC<Props> = ({ scheduleId = 0 }) => {
  const { control, handleSubmit, setValue } = useForm<ISendSchedule>();
  const [courses, setCourses] = useState<{ id: number | undefined; name: string }>({
    id: undefined,
    name: '',
  });
  const [groupName, setGroupName] = useState<{ id: number | null; name: string }>({
    id: null,
    name: '',
  });
  const [moduleName, setModuleName] = useState<{ id: number | undefined; name: string }>();
  const [schedule, setSchedule] = useState<ISchedule>();
  const [lessonName, setLessonName] = useState<string>();
  const [selectedAvailability, setAvailability] = useState<TOption>(availabilityOptions[0]);
  const [accessOption, setAccessOption] = useState<TOption>(aссessOptions[0]);
  const [deadline, setDeadline] = useState<boolean>(false);
  const [broadcastLink, setBroadcastLink] = useState<string>('');
  const [homeworks, setHomeworks] = useState<TValue[]>([]);
  const [tests, setTests] = useState<TValue[]>([]);
  const [currentHomework, setCurrentHomework] = useState<TValue>();
  const [currentTest, setCurrentTest] = useState<TValue>();
  const [isDeadline, setIsDeadline] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<TOption>(reviewOptions[0]);
  const notify = useNotification();
  const navigate = useNavigate();
  const { coursesList, groups, modules, lessons } = useCoursesListData(
    Number(courses.id),
    courses?.id,
    moduleName?.id
  );
  const [currentDate, setCurrentDate] = useState<Date>(new Date());

  const { showConfirmBox } = useConfirmBox();

  const handleConfirmation = () => {
    if (schedule?.id) {
      showConfirmBox({
        title: 'Расписание',
        message: 'Вы уверены что хотите удалить расписание?',
        okVariant: 'light',
        cancelVariant: 'red',
        okText: 'Да',
        cancelText: 'Нет',
        onAccept: () => {
          deleteSchedule(schedule.id)
            .then(() => {
              notify.success('Расписание успешно удалено');
              setTimeout(() => {
                navigate('/coach/schedule');
              }, 600);
            })
            .catch(() =>
              notify.error(
                'Не удалось удалить расписание, повторите еще раз или обратитесь в службу поддержки'
              )
            );
        },
      });
    } else {
      notify.error(
        'Не удалось удалить расписание, повторите еще раз или обратитесь в службу поддержки'
      );
    }
  };

  const formatedDate = (date: string) => {
    const convertDate = date.replaceAll('.', '-');
    const [day, month, year] = convertDate.split('-');
    const rearrangedDateString = `${year}-${month}-${day}`;

    return rearrangedDateString;
  };

  useEffect(() => {
    if (!Boolean(scheduleId)) return;

    fetchSchedule(scheduleId).then((res) => {
      setSchedule(res);
    });
  }, [scheduleId]);

  useEffect(() => {
    if (schedule && schedule?.id) {
      changeCourse(schedule.module.course.id, schedule.module.course.name);

      setValue('module_id', schedule.module.id);
      setModuleName({
        name: schedule.module.title,
        id: schedule.module.id,
      });

      setValue('lesson_id', schedule.lesson.id);
      setLessonName(schedule.lesson.name);

      changeGroup(schedule.group.id, schedule.group.group_name);

      setValue('date', formatDate(new Date(formatedDate(schedule.date)), '-'));
      setValue('time_from', schedule.time_from.slice(0, 5));
      setValue('time_to', schedule.time_to.slice(0, 5));

      const availValue = schedule.is_access_by_date
        ? availabilityOptions[1]
        : availabilityOptions[0];
      setAvailability(availValue);

      const reviewValue = schedule.is_required_block ? reviewOptions[1] : reviewOptions[0];
      setSelectedOption(reviewValue);

      const accessValue = schedule.is_required_feedback ? reviewOptions[1] : reviewOptions[0];
      setSelectedOption(accessValue);

      if (schedule.is_required_block) setIsDeadline(true);

      if (schedule?.deadline) {
        setValue(
          'deadline_date',
          formatDate(new Date(formatedDate(schedule.deadline.slice(0, 10))), '-')
        );

        setValue('deadline_time_to', schedule.deadline.slice(11));
      }

      if (schedule?.test?.id) {
        setCurrentTest({ id: schedule.test?.id, name: schedule.test?.name });
      }

      if (schedule?.hometask?.id) {
        setCurrentHomework({ id: schedule.hometask?.id, name: schedule.hometask?.name });
      }

      if (schedule?.video_broadcast_link) {
        setBroadcastLink(schedule.video_broadcast_link);
      }
    }
  }, [schedule?.id]);

  useEffect(() => {
    const findLesson = lessons.find((el) => el.name === lessonName);
    if (!findLesson?.id) return;
    fetchHomeworksById(findLesson?.id).then((res) => {
      setHomeworks(res);
    });
    fetchTests(findLesson?.id).then((res) => {
      setTests(res);
    });
  }, [lessonName]);

  const changeCourse = (id: number, name: string) => {
    if (id === courses.id) return;
    setValue('course_id', id);
    setCourses({ id, name });

    // reset group and module
    setValue('course_group_id', undefined);
    setValue('module_id', undefined);
    setGroupName({ id: null, name: '' });
    setModuleName({ id: undefined, name: '' });
  };

  const changeGroup = (id: number, name: string) => {
    if (id === groupName.id) return;
    setValue('course_group_id', id);
    setGroupName({ id: id, name: name });
  };

  const onSubmit = async (formData: ISendSchedule) => {
    const data = Object.assign({}, formData, {
      type_id: 1,
      is_access_by_date: selectedAvailability?.id,
      is_required_block: selectedOption?.id,
      is_required_feedback: accessOption?.id,
      video_broadcast_link: broadcastLink,
    });

    delete data.deadline_date;
    delete data.deadline_time_to;

    const newFormData = new FormData();
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        newFormData.append(key, data[key]);
      }
    }

    if (schedule?.id) {
      try {
        await updateSchedule(newFormData, schedule.id);
        notify.success('Расписание успешно обновлено');
        setTimeout(() => {
          navigate('/coach/schedule');
        }, 600);
      } catch (err) {
        const error = err as ErrorResponse;
        if (error.response?.status === 422) {
          notify.error('На данное время уже есть расписание');
        } else {
          notify.error(
            'Не удалось обновить расписание, повторите еще раз или обратитесь в службу поддержки'
          );
        }
      }

      return;
    }

    try {
      await SendSchedule(newFormData);
      notify.success('Расписание успешно создано');
      setTimeout(() => {
        navigate('/coach/schedule');
      }, 600);
    } catch (err) {
      const error = err as ErrorResponse;
      if (error.response?.status === 422) {
        notify.error('На данное время уже есть расписание');
      } else {
        notify.error(
          'Не удалось создать расписание, повторите еще раз или обратитесь в службу поддержки'
        );
      }
    }
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-row justify-between items-center">
        <LMSTitle tag="h4" className="text-current text-3xl">
          {schedule?.id ? 'Редактирование расписания' : 'Добавление расписания'}
        </LMSTitle>
        <div className="flex flex-row justify-end gap-4">
          <LMSButton
            onClick={handleSubmit(onSubmit)}
            className="fixed bottom-0 left-0 right-0 py-4 rounded-none sm:static sm:rounded-full"
          >
            Сохранить расписание
          </LMSButton>
          {schedule?.id && (
            <button
              onClick={handleConfirmation}
              className="w-[55px] h-[55px] flex justify-center items-center p-0 text-current theme-icon-stroke border theme-border--active bg-white bg-opacity-20 cursor-pointer rounded-full"
            >
              <TrashIcon />
            </button>
          )}
        </div>
      </div>

      <div className="w-full h-full flex flex-col gap-y-5 mt-10 mb-20 sm:mb-0">
        <div className="w-full flex flex-col gap-5 p-5 theme-coach-background--gn rounded-3xl sm:p-10">
          <div className="flex flex-col gap-5 sm:flex-row">
            <div className="w-full h-fit rounded-[14px] sm:max-w-[33%]">
              <Controller
                name="course_id"
                control={control}
                rules={{
                  required: { message: 'Выберите курс', value: true },
                }}
                render={({ fieldState: { error } }) => (
                  <DropdownSelect
                    notRounded={true}
                    title="Выберите курс"
                    value={courses.name}
                    list={coursesList}
                    error={error?.message}
                    onCourseClick={(id, name) => {
                      changeCourse(id, name);
                    }}
                  />
                )}
              />
            </div>
            <div className="w-full rounded-[14px] sm:max-w-[33%]">
              <Controller
                name="module_id"
                control={control}
                rules={{
                  required: { message: 'Выберите модуль', value: true },
                }}
                render={({ fieldState: { error } }) => (
                  <DropdownSelect
                    notRounded={true}
                    title="Выберите модуль"
                    value={moduleName?.name}
                    list={groupName ? modules : []}
                    error={error?.message}
                    onCourseClick={(id, name) => {
                      setValue('module_id', id);
                      setModuleName({ id, name });
                    }}
                  />
                )}
              />
            </div>
            <div className="w-full rounded-[14px] sm:max-w-[33%]">
              <Controller
                name="lesson_id"
                control={control}
                rules={{
                  required: { message: 'Выберите урок', value: true },
                }}
                render={({ fieldState: { error } }) => (
                  <DropdownSelect
                    notRounded={true}
                    title="Выберите урок"
                    value={lessonName}
                    list={lessons.length > 0 ? lessons : []}
                    error={error?.message}
                    onCourseClick={(id, name) => {
                      setValue('lesson_id', id);
                      setLessonName(name);
                    }}
                  />
                )}
              />
            </div>
          </div>
          <div className="w-full flex flex-col gap-5 sm:flex-row">
            <div className="w-full rounded-[14px] sm:max-w-[33%]">
              <Controller
                name="course_group_id"
                control={control}
                rules={{
                  required: { message: 'Выберите группу', value: true },
                }}
                render={({ fieldState: { error } }) => (
                  <DropdownSelect
                    notRounded={true}
                    title="Выберите группу"
                    value={groupName.name}
                    list={courses.id ? groups : []}
                    error={error?.message}
                    onCourseClick={(id, name) => {
                      changeGroup(id, name);
                    }}
                  />
                )}
              />
            </div>
            <div className="w-full rounded-[14px] sm:max-w-[32%]">
              <input
                type="text"
                placeholder="Вставьте ссылку на трансляцию"
                value={broadcastLink}
                className="w-full max-h-[52px] py-3 px-6 text-current theme-input border border-0.5 border-opacity-50 rounded-[14px] outline-none"
                onChange={(e) => setBroadcastLink(e.target.value)}
              />
            </div>
          </div>

          {/* --------------- нижнее меню ---------------- */}
          <div className="pt-10 mt-2 border-t-2 border-coachTableBorder">
            <div className="w-full flex flex-col gap-3">
              <div className="w-full flex gap-4">
                <div className="w-full flex flex-row new-lesson sm:max-w-[20%]">
                  <Controller
                    name="date"
                    control={control}
                    defaultValue={formatDate(currentDate, '-')}
                    render={({ field }) => (
                      <DatePicker
                        selected={field.value ? new Date(field.value) : null}
                        locale={ru}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        dateFormat="yyyy.MM.dd"
                        onChange={(date) => setValue('date', formatDate(date as Date, '-'))}
                      />
                    )}
                  />
                  <CalendarIcon className="ml-[-44px] mt-[14px] text-current lg:ml-[-30px]" />
                </div>

                <div className="w-full flex gap-4 sm:max-w-[30%]">
                  <Controller
                    name="time_from"
                    control={control}
                    rules={{
                      required: { message: '*', value: true },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <>
                        <InputMask
                          type="text"
                          mask="99:99"
                          placeholder="__"
                          value={field.value}
                          onChange={(e) => {
                            setValue('time_from', e.target.value);
                          }}
                          className={`${
                            error ? 'border-red-500' : 'border-white'
                          } w-full max-h-[52px] py-3 px-4 text-center text-current theme-input border border-0.5 border-opacity-50 rounded-[14px] outline-none`}
                        />
                        {error && <p className="text-red-500">{error.message}</p>}{' '}
                      </>
                    )}
                  />

                  <Controller
                    name="time_to"
                    control={control}
                    rules={{
                      required: { message: '*', value: true },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <>
                        <InputMask
                          type="text"
                          mask="99:99"
                          placeholder="__"
                          value={field.value}
                          onChange={(e) => {
                            setValue('time_to', e.target.value);
                          }}
                          className={`${
                            error ? 'border-red-500' : 'border-white'
                          } w-full max-h-[52px] py-3 px-4 text-center text-current theme-input border border-0.5 border-opacity-50 rounded-[14px] outline-none`}
                        />
                        {error && <p className="text-red-500">{error.message}</p>}{' '}
                      </>
                    )}
                  />
                </div>
              </div>

              {/* -------- Доступность -------- */}

              <div className="w-full flex flex-col gap-2 mt-4">
                <p className="text-current">Доступность урока</p>
                <div className="w-full flex flex-row gap-8">
                  {availabilityOptions.map((option) => (
                    <label
                      key={option.value}
                      className={
                        (option.value === selectedAvailability?.value ? '' : 'opacity-70') +
                        ' flex items-center space-x-3 py-2.5 cursor-pointer text-current'
                      }
                      onClick={() => setAvailability(option)}
                    >
                      <LMSRadioButton checked={option.value === selectedAvailability?.value} />
                      <p>{option.label}</p>
                    </label>
                  ))}
                </div>
              </div>
              {selectedAvailability?.id === 2 && (
                <div className="flex w-full">
                  <div className="w-full sm:w-1/2">
                    <Controller
                      name="time_to"
                      control={control}
                      rules={{
                        required: { message: '*', value: true },
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <>
                          <InputMask
                            type="text"
                            mask="99:99"
                            placeholder="__"
                            value={field.value}
                            onChange={(e) => {
                              setValue('time_to', e.target.value);
                            }}
                            className={`${
                              error ? 'border-red-500' : 'border-white'
                            } w-full max-h-[52px] py-3 px-4 text-center text-current theme-input border border-0.5 border-opacity-50 rounded-[14px] outline-none`}
                          />
                          {error && <p className="text-red-500">{error.message}</p>}{' '}
                        </>
                      )}
                    />
                  </div>
                  <div className="w-full sm:w-1/2">
                    <Controller
                      name="time_to"
                      control={control}
                      rules={{
                        required: { message: '*', value: true },
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <>
                          <InputMask
                            type="text"
                            mask="99:99"
                            placeholder="__"
                            value={field.value}
                            onChange={(e) => {
                              setValue('time_to', e.target.value);
                            }}
                            className={`${
                              error ? 'border-red-500' : 'border-white'
                            } w-full max-h-[52px] py-3 px-4 text-center text-current theme-input border border-0.5 border-opacity-50 rounded-[14px] outline-none`}
                          />
                          {error && <p className="text-red-500">{error.message}</p>}{' '}
                        </>
                      )}
                    />
                  </div>
                </div>
              )}

              {/* --------  Стоп урок -------- */}

              <div className="flex flex-col gap-2 mt-4">
                <LMSCheckbox
                  name="deadline"
                  value={isDeadline}
                  onChange={(val) => {
                    setIsDeadline(val);
                  }}
                >
                  <p className="text-current">
                    Стоп урок{'  '}
                    <span className="text-grey">
                      (студенты не получат доступ к следующим обучающим блокам)
                    </span>
                  </p>
                </LMSCheckbox>

                {isDeadline && (
                  <div className="w-full flex flex-row gap-8 hidden">
                    {aссessOptions.map((option) => (
                      <label
                        key={option.value}
                        className={
                          (option.value === accessOption?.value ? '' : 'opacity-70') +
                          ' flex items-center space-x-3 py-2.5 cursor-pointer text-current'
                        }
                        onClick={() => setAccessOption(option)}
                      >
                        <LMSRadioButton checked={option.value === accessOption?.value} />
                        <p>{option.label}</p>
                      </label>
                    ))}
                  </div>
                )}
              </div>

              {/* --------  Отзывы -------- */}
              <div className="w-full flex flex-col mt-4">
                <p className="text-current">Студенты смогут оставлять отзывы</p>
                <div className="w-full flex flex-row gap-8">
                  {reviewOptions.map((option) => (
                    <label
                      key={option.value}
                      className={
                        (option.value === selectedOption?.value ? '' : 'opacity-70') +
                        ' flex items-center space-x-3 py-2.5 cursor-pointer text-current'
                      }
                      onClick={() => setSelectedOption(option)}
                    >
                      <LMSRadioButton checked={option.value === selectedOption?.value} />
                      <p>{option.label}</p>
                    </label>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewSchedule;
