import { IAttendance, ISendAttendance } from 'modules/coach/api/dao/Attendance.dao';
import request from 'api/axios-instance';

export const fetchStudentList = (id: string): Promise<IAttendance> => {
  return request.get(`v2/coach/attendance?calendar_id=${id}`).then((res) => res.data.data);
};

export const setAttendance = (data: ISendAttendance) => {
  return request.post('v2/coach/attendance', data).then((res) => res.data);
};
