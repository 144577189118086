import React, { useEffect, useState } from 'react';

import { fetchCompletedCourses } from '../../api/repository/my-courses';
import CompletedCourseCard from '../../components/CompletedCourseCard';
import Loading from '../../components/Loading';
import { CompletedCourse } from '../../entities/CompletedCourse';

const CompletedCourses = () => {
  const [isLoading, setLoading] = useState(false);
  const [courses, setCourses] = useState<CompletedCourse[]>([]);

  useEffect(() => {
    setLoading(true);
    fetchCompletedCourses()
      .then((res) => {
        setCourses(res);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (isLoading) return <Loading theme="dark" />;

  if (!courses.length) return <p className="text-center text-2xl">Нет завершенных курсов</p>;

  return (
    <div>
      {courses.map((course) => (
        <CompletedCourseCard key={course.id} course={course} />
      ))}
    </div>
  );
};

export default CompletedCourses;
