import React from 'react';
import { ReactComponent as ClipboardIcon } from 'assets/icons/landing/clipboard.svg';
import { ReactComponent as ForkliftIcon } from 'assets/icons/landing/forklift.svg';
import { ReactComponent as JournalIcon } from 'assets/icons/landing/journal.svg';
import { ReactComponent as LaptopIcon } from 'assets/icons/landing/laptop.svg';
import { ReactComponent as ListIcon } from 'assets/icons/landing/list.svg';
import { ReactComponent as NotepadIcon } from 'assets/icons/landing/notepad.svg';
import { ReactComponent as PackageIcon } from 'assets/icons/landing/package.svg';
import { ReactComponent as TrolleyIcon } from 'assets/icons/landing/trolley.svg';
import { ReactComponent as TruckIcon } from 'assets/icons/landing/truck.svg';
import { ReactComponent as WarehouseIcon } from 'assets/icons/landing/warehouse.svg';

const modules = [
  {
    id: 1,
    title: 'Введение',
    icon: <JournalIcon />,
  },
  {
    id: 2,
    title: 'Приёмка',
    icon: <ListIcon />,
  },
  {
    id: 3,
    title: 'Хранение',
    icon: <WarehouseIcon />,
  },
  {
    id: 4,
    title: 'Доставка',
    icon: <TruckIcon />,
  },
  {
    id: 5,
    title: 'Транспортировка',
    icon: <ForkliftIcon />,
  },
  {
    id: 6,
    title: 'Работа с возвратами',
    icon: <PackageIcon />,
  },
];

const moduleContains = [
  {
    id: 1,
    title: 'Подробное описание процессов',
    icon: <NotepadIcon />,
  },
  {
    id: 2,
    title: 'Регламентирующие документы',
    icon: <LaptopIcon />,
  },
  {
    id: 3,
    title: 'Образцы документов',
    icon: <ClipboardIcon />,
  },
  {
    id: 4,
    title: 'Практические задания',
    icon: <TrolleyIcon />,
  },
];

const DescriptionBlock = () => (
  <div className="w-full flex flex-col py-5 md:py-10">
    <div className="flex flex-col h-max w-full max-w-[1400px] space-y-4 px-4 mx-auto">
      <section className="flex flex-col space-x-0 space-y-4 items-center justify-center md:flex-row md:space-x-4 md:space-y-0">
        <div className="flex flex-1 flex-col space-y-4 md:space-y-5">
          <h1 className="text-current text-2xl md:text-5xl font-semibold">
            Курс «Основы складской работы»
          </h1>
          <p className="text-current text-lg md:text-2xl font-medium">
            Здесь вы узнаете базовые принципы складской работы, необходимые для успешного выполнения
            ваших обязанностей.
          </p>
        </div>
        <div className="flex flex-1">
          <img
            src={require('assets/images/landing-description.webp')}
            alt="landing-description"
            className="w-full h-auto object-contain object-center"
          />
        </div>
      </section>

      <section className="flex flex-col space-y-4">
        <p className="text-current text-lg md:text-2xl font-medium">Курс включает шесть модулей:</p>
        <div className="grid grid-cols-2 gap-2 md:grid-cols-3 md:gap-6">
          {modules.map((module) => (
            <article
              key={module.id}
              className="flex flex-col space-y-4 p-4 md:p-8 rounded-2xl border border-[#E6EAFF]"
            >
              {module.icon}
              <p className="text-sm md:text-lg font-medium">{module.title}</p>
            </article>
          ))}
        </div>
      </section>

      <section className="flex flex-col space-y-4">
        <p className="text-current text-lg md:text-2xl font-medium">Каждый модуль содержит:</p>
        <div className="grid grid-cols-1 gap-2 md:grid-cols-2 md:gap-6">
          {moduleContains.map((item) => (
            <article
              key={item.id}
              className="flex flex-col space-y-4 p-4 md:p-8 rounded-2xl border border-[#E6EAFF]"
            >
              {item.icon}
              <p className="text-sm md:text-lg font-medium">{item.title}</p>
            </article>
          ))}
        </div>
      </section>

      <p className="text-lg md:text-2xl font-semibold pt-6">
        По завершении курса вы получите базовые навыки, которые помогут повысить эффективность
        работы и уверенность в выполнении своих обязанностей.
      </p>
    </div>
  </div>
);

export default DescriptionBlock;
