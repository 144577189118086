import { FC, useMemo } from 'react';
import Avatar from 'components/Avatar';
import FileDownloadButton from 'modules/coach/components/DownLoadButton';
import { useAppSelector } from 'hooks/redux';

import style from './messagecard.module.scss';

type File = {
  downloadLink: string;
  originalName: string;
};

type Props = {
  isMark?: boolean;
  authorAvatar?: string;
  authorFio?: string;
  studentFio?: string;
  content?: string;
  lessonName?: string;
  createdAt?: string;
  authorId?: number;
  statusId: number;
  isLast?: boolean;
  files?: File[];
  onClick?: () => void;
  onRateClick?: () => void;
};

const MessageCard: FC<Props> = ({
  isMark,
  authorAvatar,
  authorFio,
  studentFio,
  authorId,
  content,
  lessonName,
  createdAt,
  statusId,
  isLast,
  files,
  onClick,
  onRateClick,
}) => {
  const { user } = useAppSelector((state) => state.auth);
  const isCoach = useMemo(() => authorId === user?.id, [authorId, user?.id]);

  // shit code on
  // const adaptTextLink = (text: string) => {
  //   const textToArray = text.split(' ');
  //   const http = 'http';
  //   const findLinksCount = textToArray.filter((el: string) => el.includes(http));

  //   if (findLinksCount.length > 0) {
  //     let count = findLinksCount.length;

  //     while (count > 0) {
  //       const link = findLinksCount[count] as string;
  //       const findLinkInArray = textToArray.findIndex((el) => el === link);

  //       textToArray[
  //         findLinkInArray
  //       ] = `<a href=${findLinksCount[count]} target="_blank">${findLinksCount[count]}</a>`;

  //       count--;
  //     }
  //   }

  //   return textToArray.join(' ');
  // };

  // shit code off

  return (
    <div className={isCoach ? 'flex gap-x-7 flex-row-reverse' : 'flex flex-row gap-x-7'}>
      <div className="min-w-[60px] max-w-[60px] max-h-[60px] aspect-auto object-cover rounded-full">
        <Avatar image={authorAvatar} alt={authorFio} />
      </div>
      <div className="h-fit w-full flex flex-col gap-y-3 relative">
        <div className={isCoach ? style['card-reverse'] : style.card}>
          {authorFio ? (
            <span
              className={`${
                isCoach ? 'text-[#26A7EF]' : 'text-current'
              } pb-4 text-lg font-semibold`}
            >
              {isCoach ? 'Вы' : authorFio}
            </span>
          ) : (
            'Имя отсутствует'
          )}
          {content ? (
            <p
              className="text-current max-w-[200px] overflow-x-scroll lg:max-w-full"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          ) : (
            ''
          )}
          <div className="w-full flex flex-col mt-5 border-t">
            {lessonName ? (
              <div className="flex flex-row items-center gap-x-3 py-2 text-[#26A7EF] text-lg">
                Урок <p className="theme-text--default text-base hyphens-auto">{lessonName}</p>
              </div>
            ) : (
              ''
            )}
            <div className="flex flex-col gap-2 mr-auto">
              {files &&
                files.map((file, idx) => {
                  return (
                    <FileDownloadButton
                      key={idx}
                      fileLink={file.downloadLink}
                      name={file.originalName}
                      withoutWord={true}
                    />
                  );
                })}
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col justify-between gap-4 xl:flex-row">
          <span className={`${!isCoach ? '' : 'ml-auto'} text-current text-opacity-60 text-base`}>
            {createdAt}
          </span>
          {user?.currentRoleId === 6 && !isCoach && statusId === 1 && isLast ? (
            <div className="flex flex-col gap-3 sm:flex-row">
              <button
                disabled={isMark}
                onClick={onClick}
                className="w-fit py-2.5 px-4 text-current theme-default-button--active rounded-full border disabled:cursor-not-allowed"
              >
                Отправить на доработку
              </button>
              <button
                disabled={isMark}
                onClick={onRateClick}
                className="w-fit py-2.5 px-4 text-current theme-default-button--active rounded-full disabled:cursor-not-allowed"
              >
                Поставить оценку
              </button>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

export default MessageCard;
