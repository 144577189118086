import React, { useEffect } from 'react';

interface Props {
  time: number;
  className?: string;
  onClose?: () => void;
}

const Timer: React.FC<Props> = ({ time = 300, className, onClose }) => {
  const [countDown, setCountDown] = React.useState(0);
  const [runTimer, setRunTimer] = React.useState(true);

  useEffect(() => {
    let timerId: NodeJS.Timer | number = 0;

    if (runTimer) {
      setCountDown(time);
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }

    return () => clearInterval(timerId);
  }, [runTimer]);

  useEffect(() => {
    if (countDown < 0 && runTimer) {
      onClose && onClose();

      setRunTimer(false);
      setCountDown(0);
    }
  }, [countDown, runTimer]);

  const seconds = String(countDown % 60).padStart(2, '0');
  const minutes = String(Math.floor(countDown / 60)).padStart(2, '0');

  return (
    <p className={`text-2xl md:text-[32px] text-center ${className}`}>
      {minutes}:{seconds}
    </p>
  );
};

export default Timer;
