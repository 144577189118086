import { ICouchInfo } from 'modules/coach/entities/Couch';
import { ILessonDetail } from 'modules/coach/entities/LssonDetail';
import request from 'api/axios-instance';

import { ICouchDao } from '../dao/CouchDao';
import { ILessonDetailDao } from '../dao/LessonDetailDao';

export const fetchLessonsDetail = (lessonId: number) =>
  request.get(`v2/lessons/${lessonId}`).then((res) => {
    const lessons: ILessonDetail = mapLessonsDaoToEntity(res.data.data);

    console.warn('lessons', lessons);
    return lessons;
  });

function mapLessonsDaoToEntity(lessonDao: ILessonDetailDao): ILessonDetail {
  return {
    id: lessonDao.id,
    name: lessonDao.name,
    description: lessonDao.lesson_description,
    // date: lessonDao.date,
    videoUrl: lessonDao.video_url
      ? lessonDao.video_url.map((f) => ({
          downloadLink: f.download_link,
          hash: f.hash,
          name: f.name,
          size: f.size,
        }))
      : null,
    fileUrl: lessonDao.file_url
      ? lessonDao.file_url.map((f) => ({
          downloadLink: f.download_link,
          hash: f.hash,
          name: f.name,
          size: f.size,
        }))
      : null,
    presentationFileUrl: lessonDao?.presentation_file_url
      ? lessonDao?.presentation_file_url?.map((f) => ({
          downloadLink: f.download_link,
          hash: f.hash,
          name: f.name,
          size: f.size,
        }))
      : null,
    videoYoutubeUrl: lessonDao.video_youtube_link,
    // videoBroadcastUrl: lessonDao.video_broadcast_link,
    position: lessonDao.position,
    courseId: lessonDao.course_id,
    groupId: lessonDao.group_id,
    moduleId: lessonDao.module_id,
    courseName: lessonDao.course_name,
    isLast: lessonDao.is_last,
    isTest: lessonDao.is_test,
    testId: lessonDao.test_id,
    // timeFrom: lessonDao.time_from,
    // timeTo: lessonDao.time_to,
    material: lessonDao?.material || null,
    coach: mapCouchDaoToEntity(lessonDao.coach),
  };
}

function mapCouchDaoToEntity(couchDao: ICouchDao): ICouchInfo {
  return {
    id: couchDao.id,
    fullName: couchDao.full_name,
    email: couchDao.email,
    avatar: couchDao.avatar,
    occupation: couchDao.occupation,
    gender: couchDao.gender,
    company: couchDao.company,
    holding: couchDao.holding,
    division: couchDao.division,
    personalType: couchDao.personal_type,
    positionLevel: couchDao.position_level,
    positionLevelTop: couchDao.position_level_top,
    positionFamily: couchDao.position_family,
    grade: couchDao.grade,
  };
}
