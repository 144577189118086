import React from 'react';
import { NavLink } from 'react-router-dom';

interface Props {
  className?: string;
}

const MyCourseTabs: React.FC<Props> = ({ className = '' }) => {
  return (
    <div className={`flex justify-center items-center ${className}`}>
      <NavLink
        to="/student"
        className={({ isActive }) => {
          return `
            hover:text-current py-4 px-6 md:px-10 lg:px-44 border-b-2 text-xl
            ${
              isActive
                ? 'text-current theme-border-icon--active'
                : 'theme-text--disabled theme-border-icon--disabled'
            }
          `;
        }}
        end
      >
        Активные
      </NavLink>
      <NavLink
        to="/student/completed"
        className={({ isActive }) => {
          return `
            hover:text-current py-4 px-6 md:px-10 lg:px-44 border-b-2 text-xl
            ${
              isActive
                ? 'text-current theme-border-icon--active'
                : 'theme-text--disabled theme-border-icon--disabled'
            }
          `;
        }}
        end
      >
        Завершенные
      </NavLink>
    </div>
  );
};

export default MyCourseTabs;
