import request from 'api/axios-instance';
import { mapOptionDaoToEntity } from 'api/mappers/option.mappers';
import { Option } from 'entities/Option';

export const fetchLibraryCategories = () =>
  request.get('v1/library-categories').then((res) => {
    const categories: Option[] = res.data.map(mapOptionDaoToEntity);
    return categories;
  });

export const fetchLibrarySubcategories = () =>
  request.get('v1/library-subcategories').then((res) => {
    const categories: Option[] = res.data.map(mapOptionDaoToEntity);
    return categories;
  });

export const fetchLibraryPublishers = () =>
  request.get('v1/library-publishers').then((res) => {
    const categories: Option[] = res.data.map(mapOptionDaoToEntity);
    return categories;
  });

export const fetchLibraryAuthors = () =>
  request.get('v1/library-authors').then((res) => {
    const categories: Option[] = res.data.map(mapOptionDaoToEntity);
    return categories;
  });

export const fetchCourseDirections = () =>
  request.get('v1/handbook-directions').then((res) => {
    const directions: Option[] = res.data.data.map(mapOptionDaoToEntity);
    return directions;
  });

export const uploadFile = (data: FormData) =>
  request.post('v1/upload', data).then((res) => res.data.file_path as string);
