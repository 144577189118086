export default {
  nav: {
    home: 'басты',
    about: 'біз туралы',
    aboutPlatform: 'платформа туралы',
    apps: 'қолданбалар',
    analytics: 'аналитика',
    interface: 'интерфейс',
  },
  actions: {
    сooperation: 'ынтымақтастық',
    try: 'тырысу',
  },
  hero: {
    try: 'Демо нұсқасын қолданып көріңіз',
    titleTop: 'Оқытуды тиімді басқаруға',
    titleBottom: 'арналған инновациялық шешім',
    subtitle:
      'Тренингтер жасаңыз, дерекқор жинаңыз, төлем модулі арқылы төлемдерді қабылдаңыз, процестерді оңай және жылдам басқарыңыз',
    btn: 'тегін көріңіз',
  },
};
