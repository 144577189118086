import React from 'react';
import { Outlet } from 'react-router-dom';
import { useAppSelector } from 'hooks/redux';

const Default = () => {
  const { isLoading } = useAppSelector((state) => state.auth);

  if (isLoading) {
    return <span>Loading...</span>;
  }

  return <Outlet />;
};

export default Default;
