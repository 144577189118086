import { Outlet } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import { useAppSelector } from 'hooks/redux';

const Auth = () => {
  const { isLoading } = useAppSelector((state) => state.auth);
  const [query, setQuery] = useSearchParams();

  const THEME = query.get('theme') === 'light';
  const isTestIT =
    window.location.href.includes('test-it') || window.location.href.includes('cte-platform');

  const isLight = isTestIT || THEME;

  if (isLoading) {
    return <span>Loading....</span>;
  }

  return (
    <div
      className={`w-full min-h-[100dvh] flex justify-center items-center px-3 bg-no-repeat bg-cover theme ${
        isLight && 'light-theme'
      }`}
    >
      <Outlet />
    </div>
  );
};

export default Auth;
