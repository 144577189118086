import React from 'react';
import LMSButton from 'components/LMSButton';

const UI = () => {
  return (
    <div className="w-full min-h-[100vh] bg-main bg-no-repeat bg-cover">
      <p className="text-white p-4">Buttons</p>
      <div className="p-4">
        <LMSButton>Скачать сертификат</LMSButton>
        <LMSButton colorType="light">Скачать сертификат</LMSButton>
        <LMSButton variant="text">Скачать сертификат</LMSButton>
        <LMSButton loading>Скачать сертификат</LMSButton>
        <LMSButton disabled>Скачать сертификат</LMSButton>
      </div>
    </div>
  );
};

export default UI;
