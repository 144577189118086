import React from 'react';
import LMSButton from 'components/LMSButton';

interface Props {
  title: string;
  desc: string;
  buttonLabel?: string;
  nextStep: () => void;
}

const LectioWelcome: React.FC<Props> = ({ title, desc, buttonLabel = 'Начать', nextStep }) => {
  return (
    <div className="flex flex-col space-y-14 items-center justify-center w-[80%] max-w-[960px] h-max min-h-[500px] rounded-[20px] theme-student-card m-auto p-10">
      <div className="flex flex-col space-y-6 items-center">
        <p className="text-[32px] font-semibold text-center leading-10 max-w-[512px]">{title}</p>
        <p className="text-[22px] text-center leading-6 max-w-[700px] whitespace-pre-line">
          {desc}
        </p>
      </div>
      <LMSButton onClick={nextStep}>{buttonLabel}</LMSButton>
    </div>
  );
};

export default LectioWelcome;
