import React, { useEffect, useState } from 'react';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/chevron-down.svg';
import { ReactComponent as ArrowUpIcon } from 'assets/icons/chevron-up.svg';

import { fetchActiveCourseProgress } from '../../api/repository/my-courses';
import { CourseProgress as CourseProgressEntity } from '../../entities/CourseProgress';
import Loading from '../Loading';

interface Props {
  id: number;
  groupId: string;
}

const CourseProgress: React.FC<Props> = ({ id, groupId }) => {
  const [progress, setProgress] = useState<CourseProgressEntity | null>(null);
  const [isExpanded, setExpanded] = useState(true);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    fetchActiveCourseProgress(+id, +groupId).then((res) => {
      setProgress(res);
      setLoading(false);

      if (res.lessonsAttendance === 0 && res.homeworksNumber === 0 && res.testsNumber === 0)
        setExpanded(false);
    });
  }, []);

  const calcProgressWidth = (total: number, part: number) => {
    if (total === part) return '100%';

    const percentage = Math.floor((part * 100) / total);
    return `${percentage}%`;
  };

  return (
    <div className="w-full mt-5 rounded-2xl theme-student-card">
      <button
        className="flex items-center justify-between w-full p-4 md:p-8"
        onClick={() => setExpanded((prev) => !prev)}
      >
        <span className="text-xl md:text-2xl">Мой прогресс</span>
        {isExpanded ? <ArrowUpIcon /> : <ArrowDownIcon />}
      </button>
      {!progress && isLoading ? (
        <div className="p-8">
          <Loading theme="dark" />
        </div>
      ) : null}
      {!progress?.lessonsAttendance && !isLoading && isExpanded && (
        <div className="p-6">
          <p className="text-current text-center">Нет данных</p>
        </div>
      )}
      {progress && isExpanded ? (
        <div className="w-full">
          {progress.homeworksNumber > 0 && (
            <div className="grid grid-cols-1 md:grid-cols-[200px_1fr_150px] gap-3 items-center py-4 md:py-6 px-4 md:px-8 border-t theme-border--grey">
              <p>Домашние задания</p>
              <div className="flex-1 flex items-center theme-badge rounded-full overflow-hidden">
                <div
                  className="h-[4px] theme-active-effect"
                  style={{
                    width: calcProgressWidth(progress.homeworksNumber, progress.homeworksPassed),
                  }}
                />
              </div>
              <p className="text-sm md:text-right">
                <span className="font-semibold">
                  {progress.homeworksPassed} из {progress.homeworksNumber}
                </span>{' '}
                выполнено
              </p>
            </div>
          )}
          {progress.testsNumber > 0 && (
            <div className="grid grid-cols-1 md:grid-cols-[200px_1fr_150px] gap-3 items-center py-4 md:py-6 px-4 md:px-8 border-t theme-border--grey">
              <p>Тесты</p>
              <div className="flex-1 flex items-center theme-badge rounded-full overflow-hidden">
                <div
                  className="h-[4px] theme-active-effect"
                  style={{ width: calcProgressWidth(progress.testsNumber, progress.testsPassed) }}
                />
              </div>
              <p className="text-sm md:text-right">
                <span className="font-semibold">
                  {progress.testsPassed} из {progress.testsNumber}
                </span>{' '}
                пройден
              </p>
            </div>
          )}
          {progress.lessonsNumber > 0 && (
            <div className="grid grid-cols-1 md:grid-cols-[200px_1fr_150px] gap-3 items-center py-4 md:py-6 px-4 md:px-8 border-t theme-border--grey">
              <p>Посещаемость</p>
              <div className="flex-1 flex items-center  theme-badge rounded-full overflow-hidden">
                <div
                  className="h-[4px] theme-active-effect"
                  style={{
                    width: calcProgressWidth(progress.lessonsNumber, progress.lessonsAttendance),
                  }}
                />
              </div>
              <div className="flex items-center justify-between md:block text-sm md:text-right">
                <p>
                  <span className="font-semibold">
                    {progress.lessonsAttendance} из {progress.lessonsNumber}
                  </span>{' '}
                  занятий
                </p>
                <p>
                  <span className="font-semibold">0</span> пропусков
                </p>
              </div>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default CourseProgress;
