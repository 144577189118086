import { useState } from 'react';
import { useNotification } from 'hooks/notification';
import { Option } from 'entities/Option';

import { fetchCoaches } from '../api/repository/handbooks';

export const useHandbooks = () => {
  const notify = useNotification();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [coaches, setCoaches] = useState<Option[]>([]);

  const onFetchCoaches = (search?: string) => {
    setLoading(true);
    fetchCoaches(search)
      .then((res) => setCoaches(res))
      .catch((e) => notify.error(e.message))
      .finally(() => setLoading(false));
  };

  return {
    isLoading,
    coaches,
    onFetchCoaches,
  };
};
