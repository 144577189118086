import { FileUploader } from 'react-drag-drop-files';
import { ReactComponent as DownLoadIcon } from 'assets/icons/download-icon-blue.svg';
import { useNotification } from 'hooks/notification';

import { FileErrors, MAX_VIDEO_SIZE_BYTES } from './../../constants/index';

interface IProps {
  isWhite?: boolean;
  uploading?: boolean;
  title: string;
  types: string[];
  files: File[];
  hint?: string;
  isMultiple: boolean;
  handleChange: CallableFunction;
}

const UploadFile: React.FC<IProps> = ({
  isWhite,
  uploading,
  title,
  types,
  isMultiple,
  files,
  hint,
  handleChange,
}) => {
  const notify = useNotification();

  const handleFilesSelection = async (files: File[]) => {
    try {
      const validFiles = await uploadFilesToServer(files);
      handleChange(validFiles);
    } catch (error) {
      notify.error('File upload failed. Please try again.');
    }
  };

  const uploadFilesToServer = async (files: File[]) => {
    const validFiles = [];

    for (const file of files) {
      if (isValidFile(file) && !isOversized(file)) {
        await new Promise((resolve) => setTimeout(resolve, 200));
        validFiles.push(file);
      } else {
        notify.error(FileErrors.wrongFormat);
      }
    }

    return validFiles;
  };

  const handleRemoveFile = (idx: number) => {
    const updatedFiles = [...files];
    updatedFiles.splice(idx, 1);
    // true - delete marker
    handleChange(updatedFiles, true);
  };

  const isValidFile = (file: File) => {
    const isValid = isValidFileType(file.type) && !isOversized(file);
    return isValid;
  };

  const isValidFileType = (fileType: string) => {
    return types.some((type) => fileType.includes(type));
  };

  const isOversized = (file: File) => {
    return file.size > MAX_VIDEO_SIZE_BYTES;
  };

  return (
    <div className="w-full">
      <FileUploader handleChange={handleFilesSelection} name="file" multiple={isMultiple}>
        <div
          className={`${
            isWhite ? 'border-black' : 'border-white'
          } flex flex-col items-center px-5 py-3 border border-dashed border-opacity-20 rounded-[14px] cursor-pointer xl:flex-row theme-background--grey`}
        >
          <div className="flex flex-row justify-center gap-x-3 items-center">
            <DownLoadIcon />
            <span className={`${isWhite ? 'text-black' : 'text-current'} text-base`}>{title}</span>
          </div>
          <p className="text-current text-xs text-opacity-40 xl:ml-auto">{hint}</p>
        </div>
      </FileUploader>
      {uploading ? (
        <div
          className={`${
            isWhite ? 'text-black' : 'text-current'
          } flex flex-row gap-1 items-baseline mt-2`}
        >
          Загрузка{' '}
          {Array(3)
            .fill(null)
            .map((_, index) => (
              <p key={index} className="w-1 h-1 rounded-full bg-[#58DB66] animate-pulse" />
            ))}
        </div>
      ) : (
        files.length > 0 && (
          <div className="w-full flex flex-wrap gap-2 mt-[18px] theme-background--grey">
            {files.map((el, idx) => {
              return (
                <div
                  key={idx}
                  className={`${
                    isWhite ? 'text-black' : 'text-current'
                  } h-fit flex flex-row items-center gap-x-2 py-2 px-4 text-xs bg-white bg-opacity-20 rounded-[55px]`}
                >
                  <button
                    onClick={() => handleRemoveFile(idx)}
                    className={`${
                      isWhite ? 'text-black text-base' : 'text-current'
                    } w-[20px] h-[20px] flex items-center justify-center bg-white bg-opacity-40 rounded-full`}
                  >
                    x
                  </button>
                  <span>
                    {el.name.length > 28
                      ? el.name.slice(0, 25) + '...' + el.name.split('.').pop()
                      : el.name}
                  </span>
                </div>
              );
            })}
          </div>
        )
      )}
    </div>
  );
};

export default UploadFile;
