import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useNotification } from 'hooks/notification';

import { IHRJournal } from '../api/dao/journal.dao';
import { fetchBaseJournal } from '../api/repository/base-journal';

export const useJournalBase = () => {
  const [query, setQuery] = useSearchParams();

  const fio = query.get('fio');
  const startDate = query.get('date_from');
  const endDate = query.get('date_to');
  const course_id = query.get('course_id');
  const group_id = query.get('group_id');
  const page = query.get('page');

  const searchParams = {
    startDate,
    endDate,
    course_id,
    group_id,
  };

  const notify = useNotification();

  const [lastPage, setLastPage] = useState(1);

  const [isLoading, setLoading] = useState(false);
  const [journalBase, setTrainingBase] = useState<IHRJournal>();

  useEffect(() => {
    const params = {
      fio,
      date_from: startDate,
      date_to: endDate,
      course_id,
      group_id,
      // page: page ? page : '1',
      // per_page: 30,
    };

    if (!group_id && !course_id && !startDate && !endDate) {
      setTrainingBase(undefined);
      return;
    }

    if (!group_id || !course_id || !startDate || !endDate) {
      notify.error('Заполните все поля фильтра!');
      return;
    }

    setLoading(true);
    fetchBaseJournal(params)
      .then((res) => {
        setTrainingBase(res);
      })
      .catch((e) => notify.error(e.message))
      .finally(() => setLoading(false));
  }, [course_id, group_id, fio, startDate, endDate, page]);

  const setCurrentPage = (current: number) => {
    query.set('page', current.toString());
    setQuery(query);
  };

  return {
    isLoading,
    journalBase,
    currentPage: page ? +page : 1,
    lastPage,
    setCurrentPage,
    searchParams,
  };
};
