import React, { useEffect, useMemo, useState } from 'react';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/chevron-down.svg';
import { ReactComponent as ArrowUpIcon } from 'assets/icons/chevron-up.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import LMSRadioButton from 'components/LMSRadioButton';
import { Option } from 'entities/Option';

interface Props {
  selected: number | string | null;
  title: string;
  hideSearch?: boolean;
  expanded?: boolean;
  fetchOptions: () => Promise<Option[]>;
  setSelected: (val: number | null) => void;
}

const FilterAccordion: React.FC<Props> = ({
  selected,
  title,
  hideSearch = false,
  expanded = false,
  fetchOptions,
  setSelected,
}) => {
  const [search, setSearch] = useState('');
  const [options, setOptions] = useState<Option[]>([]);
  const [isExpanded, setExpanded] = useState(expanded);

  useEffect(() => {
    fetchOptions().then((res) => {
      setOptions(res);
    });
  }, [fetchOptions]);

  const filteredOptions = useMemo(() => {
    return options.filter((o) => o.label.toLowerCase().includes(search.trim())).slice(0, 8);
  }, [search, options]);

  const onChecked = (id: number) => {
    setSelected(selected === id ? null : id);
  };

  return (
    <div className="w-full py-5 border-b border-solid border-white/20 last:border-b-0">
      <button
        className="flex justify-between items-center w-full mb-4"
        onClick={() => setExpanded((prev) => !prev)}
      >
        <p className="relative text-[22px]">
          {title} <span className="absolute -right-5 text-green text-sm">{options.length}</span>
        </p>
        {isExpanded ? <ArrowUpIcon /> : <ArrowDownIcon />}
      </button>
      <div className={`${isExpanded ? 'block max-h-[500px]' : 'hidden max-h-0'} transition-all`}>
        {!hideSearch ? (
          <div className="flex items-center space-x-2 px-3 py-2 mb-5 bg-[#E2E8F04D] border border-solid border-white/30 rounded-full">
            <SearchIcon />
            <input
              value={search}
              type="text"
              className="flex-1 bg-transparent outline-none"
              placeholder="Поиск"
              onChange={(ev) => setSearch(ev.target.value)}
            />
          </div>
        ) : null}
        {filteredOptions.map((option) => (
          <label
            key={option.value}
            className="flex items-center space-x-3 py-2.5 cursor-pointer"
            onClick={() => onChecked(option.value)}
          >
            <LMSRadioButton checked={option.value === selected} />
            <p>{option.label}</p>
          </label>
        ))}
      </div>
    </div>
  );
};

export default FilterAccordion;
