import React, { useEffect, useState } from 'react';

interface Props {
  image?: string | null;
  alt?: string;
  height?: string;
  width?: string;
}

const BaseImage: React.FC<Props> = ({
  image = require('assets/images/course-plug.png'),
  alt = 'превью курса',
  height = '100%',
  width = '100%',
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [opacity, setOpacity] = useState(0);

  const onLoadImage = () => {
    setIsLoading(false);
    setOpacity(1);
  };

  const errorImage = () => {
    setIsLoading(false);
    setOpacity(0);
  };

  useEffect(() => {
    const imgElement = new Image();
    imgElement.src = image;
    imgElement.onload = onLoadImage;
    imgElement.onerror = errorImage;
  }, [image]);

  return (
    <div
      className={`w-full h-full overflow-hidden bg-background-avatar relative ${
        isLoading && 'rounded-full'
      }`}
    >
      {image && isLoading && (
        <div className="bg-blue-100 w-full h-full flex items-center justify-center relative">
          <div className="animate-spin">
            <img
              src={require('assets/images/loader-blue.png')}
              alt="загрузка"
              height={height}
              width={width}
              className="loading w-full h-full"
            />
          </div>
        </div>
      )}

      {(image || !image) && !isLoading && (
        <img
          src={image}
          alt={alt}
          style={{ opacity }}
          onLoad={onLoadImage}
          onError={errorImage}
          loading="lazy"
          className={`object-cover overflow-hidden rounded-xl w-[${width}] h-[${height}] transition-opacity ${
            !opacity && 'empty-image-plug'
          }`}
        />
      )}
    </div>
  );
};

export default BaseImage;
