import React from 'react';
import { useNavigate } from 'react-router-dom';
import LMSButton from 'components/LMSButton';
import LMSPagination from 'components/LMSPagination';
import StudentTable from 'modules/hr/components/StudentsTable';
import Loading from 'modules/student/components/Loading';

import StudentFilter from '../../components/StudentFilter';
import { useStudents } from '../../hooks/students-list';

const HrStudents = () => {
  const navigate = useNavigate();
  const { isLoading, students, currentPage, lastPage, setCurrentPage } = useStudents();

  return (
    <div className="flex flex-col items-start w-full py-4 lg:py-10">
      <div className="w-full flex flex-col lg:flex-row justify-between space-y-4 lg:space-y-0 lg:space-x-4 mb-6 lg:mb-10">
        <h2 className="text-[32px] font-semibold">База студентов</h2>
        <div className="flex flex-row items-center justify-between lg:justify-end space-x-4">
          <div className="hidden">
            <StudentFilter />
          </div>

          <LMSButton onClick={() => navigate('/hr/users/students/new-student')}>
            Добавить слушателя
          </LMSButton>
        </div>
      </div>

      {isLoading ? (
        <div className="w-full p-20">
          <Loading />
        </div>
      ) : (
        <div className="w-full">
          {students.length === 0 ? (
            <h3 className="mb-10 text-[24px] font-semibold text-center mt-10">
              Нет данных <br /> Попробуйте изменить фильтрацию
            </h3>
          ) : (
            <>
              <div className="overflow-x-auto">
                <StudentTable students={students} />
              </div>

              <div className={lastPage > 1 ? 'mt-14' : ''}>
                <LMSPagination
                  current={currentPage}
                  lastPage={lastPage}
                  onChange={setCurrentPage}
                />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default HrStudents;
