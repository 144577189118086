import request from 'api/axios-instance';

import { IHRJournal } from '../dao/journal.dao';

export const fetchBaseJournal = (params: Record<string, string | number | null>) =>
  request.get('v2/attendance/report', { params }).then((res) => {
    const result = res.data.data as IHRJournal;
    const students = result.students
      .sort((a, b) => a.calendar_id - b.calendar_id)
      .map((student) => {
        return {
          ...student,
          lesson_date: student.lesson_date.replace(/-/g, '.'),
        };
      });

    return Object.assign({}, result, { students });
  });
