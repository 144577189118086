import React from 'react';
import { ReactComponent as DocumentIcon } from 'assets/icons/document-green.svg';
import Accordion from 'components/Accordion';
import { UserDetail } from 'modules/hr/entities/User';

interface Props {
  user: UserDetail;
}

const STUDY_TYPES: { [key: string]: string } = {
  inner: 'Внутренний',
  outer: 'Внешний',
};

const UserActiveCourses: React.FC<Props> = ({ user }) => (
  <Accordion title="Активные курсы">
    <div className="flex flex-col space-y-3">
      {/* <div className="flex flex-row rounded-2xl bg-white/10 p-5">
          <p className="w-1/2 text-white">Курс:</p>
          <p className="w-1/2 text-white font-medium truncate">BIG Engineer Basic</p>
        </div> */}
      {user.type && (
        <div className="flex flex-row rounded-2xl bg-white/10 p-5">
          <p className="w-1/2 text-white">Тип обучения:</p>
          <p className="w-1/2 text-white font-medium truncate">{STUDY_TYPES[user.type]}</p>
        </div>
      )}
      {user.agreementFiles && (
        <div className="flex flex-row rounded-2xl bg-white/10 p-5">
          <p className="w-1/2 text-white">Договор:</p>
          <div className="w-1/2 flex flex-col space-y-2">
            {user.agreementFiles.map((file, index) => (
              <a
                key={index}
                href={file.download_link}
                className="flex flex-row items-center space-x-4 text-green font-medium"
                download
              >
                <DocumentIcon /> <span className="truncate">{file.original_name}</span>
              </a>
            ))}
          </div>
        </div>
      )}
      {/* <div className="flex flex-row rounded-2xl bg-white/10 p-5">
          <p className="w-1/2 text-white">Сертификат:</p>
          <div className="w-1/2 flex flex-col space-y-2">
            <a href="/" className="flex flex-row items-center space-x-4 text-green font-medium">
              <DocumentIcon /> <span className="truncate">Сертификат</span>
            </a>
          </div>
        </div> */}
      {/* <div className="flex flex-row rounded-2xl bg-white/10 p-5">
          <p className="w-1/2 text-white">Рейтинг:</p>
          <div className="w-1/2 flex flex-col space-y-2">
            <a href="/" className="flex flex-row items-center font-medium truncate">
              перейти
            </a>
          </div>
        </div> */}
    </div>
  </Accordion>
);

export default UserActiveCourses;
