import React from 'react';
import LMSTitle from 'components/LMSTitle';
import { ISimulation } from 'modules/coach/entities/Simulation';

export interface IProps {
  title: ISimulation['categoryName'];
  onClick: () => void;
}

const SimulationCard: React.FC<IProps> = ({ title, onClick }) => (
  <div className="w-full h-fit cursor-pointer" onClick={onClick}>
    <div className="h-fit w-full rounded-[20px] bg-[#58DB66]">
      <div className="h-full flex flex-col theme-background-homework-card rounded-[18px]">
        <div className="flex flex-row items-center p-4">
          <LMSTitle
            tag="h4"
            className="theme-text--default text-[15px] leading-[20px] font-bold xl:text-[20px] xl:leading-[22px] overflow-hidden"
          >
            {title}
          </LMSTitle>
        </div>
      </div>
    </div>
  </div>
);

export default SimulationCard;
