import React, { useEffect, useState } from 'react';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import BaseModal from 'components/BaseModal';
import LMSButton from 'components/LMSButton';
import LMSTitle from 'components/LMSTitle';
import Loader from 'components/Loader';
import { fetchHandbookSimulationCatalog } from 'modules/coach/api/repository/handbooks';
import { useNotification } from 'hooks/notification';
import { Option } from 'entities/Option';

import { IQuestionAnswer, ISimulationQuestion } from '../../entities/Simulation';
import UploadAudio from '../UploadAudio';

interface Props {
  initialQuestion: ISimulationQuestion | null;
  onClose: () => void;
  onChange: (question: ISimulationQuestion) => void;
}

const initialAnswers = [
  {
    name: '',
    isCorrect: false,
  },
  {
    name: '',
    isCorrect: false,
  },
  {
    name: '',
    isCorrect: false,
  },
];

const AddOrEditSimulationQuestionModal: React.FC<Props> = ({
  initialQuestion,
  onClose,
  onChange,
}) => {
  const initialAudio = initialQuestion?.newAudio
    ? { hash: initialQuestion.newAudio }
    : initialQuestion?.audio && Array.isArray(initialQuestion.audio)
    ? initialQuestion?.audio[0]
    : null;

  const notify = useNotification();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedType, setSelectedType] = useState<Option>({
    value: initialQuestion ? initialQuestion.typeId : 0,
    label: initialQuestion && initialQuestion.typeName ? initialQuestion.typeName : '',
  });
  const [simulationTypes, setSimulationTypes] = useState<Option[]>([]);
  const [question, setQuestion] = useState<string>(initialQuestion ? initialQuestion.title : '');
  const [answer, setAnswer] = useState<string>(
    initialQuestion && !Array.isArray(initialQuestion.answers) ? initialQuestion.answers.name : ''
  );
  const [answers, setAnswers] = useState<IQuestionAnswer[]>(
    initialQuestion && Array.isArray(initialQuestion.answers)
      ? initialQuestion.answers
      : initialAnswers
  );
  const [seconds, setSeconds] = useState<string>(
    initialQuestion ? String(initialQuestion.seconds) : ''
  );
  const [audio, setAudio] = useState<string | null>(initialAudio ? initialAudio.hash : null);

  const onSave = async () => {
    if (!selectedType.value) {
      return notify.error('Для создания задачи выберите категорию');
    }

    if (selectedType.value === 1 && (!answer || !question)) {
      return notify.error('Все поля должны быть заполнены');
    }

    if (selectedType.value === 2) {
      if (answers.filter((a) => !a.name).length || !question || !seconds) {
        return notify.error('Все поля должны быть заполнены');
      }
      if (answers.filter((a) => a.isCorrect).length !== 1) {
        return notify.error('У задачи должен быть один правильный ответ');
      }
    }

    if (selectedType.value === 3 && (!question || !seconds)) {
      return notify.error('Все поля должны быть заполнены');
    }

    let questionAnswers: IQuestionAnswer[] | IQuestionAnswer;
    if (selectedType.value === 2) {
      questionAnswers = answers;
    } else {
      questionAnswers = {
        name: answer,
        isCorrect: true,
      };
    }

    if (initialQuestion?.id) {
      onChange({
        ...initialQuestion,
        title: question,
        answers: questionAnswers,
        typeId: selectedType.value,
        typeName: null,
        seconds: seconds ? Number(seconds) : 30,
        ...(audio
          ? initialAudio?.hash === audio
            ? { audio, newAudio: null }
            : { audio: null, newAudio: audio }
          : { audio: null, newAudio: null }),
      });
    } else {
      onChange({
        id: Date.now(),
        title: question,
        answers: questionAnswers,
        typeId: selectedType.value,
        typeName: null,
        seconds: seconds ? Number(seconds) : 30,
        audio: null,
        newAudio: audio,
        position: 0,
      });
    }
    onClose();
  };

  useEffect(() => {
    setQuestion('');
    setAnswer('');
    setAnswers(initialAnswers);
    setSeconds('');
  }, [selectedType]);

  useEffect(() => {
    if (initialQuestion) {
      setQuestion(initialQuestion.title);
      Array.isArray(initialQuestion.answers)
        ? setAnswers(initialQuestion.answers)
        : setAnswer(initialQuestion.answers.name);
      initialQuestion.seconds && setSeconds(String(initialQuestion.seconds));
    }
  }, [initialQuestion]);

  useEffect(() => {
    setIsLoading(true);
    fetchHandbookSimulationCatalog()
      .then((res) => {
        setSimulationTypes(res.types);
      })
      .catch(() => {
        notify.error('Произошла ошибка. Обратитесь в службу поддержки');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <BaseModal wrapperClassName="sm:!max-w-max" onClose={onClose}>
      <div className="flex flex-col space-y-4">
        {isLoading ? (
          <Loader theme="dark" />
        ) : (
          <>
            <LMSTitle tag="h2">Выберите категорию задание</LMSTitle>
            <div className="flex flex-row space-x-2">
              {simulationTypes.map((type) => (
                <button
                  key={type.value}
                  type="button"
                  className={`w-full h-[63px] bg-white justify-center items-center rounded-2xl border px-10 text-current text-sm md:text-bas outline-none ${
                    type.value === selectedType.value && 'theme-border--primary'
                  }`}
                  onClick={() => setSelectedType(type)}
                >
                  {type.label}
                </button>
              ))}
            </div>
            {selectedType.value ? (
              <div className="flex flex-col max-w-[500px]">
                {selectedType.value === 1 ? (
                  <div className="flex flex-col space-y-4">
                    <div className="flex flex-col space-y-2">
                      <p>Напишите слово:</p>
                      <input
                        value={question}
                        placeholder="Введите слово"
                        className="w-full h-[50px] py-3 px-6 text-black bg-[#F2F2F2] pr-6 rounded-[14px] outline-none"
                        onChange={(event) => setQuestion(event.target.value)}
                      />
                    </div>
                    <div className="flex flex-col space-y-2">
                      <p>Введите правильное написание слова:</p>
                      <input
                        value={answer}
                        placeholder="Введите слово"
                        className="w-full h-[50px] py-3 px-6 text-black bg-[#F2F2F2] pr-6 rounded-[14px] outline-none"
                        onChange={(event) => setAnswer(event.target.value)}
                      />
                    </div>
                    <div className="flex flex-col space-y-2">
                      <p>Загрузите аудио с правильным произношением слова:</p>
                      <UploadAudio
                        initialAudio={initialAudio?.hash}
                        onChange={(value) => setAudio(value)}
                      />
                    </div>
                  </div>
                ) : selectedType.value === 2 ? (
                  <div className="flex flex-col space-y-4">
                    <div className="flex flex-col space-y-2">
                      <p>Напишите слово которое нужно запомнить:</p>
                      <input
                        value={question}
                        placeholder="Введите слово"
                        className="w-full h-[50px] py-3 px-6 text-black bg-[#F2F2F2] pr-6 rounded-[14px] outline-none"
                        onChange={(event) => setQuestion(event.target.value)}
                      />
                    </div>
                    <div className="flex flex-col space-y-2">
                      <p>Загрузите аудио с правильным произношением слова:</p>
                      <UploadAudio
                        initialAudio={initialAudio?.hash}
                        onChange={(value) => setAudio(value)}
                      />
                    </div>
                    <div className="flex flex-col space-y-2">
                      <p>Введите время в секундах для скрытия слово:</p>
                      <input
                        value={seconds}
                        type="number"
                        placeholder="Введите время в секундах"
                        className="w-full h-[50px] py-3 px-6 text-black bg-[#F2F2F2] pr-6 rounded-[14px] outline-none"
                        onChange={(event) => setSeconds(event.target.value)}
                      />
                    </div>
                    <div className="flex flex-col space-y-2">
                      <p>Введите варианты какое слово было показано:</p>
                      {answers.map((el, idx) => (
                        <div key={idx} className="flex flex-row items-center space-x-2">
                          <input
                            value={el.name}
                            placeholder={String(idx + 1)}
                            className="w-full h-[50px] py-3 px-6 text-black bg-[#F2F2F2] pr-6 rounded-[14px] outline-none"
                            onChange={(event) =>
                              setAnswers(
                                answers.map((a, index) =>
                                  index === idx ? { ...a, name: event.target.value } : a
                                )
                              )
                            }
                          />
                          <button
                            className={`flex items-center justify-center shrink-0 w-6 h-6 rounded cursor-pointer ${
                              el.isCorrect
                                ? 'bg-[#58DB66]'
                                : 'bg-white/10 border theme-border--light-black'
                            }`}
                            onClick={() =>
                              setAnswers(
                                answers.map((a, index) =>
                                  index === idx ? { ...a, isCorrect: !a.isCorrect } : a
                                )
                              )
                            }
                          >
                            <CheckIcon className="w-2.5 h-2.5" />
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col space-y-4">
                    <div className="flex flex-col space-y-2">
                      <p>Напишите слово которое нужно запомнить:</p>
                      <input
                        value={question}
                        placeholder="Введите слово"
                        className="w-full h-[50px] py-3 px-6 text-black bg-[#F2F2F2] pr-6 rounded-[14px] outline-none"
                        onChange={(event) => {
                          setQuestion(event.target.value);
                          setAnswer(event.target.value);
                        }}
                      />
                    </div>
                    <div className="flex flex-col space-y-2">
                      <p>Загрузите аудио с правильным произношением слова:</p>
                      <UploadAudio
                        initialAudio={initialAudio?.hash}
                        onChange={(value) => setAudio(value)}
                      />
                    </div>
                    <div className="flex flex-col space-y-2">
                      <p>Введите время в секундах для скрытия слово:</p>
                      <input
                        value={seconds}
                        type="number"
                        placeholder="Введите время в секундах"
                        className="w-full h-[50px] py-3 px-6 text-black bg-[#F2F2F2] pr-6 rounded-[14px] outline-none"
                        onChange={(event) => setSeconds(event.target.value)}
                      />
                    </div>
                  </div>
                )}
              </div>
            ) : null}
            <div className="flex flex-row justify-end items-center space-x-2">
              <button
                type="button"
                className="bg-[#F6F6F6] flex items-center justify-center px-3 md:px-[25px] py-2 md:py-3.5 text-current text-sm md:text-base rounded-full border-none outline-none"
                onClick={onClose}
              >
                Отмена
              </button>
              <LMSButton loading={isLoading} onClick={onSave}>
                Сохранить
              </LMSButton>
            </div>
          </>
        )}
      </div>
    </BaseModal>
  );
};

export default AddOrEditSimulationQuestionModal;
