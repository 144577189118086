import React from 'react';
import ManagerCard from 'modules/hr/components/ManagerCard';

import { Coach as Manager } from '../../entities/Coaches';

interface Props {
  managers: Manager[];
}

const ManagerTable: React.FC<Props> = ({ managers }) => {
  return (
    <table className="bg-coachTableGradient w-full rounded-[20px] backdrop-blur-[60px] min-w-[980px]">
      <thead className="block px-8 pt-4 pb-[10px] w-full bg-darkGray">
        <tr className="grid grid-cols-coachTable w-full gap-x-8">
          <td>МЕНЕДЖЕР</td>
          <td>ЛОГИН</td>
          <td>E-MAIL</td>
          <td>ТЕЛЕФОН</td>
        </tr>
      </thead>

      <tbody>
        {managers.length > 0 &&
          managers.map((manager) => (
            <tr
              className="grid grid-cols-coachTable w-full px-8 pt-4 pb-[10px] gap-x-8 border-t-[1px] border-coachTableBorder "
              key={manager.id}
            >
              <td>
                <ManagerCard info={manager} />
              </td>
              <td className="w-full flex items-center">
                <span className="w-full max-w-full truncate">{manager.login}</span>
              </td>
              <td className="w-full flex items-center">
                <a
                  className="w-full max-w-full truncate text-white cursor-pointer"
                  href={'mailto:' + manager.email}
                >
                  {manager.email}
                </a>
              </td>
              <td className="w-full flex items-center">
                <a className="w-full text-white cursor-pointer" href={'tel:' + manager.phoneNumber}>
                  {manager.phoneNumber}
                </a>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default ManagerTable;
