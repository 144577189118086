export default {
  main: 'Главная',
  loading: 'Загрузка...',
  listEmpty: 'Список пуст',
  attention: 'Внимание',
  services: 'Услуги',
  applications: 'Заявки',
  partnership: 'Партнёрство',
  branches: 'Филиалы',
  catalog: 'Каталог',
  faq: 'Часто задаваемые вопросы',
  companyData: 'Данные компании',
  employeeData: 'Данные представителя',
  accountActivation: 'Активация аккаунта',
  aboutPlatform: 'О площадке',
  manageCompany: 'Управление компанией',
  tradingAgency: 'Торговое представительство',
  saleOfPoint: 'Точка продажи',
  tradingHouse: 'Торговый дом',
  manufacturerName: 'Название производителя',
  productName: 'Название товара',
  priceFrom: 'Цена от {{from}} шт.',
  aboutManufacturer: 'О производители',
  contacts: 'Контакты',
  manufacturers: 'Производители',
  summary: 'Сводка',
  certificates: 'Сертификаты',
  yearsAgo: '{{num}} лет назад',
  title: 'Название',
  brandName: 'Название бренда',
  legalAddress: 'Юридический адрес',
  jurisdiction: 'Юрисдикция',
  regNumberBin: 'Регистрационный номер (БИН)',
  registrationDate: 'Дата регистрации',
  sinceOnSite: 'На сайте с',
  product: 'Товар',
  vendorCode: 'Артикул',
  date: 'Дата',
  display: 'Отображение',
  inStock: 'В наличии',
  actions: 'Действия',
  required: 'Обьязательные',
  optional: 'Опциональные',
  productList: 'Список товаров',
  barcode: 'Баркод или UPC/EAN',
  color: 'Цвет',
  material: 'Материал',
  currency: 'Валюта',
  unitType: 'Единица измерения',
  status: 'Статус',
  wholesalePrice: 'Оптовая цена',
  from: 'от',
  mainInfo: 'Основная информация',
  productDescription: 'Описание товара',
  dimensions: 'Габаритные размеры',
  characteristics: 'Характеристики',
  price: 'Цена',
  createAndUpdate: 'Создание и обновление',
  created: 'Создано',
  updated: 'Обновлено',
  searchTerms: 'Поисковые запросы',
  supportedFormats: 'Поддерживаемые форматы: {{formats}}',
  maxSize: 'Максимальный размер: {{size}}',
  images: 'Изображения',
  agency: 'Представительство',
  password: 'Пароль',
  addingBranch: 'Добавление филиала',
  questionAndAnswer: 'Вопросы и ответы',
  profile: 'Профиль',
  settings: 'Настройки',
  requirementsForVerification: 'Требования для верификации',
  submitFollowingDocuments: 'Предоставьте следующие типы документов',
  companyRegCertificate: 'Свидетельство о регистрации компании',
  companyCharter: 'Устав компании',
  certificateTaxReg: 'Свидетельство о налоговой регистрации',
  productionDoc: 'Документация о производстве',
  qualityCertificate: 'Свидетельства о качестве и соответствии',
  licensesAndCertificates: 'Лицензии и сертификаты',
  suppliersAndCustomersContracts: 'Договоры с поставщиками и клиентами',
  accountDeleting: 'Удаление аккаунта',
  passwordChanging: 'Смена пароля',
  today: 'сегодня',
  weight: 'Вес',
  availableOnOrder: 'Товар доступен под заказ',
  manufacturerOtherProducts: 'Другие товары производителя',
  lastActivity: 'Последняя активность',
  passwordRecovery: 'Восстановление пароля',
  forgotPassword: 'Забыли пароль?',
  haveNotAccount: 'Нет аккаунта?',
  createPassword: 'Создание пароля',
  registration: 'Регистрация',
  haveAnAccount: 'Есть аккаунт?',
  iAmManufacturer: 'Я производитель',
  iAmUser: 'Я пользователь',
  aboutCompany: 'О компании',
  logo: 'Логотип',
  personalData: 'Личные данные',
  notifications: 'Уведомления',
  todayAt: 'Сегодня в {{date}}',
  yesterdayAt: 'Вчера в {{date}}',
  all: 'Все',
  system: 'Системные',
  notSpecified: 'не указан',
};
