import React, { useEffect, useMemo, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/chevron-left.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/chevron-right.svg';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';

import { fetchAttendance } from '../../api/repository/dashboard';
import { DashboardAttendance } from '../../entities/DashboardAttendance';
import { useCalendarFilter } from '../../hooks/calendar-filter';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const MONTHS = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];

const AttendanceChart = () => {
  const [query] = useSearchParams();
  const course = query.get('course');

  const { calendar, onPeriodChange, onPrevPeriodChange, onNextPeriodChange, getWeekDateRange } =
    useCalendarFilter();
  const [attendances, setAttendances] = useState<DashboardAttendance[]>([]);

  useEffect(() => {
    const params = {
      type: calendar.type,
      year: calendar.year,
      ...(calendar.type !== 'year' && {
        month: calendar.month + 1,
      }),
      ...(calendar.type === 'week' && {
        week: calendar.week,
      }),
      ...(course && {
        course_ids: course,
      }),
    };
    fetchAttendance(params).then((res) => {
      setAttendances(res);
    });
  }, [calendar, course]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const chartData = useMemo(() => {
    return {
      labels: attendances.map((a) => a.start),
      datasets: [
        {
          label: 'Посещаемость',
          data: attendances.map((a) => a.count),
          backgroundColor: 'white',
          borderRadius: 20,
          maxBarThickness: 10,
        },
      ],
    };
  }, [attendances]);

  return (
    <div className="flex flex-col justify-between space-y-4 row-span-2 rounded-xl p-4 md:p-5 bg-studentCardBg">
      <p className="text-lg">Посещаемость</p>

      <div className="bg-[linear-gradient(126.97deg, #060C29 28.26%, rgba(4, 12, 48, 0.5) 91.2%)]">
        <Bar data={chartData} options={options} />
      </div>

      <div className="flex flex-col md:flex-row items-center justify-between space-y-4 md:space-y-0">
        <div className="flex items-center space-x-[2px] w-full md:w-auto">
          <button className="py-0.5 bg-white/20 rounded-l" onClick={onPrevPeriodChange}>
            <ArrowLeftIcon />
          </button>
          <p className="flex-1 md:min-w-[130px] px-3 py-[1px] text-center text-sm bg-white/20">
            {calendar.type === 'year'
              ? calendar.year
              : calendar.type === 'month'
              ? MONTHS[calendar.month]
              : `${getWeekDateRange()}, ${MONTHS[calendar.month]}`}
          </p>
          <button className="py-0.5 bg-white/20 rounded-r" onClick={onNextPeriodChange}>
            <ArrowRightIcon />
          </button>
        </div>

        <div>
          <button
            className={`${
              calendar.type === 'week' ? 'bg-blue text-white' : 'text-white/50'
            } px-3 py-1 text-sm rounded`}
            onClick={() => onPeriodChange('week')}
          >
            Неделя
          </button>
          <button
            className={`${
              calendar.type === 'month' ? 'bg-blue text-white' : 'text-white/50'
            } px-3 py-1 text-sm rounded`}
            onClick={() => onPeriodChange('month')}
          >
            Месяц
          </button>
          <button
            className={`${
              calendar.type === 'year' ? 'bg-blue text-white' : 'text-white/50'
            } px-3 py-1 text-sm rounded`}
            onClick={() => onPeriodChange('year')}
          >
            Год
          </button>
        </div>
      </div>
    </div>
  );
};

export default AttendanceChart;
