import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowBack } from 'assets/icons/chevron-left.svg';
import Loader from 'components/Loader';
import AddNewSimulation from 'modules/coach/components/AddOrEditSimulation';
import { useNotification } from 'hooks/notification';
import { Option } from 'entities/Option';

import { fetchHandbookSimulationCatalog } from '../../api/repository/handbooks';

const NewSimulation = () => {
  const navigate = useNavigate();
  const notify = useNotification();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [categories, setCategories] = useState<Option[]>([]);

  const goBack = () => {
    navigate('/coach/simulations');
  };

  useEffect(() => {
    setIsLoading(true);
    fetchHandbookSimulationCatalog()
      .then((res) => {
        setCategories(res.categories);
      })
      .catch(() => {
        notify.error('Произошла ошибка. Обратитесь в службу поддержки');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <div className="w-full h-full flex flex-col mt-20 lg:mt-6">
      <button
        type="button"
        onClick={() => goBack()}
        className="w-fit flex flex-row items-center justify-center gap-x-2 py-3 pl-3 pr-5 text-current text-[14px] font-medium theme-default-button rounded-[36px] border-none outline-none uppercase"
      >
        <ArrowBack />
        назад
      </button>
      <div className="flex flex-col mt-10">
        {isLoading ? <Loader /> : <AddNewSimulation categories={categories} />}
      </div>
    </div>
  );
};

export default NewSimulation;
