import { useEffect, useState } from 'react';
import { Option } from 'entities/Option';

import { fetchCoordinators } from '../api/repository/handbooks';

export const useCatalogCoordinators = () => {
  const [isLoading, setLoading] = useState(false);
  const [coordinators, setCoordinators] = useState<Option[]>([]);

  useEffect(() => {
    setLoading(true);
    fetchCoordinators()
      .then((res) => setCoordinators(res))
      .catch((e) => console.warn(e))
      .finally(() => setLoading(false));
  }, []);

  return {
    isLoading,
    coordinators,
  };
};
