import React from 'react';
import { Outlet } from 'react-router-dom';
import CheckAssignmentTabs from 'modules/coach/components/CheckAssignmentTabs';

const CheckAssignments = () => (
  <div className="flex flex-col pt-6 mt-4 rounded-[12px] px-4">
    <CheckAssignmentTabs />
    <Outlet />
  </div>
);

export default CheckAssignments;
