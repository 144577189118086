import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { ReactComponent as DropdownIcon } from 'assets/icons/chevron-down.svg';
import LMSTitle from 'components/LMSTitle';
import { INewLesson } from 'modules/coach/entities/Lessons';

export interface IProps {
  data: INewLesson;
}

const LessonCard: React.FC<IProps> = ({ data }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  // const toggleDropdown = () => {
  //   setIsOpen((prevState) => !prevState);
  // };

  return (
    <NavLink to={`/coach/lessons/lesson/${data.id}`} className="w-full h-fit">
      <div key={data.id} className="h-fit w-full rounded-[20px] bg-[#58DB66]">
        <div
          className={`${
            isOpen ? 'mr-0' : 'mr-[2px]'
          } h-full flex flex-col theme-background-homework-card rounded-[18px]`}
        >
          <div className="flex flex-row items-center p-4">
            <LMSTitle
              tag="h4"
              className="theme-text--default text-[15px] leading-[20px] font-bold xl:text-[20px] xl:leading-[22px] overflow-hidden"
            >
              {data.name}
            </LMSTitle>

            {/* <div className="flex flex-row items-center gap-x-3 ml-auto xl:gap-x-5">
            <div className="flex flex-row items-center gap-x-2 py-[4px] px-[16px] text-white rounded-[43px] bg-white bg-opacity-20 pointer-events-none xl:px-[25px] xl:py-2">
              <div className="h-1 w-1 rounded-full bg-[#58DB66] filter shadow-[#58DB66]"></div>
              {data.name}
            </div> */}
            {/* <DropdownIcon
              onClick={() => toggleDropdown()}
              className={`${
                isOpen ? 'rotate-180 ease-out duration-700' : 'rotate-360 ease-in duration-500'
              } text-white cursor-pointer`}
            /> */}
            {/* </div> */}
          </div>
          {/* На будущее */}
          {/* {isOpen && (
          <table className="table-auto w-full">
            <thead className="bg-white bg-opacity-30">
              <tr className="h-10 text-white text-lg">
                <th className="w-[50%] text-left pl-8">Группа</th>
                <th className="w-[40%] text-left">Начало обучения</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data?.groups &&
                data?.groups?.map((el) => {
                  return (
                    <tr
                      key={el.id}
                      className="border-b border-b-white border-opacity-10 last-of-type:border-none"
                    >
                      <td className="py-5 pl-8 pr-10 text-white text-base font-medium xl:text-xl">
                        {el.name}
                      </td>
                      <td className="py-5 text-white text-[16px] font-normal xl:text-xl">
                        {String(el.dateFrom)}
                      </td>
                      <td className="hidden pr-8 py-5 xl:block">
                        <button
                          onClick={() => redirectOnGroup(el.id)}
                          className="w-fit ml-auto px-4 py-2 text-black text-[14px] font-medium bg-white rounded-[55px]"
                        >
                          Перейти
                        </button>
                      </td>
                      <td className="xl:hidden">
                        <DropdownIcon
                          onClick={() => redirectOnGroup(el.id)}
                          className="text-white cursor-pointer -rotate-90"
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        )} */}
        </div>
      </div>
    </NavLink>
  );
};

export default LessonCard;
