import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as DropdownIcon } from 'assets/icons/chevron-down.svg';
import { ReactComponent as EmptyIcon } from 'assets/icons/empty-image.svg';
import LMSButton from 'components/LMSButton';
import LMSTitle from 'components/LMSTitle';
import { INewAllHomeWork } from 'modules/coach/entities/HomeWorks';

export interface IProps {
  data: INewAllHomeWork;
}

const CheckHomeWorkCard: React.FC<IProps> = ({ data }) => {
  const navigate = useNavigate();

  const redirectToHomework = () => {
    navigate(
      `/coach/homeworks/hometask/${data.lesson_id}?student_id=${data.student_id}&calendar_id=${data.calendar_id}`
    );
  };

  return (
    <section key={data.id} className="h-fit w-full rounded-[20px] bg-[#58DB66] relative">
      <div className="h-full flex flex-col bg-homeworkgradient rounded-[18px]">
        <div className="flex flex-row items-center p-4 xl:p-8">
          <LMSTitle
            tag="h4"
            className="text-white text-[15px] leading-[20px] font-bold xl:text-[26px] xl:leading-[28px] overflow-hidden"
          >
            Студент: {data.student_name} <br />
            Домашнее задание: {data.name}
          </LMSTitle>

          <div className="flex flex-row items-center gap-x-3 ml-auto xl:gap-x-5">
            <LMSButton
              onClick={redirectToHomework}
              className="flex flex-row items-center gap-x-2 py-[4px] px-[16px] text-white rounded-[43px] bg-white bg-opacity-20 xl:px-[25px] xl:py-2 cursor-pointer"
            >
              Проверить
            </LMSButton>
          </div>
        </div>
        <div className="hidden xl:flex bg-transparent text-white text-[15px] leading-[20px] absolute top-[-30px] left-[10px] flex-col p-1 bg-qrGradient rounded-md">
          <span>Курс - {data.module_name}</span>
          <span>Модуль - {data.module_name}</span>
        </div>
      </div>
    </section>
  );
};

export default CheckHomeWorkCard;
