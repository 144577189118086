import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as AddIcon } from 'assets/icons/add.svg';
import { deleteModule } from 'modules/coach/api/repository/courses/modules';
import { deleteLesson } from 'modules/coach/api/repository/delete-lesson';
import { IModules } from 'modules/coach/entities/coach-courses/Modules';
import { useConfirmBox } from 'hooks/confirm-box';
import { useNotification } from 'hooks/notification';

import EditModuleModal from '../EditModuleModal';

import ButtonGroup from './ButtonGroup';

type Props = {
  modules: IModules[];
  onFetchCourseModules: () => void;
};

const Modules: FC<Props> = (props) => {
  const navigate = useNavigate();
  const notify = useNotification();
  const { showConfirmBox } = useConfirmBox();

  const [isShowEditModuleModal, setIsShowEditModuleModal] = useState<boolean>(false);
  const [editModule, setEditModule] = useState<IModules | null>(null);
  const [openModules, setOpenModules] = useState<{ [key: number]: boolean }>({});

  const isFirstItem = (index: number) => index === 0;

  const handleModulesDropdown = (id: number) => {
    setOpenModules((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const onDeleteModule = (moduleId: number) => {
    showConfirmBox({
      message: 'Вы уверены, что хотите удалить модуль?',
      okText: 'Да',
      cancelText: 'Нет',
      cancelVariant: 'red',
      onAccept: () => {
        deleteModule(moduleId)
          .then(() => {
            props.onFetchCourseModules();
            notify.success('Модуль успешно удален');
          })
          .catch(() => notify.error('Произошла ошибка. Обратитесь в службу поддержки'));
      },
    });
  };

  const onDeleteLesson = (lessonId: number) => {
    showConfirmBox({
      message: 'Вы уверены, что хотите удалить урок?',
      okText: 'Да',
      cancelText: 'Нет',
      cancelVariant: 'red',
      onAccept: () => {
        deleteLesson(lessonId)
          .then(() => {
            props.onFetchCourseModules();
            notify.success('Урок успешно удален');
          })
          .catch(() => notify.error('Произошла ошибка. Обратитесь в службу поддержки'));
      },
    });
  };

  return (
    <div className="w-full h-full flex flex-col">
      {props.modules &&
        props.modules.map((el, idx) => (
          <div key={el.id} className="flex flex-col">
            <div
              className={`w-full h-fit flex flex-row justify-between items-center p-6 rounded-xl theme-coach-background-courses ${
                openModules[el.id] ? 'rounded-b-none' : ''
              } ${isFirstItem(idx) ? 'first:rounded-tl-none' : ''}`}
            >
              <div className="flex flex-col gap-3">
                <span className="text-[#26A7EF] text-xs">Модуль {idx + 1}</span>
                <span className="theme-text--default text-base font-semibold">{el.title}</span>
              </div>

              <ButtonGroup
                isOpenDropdown={openModules[el.id] || false}
                onDropdownClick={() => handleModulesDropdown(el.id)}
                onTrashClick={() => onDeleteModule(el.id)}
                onEditClick={() => {
                  setEditModule(el);
                  setIsShowEditModuleModal(true);
                }}
              />
            </div>
            {openModules[el.id] && (
              <div className="bg-transparent border-l border-r">
                {el.lessons.map((lesson, idx) => (
                  <div
                    key={lesson.id}
                    className="flex flex-row justify-between items-center p-6 border-b"
                  >
                    <div className="flex flex-col gap-1">
                      <span className="theme-text--default text-xs">Урок {idx + 1}</span>
                      <span className="theme-text--default text-base font-semibold">
                        {lesson.name}
                      </span>
                    </div>

                    <ButtonGroup
                      showDropdown={false}
                      onTrashClick={() => onDeleteLesson(lesson.id)}
                      onEditClick={() => navigate(`/coach/lessons/lesson/edit/${lesson.id}`)}
                    />
                  </div>
                ))}
              </div>
            )}
            <div
              className={`${
                openModules[el.id] ? 'flex' : 'hidden'
              } w-full p-6 border-b border-l border-r rounded-b-xl`}
            >
              <button
                type="button"
                className="w-fit h-fit flex flex-row items-center gap-3 ml-auto px-5 py-3 text-base border theme-outline-button rounded-full"
                onClick={() =>
                  navigate(`/coach/lessons/new-lesson?courseId=${el.courseId}&moduleId=${el.id}`)
                }
              >
                <AddIcon />
                Создать
              </button>
            </div>
          </div>
        ))}

      {isShowEditModuleModal && editModule ? (
        <EditModuleModal
          moduleId={editModule.id}
          initialValues={{
            course_id: editModule.courseId,
            title: editModule.title,
          }}
          onClose={() => {
            setEditModule(null);
            setIsShowEditModuleModal(false);
          }}
          onCallback={props.onFetchCourseModules}
        />
      ) : null}
    </div>
  );
};

export default Modules;
