import React, { useEffect, useState } from 'react';

interface Props {
  image?: string | null;
  alt?: string;
}

const Avatar: React.FC<Props> = ({
  image = require('assets/images/empty-avatar.png'),
  alt = 'аватар',
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [opacity, setOpacity] = useState(0);

  const onLoadImage = () => {
    setIsLoading(false);
    setOpacity(1);
  };

  const errorImage = () => {
    setIsLoading(false);
    setOpacity(0);
  };

  useEffect(() => {
    const imgElement = new Image();
    imgElement.src = image;
    imgElement.onload = onLoadImage;
    imgElement.onerror = errorImage;
  }, [image]);

  return (
    <div className="w-full h-full overflow-hidden rounded-full bg-background-avatar relative">
      {image && isLoading && (
        <div className="bg-blue-100 w-full h-full flex items-center justify-center relative">
          <div className="animate-spin">
            <img
              src={require('assets/images/loader-blue.png')}
              alt="загрузка"
              className="loading w-full h-full"
            />
          </div>
        </div>
      )}

      {(image || !image) && !isLoading && (
        <img
          src={image}
          alt={alt}
          style={{ opacity }}
          onLoad={onLoadImage}
          onError={errorImage}
          loading="lazy"
          className={`object-cover w-full h-full transition-opacity ${!opacity && 'empty-image'}`}
        />
      )}
    </div>
  );
};

export default Avatar;
