import React, { useMemo, useState } from 'react';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';
import LMSButton from 'components/LMSButton';
import LMSInput from 'components/LMSInput';
import { useOutsideClick } from 'hooks/outside-click';
import { useWindowSize } from 'hooks/window-size';

const CheckedHomeworksFilter = () => {
  const device = useWindowSize();

  const [isOpen, setOpen] = useState(false);
  const [course, setCourse] = useState<string>('');
  const [student, setStudent] = useState<string>('');
  const [group, setGroup] = useState<string>('');
  const [name, setName] = useState<string>('');

  const filterRef = useOutsideClick(() => {
    setOpen(false);
  });

  const filterClass = useMemo(() => {
    if (device === 'desktop') {
      return 'relative w-full h-fit flex flex-col';
    }

    if (!isOpen) return 'hidden';

    return 'fixed bottom-0 right-0 left-0 top-24 w-full bg-[#3D436B] px-8 py-5 border border-solid border-[#3D436B] rounded-t-[20px] overflow-auto z-10';
  }, [device, isOpen]);

  const onToggleOpen = () => {
    if (isOpen) {
      document.body.classList.remove('modal-open');
    } else {
      document.body.classList.add('modal-open');
    }
    setOpen((prev) => !prev);
  };

  const resetFilters = () => {
    setCourse('');
    setStudent('');
    setGroup('');
    setName('');
    onToggleOpen();
  };

  const submitFilters = () => {
    onToggleOpen();
  };

  return (
    <div>
      <button
        className="flex lg:hidden items-center justify-center w-full p-2 space-x-2 border text-current border-white border-opacity-30 rounded-xl"
        onClick={onToggleOpen}
      >
        <FilterIcon />
        <span>Фильтр</span>
      </button>

      <div ref={filterRef} className={filterClass}>
        <div className="w-full flex flex-col gap-2 md:flex-row md:flex-wrap">
          <div className="w-full md:w-fit md:max-w-[290px]">
            <LMSInput
              name="student"
              placeholder="ФИО"
              variant="rounded"
              value={student}
              onChange={(e: string) => setStudent(e)}
            />
          </div>
          <div className="w-full md:w-fit md:max-w-[290px]">
            <LMSInput
              name="course"
              placeholder="Курс"
              variant="rounded"
              value={course}
              onChange={(e: string) => setCourse(e)}
            />
          </div>
          <div className="w-full md:w-fit md:max-w-[290px]">
            <LMSInput
              name="group"
              placeholder="Группа"
              variant="rounded"
              value={group}
              onChange={(e: string) => setGroup(e)}
            />
          </div>
          <div className="w-full md:w-fit md:max-w-[290px]">
            <LMSInput
              name="name"
              placeholder="Название теста"
              variant="rounded"
              value={name}
              onChange={(e: string) => setName(e)}
            />
          </div>
        </div>
        <div className="w-full flex flex-col gap-4 mt-4 md:flex-row">
          {device === 'desktop' ? (
            <>
              <button
                type="button"
                className="w-fit flex-row gap-x-2 text-current md:flex"
                onClick={submitFilters}
              >
                &#10003; <span className="underline underline-offset-4">Применить</span>
              </button>
              <button
                type="button"
                className="w-fit flex-row gap-x-2 text-current md:flex"
                onClick={resetFilters}
              >
                x <span className="underline underline-offset-4">Сбросить фильтры</span>
              </button>
            </>
          ) : (
            <>
              <LMSButton className="w-full mt-5" colorType="light" onClick={submitFilters}>
                Применить
              </LMSButton>
              <LMSButton
                className="w-full"
                variant="text"
                prefix={<CloseIcon className="w-5 h-5" />}
                onClick={resetFilters}
              >
                Сбросить
              </LMSButton>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CheckedHomeworksFilter;
