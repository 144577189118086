import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import ConfirmBoxProvider from 'components/ConfirmBox';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { setupStore } from 'store';

import App from './App';

import 'react-toastify/dist/ReactToastify.css';
import 'assets/scss/main.scss';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const store = setupStore();
const persistor = persistStore(store);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConfirmBoxProvider>
        <App />
      </ConfirmBoxProvider>
    </PersistGate>
    <ToastContainer />
  </Provider>
);
