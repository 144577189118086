import React from 'react';
import { ReactComponent as DocumentIcon } from 'assets/icons/document-green.svg';
import Accordion from 'components/Accordion';
import { UserDetail } from 'modules/hr/entities/User';

interface Props {
  user: UserDetail;
}

const UserDocuments: React.FC<Props> = ({ user }) => (
  <Accordion title="Документы">
    <div className="flex flex-col space-y-3">
      {user.iin && (
        <div className="flex flex-row rounded-2xl bg-white/10 p-5">
          <p className="w-1/2 text-white">ИИН/БИН:</p>
          <p className="w-1/2 text-white font-medium truncate">{user.iin}</p>
        </div>
      )}

      {user.document && (
        <div className="flex flex-row rounded-2xl bg-white/10 p-5">
          <p className="w-1/2 text-white">Удостоверение/Паспорт:</p>
          <div className="w-1/2 flex flex-col space-y-2">
            {user.document.map((document, index) => (
              <a
                key={index}
                href={document.download_link}
                className="flex flex-row items-center space-x-4 text-green font-medium"
                download
              >
                <DocumentIcon /> <span className="truncate">{document.original_name}</span>
              </a>
            ))}
          </div>
        </div>
      )}

      {user.resume && (
        <div className="flex flex-row rounded-2xl bg-white/10 p-5">
          <p className="w-1/2 text-white">Резюме:</p>
          <div className="w-1/2 flex flex-col space-y-2">
            {user.resume.map((resume, index) => (
              <a
                key={index}
                href={resume.download_link}
                className="flex flex-row items-center space-x-4 text-green font-medium"
                download
              >
                <DocumentIcon /> <span className="truncate">{resume.original_name}</span>
              </a>
            ))}
          </div>
        </div>
      )}

      {user.requisites && (
        <div className="flex flex-row rounded-2xl bg-white/10 p-5">
          <p className="w-1/2 text-white">Реквизиты:</p>
          <div className="w-1/2 flex flex-col space-y-2">
            {user.requisites.map((requisites, index) => (
              <a
                key={index}
                href={requisites.download_link}
                className="flex flex-row items-center space-x-4 text-green font-medium"
                download
              >
                <DocumentIcon /> <span className="truncate">{requisites.original_name}</span>
              </a>
            ))}
          </div>
        </div>
      )}

      {user.agreementFiles && (
        <div className="flex flex-row rounded-2xl bg-white/10 p-5">
          <p className="w-1/2 text-white">Договоры:</p>
          <div className="w-1/2 flex flex-col space-y-2">
            {user.agreementFiles.map((file, index) => (
              <a
                key={index}
                href={file.download_link}
                className="flex flex-row items-center space-x-4 text-green font-medium"
                download
              >
                <DocumentIcon /> <span className="truncate">{file.original_name}</span>
              </a>
            ))}
          </div>
        </div>
      )}
    </div>
  </Accordion>
);

export default UserDocuments;
