import { useSearchParams } from 'react-router-dom';
import { usePartialState } from 'hooks/partial-state';

interface Filter {
  category: number | null;
  subcategory: number | null;
  author: number | null;
  publisher: number | null;
}

export const useLibraryFilter = () => {
  const [query, setQuery] = useSearchParams();

  const category = query.get('category');
  const subcategory = query.get('subcategory');
  const author = query.get('author');
  const publisher = query.get('publisher');

  const [filter, setFilter] = usePartialState<Filter>({
    category: category ? +category : null,
    subcategory: subcategory ? +subcategory : null,
    author: author ? +author : null,
    publisher: publisher ? +publisher : null,
  });

  const onSubmit = () => {
    Object.entries(filter).forEach(([key, value]) => {
      if (value) {
        query.set(key, value.toString());
      } else {
        query.delete(key);
      }
    });

    query.set('page', '1');

    setQuery(query);
  };

  const onReset = () => {
    setFilter({
      category: null,
      subcategory: null,
      author: null,
      publisher: null,
    });

    Object.entries(filter).forEach(([key]) => {
      query.delete(key);
    });

    query.set('page', '1');

    setQuery(query);
  };

  return {
    filter,
    setFilter,
    onSubmit,
    onReset,
  };
};
