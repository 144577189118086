import request from 'api/axios-instance';

import { IHomeWorkMark } from '../dao/HomeWork.dao';

export const fetchStudentHomeWorkMark = (lessonId: number, courseId: number) =>
  request.get(`v1/courses/${courseId}/lessons/${lessonId}/mark`).then((res) => {
    const homeWorkMark: IHomeWorkMark = res.data;

    return homeWorkMark;
  });
