import { useState } from 'react';
import { uploadFiles } from 'modules/coach/api/repository/upload-file';
import { useNotification } from 'hooks/notification';

type TFile = {
  file: File;
  path: string;
};

const useNewFileUploader = () => {
  const [uploadedFilePaths, setUploadedFilePaths] = useState<TFile[]>([]);
  const notify = useNotification();

  const upload = async (files: File[]) => {
    try {
      const uploadedFiles = [];
      const alreadyUploaded = uploadedFilePaths.map((f) => f.file.name);

      for (const file of files) {
        const form = new FormData();
        if (alreadyUploaded.includes(file.name)) continue;

        form.append('file', file);
        const res = await uploadFiles(form);
        uploadedFiles.push({ file, path: res });
      }

      setUploadedFilePaths([...uploadedFilePaths, ...uploadedFiles]);
      return uploadedFiles;
    } catch (error) {
      notify.error('Не удалось загрузить файлы, попробуйте снова');
      throw error;
    }
  };

  return { uploadedFilePaths, upload };
};

export default useNewFileUploader;
