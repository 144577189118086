import React from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { ReactComponent as DragIcon } from 'assets/icons/drag.svg';
import { ReactComponent as EditIcon } from 'assets/icons/pen.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';

import { ISimulationQuestion } from '../../entities/Simulation';

interface Props {
  dragProps: DraggableProvidedDragHandleProps | null | undefined;
  question: ISimulationQuestion;
  onEdit: () => void;
  onDelete: () => void;
}

const SimulationQuestionCard: React.FC<Props> = ({ dragProps, question, onEdit, onDelete }) => (
  <div className="mb-7 rounded-2xl theme-student-card">
    <div className="relative flex flex-col md:flex-row md:items-center md:space-x-3 space-y-4 md:space-y-0 p-4 md:p-7">
      <div className="flex items-center space-x-4 pr-5 md:border-r border-white/10">
        <button {...dragProps}>
          <DragIcon />
        </button>
        <span className="flex items-center justify-center shrink-0 w-8 h-8 rounded-full bg-white border theme-border--active text-black text-sm">
          {question.position}
        </span>
      </div>
      <div className="flex items-center flex-1 space-x-5">
        <div className="flex-1 flex items-center justify-between space-x-3">
          <p className="text-base md:text-xl">{question.title}</p>

          <div className="absolute top-3 left-20 md:static flex items-center space-x-3">
            <button
              onClick={onEdit}
              className="flex items-center justify-center w-10 h-10 rounded bg-white/20"
            >
              <EditIcon className="w-6 h-6" />
            </button>
            <button
              className="flex items-center justify-center w-10 h-10 rounded bg-white/20"
              onClick={onDelete}
            >
              <TrashIcon className="w-5 h-5" />
            </button>
          </div>
        </div>
      </div>
    </div>
    {Array.isArray(question.answers) ? (
      <div className="px-4 md:px-7 py-5 border-t border-white/20">
        {question.answers.map((answer, index) => (
          <div key={index} className="flex items-center justify-between py-2">
            <div className="flex-1">{answer.name}</div>
            <button
              className={`flex items-center justify-center shrink-0 w-6 h-6 rounded ${
                answer.isCorrect ? 'bg-[#58DB66]' : 'bg-white/10'
              }`}
            >
              <CheckIcon className="w-2.5 h-2.5" />
            </button>
          </div>
        ))}
      </div>
    ) : (
      <div className="px-4 md:px-7 py-5 border-t border-white/20">
        <div className="flex items-center justify-between py-2">
          <div className="flex-1">{question.answers.name}</div>
          <button
            className={`flex items-center justify-center shrink-0 w-6 h-6 rounded ${
              question.answers.isCorrect ? 'bg-[#58DB66]' : 'bg-white/10'
            }`}
          >
            <CheckIcon className="w-2.5 h-2.5" />
          </button>
        </div>
      </div>
    )}
  </div>
);

export default SimulationQuestionCard;
