import React, { forwardRef, useMemo, useState } from 'react';

interface Props {
  name: string;
  label?: string;
  value: string;
  error?: string;
  placeholder?: string;
  variant?: 'default' | 'rounded';
  type?: 'text' | 'tel' | 'number' | 'password';
  disabled?: boolean;
  required?: boolean;
  helperText?: string;
  maxLength?: number;
  onChange: (value: string) => void;
  onBlur?: () => void;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  className?: string;
  showPlaceholder?: boolean;
}

const LMSInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      name,
      label = '',
      value,
      error,
      variant = 'default',
      type = 'text',
      placeholder = '',
      disabled = false,
      required = false,
      helperText = '',
      maxLength = 0,
      onChange,
      onBlur = () => {},
      prefix,
      suffix,
      className,
      showPlaceholder = false,
    },
    ref
  ) => {
    const [isFocused, setFocus] = useState(false);

    const labelTop = useMemo(() => {
      if (isFocused || value) return '14px';
      return '50%';
    }, [isFocused, value]);

    const onInputBlur = () => {
      setFocus(() => !!value);
      onBlur();
    };

    const onInputChange = (val: string) => {
      if (type === 'number') {
        val = val.replace(/\D/g, '');
      }

      if (!!maxLength && val.length >= maxLength) {
        onChange(val.substring(0, maxLength));
      } else {
        onChange(val);
      }
    };

    return (
      <div className={`bg-transparent ${label ? 'pt-1.5' : ''}`}>
        <div className={`max-height-[46px] relative flex items-center`}>
          {label ? (
            <p
              className={`absolute left-4 px-1 text-sm text-grey translate-y-[-50%] pointer-events-none transition-all duration-500`}
              style={{ top: labelTop }}
            >
              {label}
              {required ? <span>*</span> : null}
            </p>
          ) : null}

          <div
            className={`w-full flex items-center justify-between gap-1 bg-inputbg border border-opacity-30 ${
              variant === 'rounded' ? 'rounded-[55px] py-3 px-6' : 'rounded-[14px] pt-6 pb-2 px-4'
            } ${error ? 'theme-input--error' : 'theme-input'}`}
          >
            {prefix && variant === 'default' ? prefix : null}
            <input
              ref={ref}
              id={name}
              type={type === 'number' ? 'text' : type}
              disabled={disabled}
              value={value}
              className={`w-full text-current outline-none bg-transparent disabled:cursor-not-allowed ${
                variant === 'default' && 'placeholder:text-sm'
              } ${className}`}
              placeholder={`${
                showPlaceholder || isFocused || variant === 'rounded' ? placeholder : ''
              }`}
              onChange={(event) => onInputChange(event.target.value)}
              onBlur={onInputBlur}
              onFocus={() => setFocus(true)}
            />
            {suffix ? suffix : null}
          </div>
        </div>

        {!!error || helperText ? (
          <div className="flex items-center justify-between mt-1 px-4">
            {!!error ? (
              <p className="pt-1 text-[red]">{error}</p>
            ) : (
              <p className="text-[#A9B5BB]">{helperText}</p>
            )}
            {!!maxLength && helperText ? (
              <p className="text-[#A9B5BB]">{`${value.length}/${maxLength}`}</p>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
);

export default LMSInput;
