import React from 'react';
import LMSButton from 'components/LMSButton';

interface Props {
  title: string;
  desc?: string;
  children?: React.ReactNode;
  buttonLabel?: string;
  nextStep: () => void;
}

const MathVortexWelcome: React.FC<Props> = ({
  title,
  desc,
  children,
  buttonLabel = 'Начать',
  nextStep,
}) => {
  return (
    <div className="flex flex-col space-y-14 items-center justify-center w-[80%] max-w-[960px] h-max rounded-[20px] theme-student-card m-auto p-10">
      <div className="flex flex-col space-y-6 items-center">
        <p className="text-[32px] font-semibold text-center leading-10 max-w-[512px]">{title}</p>
        {desc && <p className="text-[22px] text-center leading-6 max-w-[700px]">{desc}</p>}
        {children}
      </div>
      <LMSButton onClick={nextStep}>{buttonLabel}</LMSButton>
    </div>
  );
};

export default MathVortexWelcome;
