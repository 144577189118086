import React, { useEffect, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
// import { ReactComponent as CTE } from 'assets/icons/CTE-icon.svg';
// import LangSelect from 'components/LangSelect';
// import i18n from 'i18next';
import DropdownMenu from 'modules/coach/components/DropdownMenu';
import { useOutsideClick } from 'hooks/outside-click';

// const langs = [
//   {
//     id: 1,
//     title: 'ru',
//   },
//   {
//     id: 2,
//     title: 'kk',
//   },
//   {
//     id: 3,
//     title: 'en',
//   },
// ];

const LandingHeader = () => {
  // const { t } = useTranslation();
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(false);
  const [isAnimation, setStartAnimation] = useState(false);

  // const [selected, setSelected] = useState('ru');

  // const changeLanguage = (title: string): void => {
  //   if (title) {
  //     i18n.changeLanguage(title);
  //     setSelected(title);
  //     console.log('i18n', i18n);
  //   }
  // };

  // const scrollToElement = (id: string) => {
  //   const element = document.getElementById(id);
  //   if (element) {
  //     window.scrollTo({
  //       top: element.offsetTop - 100,
  //       behavior: 'smooth',
  //     });
  //   }
  //   if (isOpen) {
  //     setIsOpen(false);
  //   }
  // };

  const menuRef = useOutsideClick(() => {
    setStartAnimation(true);

    setTimeout(() => {
      setIsOpen(false);
      setStartAnimation(false);
    }, 280);
  });

  useEffect(() => {
    if (isOpen) {
      setIsOpen(false);
    }
  }, [location]);

  return (
    <div className="w-full h-[80px] theme-landing-bg sticky top-0 relative z-50">
      <div className="flex flex-row h-full justify-between max-w-[1400px] mx-auto items-center gap-6 px-4">
        <a href="/" className="max-w-[190px] h-auto text-current text-3xl font-semibold">
          <img
            src={require('assets/images/logo.webp')}
            alt="logo"
            className="w-full h-full object-contain object-center"
          />
        </a>

        {/* <nav className="hidden py-4 px-6 border rounded-[68px] theme-landing-border xl:block">
          <ul className="flex flex-row  justify-between max-w-[800px] w-full gap-8">
            <li>
              <button onClick={() => scrollToElement('hero')} className="text-current capitalize">
                {t('landing.nav.home')}
              </button>
            </li>
            <li>
              <button onClick={() => scrollToElement('clients')} className="text-current">
                {t('landing.nav.about')}
                Наши клиенты
              </button>
            </li>
            <li>
              <button onClick={() => scrollToElement('about')} className="text-current">
                {t('landing.nav.aboutPlatform')}
              </button>
            </li>
            <li>
              <button onClick={() => scrollToElement('apps')} className="text-current capitalize">
                {t('landing.nav.apps')}
              </button>
            </li>
            <li>
              <button
                onClick={() => scrollToElement('analytics')}
                className="text-current capitalize"
              >
                {t('landing.nav.analytics')}
              </button>
            </li>
            <li>
              <button
                onClick={() => scrollToElement('interface')}
                className="text-current capitalize"
              >
                {t('landing.nav.interface')}
              </button>
            </li>
          </ul>
        </nav> */}

        <div className="hidden xl:flex flex-row gap-2 items-center">
          {/* <button className=" text-white py-3 px-6 border-2 capitalize border-white rounded-[300px]">
            {t('landing.actions.сooperation')}
          </button>
          <button className="text-white py-3 px-6 border-2 capitalize border-white rounded-[300px]">
            {t('landing.actions.try')}
          </button>
          <LangSelect setSelected={changeLanguage} options={langs} position="left">
            <div className="flex justify-center items-center  bg-white/20 rounded-full w-full h-full">
              <span className="text-white">{selected}</span>
            </div>
          </LangSelect> */}
          <a
            href="https://wa.me/+77789612513"
            target="_blank"
            className="text-current py-3 px-6 border-2 max-w-[200px] theme-landing-border rounded-[300px]"
            rel="noreferrer"
          >
            Связаться с нами
          </a>
          <a
            href="/auth/login"
            className="text-current py-3 px-6 border-2 max-w-[200px] theme-landing-border rounded-[300px]"
            rel="noreferrer"
          >
            Войти
          </a>
        </div>

        <DropdownMenu size={'xl'} isOpen={isOpen} toggleDropdown={() => setIsOpen(!isOpen)} />

        {isOpen && (
          <div
            ref={menuRef}
            className={`fixed py-10 inset-y-0 right-0 theme-landing-bg w-[300px] border-l theme-landing-border flex flex-col justify-between gap-10 h-full ${
              isAnimation ? 'animate-linear-hide' : 'animate-linear-show'
            }`}
          >
            {/* <nav className="py-4 px-6 flex justify-between gap-6">
              <ul className="flex flex-col justify-between max-w-[800px] w-full gap-8">
                <li>
                  <button
                    onClick={() => scrollToElement('hero')}
                    className="text-current capitalize"
                  >
                    {t('landing.nav.home')}
                  </button>
                </li>
                <li>
                  <button onClick={() => scrollToElement('clients')} className="text-current">
                    {t('landing.nav.about')}
                    Наши клиенты
                  </button>
                </li>
                <li>
                  <button onClick={() => scrollToElement('about')} className="text-current">
                    {t('landing.nav.aboutPlatform')}
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => scrollToElement('apps')}
                    className="text-current capitalize"
                  >
                    {t('landing.nav.apps')}
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => scrollToElement('analytics')}
                    className="text-white capitalize"
                  >
                    {t('landing.nav.analytics')}
                  </button>
                </li>
              </ul> */}

            {/* <LangSelect setSelected={changeLanguage} options={langs} position="left">
                <div className="flex justify-center items-center  bg-white/20 rounded-full w-full h-full">
                  <span className="text-white">{selected}</span>
                </div>
              </LangSelect> */}
            {/* </nav> */}

            <div className="mt-10 flex flex-col gap-4 pl-4">
              {/* <button className="text-white py-3 px-6 border-2 max-w-[200px] capitalize theme-landing-border rounded-[300px]">
                {t('landing.actions.сooperation')}
              </button>
              <button className="text-white py-3 px-6 border-2 max-w-[200px] capitalize theme-landing-border rounded-[300px]">
                {t('landing.actions.try')}
              </button> */}

              <a
                href="https://wa.me/+77789612513"
                className="text-current py-3 px-6 border-2 max-w-[200px] theme-landing-border rounded-[300px]"
              >
                Связаться с нами
              </a>
              <a
                href="/auth/login"
                className="text-current py-3 px-6 border-2 max-w-[200px] theme-landing-border rounded-[300px]"
                rel="noreferrer"
              >
                Войти
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LandingHeader;
