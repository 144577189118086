import React, { useEffect, useState } from 'react';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/chevron-down.svg';
import { ReactComponent as ArrowUpIcon } from 'assets/icons/chevron-up.svg';
import { useWindowSize } from 'hooks/window-size';

import { fetchActiveCourseScore } from '../../api/repository/my-courses';
import { CourseScore } from '../../entities/CourseScore';
import Loading from '../Loading';

interface Props {
  id: number;
  groupId: string;
}

const CourseScores: React.FC<Props> = ({ id, groupId }) => {
  const device = useWindowSize();
  const [isExpanded, setExpanded] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [scores, setScores] = useState<CourseScore[]>([]);

  useEffect(() => {
    fetchActiveCourseScore(+id, +groupId).then((res) => {
      setScores(res);
      setLoading(false);
      if (res.length) setExpanded(true);
    });
  }, []);

  return (
    <div className="w-full mt-5 rounded-2xl theme-student-card">
      <button
        className="flex items-center justify-between w-full p-4 sm:p-8"
        onClick={() => setExpanded((prev) => !prev)}
      >
        <span className="text-xl sm:text-2xl">Моя успеваемость</span>
        {isExpanded ? <ArrowUpIcon /> : <ArrowDownIcon />}
      </button>
      {!scores.length && isLoading ? (
        <div className="p-6">
          <Loading theme="dark" />
        </div>
      ) : null}
      {!scores.length && !isLoading && isExpanded ? (
        <div className="p-6">
          <p className="text-current text-center">Нет данных</p>
        </div>
      ) : null}
      {scores.length && isExpanded ? (
        <div className="text-sm">
          <div className="hidden sm:grid grid-cols-[50px_2.5fr_1fr_1fr_1fr] gap-3 items-center px-8 py-4 theme-light-text border-b theme-border--grey uppercase">
            <span className="text-center">УРОК</span>
            <span>НАЗВАНИЕ</span>
            <span className="text-center">Д/З</span>
            <span className="text-center">Тесты</span>
            <span className="text-center overflow-hidden">Посещаемость</span>
          </div>
          {device === 'phone'
            ? scores.map((score) => (
                <div key={score.lessonId} className="py-[14px] px-4 border-t theme-border--grey">
                  <div>
                    <p className="text-xs theme-text--active">Урок {score.number}</p>
                    <p className="text-lg">{score.lessonName}</p>
                  </div>
                  <div className="grid grid-cols-3 mt-[18px] text-sm">
                    <div>
                      <p className="theme-light-text">Д/З</p>
                      <p>{score.homeworkMark || 0} из 100</p>
                    </div>
                    <div>
                      <p className="theme-light-text">Тесты</p>
                      <p>{score.testScore || 0} из 100</p>
                    </div>
                    <div>
                      <p className="theme-light-text">Посещаемость</p>
                      <p>{score.attendance || 'Не посетил(-а)'}</p>
                    </div>
                  </div>
                </div>
              ))
            : scores.map((score) => (
                <div
                  key={score.lessonId}
                  className="grid grid-cols-[50px_2.5fr_1fr_1fr_1fr] gap-3 items-center px-8 py-6"
                >
                  <span className="text-center">{score.number}</span>
                  <span className="overflow-hidden">{score.lessonName}</span>
                  <span className="text-center">{score.homeworkMark || 0} из 100</span>
                  <span className="text-center">{score.testScore || 0} из 100</span>
                  <span className="text-center">{score.attendance || 'Не посетил(-а)'}</span>
                </div>
              ))}
        </div>
      ) : null}
    </div>
  );
};

export default CourseScores;
