import React, { useMemo, useState } from 'react';
import { ReactComponent as PlusIcon } from 'assets/icons/add.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import BaseModal from 'components/BaseModal';
import LMSButton from 'components/LMSButton';
import LMSTitle from 'components/LMSTitle';
import { Option } from 'entities/Option';

interface IUser extends Option {
  role: string;
}

interface Props {
  onClose: () => void;
}

const AddUserBySearchModal: React.FC<Props> = ({ onClose }) => {
  const [search, setSearch] = useState('');
  const [selectedUsers, setSelectedUsers] = useState<IUser[]>([]);

  const users: IUser[] = useMemo(() => {
    const list = [
      {
        role: '1',
        value: 3,
        label: 'Я координатор',
      },
      {
        role: '0',
        value: 5,
        label: 'Ольга Андреевна  Петрова',
      },
      {
        role: '0',
        value: 6,
        label: 'Асель Каратаева Мараткызы',
      },
    ];
    return list.filter((item: IUser) => item.label.toLowerCase().includes(search.toLowerCase()));
  }, [search]);

  const checkUserAdded = (user: IUser) => {
    return !!selectedUsers.find((u: IUser) => u.value === user.value);
  };

  const addUser = (user: IUser) => {
    const isUserAdded = !!selectedUsers.find((u: IUser) => u.value === user.value);
    if (isUserAdded) {
      setSelectedUsers([...selectedUsers].filter((u) => u.value !== user.value));
    } else {
      setSelectedUsers([...selectedUsers, user]);
    }
  };

  const selectAll = () => {
    if (selectedUsers.length === users.length) {
      return setSelectedUsers([]);
    }
    return setSelectedUsers(users);
  };

  return (
    <BaseModal wrapperClassName="sm:!max-w-max" onClose={onClose}>
      <div className="flex flex-col space-y-6 min-w-full lg:min-w-[603px] p-6 lg:p-10">
        <LMSTitle tag="h2">Поиск пользователя</LMSTitle>

        <div className="w-full flex flex-col gap-10">
          <div className="flex flex-col space-y-6">
            <div className="flex flex-col space-x-0 space-y-6 sm:flex-row sm:space-x-6 sm:space-y-0">
              <div className="w-full flex items-center space-x-2 rounded-[14px] py-3 px-6 bg-[#F2F2F2]">
                <input
                  value={search}
                  type="text"
                  className="flex-1 bg-transparent outline-none"
                  placeholder="Имя пользователя"
                  onChange={(ev) => setSearch(ev.target.value)}
                />
                <SearchIcon />
              </div>
              <button
                className="text-blue whitespace-nowrap p-0 m-0 outline-none"
                onClick={() => selectAll()}
              >
                Выбрать всех
              </button>
            </div>

            <div className="flex flex-col h-[412px] w-full overflow-y-auto">
              {users.map((user) => (
                <article
                  key={user.value}
                  className={`flex flex-row p-5 rounded-xl ${
                    checkUserAdded(user) && 'bg-[#F4F9FF]'
                  } space-x-4 transition hover:bg-[#F4F9FF]`}
                >
                  {/* <div className="w-[48px] h-[48px] min-w-[48px]">
                    <Avatar image={user.avatar} alt={user.fullName} />
                  </div> */}
                  <div className="flex flex-col justify-center">
                    <p>
                      <span className="font-semibold">{user.label}</span> &#183;{' '}
                      <span className="opacity-60">{user.role}</span>
                    </p>
                    {/* <p className="opacity-60">{user.email}</p> */}
                  </div>
                  {checkUserAdded(user) ? (
                    <button
                      type="button"
                      className="outline-none min-w-[48px] w-[48px] h-[48px] p-0 m-0 !ml-auto bg-blue rounded-full flex items-center justify-center"
                      onClick={() => addUser(user)}
                    >
                      <CheckIcon />
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="outline-none min-w-[48px] w-[48px] h-[48px] p-0 m-0 !ml-auto bg-[#F2F2F2] rounded-full flex items-center justify-center transition hover:bg-blue"
                      onClick={() => addUser(user)}
                    >
                      <PlusIcon />
                    </button>
                  )}
                </article>
              ))}
            </div>

            <div className="flex flex-row justify-between items-center space-x-6">
              <p>Выбрано: {selectedUsers.length}</p>
              <LMSButton type="button" onClick={() => {}}>
                ПРИГЛАСИТЬ
              </LMSButton>
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

export default AddUserBySearchModal;
