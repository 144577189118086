import { Option } from 'entities/Option';

export const genders: Option[] = [
  {
    value: 1,
    label: 'Мужской',
  },
  {
    value: 2,
    label: 'Женский',
  },
];

export const userTypes: Option[] = [
  {
    value: 1,
    label: 'Внутренний',
  },
  {
    value: 2,
    label: 'Внешний',
  },
];

export const fileTypes = ['png', 'doc', 'pdf', 'jpeg', 'jpg', 'webp'];

export const currencies = [
  {
    value: 1,
    label: 'Тенге',
  },
  {
    value: 2,
    label: 'Рубль',
  },
  {
    value: 3,
    label: 'Доллар',
  },
  {
    value: 4,
    label: 'Евро',
  },
];

export const currencyShortNames: { [key: number]: string } = {
  1: 'kzt',
  2: 'rub',
  3: 'usd',
  4: 'eur',
};
