import request from 'api/axios-instance';

import {
  ICourse,
  ICourseStudent,
  IHomework,
  ILesson,
  IStudentHomeworks,
  IStudentLessons,
  IStudentTests,
  ITest,
} from '../../../entities/coach-courses/Students';
import {
  ICourseDao,
  ICourseStudentDao,
  IHomeworkDao,
  ILessonDao,
  IStudentHomeworksDao,
  IStudentLessonsDao,
  IStudentTestsDao,
  ITestDao,
} from '../../dao/courses/GetStudents.dao';

export const fetchStudents = (groupId: number, courseId: number) =>
  request
    .get(
      `/v2/coach/total-courses/students?by_this_coach=1&group_id=${groupId}&course_id=${courseId}`
    )
    .then((res) => {
      const students: ICourse = mapCourseDaoToEntity(res.data.data);

      return students;
    });

export const fetchStudentLessons = (
  studentId: number,
  groupId: number,
  courseId: number,
  perPage?: number,
  page?: number
) =>
  request
    .get(
      `/v2/coach/total-courses/students/${studentId}?group_id=${groupId}&course_id=${courseId}`,
      { params: { perPage, page } }
    )
    .then((res) => {
      const studentLessons: IStudentLessons = mapStudentLessonsDaoToEntity(res.data.data);

      return { studentLessons, lastPage: (res?.data?.meta?.last_page as number) || 1 };
    });

export const fetchStudentHomeworks = (
  studentId: number,
  groupId: number,
  courseId: number,
  perPage?: number,
  page?: number
) =>
  request
    .get(
      `/v2/coach/total-courses/students/${studentId}/homeworks?group_id=${groupId}&course_id=${courseId}`,
      { params: { perPage, page } }
    )
    .then((res) => {
      const studentHomeworks: IStudentHomeworks = mapStudentHomeworksDaoToEntity(res.data.data);

      return { studentHomeworks, lastPage: (res?.data?.meta?.last_page as number) || 1 };
    });

export const fetchStudentTests = (
  studentId: number,
  groupId: number,
  courseId: number,
  perPage?: number,
  page?: number
) =>
  request
    .get(
      `/v2/coach/total-courses/students/${studentId}/tests?group_id=${groupId}&course_id=${courseId}`,
      { params: { perPage, page } }
    )
    .then((res) => {
      const studentTests: IStudentTests = mapStudentTestsDaoToEntity(res.data.data);

      return { studentTests, lastPage: (res?.data?.meta?.last_page as number) || 1 };
    });

function mapCourseDaoToEntity(courseDao: ICourseDao): ICourse {
  return {
    id: courseDao.id,
    name: courseDao.name,
    image: courseDao.image,
    dateFrom: courseDao.date_from,
    dateTo: courseDao.date_to,
    groupId: courseDao.group_id,
    groupName: courseDao.group_name,
    students: courseDao.students.map(mapCourseStudentDaoToEntity),
  };
}

function mapCourseStudentDaoToEntity(courseStudentDao: ICourseStudentDao): ICourseStudent {
  return {
    id: courseStudentDao.id,
    fullName: courseStudentDao.full_name,
    avatar: courseStudentDao.avatar,
    email: courseStudentDao.email,
    wasOnline: courseStudentDao.was_online,
  };
}

function mapStudentLessonsDaoToEntity(studentLessonsDao: IStudentLessonsDao): IStudentLessons {
  return {
    id: studentLessonsDao.id,
    avatar: studentLessonsDao.avatar,
    fullName: studentLessonsDao.full_name,
    login: studentLessonsDao.login,
    courseId: studentLessonsDao.course_id,
    course: studentLessonsDao.course,
    groupId: studentLessonsDao.group_id,
    group: studentLessonsDao.group,
    lessons: studentLessonsDao.lessons.map(mapLessonsDaoToEntity),
  };
}

function mapLessonsDaoToEntity(lessonDao: ILessonDao): ILesson {
  return {
    id: lessonDao.id,
    calendarId: lessonDao.calendar_id,
    lessonName: lessonDao.lesson_name,
    viewDate: lessonDao.view_date,
    calendarDate: lessonDao.calendar_date,
    isPresent: lessonDao.is_present,
    isPresentExists: lessonDao.is_present_exists,
  };
}

function mapStudentHomeworksDaoToEntity(
  studentHomeworksDao: IStudentHomeworksDao
): IStudentHomeworks {
  return {
    id: studentHomeworksDao.id,
    avatar: studentHomeworksDao.avatar,
    fullName: studentHomeworksDao.full_name,
    login: studentHomeworksDao.login,
    courseId: studentHomeworksDao.course_id,
    course: studentHomeworksDao.course,
    groupId: studentHomeworksDao.group_id,
    group: studentHomeworksDao.group,
    hometasks: studentHomeworksDao.hometasks.map(mapHomeworkDaoToEntity),
  };
}

function mapHomeworkDaoToEntity(homeworkDao: IHomeworkDao): IHomework {
  return {
    id: homeworkDao.id,
    calendarId: homeworkDao.calendar_id,
    lessonId: homeworkDao.lesson_id,
    hometaskName: homeworkDao.hometask_name,
    finishDate: homeworkDao.finish_date,
    deadlineDate: homeworkDao.deadline_date,
    calendarDate: homeworkDao.calendar_date,
    mark: homeworkDao.mark,
    status: homeworkDao.status,
  };
}

function mapStudentTestsDaoToEntity(studentTestsDao: IStudentTestsDao): IStudentTests {
  return {
    id: studentTestsDao.id,
    avatar: studentTestsDao.avatar,
    fullName: studentTestsDao.full_name,
    login: studentTestsDao.login,
    courseId: studentTestsDao.course_id,
    course: studentTestsDao.course,
    groupId: studentTestsDao.group_id,
    group: studentTestsDao.group,
    tests: studentTestsDao.tests.map(mapTestDaoToEntity),
  };
}

function mapTestDaoToEntity(testDao: ITestDao): ITest {
  return {
    id: testDao.id,
    calendarId: testDao.calendar_id,
    lessonId: testDao.lesson_id,
    testName: testDao.test_name,
    finishDate: testDao.finish_date,
    deadlineDate: testDao.deadline_date,
    calendarDate: testDao.calendar_date,
    mark: testDao.mark,
    status: testDao.status,
  };
}
