import { ISchedule } from 'modules/coach/api/dao/Schedule.dao';
import { ISendSchedule } from 'modules/coach/entities/SendSchedule';
import request from 'api/axios-instance';

export const SendSchedule = (data: FormData) => {
  return request.post('v2/calendar', data).then((res) => res.data);
};

export const updateSchedule = (data: FormData, id: string | number) => {
  return request.post(`v2/calendar/${id}`, data).then((res) => res.data);
};

export const deleteSchedule = (id: string | number) => {
  return request.delete(`v2/calendar/${id}`).then((res) => res.data);
};

export const fetchSchedule = async (id: string | number) => {
  const res = await request.get(`v2/calendar/${id}`).then((res) => {
    const schedule: ISchedule = res.data.data;
    return schedule;
  });

  return res;
};
