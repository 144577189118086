import React from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';
import LMSButton from 'components/LMSButton';
import LMSPagination from 'components/LMSPagination';

import Header from '../../components/Header';
import LibraryFilter from '../../components/LibraryFilter';
import Loading from '../../components/Loading';
import Title from '../../components/Title';
import { useLibrary } from '../../hooks/library';

const Library = () => {
  const { isLoading, books, lastPage, currentPage, setCurrentPage } = useLibrary();

  const jumpToMain = () => {
    window.location.href = '/student';
  };

  return (
    <>
      <Header />
      <div className="w-full px-4 pt-12 md:pt-20">
        <Title className="text-center">Библиотека</Title>

        <div className="flex flex-col lg:flex-row lg:space-x-6 mt-10 md:mt-16">
          <div className="hidden">
            <LibraryFilter />
          </div>

          <div className="flex-1 flex flex-col space-y-5">
            {isLoading && books.length === 0 ? <Loading /> : null}
            {!isLoading && books.length === 0 ? (
              <div className="flex flex-col items-center justify-center h-[460px] rounded-[20px] theme-student-card">
                <p className="text-[30px] text-center leading-10 max-w-[420px] mb-14">
                  Книги не найдены. Продолжите поиск, чтобы найти нужную книгу
                </p>
                <LMSButton onClick={jumpToMain}>Продолжить обучение</LMSButton>
              </div>
            ) : null}

            {books.map((book) => (
              <div
                key={book.id}
                className="flex flex-col md:flex-row md:space-x-10 p-[18px] md:p-8 rounded-[20px] theme-student-card"
              >
                <NavLink
                  to={`/student/library/${book.id}`}
                  className="shrink-0 w-full sm:w-[200px] h-[270px] rounded-lg overflow-hidden"
                >
                  <img className="w-full h-full object-cover" src={book.imageUrl} alt={book.name} />
                </NavLink>

                <div className="flex flex-col mt-4 md:mt-0">
                  {book.category?.label && (
                    <div>
                      <span className="mb-2 px-5 py-2 theme-background--grey text-xs md:text-base rounded-full truncate overflow-hidden">
                        {book.category.label}
                      </span>
                    </div>
                  )}
                  <NavLink
                    to={`/student/library/${book.id}`}
                    className="mt-[30px] md:mt-10 text-[22px] md:text-2xl theme-text--default"
                  >
                    {book.name}
                  </NavLink>
                  {book.author?.label && (
                    <p className="mt-3 theme-light-text">
                      {book.author.label}, {book.pageNumber} страниц
                    </p>
                  )}

                  <div className="flex-1 flex items-end mt-2">
                    {book.filesUrl.map((file) => (
                      <a
                        key={file.extension}
                        href={file.downloadLink}
                        className="flex items-center space-x-2 py-2 px-4 border border-solid border-white rounded-full theme-text--default cursor-pointer theme-text-hover-effect hover:bg-white/10"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <UploadIcon /> <span>{file.extension}</span>
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            ))}

            <LMSPagination
              current={currentPage}
              lastPage={lastPage}
              onChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Library;
