import React from 'react';
import { Outlet } from 'react-router-dom';

import UserTabs from '../../components/UserTabs';

const Users = () => (
  <>
    <UserTabs />
    <Outlet />
  </>
);

export default Users;
