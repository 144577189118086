import React, { useEffect, useState } from 'react';

import { fetchActiveCourses } from '../../api/repository/my-courses';
import CourseCard from '../../components/CourseCard';
import Loading from '../../components/Loading';
import { Course } from '../../entities/Course';

const ActiveCourses = () => {
  const [isLoading, setLoading] = useState(false);
  const [courses, setCourses] = useState<Course[]>([]);

  useEffect(() => {
    setLoading(true);
    fetchActiveCourses()
      .then((res) => {
        setCourses(res);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (isLoading) return <Loading theme="dark" />;

  if (!courses.length) return <p className="text-center text-2xl">Нет активных курсов</p>;

  return (
    <div className="flex flex-col space-y-5">
      {courses.map((course, index) => (
        <CourseCard key={index} course={course} />
      ))}
    </div>
  );
};

export default ActiveCourses;
