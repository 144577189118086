import React, { Suspense, useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { router } from 'router';
import { authSlice } from 'store/auth';
import { fetchUser } from 'store/auth/auth.action-creators';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { injectAuthToken } from 'api/axios-instance';

import './lang';

const App = () => {
  const dispatch = useAppDispatch();
  const { authToken } = useAppSelector((state) => state.auth);

  useEffect(() => {
    injectAuthToken(authToken);

    if (authToken) {
      dispatch(fetchUser());
    } else {
      dispatch(authSlice.actions.logout());
    }
  }, [authToken]);

  return (
    <Suspense fallback="loading...">
      <RouterProvider router={router} />
    </Suspense>
  );
};

export default App;
