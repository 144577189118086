import React, { useEffect, useState } from 'react';
import LMSButton from 'components/LMSButton';
import { useNotification } from 'hooks/notification';

import { createQuestion, fetchFaqs } from '../../api/repository/faq';
import FaqAccordion from '../../components/FaqAccordion';
import Header from '../../components/Header';
import Title from '../../components/Title';
import { Faq } from '../../entities/Faq';

const FaqStudent = () => {
  const notify = useNotification();
  const [faqs, setFaqs] = useState<Faq[]>([]);
  const [question, setQuestion] = useState('');

  useEffect(() => {
    fetchFaqs().then((res) => {
      setFaqs(res);
    });
  }, []);

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (question) {
      createQuestion(question).then(() => {
        notify.success('Ваш вопрос успешно отправлен!');
        setQuestion('');
      });
    }
  };

  return (
    <>
      <Header />
      <div className="w-full px-4 pt-12 md:pt-20">
        <Title className="text-center">ВОПРОС И ОТВЕТ</Title>

        <div className="flex flex-col space-y-3 md:space-y-5 mt-10 md:mt-16">
          {faqs.map((faq) => (
            <FaqAccordion faq={faq} key={faq.id} />
          ))}
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 mt-10 md:mt-16">
          <div className="py-8 md:py-12 px-8 md:px-14 rounded-2xl theme-student-card">
            <p className="mb-6 text-3xl md:text-5xl">Не нашли ответа на свой вопрос?</p>
            <p className="text-lg md:text-2xl theme-light-text">
              Отправьте нам свой вопрос, используя форму ниже
            </p>
          </div>
          <form className="p-6 md:p-10 rounded-2xl theme-student-card" onSubmit={onSubmit}>
            <textarea
              value={question}
              className="w-full h-[225px] p-4 mb-4 md:mb-6 theme-background--grey rounded-2xl md:rounded-3xl outline-none"
              placeholder="Введите ваш вопрос"
              onChange={(event) => setQuestion(event.target.value)}
            ></textarea>
            <LMSButton className="w-full" type="submit">
              ОТПРАВИТЬ
            </LMSButton>
          </form>
        </div>
      </div>
    </>
  );
};

export default FaqStudent;
