import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowBack } from 'assets/icons/chevron-left.svg';
import AddNewCourse from 'modules/hr/components/AddNewCourse';
import Loading from 'modules/student/components/Loading';

import { useHandbooks } from '../../hooks/handbooks';

const NewCourse = () => {
  const navigate = useNavigate();
  const { isLoading, coaches, onFetchCoaches } = useHandbooks();

  const goBack = () => {
    navigate('/hr/courses');
  };

  useEffect(() => onFetchCoaches(), []);

  return (
    <div className="block lg:flex lg:flex-col items-start">
      <button
        type="button"
        onClick={() => goBack()}
        className="w-fit flex flex-row items-center justify-center gap-x-2 py-3 pl-3 pr-5 text-white text-[14px] font-medium bg-white bg-opacity-20 rounded-[36px] border-none outline-none uppercase mb-10"
      >
        <ArrowBack />
        назад
      </button>

      <div className="w-full flex flex-col !m-0">
        {isLoading ? <Loading /> : <AddNewCourse coaches={coaches} />}
      </div>
    </div>
  );
};

export default NewCourse;
