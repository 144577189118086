import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { ReactComponent as ArrowBack } from 'assets/icons/chevron-left.svg';
import EditCourse from 'modules/hr/components/EditCourse';
import Loading from 'modules/student/components/Loading';
import { Option } from 'entities/Option';

import { fetchCourseById } from '../../api/repository/courses';
import { fetchStudyTypes } from '../../api/repository/handbooks';
import { ICourseDetail } from '../../entities/Course';
import { useHandbooks } from '../../hooks/handbooks';

const HrEditCourseDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { isLoading: isLoadingCoaches, coaches, onFetchCoaches } = useHandbooks();

  const [course, setCourse] = useState<ICourseDetail | null>(null);
  const [studyTypes, setStudyTypes] = useState<Option[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!id) return;

    setIsLoading(true);
    Promise.all([fetchCourseById(+id), fetchStudyTypes(), onFetchCoaches()])
      .then(([resCourse, resStudyTypes]) => {
        setCourse(resCourse);
        setStudyTypes(resStudyTypes);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  return (
    <div className="block lg:flex lg:flex-col items-start">
      <button
        type="button"
        onClick={() => navigate(`/hr/courses/course/${id}`)}
        className="w-fit flex flex-row items-center justify-center gap-x-2 py-3 pl-3 pr-5 text-white text-[14px] font-medium bg-white bg-opacity-20 rounded-[36px] border-none outline-none uppercase mb-10"
      >
        <ArrowBack />
        назад
      </button>

      <div className="w-full flex flex-col !m-0">
        {isLoading || isLoadingCoaches ? (
          <Loading />
        ) : id && course ? (
          <EditCourse course={course} coaches={coaches} studyTypes={studyTypes} />
        ) : (
          <div className="flex justify-center items-center text-white">
            Произошла ошибка... обратитесь в тех.поддержку
          </div>
        )}
      </div>
    </div>
  );
};

export default HrEditCourseDetail;
