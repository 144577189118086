export default {
  nav: {
    home: 'Главная',
    about: 'О нас',
    aboutPlatform: 'О платформе',
    apps: 'Приложения',
    analytics: 'Аналитика',
    interface: 'Интерфейс',
  },
  actions: {
    сooperation: 'Сотрудничество',
    try: 'Попробовать',
  },
  hero: {
    try: 'Попробуйте demo-версию',
    titleTop: 'Комфортное обучение для',
    titleBottom: 'ваших сотрудников',
    subtitle: 'Создавайте обучение и отслеживайте прогресс ваших сотрудников в одной платформе',
    btn: 'Попробовать бесплатно',
  },
};
