import request from 'api/axios-instance';

import { StudentInList } from '../../entities/Student';
import { UserDetail } from '../../entities/User';
import { StudentDao, StudentInListDao } from '../dao/student.dao';

export const fetchStudentById = (id: number) =>
  request.get(`v1/listeners/${id}`).then((res) => mapStudentDaoToEntity(res.data.data));

export const fetchStudents = (params: Record<string, string | number | null>) =>
  request.get('v1/listeners', { params }).then((res) => {
    const students: StudentInList[] = res?.data?.data?.map(mapStudentInListDaoToEntity) || [];
    return { students, lastPage: (res?.data?.meta?.last_page as number) || 1 };
  });

function mapStudentDaoToEntity(student: StudentDao): UserDetail {
  return {
    id: student.id,
    fullName: student.full_name,
    phone: student.phone,
    email: student.email,
    age: student.age,
    city: student.city,
    avatar: student?.avatar || null,
    occupationName: student.occupation_name,
    companyName: student.company_name,
    learningType: student.learning_type,
    programs: student.programs,
    courses: student.courses,
    finishedCourses: student.finished_courses,
  };
}

function mapStudentInListDaoToEntity(student: StudentInListDao): StudentInList {
  return {
    id: student.id,
    fullName: student.full_name,
    phoneNumber: student.phone_number,
    email: student.email,
    login: student.login,
    photo: student.photo,
    studentType: student.student_type,
  };
}
