import { useEffect, useState } from 'react';
import { registerLocale } from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import ReactQuill from 'react-quill';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import LMSButton from 'components/LMSButton';
import LMSTitle from 'components/LMSTitle';
import Loader from 'components/Loader';
import ru from 'date-fns/locale/ru';
import { editHomework, fetchHomework } from 'modules/coach/api/repository/homeworks';
import { INewHomeWork } from 'modules/coach/entities/HomeWorks';
import { ISendHomeWork } from 'modules/coach/entities/SendHomework';
import useFileUpload from 'modules/coach/hooks/multiFileUpload';
import useVideoUpload from 'modules/coach/hooks/multiVideoUpload';
import useFileControl from 'modules/coach/hooks/useFileControl';
import useHandleError from 'hooks/handleError';
import { useNotification } from 'hooks/notification';
import { ErrorResponse } from 'entities/Error';

import FileDownloadButton from '../DownLoadButton';
import UploadFile from '../UploadFile';

import { fileTypes, toolbarOptions, videoTypes } from './../../constants/index';

registerLocale('ru', ru);

const EditHomeWork = () => {
  const { control, handleSubmit, setValue } = useForm<ISendHomeWork>();

  const navigate = useNavigate();
  const { id } = useParams();
  const notify = useNotification();
  const { handleCommonError } = useHandleError();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const { uploadedFilePaths, upload } = useFileUpload();
  const { uploadedVideoFilePaths, uploadVideo } = useVideoUpload();
  const [isFileUploading, setIsFileUploading] = useState(false);
  const { newFiles, newVideo, controlFiles, controlVideo, deleteNewVideo, deleteNewFiles } =
    useFileControl();
  const [homeworkDetails, setHomewokDetails] = useState<INewHomeWork>();
  const [isVideoUploading, setIsVideoUploading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    fetchHomework(Number(id))
      .then((res) => setInitialValues(res))
      .catch((err: unknown) => {
        const error = err as ErrorResponse;
        handleCommonError(error);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const onSubmit = async (formData: ISendHomeWork) => {
    setIsButtonLoading(true);
    const updatedFormData = {
      id: Number(id),
      name: formData.name,
      description: '<div style="color: white;">' + formData.description + '</div>',
      file_url: [] as string[],
      video_url: [] as string[],
      new_file_url: uploadedFilePaths,
      new_video_url: uploadedVideoFilePaths,
    };

    if (uploadedFilePaths.length > 0) {
      updatedFormData.new_file_url = uploadedFilePaths;
    }

    if (homeworkDetails?.fileUrl && homeworkDetails?.fileUrl?.length > 0) {
      const filePaths = homeworkDetails?.fileUrl?.map((file) => file.hash);
      updatedFormData.file_url = filePaths;
    }

    if (uploadedVideoFilePaths.length > 0) {
      updatedFormData.new_video_url = uploadedVideoFilePaths;
    }

    if (homeworkDetails?.videoUrl && homeworkDetails?.videoUrl?.length > 0) {
      const videoPaths = homeworkDetails?.videoUrl?.map((file) => file.hash);
      updatedFormData.video_url = videoPaths;
    }

    editHomework(updatedFormData)
      .then(() => {
        notify.success('Домашнее задание успешно отредактировано');
        navigate('/coach/homeworks');
      })
      .catch(() =>
        notify.error(
          'Не удалось отредактировать урок, повторите еще раз или обратитесь в службу поддержки'
        )
      )
      .finally(() => setIsButtonLoading(false));
  };

  const handleChangeFiles = async (files: File[], isDelete?: false) => {
    if (isDelete) {
      deleteNewFiles(files);
      return;
    }

    setIsFileUploading(true);

    try {
      const existingFileNames = homeworkDetails?.fileUrl?.map((fileInfo) => fileInfo.name) || [];

      const filesToUpload = files.filter((newFile) => {
        return !existingFileNames.includes(newFile.name);
      });

      if (filesToUpload.length === 0) {
        notify.error('Файл с таким именем уже загружен');
        return;
      }

      controlFiles(filesToUpload);

      await upload(filesToUpload);
      setIsFileUploading(false);
    } catch (error) {
      notify.error('Ошибка загрузки файла');
      setIsFileUploading(false);
    } finally {
      setIsFileUploading(false);
    }
  };

  const handleChangeVideos = async (files: File[], isDelete?: false) => {
    if (isDelete) deleteNewVideo(files);

    setIsVideoUploading(true);

    try {
      const existingFileNames = homeworkDetails?.videoUrl?.map((fileInfo) => fileInfo.name) || [];

      const filesToUpload = files.filter((newFile) => {
        return !existingFileNames.includes(newFile.name);
      });

      if (filesToUpload.length === 0) {
        notify.error('Файл с таким именем уже загружен');
        return;
      }

      controlVideo(filesToUpload);

      await uploadVideo(filesToUpload);
      setIsVideoUploading(false);
    } catch (error) {
      notify.error('Ошибка загрузки файла');
      setIsVideoUploading(false);
    } finally {
      setIsVideoUploading(false);
    }
  };

  const deleteFile = (file: { hash: string; name: string }, typeName: string) => async () => {
    if (typeName === 'file' && homeworkDetails?.fileUrl) {
      setHomewokDetails({
        ...homeworkDetails,
        fileUrl: homeworkDetails.fileUrl.filter((el) => el.hash !== file.hash),
      });
    }

    if (typeName === 'video' && homeworkDetails?.videoUrl) {
      setHomewokDetails({
        ...homeworkDetails,
        videoUrl: homeworkDetails.videoUrl.filter((el) => el.hash !== file.hash),
      });
    }
  };

  const setInitialValues = (initialValues: INewHomeWork) => {
    setValue('name', initialValues.name);
    setValue('description', initialValues.description);
    setHomewokDetails(initialValues);
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-row justify-between items-end">
        <LMSTitle tag="h4" className="text-current text-3xl">
          Редактирование домашнего задания
        </LMSTitle>
        <LMSButton
          loading={isButtonLoading}
          onClick={handleSubmit(onSubmit)}
          className="!fixed bottom-0 left-0 right-0 py-4 rounded-none sm:!relative sm:rounded-full"
        >
          Сохранить изменения
        </LMSButton>
      </div>

      {isLoading ? (
        <div className="my-10">
          <Loader theme="dark" />
        </div>
      ) : (
        <form
          onSubmit={(e) => e.preventDefault()}
          className="w-full h-full flex flex-col gap-y-5 mt-10 mb-20 sm:mb-0"
        >
          <div className="w-full flex flex-col gap-5 p-5 theme-student-card rounded-3xl sm:p-10">
            <div className="w-full rounded-[14px]">
              <Controller
                name="name"
                control={control}
                rules={{
                  required: { message: 'Введите название урока', value: true },
                }}
                render={({ field, fieldState: { error } }) => (
                  <div className="w-full rounded-[14px]">
                    <input
                      type="text"
                      placeholder="Введите название урока"
                      {...field}
                      className={`w-full max-h-[52px] py-3 px-6 text-current theme-input bg-transparent border border-0.5 rounded-[14px] outline-none ${
                        error ? 'theme-input--error' : 'theme-input--grey'
                      }`}
                    />
                    {error && <p className="theme-input--error">{error.message}</p>}{' '}
                  </div>
                )}
              />
            </div>
          </div>

          <div className="flex flex-col gap-y-5 p-5 theme-student-card rounded-3xl sm:p-10">
            <div className="flex flex-row justify-between">
              <p className="text-current text-2xl">Описание задания</p>
            </div>
            <div className="mt-7 couch-editor">
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <ReactQuill
                    {...field}
                    placeholder={
                      'Опишите, что нужно сделать и какой результат вы ждете от студента. Прикрепите дополнительные материалы, если необходимо'
                    }
                    style={{ color: '#fff' }}
                    modules={{
                      toolbar: toolbarOptions,
                    }}
                    onChange={(data) => setValue('description', data)}
                  />
                )}
              />
            </div>
          </div>

          <div className="flex flex-col gap-y-5 p-5 theme-student-card rounded-3xl sm:p-10">
            <span className="text-current text-xl font-medium">Загрузка файлов и видео</span>
            <div className="w-full flex flex-col gap-5 sm:flex-row">
              <div className="w-full sm:w-1/2">
                {homeworkDetails?.fileUrl?.length &&
                  homeworkDetails?.fileUrl?.map((el, idx) => (
                    <div
                      key={idx}
                      className="w-full flex flex-row justify-between items-center p-7 bg-gradient rounded-[14px] relative"
                    >
                      <div className="max-w-[400px] flex flex-col gap-2">
                        <p className="flex flex-row items-end gap-x-2 text-current text-ld">
                          {el.name}
                          <span className="text-current text-opacity-30 text-base">
                            {(Number(el.size) / (1024 * 1024)).toFixed(2)} мб
                          </span>
                        </p>
                      </div>
                      <FileDownloadButton fileLink={el.downloadLink} name={el.name} />
                      <CloseIcon
                        onClick={deleteFile(el, 'file')}
                        className="absolute right-[-8px] top-[-8px] w-5 h-5 bg-white rounded-full cursor-pointer z-10"
                      />
                    </div>
                  ))}
                <UploadFile
                  uploading={isFileUploading}
                  title="Загрузить файл"
                  hint="PNG, DOC, EXCEL, PDF, JPEG"
                  types={fileTypes}
                  isMultiple={true}
                  files={newFiles}
                  handleChange={handleChangeFiles}
                />
              </div>
              <div className="w-full sm:w-1/2">
                {homeworkDetails?.videoUrl?.length &&
                  homeworkDetails?.videoUrl?.map((el, idx) => (
                    <div
                      key={idx}
                      className="w-full flex flex-row justify-between items-center p-7 bg-gradient rounded-[14px] relative"
                    >
                      <div className="max-w-[400px] flex flex-col gap-2">
                        <p className="flex flex-row items-end gap-x-2 text-current text-ld">
                          {el.name}
                          <span className="text-current text-opacity-30 text-base">
                            {(Number(el.size) / (1024 * 1024)).toFixed(2)} мб
                          </span>
                        </p>
                      </div>
                      <FileDownloadButton fileLink={el.downloadLink} name={el.name} />
                      <CloseIcon
                        onClick={deleteFile(el, 'video')}
                        className="absolute right-[-8px] top-[-8px] w-5 h-5 bg-white rounded-full cursor-pointer z-10"
                      />
                    </div>
                  ))}
                <UploadFile
                  uploading={isVideoUploading}
                  title="Загрузите видео"
                  hint="Ограничение до 1 гб"
                  types={videoTypes}
                  isMultiple={true}
                  files={newVideo}
                  handleChange={handleChangeVideos}
                />
              </div>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default EditHomeWork;
