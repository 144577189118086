import React from 'react';
import { ReactComponent as EyeIcon } from 'assets/icons/close-eye.svg';
import LMSButton from 'components/LMSButton';
import LMSInput from 'components/LMSInput';

const ResetPasswordStudent = () => {
  return (
    <div className="mt-12">
      <h2 className="text-2xl md:text-[32px]">Пароль</h2>
      <p className="mt-4 text-base md:text-lg theme-light-text">
        Вы не можете менять пароль в корпоративном профиле
      </p>

      <div className="flex flex-col space-y-5 md:space-y-8 max-w-[500px] mt-6 p-5 md:p-10 rounded-2xl theme-student-card">
        <LMSInput
          name="oldPassword"
          label="Текущий пароль"
          value=""
          disabled
          suffix={<EyeIcon className="-translate-y-2" />}
          onChange={(value) => {}}
        />
        <LMSInput
          name="newPassword"
          label="Новый пароль"
          value=""
          disabled
          suffix={<EyeIcon className="-translate-y-2" />}
          onChange={(value) => {}}
        />
        <LMSInput
          name="confirmPassword"
          label="Повторите пароль"
          value=""
          disabled
          suffix={<EyeIcon className="-translate-y-2" />}
          onChange={(value) => {}}
        />

        <LMSButton disabled>ПОМЕНЯТЬ ПАРОЛЬ</LMSButton>
      </div>
    </div>
  );
};

export default ResetPasswordStudent;
