import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import useHandleError from 'hooks/handleError';
import { ErrorResponse } from 'entities/Error';

import {
  fetchLessonTestResult,
  fetchLessonTests,
  sendTestAnswers,
} from '../api/repository/my-courses';
import { Result } from '../entities/Result';
import { Test } from '../entities/Test';

export const useTest = () => {
  const { testId } = useParams();
  const { handleCommonError } = useHandleError();

  const [loadingCount, setLoading] = useState(0);
  const [testResult, setTestResult] = useState<Result | null>(null);
  const [test, setTest] = useState<Test | null>(null);

  const [isTestSending, setTestSending] = useState(false);

  useEffect(() => {
    if (!testId) return;

    setLoading((prev) => prev + 1);
    fetchLessonTests(+testId)
      .then((res) => {
        setTest(res);
      })
      .finally(() => {
        setLoading((prev) => prev - 1);
      });

    setLoading((prev) => prev + 1);
    fetchLessonTestResult(+testId)
      .then((res) => {
        setTestResult(res);
      })
      .catch((err: unknown) => {
        const error = err as ErrorResponse;

        if (error.response?.status === 422) {
          setTestResult(null);
        } else {
          handleCommonError(error);
        }
      })
      .finally(() => {
        setLoading((prev) => prev - 1);
      });
  }, [testId]);

  const isLoading = useMemo(() => {
    return loadingCount > 0;
  }, [loadingCount]);

  const onTestAnswerSend = async (answers: Record<string, number[]>) => {
    const data = Object.entries(answers).map(([key, val]) => ({ question_id: +key, answers: val }));
    if (test) {
      try {
        setTestSending(true);
        setTestResult(await sendTestAnswers(test.id, data));
      } catch (err: unknown) {
        const error = err as ErrorResponse;
        handleCommonError(error);
      } finally {
        setTestSending(false);
      }
    }
  };

  return {
    testResult,
    test,
    isLoading,
    isTestSending,
    onTestAnswerSend,
  };
};
