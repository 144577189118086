import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import BaseModal from 'components/BaseModal';
import LMSButton from 'components/LMSButton';
import LMSTitle from 'components/LMSTitle';
import { useNotification } from 'hooks/notification';

import { editModule } from '../../api/repository/courses/modules';

interface Props {
  moduleId: number;
  initialValues: Record<string, string | number>;
  onClose: () => void;
  onCallback: () => void;
}

const EditModulesModal: React.FC<Props> = ({ moduleId, initialValues, onClose, onCallback }) => {
  const { id } = useParams();
  const notify = useNotification();
  const { control, handleSubmit } = useForm<Record<string, string | number>>({
    defaultValues: initialValues,
  });

  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = (values: Record<string, string | number>) => {
    if (!id) return;

    setLoading(true);
    editModule(moduleId, { course_id: id, ...values })
      .then(() => {
        onCallback();
        onClose();
        notify.success('Модуль успешно отредактирован');
      })
      .catch(() => notify.error('Произошла ошибка. Обратитесь в службу поддержки'))
      .finally(() => setLoading(false));
  };

  return (
    <BaseModal wrapperClassName="sm:!max-w-max" onClose={onClose}>
      <div className="flex flex-col space-y-10 min-w-full lg:min-w-[553px] max-h-[80vh] overflow-y-auto p-0 sm:p-4">
        <LMSTitle tag="h2">Редактирование модуля</LMSTitle>

        <form onSubmit={(e) => e.preventDefault()} className="flex flex-col gap-6">
          <Controller
            name="title"
            control={control}
            rules={{
              required: { message: 'Введите название модуля', value: true },
            }}
            render={({ field, fieldState: { error } }) => (
              <div className="w-full">
                <input
                  type="text"
                  placeholder="Название модуля"
                  {...field}
                  className={`w-full max-h-[52px] py-3 px-6 text-black border border-0.5 border-opacity-50 rounded-[14px] outline-none bg-[#F2F2F2] ${
                    error ? 'border-red-500' : 'border-white'
                  }`}
                />
                {error && <p className="text-red-500">{error.message}</p>}{' '}
              </div>
            )}
          />
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <div className="row-span-2 w-full">
                <textarea
                  {...field}
                  className={`w-full h-[142px] py-3 px-6 text-black border border-0.5 border-opacity-50 outline-none rounded-[14px] bg-[#F2F2F2] border-white`}
                  placeholder="Описание модуля"
                ></textarea>
              </div>
            )}
          />
        </form>

        <div className="flex flex-col lg:flex-row lg:justify-end">
          <LMSButton loading={loading} onClick={handleSubmit(onSubmit)}>
            СОХРАНИТЬ
          </LMSButton>
        </div>
      </div>
    </BaseModal>
  );
};

export default EditModulesModal;
