import { useTranslation } from 'react-i18next';

export const useFormatDate = () => {
  const { t } = useTranslation();

  const formatTime = (date: Date) => `${date.getHours()}:${date.getMinutes()}`;

  const toHumanReadableString = (createdAt: Date) => {
    const date = new Date(createdAt);
    const now = new Date();

    const isToday = date.toLocaleDateString() === now.toLocaleDateString();
    const isYesterday =
      date.toLocaleDateString() ===
      new Date(now.getTime() - 24 * 60 * 60 * 1000).toLocaleDateString();

    if (isToday) {
      return t('common.todayAt', { date: formatTime(date) });
    }
    if (isYesterday) {
      return t('common.yesterdayAt', { date: formatTime(date) });
    }
    return date.toLocaleDateString('ru-RU', { day: 'numeric', month: 'short' });
  };

  // get date format 20.12.2022
  const toDateMethodReadableString = (date: string) => {
    const dateArray = date.split('.');
    const day: number = +dateArray[0];
    const month: number = +dateArray[1];
    const year: number = +dateArray[2];
    const readableDate = new Date();
    readableDate.setFullYear(year);
    readableDate.setMonth(month - 1);
    readableDate.setDate(day);
    return readableDate;
  };

  return {
    formatTime,
    toHumanReadableString,
    toDateMethodReadableString,
  };
};
