import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import DatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { ReactComponent as ArrowBack } from 'assets/icons/chevron-left.svg';
import { ReactComponent as EditIcon } from 'assets/icons/pen.svg';
import LMSButton from 'components/LMSButton';
import LMSRadioButton from 'components/LMSRadioButton';
import { fetchCoachTest } from 'modules/coach/api/repository/edit-test';
import * as Test from 'modules/coach/entities/Test';
import { getFormattedDateWithTime } from 'modules/coach/utils/dateUtils';
import { useNotification } from 'hooks/notification';

import { CoachCurrentTestDao } from '../../api/dao/Test.dao';
import { createCoachTests } from '../../api/repository/tests';
import DropdownSelect from '../../components/DropdownSelect';
import TestQuestionCard from '../../components/TestQuestionCard';
import { Answer } from '../test-create/Answer';

export interface Question {
  id: number;
  name: string;
  answers: Answer[];
  done: boolean;
  isExpand: boolean;
}

const TestDetail = () => {
  const { control, handleSubmit, setValue } = useForm<Test.ICreateTest>();
  const [query] = useSearchParams();
  const { testId } = useParams();
  const navigate = useNavigate();
  const notify = useNotification();

  const [questions, setQuestions] = useState<Question[]>([]);
  const [isLastTest, setIsLastTest] = useState<boolean>(false);
  const [lessonName, setLessonName] = useState('');
  const [Test, setTest] = useState<CoachCurrentTestDao>();
  const [isLoading, setLoading] = useState(true);
  const [deadlineDate, setDeadlineDate] = useState<Date>(new Date());
  const title = query.get('title');

  useEffect(() => {
    fetchCoachTest(String(testId))
      .then((res) => {
        setTest(res);
      })
      .catch(() => {
        notify.error('Произошла ошибка. Обратитесь в службу поддержки');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [testId]);

  const goBackToTests = () => {
    navigate('/coach/tests');
  };

  const mapQuestionsToDto = (question: Question) => {
    return {
      name: question.name,
      answers: question.answers.map(mapAnswersToDto),
    };
  };

  const mapAnswersToDto = (answer: Answer) => {
    return {
      name: answer.name,
      is_correct: answer.isCorrect,
    };
  };

  return (
    <div className="mt-10 lg:mt-0 text-current">
      <div className="mt-4 mb-8">
        <button
          type="button"
          onClick={goBackToTests}
          className="w-fit flex flex-row items-center justify-center gap-x-2 py-3 pl-3 pr-5 text-current text-[14px] font-medium theme-default-button rounded-[36px] border-none outline-none uppercase"
        >
          <ArrowBack />
          Назад
        </button>

        <div className="flex flex-col md:flex-row md:items-center justify-between my-8 space-y-2 md:space-y-0 gap-x-4">
          <h2 className="text-[30px] font-normal w-full">{`Тест к уроку: ${
            isLoading ? '' : Test?.lesson_name
          }`}</h2>
          <LMSButton
            prefix={<EditIcon />}
            onClick={() => navigate(`/coach/tests/edit/${Test?.id}`)}
          >
            РЕДАКТИРОВАТЬ
          </LMSButton>
        </div>

        {!!Test?.questions.length &&
          Test.questions.map((question, index) => (
            <div className="p-4 md:p-10 mb-10 rounded-2xl theme-student-card" key={question.id}>
              <p className="text-current text-opacity-50">Вопрос №{index + 1}</p>
              <p className="text-lg md:text-[26px] mb-9">{question.name}</p>

              {question.answer_options.map((answer) => (
                <div
                  key={answer.id}
                  className={`flex items-start space-x-8 py-5 md:py-7 border-t-white/20 border-t cursor-pointer ${
                    answer.is_correct && 'border-green'
                  }`}
                >
                  <LMSRadioButton checked={answer.is_correct} />
                  <p className="text-sm md:text-base">{answer.name}</p>
                </div>
              ))}
            </div>
          ))}
      </div>
    </div>
  );
};

export default TestDetail;
