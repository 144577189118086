import { FC, MouseEventHandler } from 'react';
import { ReactComponent as DropdownIcon } from 'assets/icons/chevron-down.svg';
import { ReactComponent as EditIcon } from 'assets/icons/couch/cedit.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';

interface Props {
  onTrashClick?: MouseEventHandler;
  onEditClick?: MouseEventHandler;
  onDropdownClick?: MouseEventHandler;
  showTrash?: boolean;
  showEdit?: boolean;
  showDropdown?: boolean;
  isOpenDropdown?: boolean;
}

const ButtonGroup: FC<Props> = ({
  onTrashClick,
  onEditClick,
  onDropdownClick,
  showTrash = true,
  showEdit = true,
  showDropdown = true,
  isOpenDropdown,
}) => {
  return (
    <div className="flex flex-row gap-3">
      {showTrash && (
        <button
          onClick={onTrashClick}
          className="w-7 h-7 flex justify-center items-center p-1 theme-default-button rounded"
        >
          <TrashIcon className="theme-text--default" />
        </button>
      )}
      {showEdit && (
        <button
          onClick={onEditClick}
          className="w-7 h-7 flex justify-center items-center p-1 theme-default-button rounded"
        >
          <EditIcon className="theme-text--default" />
        </button>
      )}
      {showDropdown && (
        <button
          onClick={onDropdownClick}
          className="w-7 h-7 flex justify-center items-center p-1 theme-default-button rounded"
        >
          <DropdownIcon className={`${isOpenDropdown ? 'rotate-180' : ''} theme-text--default`} />
        </button>
      )}
    </div>
  );
};

export default ButtonGroup;
