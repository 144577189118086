import React from 'react';
import { Outlet } from 'react-router-dom';
import HomeworkTabs from 'modules/coach/components/HomeworkTabs';

const CheckHomeworks = () => (
  <div className="flex flex-col pt-6">
    <HomeworkTabs />
    <Outlet />
  </div>
);

export default CheckHomeworks;
