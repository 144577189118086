import { IFiles } from 'modules/coach/entities/Files';
import { IStudentInfoMessages, IStudentMessages } from 'modules/coach/entities/StudentMessages';
import request from 'api/axios-instance';

import {
  IStudentInfoMessagesDao,
  IStudentMessagesDao,
  IStudentMessagesFilesDao,
} from '../dao/StudentMessagesDao';

type SendMessage = {
  student_id: number;
  hometask_id: number;
  calendar_id: number;
  status_id: string;
  content: string;
  files?: string;
};

export const sendMessagesOnRefinement = (params: SendMessage) =>
  request.post('v1/coach/homeworks/mark', params).then((res) => res.data);

export const fetchStudentMessages = (studentId: number, lessonId: number) =>
  request
    .get(`v1/coach/homeworks/messages?lesson_id=${lessonId}&student_id=${studentId}`)
    .then((res) => {
      const studentMessages: IStudentMessages[] = res.data.data.map(studentMessagesDaoToEntity);

      return studentMessages;
    });

function studentMessagesDaoToEntity(studentMessagesDao: IStudentMessagesDao): IStudentMessages {
  return {
    id: studentMessagesDao.id,
    content: studentMessagesDao.content,
    createdAt: studentMessagesDao.created_at,
    statusId: Number(studentMessagesDao.status.id),
    files: studentMessagesDao.files && studentMessagesDao.files.map(mapMessageFileDaoToEntity),
    // student: studentMessagesDao.student && mapStudentDaoToEntity(studentMessagesDao.student),
    author: studentMessagesDao.user && mapStudentDaoToEntity(studentMessagesDao.user),
  };
}

function mapStudentDaoToEntity(studentDao: IStudentInfoMessagesDao): IStudentInfoMessages {
  return {
    id: studentDao.id,
    name: studentDao.name,
    fio: studentDao.fio,
    avatar: studentDao.avatar,
  };
}

function mapMessageFileDaoToEntity(file: IStudentMessagesFilesDao): IFiles {
  return {
    downloadLink: file.download_link,
    originalName: file.original_name,
    size: file.size,
    extension: file.extension,
  };
}
