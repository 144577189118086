import React, { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ReactComponent as ArrowRight } from 'assets/icons/chevron-right.svg';
import LMSButton from 'components/LMSButton';

import DropdownSelect from '../DropdownSelect';

interface ITask {
  num1: number;
  num2: number;
  answer: number;
}

const MathTest = () => {
  const { control, handleSubmit, reset, setError, setValue, clearErrors } = useForm();

  const [step, setStep] = useState<number>(1);
  const [showComponent, setShowComponent] = useState<'tasks' | 'result' | 'table'>('table');
  const [countCorrectAnswers, setCountCorrectAnswers] = useState<number>(0);
  const [countUnCorrectAnswers, setCountUnCorrectAnswers] = useState<number>(0);

  const MultiplicationTables = () => {
    return Array.from({ length: 10 }, (_, i) => i + 1);
  };

  const tasks = useMemo(() => {
    const newTasks: ITask[] = [];
    Array.from({ length: 10 }, (_, i) => {
      newTasks.push({
        num1: step,
        num2: i + 1,
        answer: step * (i + 1),
      });
    });
    return newTasks;
  }, [step]);

  const handleShowTasks = () => {
    setShowComponent('tasks');
  };

  const checkErrors = (values: Record<string, string>): boolean => {
    let countErrors = 0;
    tasks.forEach((test, index) => {
      if (test.answer !== Number(values[index + 1])) {
        setError(String(index + 1), { type: 'custom', message: 'Ответ не верный' });
        ++countErrors;
      }
    });
    return !!countErrors;
  };

  const onSubmit = (values: Record<string, string>) => {
    if (checkErrors(values)) return;

    let correctAnswersCount = 0;
    for (const key in values) {
      if (tasks[+key - 1].answer === Number(values[key])) {
        correctAnswersCount += 1;
      }
    }
    setCountCorrectAnswers(correctAnswersCount);
    setCountUnCorrectAnswers(tasks.length - correctAnswersCount);
    setShowComponent('result');
  };

  const handleNextTask = () => {
    if (step > 9) return;
    reset();
    setStep(step + 1);
    setShowComponent('table');
  };

  return (
    <div className="flex flex-col items-center justify-center space-y-10 w-full">
      <div className="flex flex-row items-center space-x-2">
        <h2 className="text-2xl md:text-[32px]">Таблица умножения на</h2>
        <DropdownSelect
          value={step}
          list={MultiplicationTables().map((item) => ({ id: item, name: item }))}
          onChangeClick={(id, _) => {
            setStep(id);
          }}
        />
      </div>

      {showComponent === 'table' && (
        <>
          <table>
            <tbody>
              {Array.from({ length: 10 }, (_, i) => (
                <tr key={i}>
                  <td className="text-2xl">
                    {step} x {i + 1} =
                  </td>
                  <td className="text-2xl">{step * (i + 1)}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <LMSButton suffix={<ArrowRight />} onClick={handleShowTasks}>
            Начать
          </LMSButton>
        </>
      )}

      {showComponent === 'tasks' && (
        <form className="flex flex-col space-y-4 items-center">
          {tasks.map((task, index) => (
            <div key={index} className="flex flex-row space-x-4 items-center">
              <p className="w-full text-2xl">
                {task.num1} x {task.num2} =
              </p>
              <Controller
                name={`${index + 1}`}
                control={control}
                rules={{
                  required: { message: 'Введите ответ', value: true },
                }}
                render={({ field, fieldState: { error } }) => (
                  <div className="w-full rounded-[14px]">
                    <input
                      {...field}
                      autoComplete="off"
                      className={`w-full max-h-[52px] py-3 px-6 text-current theme-input border border-0.5 border-opacity-50 rounded-[14px] outline-none ${
                        error ? '!border-red-500' : 'border-white'
                      }`}
                      onChange={(e) => {
                        if (/^\d*$/.test(e.target.value)) {
                          setValue(String(index + 1), e.target.value);
                          if (Number(e.target.value) !== task.answer) {
                            setError(String(index + 1), {
                              type: 'custom',
                              message: 'Ответ не верный',
                            });
                          } else {
                            clearErrors([String(index + 1)]);
                          }
                        }
                      }}
                    />
                    {error && <p className="text-red-500">{error.message}</p>}{' '}
                  </div>
                )}
              />
            </div>
          ))}
          <div className="flex flex-row gap-2 w-full justify-center">
            <LMSButton type="submit" onClick={handleSubmit(onSubmit)}>
              Готово
            </LMSButton>
          </div>
        </form>
      )}

      {showComponent === 'result' && (
        <div className="flex flex-col space-y-6">
          <p>Верных ответов - {countCorrectAnswers}</p>
          <p>Неправильных ответов - {countUnCorrectAnswers}</p>
          <LMSButton onClick={handleNextTask}>Далее</LMSButton>
        </div>
      )}
    </div>
  );
};

export default MathTest;
