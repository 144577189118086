import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import book from 'assets/icons/couch/book.svg';
import edit from 'assets/icons/couch/cedit.svg';
import documentIcon from 'assets/icons/couch/document-text.svg';
import journal from 'assets/icons/couch/journal.svg';
import password from 'assets/icons/couch/password.svg';
import dashboardIcon from 'assets/icons/dashboard.svg';
import groupIcon from 'assets/icons/group-white.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/logout.svg';
import userIcon from 'assets/icons/user-white.svg';
import Avatar from 'components/Avatar';
import ChangeRoleButton from 'components/ChangeRoleButton';
import LMSTitle from 'components/LMSTitle';
import { authSlice } from 'store/auth';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

import DropdownMenu from '../DropdownMenu';

const SideBarMobile = () => {
  const { user } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const navItems = [
    {
      id: 0,
      title: 'Аналитика',
      link: '/hr/',
      icon: dashboardIcon,
    },
    {
      id: 1,
      title: 'Пользователи',
      link: '/hr/users/coaches',
      icon: userIcon,
    },
    {
      id: 2,
      title: 'Группы',
      link: '/hr/groups',
      icon: groupIcon,
    },
    {
      id: 3,
      title: 'Курсы',
      link: '/hr/courses',
      icon: book,
    },
    {
      id: 4,
      title: 'База обученности',
      link: '/hr/training',
      icon: documentIcon,
    },
    {
      id: 5,
      title: 'Журнал посещаемости',
      link: '/hr/journal',
      icon: journal,
    },
    {
      id: 6,
      title: 'Редактировать профиль',
      link: '/hr/edit-profile',
      icon: edit,
    },
    {
      id: 7,
      title: 'Пароль',
      link: '/hr/change-password',
      icon: password,
    },
  ];

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const logout = () => {
    dispatch(authSlice.actions.logout());
  };

  return (
    <>
      <div className="w-full flex flex-row justify-between items-center px-6 py-2 bg-main border-b border-white border-opacity-30">
        <div className="text-3xl text-white text-center font-semibold select-none">
          <img
            src={require('assets/images/logo.webp')}
            alt="logo"
            className="max-w-[190px] h-auto object-contain object-center"
          />
          {/* {user?.business?.name ? <span>{user?.business?.name}</span> : 'CTE PLATFORM'} */}
        </div>
        <div className="z-40">
          <DropdownMenu isOpen={isOpen} toggleDropdown={toggleDropdown} />
        </div>
      </div>
      {isOpen && (
        <div className="fixed inset-0 flex flex-col bg-[#050e2e] overflow-y-scroll">
          <div className="flex flex-row items-center gap-x-4 py-6 px-4">
            <div className="w-[60px] h-[60px]">
              <Avatar image={user?.avatar} />
            </div>
            {user?.fio && (
              <LMSTitle
                tag={'h3'}
                className="text-center text-white text-xl font-semibold lg:pt-5 "
              >
                {user?.fio}
              </LMSTitle>
            )}
          </div>
          <div className="ml-auto mr-6 mb-6 md:mr-[90%]">
            <ChangeRoleButton />
          </div>
          <nav className="w-full max-h-[100%]">
            <ul onClick={toggleDropdown}>
              {navItems &&
                navItems?.map((el) => {
                  return (
                    <li
                      key={el.id}
                      className="border-[#3D436B] border-t cursor-pointer last-of-type:border-b-none"
                    >
                      <NavLink
                        to={`${el.link}`}
                        className={({ isActive }) => {
                          return `
                          flex flex-row items-center gap-x-5 px-4 py-5 text-white hover:text-white lg:px-[30px] 2xl:text-[22px]
                          ${
                            isActive
                              ? 'bg-[#26A7EF] bg-opacity-[6%] border-blue border-r-[2px]'
                              : ''
                          }
                        `;
                        }}
                      >
                        <img src={el.icon} alt="" />
                        {el.title}
                      </NavLink>
                    </li>
                  );
                })}
            </ul>
          </nav>
          <button
            onClick={() => logout()}
            className="w-full flex flex-row items-center justify-start gap-x-5 mt-auto p-[26px] text-white text-xl font-medium border-t border-t-[#3D436B]"
          >
            <LogoutIcon />
            Выйти из аккаунта
          </button>
        </div>
      )}
    </>
  );
};

export default SideBarMobile;
