import request from 'api/axios-instance';

import { TrainingBaseInList } from '../../entities/TrainingBase';
import { BaseTrainingDao } from '../dao/baseTrainings';

export const fetchBaseTrainings = (params: Record<string, string | number | null>) =>
  request.get('v2/base-trainings', { params }).then((res) => {
    const baseTrainings: TrainingBaseInList[] =
      res?.data?.data?.map(mapStudentInListDaoToEntity) || [];
    return { baseTrainings, lastPage: (res?.data?.meta?.last_page as number) || 1 };
  });

function mapStudentInListDaoToEntity(baseTraining: BaseTrainingDao): TrainingBaseInList {
  return {
    id: baseTraining.id,
    number: baseTraining.number,
    fullName: baseTraining.full_name,
    company: baseTraining.company,
    too: baseTraining.too,
    project: baseTraining.project,
    position: baseTraining.position,
    program: baseTraining.program,
    quantityHours: baseTraining.quantity_hours,
    group: baseTraining.group,
    dateTraining: baseTraining.date_training,
    dateCompletion: baseTraining.date_completion,
    priceTraining: baseTraining.price_training,
    createdAt: baseTraining.created_at,
    updatedAt: baseTraining.updated_at,
  };
}
