import UpdatePasswordForm from 'modules/coach/components/UpdatePasswordForm';

const Password = () => {
  return (
    <div className="w-full h-full flex flex-col">
      <h2 className="text-[32px] font-semibold mb-6 lg:mb-10">Смена пароля</h2>
      <UpdatePasswordForm />
    </div>
  );
};

export default Password;
