import React, { useMemo } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg';
import { ReactComponent as EditIcon } from 'assets/icons/couch/cedit.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { deleteSchedule } from 'modules/coach/api/repository/schedule';
import { Lesson } from 'modules/student/entities/Lesson';
import { useConfirmBox } from 'hooks/confirm-box';
import { useNotification } from 'hooks/notification';

const ScheduleLessonCard = ({ lesson }: { lesson: Lesson }) => {
  const navigate = useNavigate();
  const notify = useNotification();
  const { showConfirmBox } = useConfirmBox();

  const onDelete = (id?: number) => {
    if (!id) {
      return notify.error(
        'Не удалось удалить расписание, повторите еще раз или обратитесь в службу поддержки'
      );
    }

    showConfirmBox({
      title: 'Расписание',
      message: 'Вы уверены что хотите удалить расписание?',
      okVariant: 'light',
      cancelVariant: 'red',
      okText: 'Да',
      cancelText: 'Нет',
      onAccept: () => {
        deleteSchedule(id)
          .then(() => {
            notify.success('Расписание успешно удалено');
            setTimeout(() => {
              navigate(0);
            }, 600);
          })
          .catch(() =>
            notify.error(
              'Не удалось удалить расписание, повторите еще раз или обратитесь в службу поддержки'
            )
          );
      },
    });
  };

  const jumpToAttendance = (calendarId: number) => {
    if (!calendarId) throw new Error('calendar_id is not defined');
    navigate(`/coach/lessons/lesson/attendance/${calendarId}`);
  };

  const onEdit = (id?: number) => {
    navigate(`/coach/schedule/edit-schedule/${id}`);
  };

  const isActiveLesson = useMemo(() => {
    const dateArray = lesson.date.split('.');
    const day: number = +dateArray[0];
    const month: number = +dateArray[1];
    const year: number = +dateArray[2];
    const lessonDate = new Date();
    lessonDate.setFullYear(year);
    lessonDate.setMonth(month - 1);
    lessonDate.setDate(day);
    lessonDate.setHours(23);
    return new Date(lessonDate) >= new Date();
  }, [lesson.date]);

  return (
    <NavLink
      to={`/coach/lessons/lesson/${lesson.id}`}
      className="flex relative flex-col justify-between p-7 theme-student-card rounded-[20px] gap-7"
    >
      {isActiveLesson && (
        <div className="flex justify-end gap-4 absolute top-[16px] right-[16px]">
          <button
            className="flex items-center justify-center w-32 h-10 rounded bg-white/10"
            onClick={(e) => {
              e.preventDefault();
              jumpToAttendance(Number(lesson.scheduleId));
            }}
          >
            <span className="text-[14px] font-medium">Посещаемость</span>
          </button>
          <button
            className="flex items-center justify-center w-10 h-10 rounded bg-white/10 theme-text--default"
            onClick={(e) => {
              e.preventDefault();
              onEdit(lesson.scheduleId);
            }}
          >
            <EditIcon className="w-5 h-5" />
          </button>
          <button
            className="flex items-center justify-center w-10 h-10 rounded bg-white/10 theme-text--default"
            onClick={(e) => {
              e.preventDefault();
              onDelete(lesson.scheduleId);
            }}
          >
            <TrashIcon className="w-5 h-5" />
          </button>
        </div>
      )}

      <p className="text-sm text-[#57C0FA] mt-[1px]">{lesson.courseName}</p>

      <p className="theme-text--default text-[22px]">{lesson.name}</p>

      <div className="flex flex-row flex-wrap gap-2">
        {/* <p className="px-3 py-[2px] rounded-full bg-white/20 whitespace-nowrap theme-text--default">Урок 3</p> */}
        {/* <p className="px-3 py-[2px] rounded-full bg-white/20 whitespace-nowrap theme-text--default">
                    Ауд: {lesson.auditoriumName}
                  </p> */}
        {/* <p className="px-3 py-[2px] rounded-full bg-white/20 whitespace-nowrap text-[#57C0FA]">
                    {lesson.name}
                  </p> */}
        <p className="flex items-center space-x-2 px-3 py-[2px] rounded-full bg-white/20 whitespace-nowrap theme-text--default">
          <CalendarIcon />
          <span>{lesson.date}</span>
        </p>
        <p className="flex items-center space-x-2 px-3 py-[2px] rounded-full bg-white/20 whitespace-nowrap theme-text--default">
          <ClockIcon />
          <span>
            {lesson.timeFrom.substring(0, 5)} - {lesson.timeTo.substring(0, 5)}
          </span>
        </p>
      </div>
    </NavLink>
  );
};

export default ScheduleLessonCard;
