import request from 'api/axios-instance';

import { mapOptionDaoToEntity } from '../../../../api/mappers/option.mappers';
import { ActiveCourse, Module } from '../../entities/ActiveCourse';
import { CompletedCourse } from '../../entities/CompletedCourse';
import { Course } from '../../entities/Course';
import { CourseProgress } from '../../entities/CourseProgress';
import { CourseScore } from '../../entities/CourseScore';
import { Coach, Homework, Lesson, SingleLesson } from '../../entities/Lesson';
import { Result } from '../../entities/Result';
import { Test } from '../../entities/Test';
import { ActiveCourseDao } from '../dao/ActiveCourse.dao';
import { CompletedCourseDao } from '../dao/CompletedCourse.dao';
import { CourseDao } from '../dao/Course.dao';
import { CourseProgressDao } from '../dao/CourseProgress.dao';
import { CourseScoreDao } from '../dao/CourseScore.dao';
import { CoachDao, HomeworkDao, LessonDao, newLessonDao, SingleLessonDao } from '../dao/Lesson.dao';
import { ResultDao } from '../dao/Result.dao';
import { TestDao } from '../dao/Test.dao';

export const fetchCalendarLessons = (params?: Record<string, string | number>) =>
  request.get('v2/calendar', { params }).then((res) => {
    const lessons: Lesson[] = res.data.data.map(mapNewLessonDaoToEntity);
    return lessons;
  });

export const fetchClosestLessons = () =>
  request.get('v1/auth/profile/closest-lessons').then((res) => {
    const lessons: Lesson[] = res.data.data.map(mapLessonDaoToEntity);
    return lessons;
  });

export const fetchActiveCourses = () =>
  request.get('v1/auth/profile/courses').then((res) => {
    const courses: Course[] = res.data.data.map(mapCourseDaoToEntity);
    return courses;
  });

export const fetchActiveCourseById = (id: number, groupId: string | null) =>
  request.get(`v2/student/courses/${id}?group_id=${groupId}`).then((res) => {
    const entityCourse = mapActiveCourseDaoToEntity(res.data.data, groupId);
    return mapCorseModulesLessons(entityCourse);
  });

export const fetchCompletedCourses = () =>
  request.get('v1/auth/profile/finished-courses').then((res) => {
    const courses: CompletedCourse[] = res.data.data.map(mapFinishedCourseDaoToEntity);
    return courses;
  });

export const fetchActiveCourseScore = (id: number, groupdId: number) =>
  request.get(`v1/auth/profile/my-score/${id}?group_id=${groupdId}`).then((res) => {
    const scores: CourseScore[] = res.data.data.map(mapCourseScoreDaoToEntity);
    return scores;
  });

export const fetchActiveCourseProgress = (id: number, courseId: number) =>
  request
    .get(`v1/auth/profile/my-progress/${id}?group_id=${courseId}`)
    .then((res) => mapCourseProgressDaoToEntity(res.data));

export const fetchCourseLesson = (calendarId: number) =>
  request
    .get(`v2/student/lessons/${calendarId}`)
    .then((res) => mapSingleLessonDaoToEntity(res.data.data));

export const sendHomework = (
  courseId: number,
  lessonId: number,
  data: { content: string; files?: string }
) => request.post(`v1/courses/${courseId}/lessons/${lessonId}`, data);

export const fetchLessonTests = (testId: number) =>
  request.get(`v1/tests/${testId}`).then((res) => mapTestDaoToEntity(res.data.data));

export const fetchLessonTestResult = (testId: number) =>
  request.get(`v1/tests/${testId}/result`).then((res) => {
    return mapTestResultDaoToEnitity(res.data.data);
  });

export const sendAttendanceStatus = (calendarId: number, groupId: number) =>
  request
    .post('v2/student/lessons/progress', {
      calendar_id: calendarId,
      group_id: groupId,
    })
    .then((res) => {
      return res;
    });

interface TestAnswer {
  question_id: number;
  answers: number[];
}

export const sendTestAnswers = (testId: number, data: TestAnswer[]) =>
  request
    .post(`v1/tests/${testId}/response`, { responses: data })
    .then((res) => mapTestResultDaoToEnitity(res.data.data));

function mapCourseDaoToEntity(course: CourseDao): Course {
  return {
    id: course.id,
    name: course.name,
    description: course.description,
    dateFrom: course.date_from,
    dateTo: course.date_to,
    duration: course.duration,
    imageUrl: course.image_url,
    courseType: course.course_type,
    groupId: course.group_id,
    groupNumber: course.group_number,
    groupName: course.group_name,
    lessonsCount: course.lessons_count,
    lessonsLeft: course.lessons_left,
    certificateUrl: course.certificate_url,
    lessons: course?.lessons?.map(mapLessonDaoToEntity),
    manager: {
      id: course?.manager?.id,
      fullName: course?.manager?.full_name,
      login: course?.manager?.login,
      phoneNumber: course?.manager?.phone_number,
      email: course?.manager?.email,
      photo: course?.manager?.photo,
      position: course?.manager?.position,
      courses: course?.manager?.courses,
    },
  };
}

function mapActiveCourseDaoToEntity(course: ActiveCourseDao, groupId: string | null): ActiveCourse {
  const findGroup = course.groups.find((group) => {
    if (groupId) {
      return group.id === +groupId;
    }
    return group.id === course.groups[0].id;
  });

  return {
    id: course.id,
    name: course.name,
    description: course.description,
    dateFrom: course.date_from,
    dateTo: course.date_to,
    duration: course.duration,
    imageUrl: course.image_url,
    courseType: course.course_type,
    groupId: findGroup?.id || 0,
    groupNumber: course.group_number,
    groupName: findGroup?.name || '',
    lessonsCount: course.lessons_count,
    lessonsLeft: course.lessons_left,
    certificateUrl: course.certificate_url,
    modules: course.groups?.length
      ? findGroup!.modules.map((m) => ({
          id: m.id,
          name: m.title,
          lessons: m?.lessons ? m.lessons.map(mapLessonDaoToEntity) : [],
        }))
      : [],
    manager: {
      id: findGroup?.manager?.id || 0,
      fullName: findGroup?.manager?.full_name || '',
      login: findGroup?.manager?.login || '',
      phoneNumber: findGroup?.manager?.phone_number || '',
      email: findGroup?.manager?.email || '',
      photo: findGroup?.manager?.photo || '',
      position: findGroup?.manager?.position || '',
    },
  };
}

function mapFinishedCourseDaoToEntity(course: CompletedCourseDao): CompletedCourse {
  return {
    id: course.id,
    name: course.name,
    description: course.description,
    imageUrl: course.image_url,
    courseType: course.course_type,
    groupName: course.group_name,
    dateFrom: course.date_from,
    dateTo: course.date_to,
    result: course.result,
    certificateUrl: course.certificate_url,
  };
}

function mapLessonDaoToEntity(lesson: LessonDao): Lesson {
  return {
    id: lesson.id,
    name: lesson.name,
    groupName: lesson.group_name,
    programName: lesson.program_name,
    courseName: lesson.course_name,
    lessonDescription: lesson.lesson_description,
    position: lesson.position,
    date: lesson.date,
    timeFrom: lesson.time_from,
    timeTo: lesson.time_to,
    videoUrl: lesson.video_url,
    videoYoutubeUrl: lesson.video_youtube_url,
    videoBroadcastUrl: lesson.video_broadcast_url,
    isTest: lesson.is_test,
    isLast: lesson.is_last,
    testId: lesson.test_id,
    isPassed: lesson.is_passed,
    homeworkDeadline: lesson.homework_deadline,
    auditoriumName: lesson.auditorium_name,
    coach: mapCoachDaoToEntity(lesson.coach),
    type_id: lesson?.type_id,
    is_accessed: lesson?.is_accessed,
    calendar_id: lesson?.calendar_id,
  };
}

function mapNewLessonDaoToEntity(lesson: newLessonDao): Lesson {
  return {
    scheduleId: lesson.id,
    id: lesson.lesson.id,
    name: lesson.lesson.name,
    groupName: lesson.group.group_name,
    courseId: lesson.module?.course.id,
    courseName: lesson.module?.course.name,
    lessonDescription: lesson.lesson.lesson_description,
    position: lesson.lesson.position,
    date: lesson.date,
    timeFrom: lesson.time_from,
    timeTo: lesson.time_to,
    videoUrl: lesson.lesson.video_url,
    videoYoutubeUrl: lesson.lesson.video_youtube_url,
    videoBroadcastUrl: lesson.lesson.video_broadcast_url,
    isTest: lesson.lesson.is_test,
    isLast: lesson.lesson.is_last,
    testId: lesson.lesson.test_id,
    isPassed: lesson.lesson.is_passed,
    homeworkDeadline: lesson.lesson.homework_deadline,
    auditoriumName: lesson.lesson?.auditorium_name,
    type_id: lesson.lesson?.type_id,
    is_accessed: lesson.lesson?.is_accessed,
    calendar_id: lesson.lesson?.calendar_id,
    coach: mapCoachDaoToEntity(lesson.lesson.coach),
  };
}

function mapSingleLessonDaoToEntity(lesson: SingleLessonDao): SingleLesson {
  return {
    id: lesson.id,
    name: lesson.name,
    description: lesson.description,
    videoYoutubeUrl: lesson.video_youtube_link,
    videoBroadcastUrl: lesson.video_broadcast_link,
    position: lesson.position,
    courseId: lesson.course_id,
    courseName: lesson.course_name,
    isLast: lesson.is_last,
    isTest: lesson.is_test,
    testId: lesson.test_id,
    type_id: lesson?.type_id,
    is_accessed: lesson?.is_accessed,
    deadline: lesson?.deadline,
    calendar_id: lesson?.calendar_id,
    is_present: lesson?.is_present,
    is_present_exists: lesson?.is_present_exists,
    fileUrl: lesson.file_url
      ? lesson.file_url.map((f) => ({
          downloadLink: f.download_link,
          hash: f.hash,
          name: f.name,
          size: f.size,
        }))
      : null,
    videoUrl: lesson.video_url
      ? lesson.video_url.map((v) => ({
          downloadLink: v.download_link,
          hash: v.hash,
          name: v.name,
          size: v.size,
        }))
      : null,
    coach: mapCoachDaoToEntity(lesson.coach),
    ...(lesson.homework && {
      homework: {
        id: lesson.homework.id,
        name: lesson.homework.name,
        description: lesson.homework.description,
        deadline: lesson.homework.deadline,
        fileUrl: lesson.homework.file_url
          ? lesson.homework.file_url.map((f) => ({
              downloadLink: f.download_link,
              hash: f.hash,
              name: f.name,
              size: f.size,
            }))
          : [],
        videoUrl: lesson.homework.video_url
          ? lesson.homework.video_url.map((v) => ({
              downloadLink: v.download_link,
              hash: v.hash,
              name: v.name,
              size: v.size,
            }))
          : [],
      },
    }),
  };
}

function mapCoachDaoToEntity(coach: CoachDao): Coach {
  return {
    id: coach?.id,
    fullName: coach?.full_name,
    email: coach?.email,
    avatar: coach?.avatar,
    occupation: coach?.occupation,
    gender: coach?.gender,
    company: coach?.company,
    holding: coach?.holding,
    division: coach?.division,
    personalType: coach?.personal_type,
    positionLevel: coach?.position_level,
    positionLevelTop: coach?.position_level_top,
    positionFamily: coach?.position_family,
    grade: coach?.grade,
    roles: coach?.roles,
  };
}

function mapCourseScoreDaoToEntity(score: CourseScoreDao): CourseScore {
  return {
    lessonId: score.lesson_id,
    lessonName: score.lesson_name,
    number: score.number,
    homeworkMark: score.homework_mark,
    testScore: score.test_score,
    attendance: score.attendance,
  };
}

function mapCourseProgressDaoToEntity(progress: CourseProgressDao): CourseProgress {
  return {
    lessonsNumber: progress.lessons_number,
    homeworksNumber: progress.homeworks_number,
    testsNumber: progress.tests_number,
    lessonsAttendance: progress.lessons_attendance,
    testsPassed: progress.tests_passed,
    homeworksPassed: progress.homeworks_passed,
  };
}

function mapTestDaoToEntity(test: TestDao): Test {
  return {
    id: test.id,
    deadline: test.deadline,
    timeAmount: test.time_amount,
    isLast: test.is_last,
    attempts_number: test.attempts_number,
    lesson_id: test.lesson_id,
    lesson_name: test.lesson_name,
    minimum_score_to_pass: test.minimum_score_to_pass,
    name: test.name,
    position: test.position,
    questions: test.questions?.length
      ? test.questions.map((question) => {
          return {
            id: question.id,
            name: question.name,
            hasMultipleCorrectAnswers: question.has_multiple_correct_answers,
            correctAnswersNumber: question.correct_answers_number,
            answerOptions: question.answer_options.map(mapOptionDaoToEntity),
          };
        })
      : [],
  };
}

function mapTestResultDaoToEnitity(result: ResultDao): Result {
  return {
    id: result.id,
    correctAnswersNumber: result.correct_answers_number,
    isPassed: result.is_passed,
    questionsNumber: result.questions_number,
    score: result.score,
  };
}

function mapCorseModulesLessons(course: ActiveCourse): ActiveCourse {
  const { modules } = course;

  const mapModules = [] as Module[];

  modules.forEach((module) => {
    const mapLessons = [] as Lesson[];

    module.lessons.forEach((lesson) => {
      const { id, type_id } = lesson;

      const alreadyInMap = mapLessons.find((l) => l.id === id);
      const alreadyInMapIndex = mapLessons.findIndex((l) => l.id === id);

      if (alreadyInMap) {
        const isEqualTypes = alreadyInMap.type_id === type_id;

        if (isEqualTypes) {
          return;
        }

        if (type_id === 1) {
          mapLessons[alreadyInMapIndex] = Object.assign({}, lesson, {
            isTest: alreadyInMap.isTest,
            isLast: alreadyInMap.isLast,
            testId: alreadyInMap.testId,
            isPassed: alreadyInMap.isPassed,
            homeworkDeadline: alreadyInMap.homeworkDeadline,
            type_id: alreadyInMap.type_id, // 1 - урок, 2 - тест, 3 - домашнее задание
            is_accessed: alreadyInMap.is_accessed, // доступность по времени
            calendar_id: alreadyInMap.calendar_id,
          });
        } else {
          mapLessons[alreadyInMapIndex] = Object.assign({}, alreadyInMap, {
            isTest: lesson.isTest,
            isLast: lesson.isLast,
            testId: lesson.testId,
            isPassed: lesson.isPassed,
            homeworkDeadline: lesson.homeworkDeadline,
            type_id: lesson.type_id, // 1 - урок, 2 - тест, 3 - домашнее задание
            is_accessed: lesson.is_accessed, // доступность по времени
            calendar_id: lesson.calendar_id,
          });
        }
      } else {
        mapLessons.push(lesson);
      }
    });

    mapModules.push({
      ...module,
      lessons: mapLessons,
    });
  });

  return Object.assign({}, course, { modules: mapModules });
}
