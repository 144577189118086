import { useSearchParams } from 'react-router-dom';
import { usePartialState } from 'hooks/partial-state';

interface Filter {
  course: number | null;
  group: number | null;
  dateFrom: Date | string | null;
  dateTo: Date | string | null;
}

export const useCalendarFilter = () => {
  const [query, setQuery] = useSearchParams();

  const course = query.get('course');
  const group = query.get('group');
  const status = query.get('status');
  const dateFrom = query.get('dateFrom');
  const dateTo = query.get('dateTo');

  const [filter, setFilter] = usePartialState<Filter>({
    course: course ? +course : null,
    group: group ? +group : null,
    dateFrom: dateFrom || null,
    dateTo: dateTo || null,
  });

  const onSubmit = () => {
    Object.entries(filter).forEach(([key, value]) => {
      if (key && value !== null) {
        query.set(key, value.toString());
      } else {
        query.delete(key);
      }
    });

    status && query.set('status', status);

    setQuery(query);
  };

  const onReset = () => {
    setFilter({
      course: null,
      group: null,
      dateFrom: null,
      dateTo: null,
    });

    Object.entries(filter).forEach(([key]) => {
      query.delete(key);
    });

    status && query.set('status', status);

    setQuery(query);
  };

  return {
    filter,
    setFilter,
    onSubmit,
    onReset,
  };
};
