import React, { useEffect, useState } from 'react';
import { ReactComponent as EmailIcon } from 'assets/icons/email.svg';
import { ReactComponent as PhoneIcon } from 'assets/icons/phone.svg';
import Avatar from 'components/Avatar';
import BaseModal from 'components/BaseModal';
import Loader from 'components/Loader';

import { fetchStudentById } from '../../api/repository/students';
import { UserDetail } from '../../entities/User';

interface Props {
  studentId: number;
  onClose: () => void;
}

const StudentInfoModal: React.FC<Props> = ({ studentId, onClose }) => {
  const [student, setStudent] = useState<UserDetail | null>(null);

  useEffect(() => {
    fetchStudentById(studentId).then((res) => setStudent(res));
  }, [studentId]);

  return (
    <BaseModal wrapperClassName="sm:!max-w-max" onClose={onClose}>
      <div className="min-w-full lg:min-w-[850px]">
        {student ? (
          <div>
            <div className="flex space-x-3 md:space-x-7 p-6 lg:p-10">
              <div className="w-12 md:w-[100px] h-12 md:h-[100px] rounded-full bg-grey overflow-hidden shrink-0">
                <Avatar image={student?.avatar} alt={student.fullName + ' аватар'} />
              </div>
              <div>
                <p className="text-[22px] md:text-[26px]">{student.fullName}</p>

                <div className="flex flex-col md:flex-row md:space-x-1 space-y-2 md:space-y-0 flex-wrap mt-2">
                  <div className="flex items-center space-x-3 rounded-full p-1 pr-4 border border-gray">
                    <div className="flex items-center justify-center w-10 h-10 bg-blue text-white rounded-full overflow-hidden shrink-0">
                      <PhoneIcon />
                    </div>
                    <a href={'tel:' + student.phone} className="truncate text-black">
                      {student.phone}
                    </a>
                  </div>
                  <div className="flex items-center space-x-3 rounded-full p-1 pr-4 border border-gray">
                    <div className="flex items-center justify-center w-10 h-10 bg-blue text-white rounded-full overflow-hidden shrink-0">
                      <EmailIcon />
                    </div>
                    <a href={'mailto:' + student.email} className="truncate text-black">
                      {student.email}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-[80px_1fr] md:grid-cols-[1fr_2fr_1fr_1fr_1fr] gap-2 py-2 bg-black/10 text-black/60">
              <p className="text-center">Группа</p>
              <p>Курс</p>
              <p className="hidden md:block">Тип курса</p>
              <p className="hidden md:block">Формат курса</p>
              <p className="hidden md:block">Статус курса</p>
            </div>
            {student.courses &&
              student.courses.map((course, index) => (
                <div
                  key={index}
                  className="grid grid-cols-[80px_1fr] md:grid-cols-[1fr_2fr_1fr_1fr_1fr] gap-2 py-4"
                >
                  <p className="text-center">{index + 1}</p>
                  <p className="max-w-[95%]">{course.name}</p>
                  <p>{course.course_type === 'offline' ? 'Оффлайн' : 'Онлайн'}</p>
                  <p>{course.study_type === 'inner' ? 'Внутренний' : 'Внешний'}</p>
                  <p>{course.is_finished ? 'Закончен' : 'Не закончен'}</p>
                </div>
              ))}
          </div>
        ) : (
          <div className="p-10">
            <Loader theme="dark" />
          </div>
        )}
      </div>
    </BaseModal>
  );
};

export default StudentInfoModal;
