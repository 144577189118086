import React, { useEffect, useMemo, useState } from 'react';
import { ReactComponent as ArrowLeft } from 'assets/icons/chevron-left.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/chevron-right.svg';
import LMSButton from 'components/LMSButton';
import LMSRadioButton from 'components/LMSRadioButton';
import { useNotification } from 'hooks/notification';
import { usePartialState } from 'hooks/partial-state';

import { Question } from '../../entities/Test';

interface Props {
  questions: Question[];
  isLoading: boolean;
  closeTest: () => void;
  onSubmit: (answers: Record<string, number[]>) => void;
}

const TestQuestion: React.FC<Props> = ({ questions, isLoading, closeTest, onSubmit }) => {
  const notify = useNotification();

  const [index, setIndex] = useState(1);
  const [answers, setAnswers] = usePartialState<Record<string, number[]>>({});

  useEffect(() => {
    for (const q of questions) {
      setAnswers({ [q.id]: [] });
    }
  }, []);

  const question = useMemo(() => {
    return questions[index - 1];
  }, [index]);

  const addAnswer = (id: number, optionId: number) => {
    if (question.hasMultipleCorrectAnswers) {
      const isAlreadySelect = answers[id]?.includes(optionId);

      if (!isAlreadySelect) {
        setAnswers({ [id]: [...answers[id], optionId] });
      } else {
        setAnswers({ [id]: answers[id].filter((item) => item !== optionId) });
      }
    } else {
      setAnswers({ [id]: [optionId] });
    }
  };

  const goBack = () => {
    if (index === 1) {
      closeTest();
      return;
    }
    setIndex((prev) => prev - 1);
  };

  const goForward = () => {
    if (answers[question.id].length === 0) {
      notify.error('Нужно выбрать хотя бы один ответ');
      return;
    }
    if (index === questions.length) {
      onSubmit(answers);
      return;
    }

    setIndex((prev) => prev + 1);
  };

  if (!question) {
    return <p>Список тестов пуст</p>;
  }

  return (
    <div>
      <div className="p-4 md:p-10 rounded-2xl theme-student-card">
        <p className="theme-light-grey">Вопрос №{index}</p>
        <p className="text-lg md:text-[26px] mb-9">{question.name}</p>

        {question.answerOptions.map((option) => (
          <div
            key={option.value}
            className="flex items-start space-x-8 py-5 md:py-7 border-t-white/20 border-t cursor-pointer"
            onClick={() => addAnswer(question.id, option.value)}
          >
            <LMSRadioButton checked={answers[question.id]?.includes(option.value)} />
            <p className="text-sm md:text-base">{option.label}</p>
          </div>
        ))}
      </div>
      <div className="flex items-center justify-between mt-6">
        <LMSButton prefix={<ArrowLeft />} onClick={goBack}>
          НАЗАД
        </LMSButton>
        <LMSButton
          colorType="light"
          loading={isLoading}
          suffix={<ArrowRight />}
          onClick={goForward}
        >
          {index === questions.length ? 'ГОТОВО' : 'СЛЕДУЮЩИЙ ВОПРОС'}
        </LMSButton>
      </div>
    </div>
  );
};

export default TestQuestion;
