import { LessonComment } from 'modules/student/entities/LessonComments';
import request from 'api/axios-instance';

interface ICreateLessonCommentParams {
  calendar_id: number;
  text: string;
  parent_id?: number;
}

export const fetchLessonComments = (calendar_id: number) =>
  request.get(`v1/comments-by-calendar/${calendar_id}`).then((res) => {
    const lessonComments: LessonComment[] = res.data.data;

    return lessonComments;
  });

export const createLessonComment = (calendarId: number, params: ICreateLessonCommentParams) =>
  request.post(`v1/comments`, params);
