import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as ArrowBack } from 'assets/icons/chevron-left.svg';
import Loader from 'components/Loader';
import { fetchStudentLessons } from 'modules/coach/api/repository/courses/students';
import CoachCourseStudentHomeWork from 'modules/coach/components/courses/StudenHomeWorks';
// import CoachCourseStudentAnalytics from 'modules/coach/components/courses/StudentAnalytics';
import CoachCourseStudentInfo from 'modules/coach/components/courses/StudentInfo';
import CoachCourseStudentLessons from 'modules/coach/components/courses/StudentLessons';
import CoachCourseStudentTests from 'modules/coach/components/courses/StudentTests';
import { useNotification } from 'hooks/notification';

import { IStudentDetail } from '../../../entities/coach-courses/Students';

const CoachCourseStudent = () => {
  const { id, groupId, studentId } = useParams();
  const navigate = useNavigate();
  const notify = useNotification();

  const [activeTab, setActiveTab] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [student, setStudent] = useState<IStudentDetail | null>(null);

  const goBack = () => {
    navigate(-1);
  };

  const switchTab = (tab: number) => {
    setActiveTab(tab);
  };

  const renderStudentComponents = (tab: number) => {
    switch (tab) {
      case 0:
        return <CoachCourseStudentLessons />;
      case 1:
        return <CoachCourseStudentHomeWork />;
      case 2:
        return <CoachCourseStudentTests />;
      // case 3:
      //   return <CoachCourseStudentAnalytics />;
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchStudentLessons(Number(studentId), Number(groupId), Number(id))
      .then((res) => {
        setStudent(res.studentLessons);
      })
      .catch(() => {
        notify.error('Произошла ошибка. Обратитесь в службу поддержки');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <div className="w-full h-full flex flex-col pt-10 lg:pt-6">
      <button
        type="button"
        onClick={goBack}
        className="w-fit flex flex-row items-center justify-center gap-x-2 py-3 pl-3 pr-5 theme-text--default text-[14px] font-medium theme-default-button rounded-[36px] border-none outline-none uppercase"
      >
        <ArrowBack />
        назад
      </button>

      {isLoading ? (
        <Loader />
      ) : (
        <>
          {student && (
            <div className="flex flex-col pt-10">
              <CoachCourseStudentInfo student={student} />
            </div>
          )}

          <div className="pt-10">
            <div className="flex flex-row">
              {['Уроки', 'Д/З', 'Тесты'].map((el, idx) => (
                <div
                  key={idx}
                  className={`${
                    activeTab === idx
                      ? 'theme-coach-background-courses-tab'
                      : 'bg-transparent border-t border-l border-r'
                  } py-3 px-4 text-current text-base rounded-tr-xl rounded-tl-xl cursor-pointer md:px-10 md:text-lg`}
                  onClick={() => switchTab(idx)}
                >
                  {el.toString()}
                </div>
              ))}
            </div>
            <div className="w-full h-full flex flex-col">{renderStudentComponents(activeTab)}</div>
          </div>
        </>
      )}
    </div>
  );
};

export default CoachCourseStudent;
