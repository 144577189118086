import React, { useState } from 'react';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ReactComponent as SendIcon } from 'assets/icons/send.svg';
import Avatar from 'components/Avatar';
import { useAppSelector } from 'hooks/redux';

import { LessonComment as ILessonComment, SendLessonComment } from '../../entities/LessonComments';
import { useLessonComments } from '../../hooks/lesson-comments';
import LessonComment from '../LessonCommit';

type Props = {
  id: number;
};

const LessonComments: FC<Props> = ({ id }) => {
  const { user } = useAppSelector((state) => state.auth);
  const { lessonComments, onCreateLessonComment } = useLessonComments();
  const { control, handleSubmit, reset, setFocus } = useForm<SendLessonComment>();

  const [responseComment, setResponseComment] = useState<ILessonComment | null>(null);

  const onSubmit = (formData: SendLessonComment) => {
    const params = formData;
    responseComment && Object.assign(params, { parent_id: responseComment.id });
    onCreateLessonComment(params, id);
    reset({ text: '' });
    setFocus('text');
    setResponseComment(null);
  };

  return (
    <div className="mt-8 rounded-3xl theme-student-card w-full flex flex-col overflow-hidden">
      <div className="flex flex-col p-8 lg:p-10 border-b theme-border--grey space-y-10">
        <p className="text-2xl text-current">Комментарии</p>

        {lessonComments && lessonComments.length ? (
          <div className="flex flex-col space-y-10">
            {lessonComments.map((comment) => (
              <LessonComment
                key={comment.id}
                comment={comment}
                onSetResponseComment={setResponseComment}
              />
            ))}
          </div>
        ) : null}
      </div>

      <div className="flex flex-col space-y-4 p-8 lg:p-10">
        {responseComment && (
          <div
            className="flex flex-row space-x-2 cursor-pointer items-center"
            onClick={() => setResponseComment(null)}
          >
            <p className="theme-active-text">В ответ {responseComment.user.full_name}</p>
            <p className="text-error">&#10005;</p>
          </div>
        )}
        <div className="flex flex-row items-start space-x-4">
          <div className="w-[46px] h-[46px]">
            <Avatar image={user?.avatar} alt="avatar" />
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="w-full h-full flex flex-row items-start space-x-4"
          >
            <Controller
              name="text"
              control={control}
              rules={{
                required: { message: 'Это поле обязательно к заполнению', value: true },
              }}
              render={({ field, fieldState: { error } }) => (
                <div className="w-full rounded-[14px]">
                  <input
                    type="text"
                    placeholder="Написать комментарий"
                    {...field}
                    className={`w-full max-h-[46px] py-3 px-6 text-current bg-transparent border border-0.5 border-opacity-50 rounded-[14px] outline-none ${
                      error ? 'border-red-500' : 'theme-border--grey'
                    }`}
                  />
                  {error && <p className="theme-text--error">{error.message}</p>}{' '}
                </div>
              )}
            />
            <button
              type="submit"
              className="p-0 h-[46px] w-[46px] outline-none cursor-pointer flex justify-center items-center theme-icon-stroke"
            >
              <SendIcon />
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LessonComments;
