import { IAuthorInfoMessages, IHomeWorkMessages } from 'modules/student/entities/HomeWorkMessages';
import request from 'api/axios-instance';

import { IAuthorInfoMessagesDao, IHomeWorkMessagesDao } from './../dao/HomeWorkMessagesDao';

// type SendMessage = {
//   student_id: number;
//   lesson_id: number;
//   content: string;
//   files?: string[];
// };

// export const sendMessagesOnRefinement = (params: SendMessage) =>
//   request.post('/coach/homeworks/messages', params).then((res) => res.data);

export const fetchHomeWorkMessages = (lessonId: number, courseId: number) =>
  request.get(`v1/courses/${courseId}/lessons/${lessonId}/messages`).then((res) => {
    const homeWorkMessages: IHomeWorkMessages[] = res.data.data.map(homeWorkMessagesDaoToEntity);

    return homeWorkMessages;
  });

function homeWorkMessagesDaoToEntity(studentMessagesDao: IHomeWorkMessagesDao): IHomeWorkMessages {
  return {
    id: studentMessagesDao.id,
    content: studentMessagesDao.content,
    createdAt: studentMessagesDao.created_at,
    files: studentMessagesDao?.files.map((f) => ({
      downloadLink: f.download_link,
      extension: f.extension,
      originalName: f.original_name,
      size: f.size,
    })),
    statusId: studentMessagesDao.status_id ? Number(studentMessagesDao.status_id) : 0,
    author: mapStudenDaoToEntity(studentMessagesDao.author),
    student: mapStudenDaoToEntity(studentMessagesDao.student),
  };
}

function mapStudenDaoToEntity(studentDao: IAuthorInfoMessagesDao): IAuthorInfoMessages {
  return {
    id: studentDao.id,
    name: studentDao.name,
    fio: studentDao.fio,
    avatar: studentDao.avatar,
  };
}
