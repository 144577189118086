import request from 'api/axios-instance';

interface IMarkData {
  student_id: number;
  calendar_id: number;
  status_id: string;
  hometask_id: number;
  mark: number;
}

export const sendHomeworkMark = (params: IMarkData) =>
  request.post('v1/coach/homeworks/mark', params).then((res) => res.data);

export const fetchHomeWorkMark = (lessonId: number, student_id: number) =>
  request
    .get(`v1/coach/homeworks/mark?lesson_id=${lessonId}&student_id=${student_id}`)
    .then((res) => res.data.data);
