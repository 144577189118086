import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useNotification } from 'hooks/notification';

import { fetchBaseTrainings } from '../api/repository/base-trainings';
import { TrainingBaseInList } from '../entities/TrainingBase';

export const useTrainingBase = () => {
  const [query, setQuery] = useSearchParams();

  const company = query.get('company');
  const project = query.get('project');
  const position = query.get('position');
  const program = query.get('program');
  const fullName = query.get('full_name');
  const startDate = query.get('start_date');
  const endDate = query.get('end_date');
  const page = query.get('page');

  const notify = useNotification();

  const [lastPage, setLastPage] = useState(1);

  const [isLoading, setLoading] = useState(false);
  const [trainingBase, setTrainingBase] = useState<TrainingBaseInList[]>([]);

  useEffect(() => {
    const params = {
      company,
      project,
      position,
      program,
      full_name: fullName,
      start_date: startDate,
      end_date: endDate,
      page: page ? page : '1',
      per_page: 30,
    };
    setLoading(true);
    fetchBaseTrainings(params)
      .then((res) => {
        setTrainingBase(res.baseTrainings);
        setLastPage(res.lastPage);
      })
      .catch((e) => notify.error(e.message))
      .finally(() => setLoading(false));
  }, [company, project, position, program, fullName, startDate, endDate, page]);

  const setCurrentPage = (current: number) => {
    query.set('page', current.toString());
    setQuery(query);
  };

  return {
    isLoading,
    trainingBase,
    currentPage: page ? +page : 1,
    lastPage,
    setCurrentPage,
  };
};
