import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as AddIcon } from 'assets/icons/add.svg';
import LMSTitle from 'components/LMSTitle';
import Loader from 'components/Loader';
import { fetchLessons } from 'modules/coach/api/repository/lessons';
import LessonCard from 'modules/coach/components/LessonCard';
import { INewLesson } from 'modules/coach/entities/Lessons';
import { useNotification } from 'hooks/notification';

export const emptyIcon = 'assets/images/empty-image.png';

const Lessons = () => {
  const [Lessons, setLessons] = useState<INewLesson[]>([]);
  const [isLoading, setLoading] = useState(true);
  const notify = useNotification();

  useEffect(() => {
    fetchLessons()
      .then((res) => {
        setLessons(res);
      })
      .catch(() => {
        notify.error('Произошла ошибка. Обратитесь в службу поддержки');
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div className="w-full mt-20 lg:mt-0">
      <div className="flex flex-col pt-6">
        <div className="flex flex-row justify-between items-end">
          <LMSTitle tag="h4" className="mt-auto text-current text-3xl">
            Уроки
          </LMSTitle>
          <NavLink
            to="/coach/lessons/new-lesson"
            className="h-fit w-fit flex flex-row items-center justify-center gap-x-2 py-3 px-6 text-[12px] font-bold uppercase rounded-[12px] md:relative md:rounded-[30px] theme-active-button"
          >
            <AddIcon />
            <span className="hidden md:block">Новый урок</span>
          </NavLink>
        </div>

        <div className="w-full flex flex-col gap-y-4 pt-9">
          {isLoading && <Loader theme="dark" />}
          {Lessons ? (
            Lessons?.map((el) => {
              return <LessonCard key={el.id} data={el} />;
            })
          ) : (
            <div className="text-current">Созданные уроки отстуствуют</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Lessons;
