import React, { useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as BookIcon } from 'assets/icons/book-line.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as CameraIcon } from 'assets/icons/camera.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/chevron-down.svg';
import { ReactComponent as ChevronRightIcon } from 'assets/icons/chevron-right.svg';
import { ReactComponent as ListIcon } from 'assets/icons/list-check.svg';
// import { ReactComponent as ArrowRightIcon } from 'assets/icons/long-right-arrow.svg';
import { ReactComponent as UserIcon } from 'assets/icons/user-full.svg';
import LMSRadioButton from 'components/LMSRadioButton';
import { useAppSelector } from 'hooks/redux';
import { useWindowSize } from 'hooks/window-size';

import { ActiveCourse } from '../../entities/ActiveCourse';

interface Props {
  isOpen: boolean;
  course: ActiveCourse;
  onClose: (val: boolean) => void;
}

const ActiveCourseSidebar: React.FC<Props> = ({ isOpen, course, onClose }) => {
  const device = useWindowSize();
  const { user } = useAppSelector((state) => state.auth);

  console.warn('course', course);

  const [expandedList, setExpandedList] = useState<number[]>([]);
  const [expandedLessons, setExpandedLessons] = useState<number[]>([]);

  const sidebarClass = useMemo(() => {
    if (device === 'desktop') {
      return 'w-80 h-full rounded-2xl border theme-border--light-grey overflow-hidden';
    }

    if (isOpen) {
      return `
        fixed bottom-0 right-0 top-[90px] left-0 z-10 bg-[#ffffff40] flex justify-end
      `;
    }

    return 'hidden';
  }, [device, isOpen]);

  const progressBarWidth = useMemo(() => {
    if (course.lessonsLeft === 0) return '100%';

    const percentage = ((course.lessonsCount - course.lessonsLeft) * 100) / course.lessonsCount;
    return `${percentage}%`;
  }, [course]);

  const toggleAccordion = (id: number) => {
    if (expandedList.includes(id)) {
      setExpandedList((prev) => prev.filter((n) => n !== id));
    } else {
      setExpandedList((prev) => [...prev, id]);
    }
  };

  const toggleLessonAccordion = (id: number) => {
    if (expandedLessons.includes(id)) {
      setExpandedLessons((prev) => prev.filter((n) => n !== id));
    } else {
      setExpandedLessons((prev) => [...prev, id]);
    }
  };

  return (
    <div className={sidebarClass} onClick={() => onClose(false)}>
      <div
        className="w-full sm:w-80 h-full theme-student-sidebar overflow-y-auto"
        onClick={(event) => event.stopPropagation()}
      >
        <NavLink
          to="/student"
          className="hidden lg:flex items-center justify-center py-7 text-3xl theme-text text-center font-semibold border-b theme-border--light-grey"
        >
          <img
            src={require('assets/images/logo.webp')}
            alt="logo"
            className="max-w-[190px] h-auto object-contain object-center"
          />
          {/* {user?.business?.name ? <span>{user?.business?.name}</span> : 'CTE PLATFORM'} */}
        </NavLink>

        <div className="flex lg:hidden items-center justify-between space-x-4 pt-7 pb-5 px-6 border-b theme-border--light-grey">
          <div className="flex items-center justify-center w-[42px] h-[42px] bg-white rounded-full text-black">
            <UserIcon />
          </div>

          <div className="flex-1">
            <p className="truncate">{user?.fio}</p>
            <p className="text-xs text-blue">Профиль</p>
          </div>

          <ChevronRightIcon className="shrink-0" />
        </div>

        <div className="flex items-center justify-between pt-9 px-6">
          <p className="theme-light-text">{course.courseType}</p>
          <CalendarIcon />
        </div>

        <p className="mt-3 text-xl font-semibold px-6">{course.name}</p>

        {course.lessonsCount && course.lessonsLeft && (
          <div className="flex items-center mt-9 mx-6  bg-white/30 rounded-full overflow-hidden">
            <div className="h-[5px] bg-studentProgressBg" style={{ width: progressBarWidth }} />
          </div>
        )}

        {course.lessonsCount && course.lessonsLeft && (
          <div className="flex items-center justify-between mt-6 px-6">
            <span className="text-sm">
              {course.lessonsCount - course.lessonsLeft}/{course.lessonsCount} уроков
            </span>
            {/* <span className="flex items-center space-x-1 text-xs">
            <span>Рейтинг</span> <ArrowRightIcon />
          </span> */}
          </div>
        )}
        <div className="mt-10">
          {course?.modules?.map((module, index) => (
            <div key={module.id} className="border-t theme-border--light-grey">
              <button
                className="flex items-center justify-between w-full py-4 px-6 bg-white/10 border-b theme-border--light-grey"
                onClick={() => toggleAccordion(module.id)}
              >
                <p className="flex flex-col items-start">
                  <span className="text-left text-blue text-xs">Модуль {index + 1}</span>
                  <span className="text-left text-lg">{module.name}</span>
                </p>

                <ArrowDownIcon className="shrink-0" />
              </button>
              {expandedList?.includes(module.id)
                ? module?.lessons?.map((lesson, index) => (
                    <div key={lesson.id} className="p-5 border-b theme-border--light-grey">
                      <div
                        className="flex items-center justify-between space-x-2 cursor-pointer"
                        onClick={() => toggleLessonAccordion(lesson.id)}
                      >
                        <LMSRadioButton checked={lesson.isPassed} />
                        <div className="flex-1">
                          <p className="text-xs text-blue">Урок {index + 1}</p>
                          <p>{lesson.name}</p>
                        </div>
                        <ArrowDownIcon className="shrink-0" />
                      </div>
                      {expandedLessons.includes(lesson.id) && lesson?.is_accessed ? (
                        <div className="px-6 py-4">
                          <NavLink
                            to={`/student/active/${course.id}/lessons/${lesson.calendar_id}?groupId=${course.groupId}`}
                            className={({ isActive }) => {
                              return `flex items-center space-x-2 py-1.5 ${
                                isActive ? 'text-blue' : 'theme-light-text'
                              }`;
                            }}
                          >
                            <CameraIcon /> <span>Просмотр урока</span>
                          </NavLink>
                          {lesson.homeworkDeadline ? (
                            <NavLink
                              to={`/student/active/${course.id}/homeworks/${lesson.calendar_id}?groupId=${course.groupId}`}
                              className={({ isActive }) => {
                                return `flex items-center space-x-2 py-1.5 ${
                                  isActive ? 'text-blue' : 'theme-light-text'
                                }`;
                              }}
                            >
                              <BookIcon /> <span>Домашнее задание</span>
                            </NavLink>
                          ) : null}
                          {lesson.testId ? (
                            <NavLink
                              to={`/student/active/${course.id}/tests/${lesson.testId}?groupId=${course.groupId}`}
                              className={({ isActive }) => {
                                return `flex items-center space-x-2 py-1.5 ${
                                  isActive ? 'text-blue' : 'theme-light-text'
                                }`;
                              }}
                            >
                              <ListIcon /> <span>Тест</span>
                            </NavLink>
                          ) : null}
                        </div>
                      ) : null}
                      {expandedLessons.includes(lesson.id) && !lesson?.is_accessed && (
                        <div className="px-6 py-4">
                          <div className="flex items-center space-x-2 py-1.5 theme-light-text">
                            <CameraIcon /> <span>Урок недоступен</span>
                          </div>
                        </div>
                      )}
                    </div>
                  ))
                : null}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ActiveCourseSidebar;
