import { useEffect, useState } from 'react';

import { fetchCalendarLessons, fetchClosestLessons } from '../api/repository/my-courses';
import { Lesson } from '../entities/Lesson';

export const useCalendar = () => {
  const [closestLessons, setClosestLessons] = useState<Lesson[]>([]);
  const [calendarLessons, setCalendarLessons] = useState<Lesson[]>([]);
  const [closestLessonDate, setClosestLessonDate] = useState<null | Date>(null);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [studyDays, setStudyDays] = useState<string[]>([]);

  useEffect(() => {
    const today = new Date();
    const dayFrom = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    const currentDay = new Date();
    const dayTo = new Date(currentDay.setDate(currentDay.getDate() + 31));

    fetchCalendarLessons({
      date_from: formatDate(dayFrom),
      date_to: formatDate(dayTo),
      limit: 2,
    }).then((res) => setClosestLessons(res));
  }, []);

  useEffect(() => {
    fetchCalendarLessons().then((res) => {
      setCalendarLessons(res);
      setStudyDays(res.map((l) => l.date));
    });
  }, [currentDate]);

  // useEffect(() => {
  //   fetchClosestLessons().then((res) => {
  //     setClosestLessons(res);
  //     if (res.length) {
  //       setClosestLessonDate(new Date(res[0].date.split('.').reverse().join('-')));
  //     }
  //   });
  // }, []);

  const formatDate = (date: Date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  return {
    closestLessons,
    calendarLessons,
    studyDays,
    closestLessonDate,
    setCurrentDate,
  };
};
