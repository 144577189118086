import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';

// import LMSButton from 'components/LMSButton';
import { fetchCourseById } from '../../api/repository/courses';
import Header from '../../components/Header';
import Loading from '../../components/Loading';
import Title from '../../components/Title';
import { CommonCourse } from '../../entities/CommonCourse';

const CommonCourseDetail = () => {
  const { id } = useParams();

  const [isLoading, setLoading] = useState(false);
  const [course, setCourse] = useState<CommonCourse | null>(null);

  useEffect(() => {
    if (!id) return;

    setLoading(true);
    fetchCourseById(+id)
      .then((res) => {
        setCourse(res);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  const badgeClass =
    'flex items-center mb-2 px-5 py-2 theme-background--grey text-xs md:text-base rounded-full truncate overflow-hidden';

  return (
    <>
      <Header />
      {isLoading ? (
        <div className="mt-20">
          <Loading />
        </div>
      ) : null}

      {course && !isLoading ? (
        <div className="w-full px-4 pt-12 md:pt-20">
          <div className="flex flex-col p-4 md:p-10 rounded-3xl bg-studentCardBg">
            <div className="flex items-center space-x-1 mb-14">
              <div className={badgeClass}>
                <CalendarIcon className="mr-3" />
                {course.dateFrom}-{course.dateTo}
              </div>
              <p className={badgeClass}>{course.direction || 'Не указан'}</p>
            </div>

            <Title>{course.name}</Title>

            <div className="flex flex-col-reverse md:flex-row items-center justify-between mt-10">
              {/* <LMSButton>ЗАПИСАТЬСЯ НА КУРС</LMSButton> */}
              <div></div>

              <div className="flex flex-row md:flex-col items-center justify-between w-full md:w-auto p-4 mb-4 md:mb-0 rounded-lg bg-white text-current">
                <p className="theme-light-text">Стоимость курса:</p>
                <p className="text-[28px] font-semibold">
                  {new Number(course.price).toLocaleString()} {course.currency}
                </p>
              </div>
            </div>
          </div>

          {course.description && (
            <div className="mt-12 md:mt-28">
              <Title>Подробнее о курсе</Title>
              <div
                className="mt-6 md:mt-10 p-5 md:p-10 rounded-2xl bg-studentCardBg"
                dangerouslySetInnerHTML={{ __html: course.description }}
              />
            </div>
          )}

          {course.programContent && (
            <div className="mt-12 md:mt-28">
              <Title>Программа </Title>
              <div
                className="mt-6 md:mt-10 p-5 md:p-10 rounded-2xl bg-studentCardBg"
                dangerouslySetInnerHTML={{ __html: course.programContent }}
              />
            </div>
          )}
        </div>
      ) : null}
    </>
  );
};

export default CommonCourseDetail;
