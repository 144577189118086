import React from 'react';
import LMSButton from 'components/LMSButton';

interface Props {
  countCorrectAnswers: number;
  countWrongAnswers: number;
  time: number;
  tryAgain: () => void;
  onFinish: () => void;
}

const CountQuicklyCongrats: React.FC<Props> = ({
  countCorrectAnswers,
  countWrongAnswers,
  time,
  tryAgain,
  onFinish,
}) => {
  const parseTime = (milliseconds: number): string => {
    const seconds = Math.trunc(milliseconds / 1000);
    const minutes = Math.trunc(seconds / 60);
    const leftSeconds = seconds % 60;
    return `${minutes}:${leftSeconds.toString().padStart(2, '0')}`;
  };

  return (
    <div className="flex flex-col space-y-14 items-center justify-center w-[80%] max-w-[960px] h-max rounded-[20px] theme-student-card m-auto px-10 py-20">
      <div className="flex flex-col space-y-8 items-center">
        <p className="text-[32px] font-semibold text-center leading-10 max-w-[512px]">
          Поздравляем! <br /> Вы завершили игру
        </p>
        <p className="text-[22px] text-center leading-6 max-w-[700px]">Вот ваши результаты:</p>
        <p>Затраченное время: {parseTime(time)}</p>
        <div className="flex flex-row space-x-4">
          <article className="w-[250px] flex flex-col items-center justify-center rounded-xl px-10 py-5 bg-[#F1FEF2]">
            <p className="text-3xl text-green">{countCorrectAnswers}</p>
            <p className="text-[#797979]">Правильных ответов</p>
          </article>
          <article className="w-[250px] flex flex-col items-center justify-center rounded-xl px-10 py-5 bg-[#FEF1F1]">
            <p className="text-3xl text-red-700">{countWrongAnswers}</p>
            <p className="text-[#797979] text-center">Неправильных ответов</p>
          </article>
        </div>
      </div>
      <div className="flex flex-row space-x-3">
        <LMSButton className="min-w-[200px]" onClick={tryAgain}>
          Начать заново
        </LMSButton>
        <button
          type="button"
          className="min-w-[200px] flex items-center justify-center text-sm md:text-base px-3 md:px-[25px] py-2 md:py-3.5 rounded-full border theme-border--primary theme-active-text"
          onClick={onFinish}
        >
          Выйти
        </button>
      </div>
    </div>
  );
};

export default CountQuicklyCongrats;
