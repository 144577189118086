import React, { useEffect, useState } from 'react';
import { useNotification } from 'hooks/notification';

import Loading from '../../components/Loading';
import TestQuestion from '../../components/TestQuestion';
import TestResult from '../../components/TestResult';
import TestStart from '../../components/TestStart';
import { useTest } from '../../hooks/test';

const TestDetail = () => {
  const { isLoading, test, testResult, isTestSending, onTestAnswerSend } = useTest();
  const [testAttempts, setTestAttempts] = useState(test?.attempts_number);
  const [currentAttempt, setCurrentAttempt] = useState(1);

  const notify = useNotification();

  const [isTestMode, setTestMode] = useState(false);

  const startTesting = () => {
    if (!!testAttempts && currentAttempt > testAttempts) {
      notify.error('Нет доступных попыток');
      return;
    }

    // костыль для сохранения
    window.localStorage.setItem(
      'tests',
      JSON.stringify([...JSON.parse(window.localStorage.getItem('tests') || '[]'), test?.id])
    );
    setTestMode(true);
  };

  useEffect(() => {
    setTestAttempts(test?.attempts_number);

    const tests = window.localStorage.getItem('tests');
    const parsedTests = tests ? JSON.parse(tests) : null;

    if (parsedTests && parsedTests.length) {
      const findTestAttempts = parsedTests.filter((id: number) => id === test?.id);
      setCurrentAttempt(findTestAttempts.length + 1);

      console.warn('parsedTests', parsedTests, findTestAttempts.length + 1);
    }
  }, [test?.id]);

  const onSubmit = async (answers: Record<string, number[]>) => {
    await onTestAnswerSend(answers);
    setTestMode(false);
  };

  if (isLoading)
    return (
      <div>
        <Loading theme="dark" />
      </div>
    );

  if (!test) return <div />;

  return (
    <>
      {isTestMode ? (
        <TestQuestion
          questions={test.questions}
          isLoading={isTestSending}
          closeTest={() => setTestMode(false)}
          onSubmit={onSubmit}
        />
      ) : testResult ? (
        <TestResult result={testResult} reTest={startTesting} />
      ) : (
        <TestStart test={test} startTesting={startTesting} />
      )}
    </>
  );
};

export default TestDetail;
