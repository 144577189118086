import { useState } from 'react';
import axios from 'axios';

const useFileDownload = (link: string, name: string) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const downloadFile = () => {
    setIsDownloading(true);
    setError(null);

    axios({
      url: link,
      method: 'GET',
      responseType: 'blob',
    })
      .then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', name);
        document.body.appendChild(fileLink);

        fileLink.click();
        setIsDownloading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setError('Ошибка при скачивании файла.');
          setIsDownloading(false);
        }
      });
  };

  return { downloadFile, isDownloading, error };
};

export default useFileDownload;
