import React, { FC, useState } from 'react';
import { ReactComponent as DropdownIcon } from 'assets/icons/chevron-down.svg';

type Props = {
  name: string;
  idx: number;
  passed: number;
  studentsCount: number;
  children?: React.ReactNode;
};

const DetailLessonWithStudents: FC<Props> = (props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleDropdown = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <div>
      <div
        onClick={() => toggleDropdown()}
        className="grid grid-cols-3 grid-flow-col-dense py-4 px-10 text-white text-lg border-t border-white border-opacity-20"
      >
        <div className="col-span-2">
          Урок {props.idx + 1} - {props.name}
        </div>
        <div className="w-fit flex items-center py-px px-4 text-black text-base bg-white rounded-full">
          Сдали {props.passed} из {props.studentsCount}
        </div>
        <DropdownIcon
          className={`${
            isOpen ? 'rotate-180 ease-out duration-700' : 'rotate-360 ease-in duration-500'
          } text-white cursor-pointer`}
        />
      </div>
      {isOpen && props.children}
    </div>
  );
};

export default DetailLessonWithStudents;
