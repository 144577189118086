import { FC, useState } from 'react';
import BaseModal from 'components/BaseModal';
import { sendMessagesOnRefinement } from 'modules/coach/api/repository/messages';
import UploadFile from 'modules/coach/components/UploadFile';
import useFileUpload from 'modules/coach/hooks/multiFileUpload';
import { useNotification } from 'hooks/notification';

import { fileTypes } from '../../modules/coach/constants/index';

type StudentMessage = {
  studentId: number;
  calendarId: number;
  hometaskId: number;
  onCallback: () => void;
  onClose: () => void;
};

const HomeWorkMessageModal: FC<StudentMessage> = ({
  studentId,
  calendarId,
  hometaskId,
  onCallback,
  onClose,
}) => {
  const [description, setDescription] = useState<string>('');
  const [file, setFile] = useState<File[]>([]);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const { uploadedFilePaths, upload } = useFileUpload();
  const notify = useNotification();

  const handleChangeFiles = async (file: File[]) => {
    setFile(file);
    setIsFileUploading(true);

    try {
      await upload(file);
      setIsFileUploading(false);
    } catch (error) {
      setIsFileUploading(false);
    }
  };

  const sendOnRefinement = async () => {
    const params = {
      student_id: studentId,
      hometask_id: hometaskId,
      calendar_id: calendarId,
      status_id: '2',
      files: uploadedFilePaths[0],
      content: description,
    };

    try {
      await sendMessagesOnRefinement(params);
      await onCallback();
    } catch (error) {
      notify.error(String(error));
    } finally {
      notify.success('Данные успешно отправлены');
      onClose();
    }
  };

  return (
    <BaseModal onClose={onClose}>
      <div className="p-6 sm:min-w-[500px] z-100">
        <h4 className="text-center text-lg font-medium">Отправка на доработку</h4>
        <div className="flex flex-col gap-y-3 pt-10">
          <span className="text-base">Комментарий к работе</span>
          <div className="w-full h-full">
            <textarea
              onChange={(data) => setDescription(String(data.target.value))}
              placeholder="Напишите, что нужно исправить в работе, чтобы вы ее приняли и поставили оценку"
              className="w-full min-h-[150px] max-h-[300px] p-4 border border-[#26A7EF] rounded outline-none"
            />
          </div>
        </div>
        <div className="flex flex-col justify-between pt-5 sm:flex-row">
          <div className="w-fit h-fit">
            <UploadFile
              isWhite={true}
              uploading={isFileUploading}
              title="Загрузить файл"
              types={fileTypes}
              isMultiple={true}
              files={file}
              handleChange={handleChangeFiles}
            />
          </div>
          <button
            onClick={() => sendOnRefinement()}
            className="w-fit h-fit text-white px-6 py-3 bg-[#26A7EF] rounded-full sm:ml-auto"
          >
            Отправить
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default HomeWorkMessageModal;
