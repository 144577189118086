import React from 'react';
import ReactQuill from 'react-quill';

interface Props {
  placeholder?: string;
  value: string;
  onChange: (val: string) => void;
}

const TextEditor: React.FC<Props> = ({ value, placeholder = 'Введите текст', onChange }) => {
  return (
    <div>
      <ReactQuill
        value={value}
        placeholder={placeholder}
        modules={{
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ align: 'center' }, { align: 'right' }],
          ],
        }}
        onChange={onChange}
      />
    </div>
  );
};

export default TextEditor;
