export default {
  nav: {
    home: 'main',
    about: 'about us',
    aboutPlatform: 'about platform',
    apps: 'apps',
    analytics: 'analitycs',
    interface: 'interface',
  },
  actions: {
    сooperation: 'сooperation',
    try: 'try',
  },
  hero: {
    try: 'try demo-version',
    titleTop: 'An innovative solution for',
    titleBottom: 'effective learning management',
    subtitle:
      'Create trainings, collect a database, accept payments through our payment module, manage processes easily and quickly',
    btn: 'Try for free',
  },
};
