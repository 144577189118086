import { ICalendarLessonParams } from 'modules/coach/entities/CalendarLesson';
import { CoachDao, newLessonDao } from 'modules/student/api/dao/Lesson.dao';
import { Coach, Lesson } from 'modules/student/entities/Lesson';
import request from 'api/axios-instance';

export const fetchCalendar = (params?: ICalendarLessonParams) =>
  request.get('v2/calendar', { params }).then((res) => {
    const lessons: Lesson[] = res.data.data.map(mapLessonDaoToEntity);
    return lessons;
  });

function mapLessonDaoToEntity(lesson: newLessonDao): Lesson {
  return {
    scheduleId: lesson.id,
    id: lesson.lesson.id,
    name: lesson.lesson.name,
    groupName: lesson.group.group_name,
    courseId: lesson.module?.course.id,
    courseName: lesson.module?.course.name,
    lessonDescription: lesson.lesson.lesson_description,
    position: lesson.lesson.position,
    date: lesson.date,
    timeFrom: lesson.time_from,
    timeTo: lesson.time_to,
    videoUrl: lesson.lesson.video_url,
    videoYoutubeUrl: lesson.lesson.video_youtube_url,
    videoBroadcastUrl: lesson.lesson.video_broadcast_url,
    isTest: lesson.lesson.is_test,
    isLast: lesson.lesson.is_last,
    testId: lesson.lesson.test_id,
    isPassed: lesson.lesson.is_passed,
    homeworkDeadline: lesson.lesson.homework_deadline,
    auditoriumName: lesson.lesson?.auditorium_name,
    type_id: lesson.lesson?.type_id,
    is_accessed: lesson.lesson?.is_accessed,
    calendar_id: lesson.lesson?.calendar_id,
    coach: mapCoachDaoToEntity(lesson.lesson.coach),
  };
}

function mapCoachDaoToEntity(coach: CoachDao): Coach {
  return {
    id: coach?.id,
    fullName: coach?.full_name,
    email: coach?.email,
    avatar: coach?.avatar,
    occupation: coach?.occupation,
    gender: coach?.gender,
    company: coach?.company,
    holding: coach?.holding,
    division: coach?.division,
    personalType: coach?.personal_type,
    positionLevel: coach?.position_level,
    positionLevelTop: coach?.position_level_top,
    positionFamily: coach?.position_family,
    grade: coach?.grade,
    roles: coach?.roles,
  };
}
