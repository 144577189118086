import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LMSButton from 'components/LMSButton';
import LMSPagination from 'components/LMSPagination';
import CoachTable from 'modules/hr/components/CoachTable';
import Loading from 'modules/student/components/Loading';

import AddUserByLinkModal from '../../components/AddUserByLinkModal';
import AddUserBySearchModal from '../../components/AddUserBySearchModal';
import AddUserModal from '../../components/AddUserModal';
import CoachFilter from '../../components/CoachFilter';
import { useCatalogCourses } from '../../hooks/catalog-courses';
import { useCoaches } from '../../hooks/coach-list';

const CoachList = () => {
  const navigate = useNavigate();
  const { isLoading, coaches, currentPage, lastPage, setCurrentPage } = useCoaches();
  const { isLoading: isLoadingCourses, courses } = useCatalogCourses();

  const [isShowAddUserModal, setIsShowAddUserModal] = useState<boolean>(false);
  const [isShowAddUserByLinkModal, setIsShowAddUserByLinkModal] = useState<boolean>(false);
  const [isShowAddUserBySearchModal, setIsShowAddUserBySearchModal] = useState<boolean>(false);

  return (
    <div className="flex flex-col items-start w-full py-4 lg:py-10">
      <div className="w-full flex flex-col lg:flex-row justify-between space-y-4 lg:space-y-0 lg:space-x-4 mb-6 lg:mb-10">
        <h2 className="text-[32px] font-semibold">База тренеров</h2>
        <div className="flex flex-row items-center justify-between lg:justify-end space-x-4">
          <div className="hidden">
            <CoachFilter />
          </div>
          <LMSButton onClick={() => setIsShowAddUserModal(true)}>Добавить тренера</LMSButton>
        </div>
      </div>

      {isLoading || isLoadingCourses ? (
        <div className="w-full p-20">
          <Loading />
        </div>
      ) : null}

      {coaches && !isLoading && !isLoadingCourses && coaches.length > 0 && (
        <>
          <div className="w-full overflow-x-auto">
            <CoachTable coaches={coaches} />
          </div>

          <div className={lastPage > 1 ? 'mt-14' : ''}>
            <LMSPagination current={currentPage} lastPage={lastPage} onChange={setCurrentPage} />
          </div>
        </>
      )}

      {coaches.length === 0 && !isLoading && !isLoadingCourses && (
        <h3 className="w-full mb-10 text-[24px] font-semibold text-center mt-10">
          Нет данных <br /> Попробуйте изменить фильтрацию
        </h3>
      )}

      {isShowAddUserModal ? (
        <AddUserModal
          onClose={() => setIsShowAddUserModal(false)}
          addUserManually={() => navigate('/hr/users/coaches/new-coach')}
          openModalAddUserByLink={() => setIsShowAddUserByLinkModal(true)}
        />
      ) : null}
      {isShowAddUserByLinkModal ? (
        <AddUserByLinkModal courses={courses} onClose={() => setIsShowAddUserByLinkModal(false)} />
      ) : null}
      {isShowAddUserBySearchModal ? (
        <AddUserBySearchModal onClose={() => setIsShowAddUserBySearchModal(false)} />
      ) : null}
    </div>
  );
};

export default CoachList;
