import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as AddIcon } from 'assets/icons/add.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg';
import { ReactComponent as EditIcon } from 'assets/icons/couch/cedit.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import LMSTitle from 'components/LMSTitle';
import { deleteSchedule } from 'modules/coach/api/repository/schedule';
import SwitchTabs from 'modules/coach/components/courses/SwitchTabs';
import Filter from 'modules/coach/components/Filter';
import ScheduleLessonCard from 'modules/coach/components/ScheduleLessonCard';
import Calendar from 'modules/student/components/Calendar';
import { useConfirmBox } from 'hooks/confirm-box';
import { useNotification } from 'hooks/notification';

import { useCalendar } from '../../hooks/useCalendar';

const ACTIVE_TAB_ID = 1;
const FINISHED_TAB_ID = 0;

const Schedule = () => {
  const navigate = useNavigate();
  const notify = useNotification();
  const { showConfirmBox } = useConfirmBox();
  const [query, setQuery] = useSearchParams();
  const { horizontalLessons, verticalLessons, calendarLessons, setCurrentDate } = useCalendar();

  const [activeTab, setActiveTab] = useState<number>(1);

  const onDelete = (id?: number) => {
    if (!id) {
      return notify.error(
        'Не удалось удалить расписание, повторите еще раз или обратитесь в службу поддержки'
      );
    }

    showConfirmBox({
      title: 'Расписание',
      message: 'Вы уверены что хотите удалить расписание?',
      okVariant: 'light',
      cancelVariant: 'red',
      okText: 'Да',
      cancelText: 'Нет',
      onAccept: () => {
        deleteSchedule(id)
          .then(() => {
            notify.success('Расписание успешно удалено');
            setTimeout(() => {
              navigate(0);
            }, 600);
          })
          .catch(() =>
            notify.error(
              'Не удалось удалить расписание, повторите еще раз или обратитесь в службу поддержки'
            )
          );
      },
    });
  };

  const onEdit = (id?: number) => {
    navigate(`/coach/schedule/edit-schedule/${id}`);
  };

  useEffect(() => {
    query.set('status', activeTab === ACTIVE_TAB_ID ? 'active' : 'finished');
    setQuery(query);
  }, [activeTab]);

  useEffect(() => {
    const currentActiveTab = query.get('status') === 'active' ? ACTIVE_TAB_ID : FINISHED_TAB_ID;
    setActiveTab(currentActiveTab);
  }, []);

  return (
    <div className="w-full h-full md:min-h-[870px] mt-20 lg:mt-0">
      <div className="h-full flex flex-col">
        <div className="flex flex-col pt-6">
          <div className="flex flex-row justify-between items-end mb-6">
            <LMSTitle tag="h4" className="w-fit mt-auto text-current text-2xl md:text-4xl">
              Расписание уроков
            </LMSTitle>
            <NavLink
              to="/coach/schedule/new-schedule"
              className="h-fit w-fit flex flex-row items-center justify-center gap-x-2 py-3 px-6 text-[12px] font-bold uppercase rounded-[12px] theme-default-button--active md:relative md:rounded-[30px]"
            >
              <AddIcon />
              <span className="hidden md:block">НОВОЕ РАСПИСАНИЕ</span>
            </NavLink>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-[1.8fr_1fr_1fr] gap-5">
          <div className="py-6 px-8 theme-student-card rounded-[20px]">
            <Calendar
              studyDays={calendarLessons.map((l) => l.date)}
              setDate={setCurrentDate}
              lessons={calendarLessons}
            />
          </div>

          {horizontalLessons.map((lesson) => (
            <NavLink
              to={`/coach/lessons/lesson/${lesson.id}`}
              key={lesson.id}
              className="flex flex-col justify-between md:min-h-[400px] p-7 theme-student-card rounded-[20px] relative"
            >
              <div className="flex justify-end gap-2 absolute top-[16px] right-[16px]">
                <button
                  className="flex items-center justify-center w-10 h-10 rounded bg-white/10 theme-icon-stroke--blue"
                  onClick={(e) => {
                    e.preventDefault();
                    onEdit(lesson.scheduleId);
                  }}
                >
                  <EditIcon className="w-5 h-5" />
                </button>
                <button
                  className="flex items-center justify-center w-10 h-10 rounded bg-white/10 theme-icon-stroke--blue"
                  onClick={(e) => {
                    e.preventDefault();
                    onDelete(lesson.scheduleId);
                  }}
                >
                  <TrashIcon className="w-5 h-5" />
                </button>
              </div>
              <p className="text-sm text-[#57C0FA] pt-8">{lesson.courseName}</p>

              <div>
                <p className="flex space-x-1 theme-text--default text-sm mt-5 md:mt-0">
                  {/* <span className="px-3 py-[2px] rounded-full bg-white/20 whitespace-nowrap">
                    Урок 3
                  </span> */}
                  {/* <span className="px-3 py-[2px] rounded-full bg-white/20 whitespace-nowrap">
                    Ауд: {lesson.auditoriumName}
                  </span> */}
                </p>
                <p className="mt-6 theme-text--default text-[22px]">{lesson.name}</p>
              </div>

              <div className="mt-6">
                <div className="flex items-center theme-text--default space-x-2 mb-2 md:mb-4 theme-icon-stroke--blue">
                  <CalendarIcon />
                  <p>{lesson.date}</p>
                </div>
                <div className="flex items-center theme-text--default space-x-2 theme-icon-stroke--blue">
                  <ClockIcon />
                  <p>
                    {lesson.timeFrom.substring(0, 5)} - {lesson.timeTo.substring(0, 5)}
                  </p>
                </div>
              </div>
            </NavLink>
          ))}
        </div>

        <div className="flex flex-col mt-10 space-y-10 lg:space-y-12">
          <SwitchTabs activeTab={Number(activeTab)} onTabChange={(value) => setActiveTab(value)} />

          <div className="hidden lg:flex flex-row">
            <Filter />
          </div>

          <div className="block lg:hidden">
            <Filter />
          </div>

          {verticalLessons && verticalLessons.length ? (
            <div className="flex flex-col space-y-4">
              {verticalLessons.map((lesson, index) => (
                <ScheduleLessonCard lesson={lesson} key={index} />
              ))}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Schedule;
