import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import DatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/chevron-left.svg';
import LMSButton from 'components/LMSButton';
import { fetchLessons } from 'modules/coach/api/repository/lessons';
import { INewLesson } from 'modules/coach/entities/Lessons';
import * as Test from 'modules/coach/entities/Test';
import { getFormattedDateWithTime } from 'modules/coach/utils/dateUtils';
import { useNotification } from 'hooks/notification';

import { createCoachTests } from '../../api/repository/tests';
import DropdownSelect from '../../components/DropdownSelect';
import TestQuestionCard from '../../components/TestQuestionCard';

import { Answer } from './Answer';

export interface Question {
  id: number;
  name: string;
  answers: Answer[];
  done: boolean;
  isExpand: boolean;
  position: number;
}

const TestCreate = () => {
  const { control, handleSubmit, setValue } = useForm<Test.ICreateTest>();
  const [query] = useSearchParams();
  const { lessonId } = useParams();
  const navigate = useNavigate();
  const notify = useNotification();

  const [questions, setQuestions] = useState<Question[]>([]);
  const [isLastTest, setIsLastTest] = useState<boolean>(false);
  const [Lessons, setLessons] = useState<INewLesson[]>([]);
  const [lessonName, setLessonName] = useState('');
  const [isLoading, setLoading] = useState(true);
  const [deadlineDate, setDeadlineDate] = useState<Date>(new Date());
  const title = query.get('title') || 'Создание теста';

  useEffect(() => {
    fetchLessons()
      .then((res) => {
        setLessons(res);
      })
      .catch(() => {
        notify.error('Произошла ошибка. Обратитесь в службу поддержки');
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const goBackToTests = () => {
    navigate('/coach/tests');
  };

  const addNewQuestion = () => {
    const question: Question = {
      id: Date.now(),
      name: '',
      answers: [],
      done: false,
      isExpand: true,
      position: questions.length + 1,
    };

    setQuestions((prev) => [...prev, question]);
  };

  const onQuestionChange = (question: Question) => {
    setQuestions((prev) => prev.map((q) => (q.id === question.id ? question : q)));
  };

  const onQuestionCopy = (question: Question) => {
    setQuestions((prev) => [
      ...prev,
      {
        id: Date.now(),
        name: question.name,
        answers: [...question.answers],
        done: false,
        isExpand: true,
        position: questions.length + 1,
      },
    ]);
  };

  const onQuestionOrderChange = (result: DropResult) => {
    if (!result.destination) return;

    const newQuestions = [...questions];
    const [reorderedQuestion] = newQuestions.splice(result.source.index, 1);
    newQuestions.splice(result.destination.index, 0, reorderedQuestion);
    setQuestions(newQuestions);
  };

  const onSubmit = (formData: Test.ICreateTest) => {
    if (!questions.length) {
      notify.error('Добавьте вопросы');
      return;
    }

    if (!questions.every((q) => q.done)) {
      notify.error('Есть не сохраненные тесты');
      return;
    }

    if (questions.length < 3) {
      notify.error('Для создания теста необходимо более 3-х вопросов');
      return;
    }

    const lessonId = Lessons.find((l) => l.name === lessonName)?.id;

    const data = {
      deadline: getFormattedDateWithTime(String(deadlineDate)),
      lesson_id: +(lessonId || 0),
      is_last: isLastTest,
      name: formData.name,
      attempts_number: formData.attempts_number,
      minimum_score_to_pass: formData.minimum_score_to_pass,
      time_amount: formData.time_amount,
      questions: questions.map((question, index) =>
        mapQuestionsToDto({ ...question, position: index + 1 })
      ),
    };
    createCoachTests(data)
      .then(() => {
        goBackToTests();
      })
      .catch((e) => {
        notify.error(e?.response?.data?.message || 'Неизвестная ошибка! Попробуйте позже');
      });
  };

  const mapQuestionsToDto = (question: Question) => {
    return {
      name: question.name,
      answers: question.answers.map((answer, index) =>
        mapAnswersToDto({ ...answer, position: index + 1 })
      ),
    };
  };

  const mapAnswersToDto = (answer: Answer) => {
    return {
      name: answer.name,
      is_correct: answer.isCorrect,
    };
  };

  return (
    <div className="mt-20 lg:mt-0 text-current">
      <div className="mt-6 mb-8">
        <button
          type="button"
          onClick={() => goBackToTests()}
          className="w-fit flex flex-row items-center justify-center gap-x-2 py-3 pl-3 pr-5 text-current text-[14px] font-medium theme-default-button rounded-[36px] border-none outline-none uppercase"
        >
          <ArrowLeftIcon />
          назад
        </button>

        <div className="flex flex-col md:flex-row md:items-center justify-between mt-8 md:mt-14 space-y-2 md:space-y-0">
          <h2 className="text-[32px] font-normal">{title}</h2>
          <LMSButton onClick={handleSubmit(onSubmit)}>Сохранить тест</LMSButton>
        </div>
      </div>

      <div className="flex flex-col p-7 theme-student-card rounded-xl">
        <div className="flex flex-col gap-5">
          <div className="w-full h-fit rounded-[14px]">
            <Controller
              name="name"
              control={control}
              rules={{
                required: { message: 'Введите название теста', value: true },
              }}
              render={({ field, fieldState: { error } }) => (
                <div className="w-full rounded-[14px]">
                  <input
                    type="text"
                    placeholder="Введите название теста"
                    {...field}
                    className={`w-full max-h-[52px] py-3 px-6 text-current theme-input border border-0.5 border-opacity-50 rounded-[14px] outline-none ${
                      error ? 'border-red-500' : 'border-white'
                    }`}
                    // onChange={(data) => setValue('name', data)}
                  />
                  {error && <p className="text-red-500">{error.message}</p>}{' '}
                </div>
              )}
            />
          </div>

          <div className="flex flex-col gap-5 sm:flex-row">
            <div className="w-full h-fit rounded-[14px] sm:max-w-[49%]">
              <Controller
                name="lesson_id"
                control={control}
                rules={{
                  required: { message: 'Выберите урок', value: true },
                }}
                render={({ fieldState: { error } }) => (
                  <DropdownSelect
                    notRounded={true}
                    title="Выберите урок"
                    value={lessonName}
                    list={
                      Lessons.length > 0 ? Lessons.map(({ id, name }) => ({ id, name: name })) : []
                    }
                    error={error?.message}
                    onCourseClick={(id, name) => {
                      setLessonName(name);
                      setValue('lesson_id', id);
                    }}
                  />
                )}
              />
            </div>
            <div className="w-full h-fit rounded-[14px] sm:max-w-[49%]">
              <Controller
                name="time_amount"
                control={control}
                rules={{
                  required: { message: 'Введите время отведенное на тест', value: true },
                }}
                render={({ field, fieldState: { error } }) => (
                  <div className="w-full rounded-[14px]">
                    <input
                      type="number"
                      placeholder="Введите время отведенное на тест в минутах"
                      {...field}
                      className={`w-full max-h-[52px] py-3 px-6 text-current theme-input border border-0.5 border-opacity-50 rounded-[14px] outline-none ${
                        error ? 'border-red-500' : 'border-white'
                      }`}
                      // onChange={(data) => setValue('name', data)}
                    />
                    {error && <p className="text-red-500">{error.message}</p>}{' '}
                  </div>
                )}
              />
            </div>
          </div>

          <div className="flex flex-col gap-5 sm:flex-row">
            <div className="w-full h-fit rounded-[14px] sm:max-w-[49%]">
              <Controller
                name="minimum_score_to_pass"
                control={control}
                rules={{
                  required: { message: 'Введите проходной процент', value: true },
                }}
                render={({ field, fieldState: { error } }) => (
                  <div className="w-full rounded-[14px]">
                    <input
                      type="number"
                      placeholder="Введите проходной процент"
                      {...field}
                      className={`w-full max-h-[52px] py-3 px-6 text-current theme-input border border-0.5 border-opacity-50 rounded-[14px] outline-none ${
                        error ? 'border-red-500' : 'border-white'
                      }`}
                      // onChange={(data) => setValue('name', data)}
                    />
                    {error && <p className="text-red-500">{error.message}</p>}{' '}
                  </div>
                )}
              />
            </div>
            <div className="w-full h-fit rounded-[14px] sm:max-w-[49%]">
              <Controller
                name="attempts_number"
                control={control}
                rules={{
                  required: { message: 'Введите кол-во попыток', value: true },
                }}
                render={({ field, fieldState: { error } }) => (
                  <div className="w-full rounded-[14px]">
                    <input
                      type="number"
                      placeholder="Введите кол-во попыток"
                      {...field}
                      className={`w-full max-h-[52px] py-3 px-6 text-current theme-input border border-0.5 border-opacity-50 rounded-[14px] outline-none ${
                        error ? 'border-red-500' : 'border-white'
                      }`}
                      // onChange={(data) => setValue('name', data)}
                    />
                    {error && <p className="text-red-500">{error.message}</p>}{' '}
                  </div>
                )}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col sm:flex-row justify-between mt-10 p-7 theme-student-card text-current rounded-xl">
        <div className="flex flex-row items-center gap-4">
          <p className="whitespace-nowrap">Срок сдачи:</p>
          <div className="new-group w-full flex flex-row">
            <DatePicker
              selected={deadlineDate}
              placeholderText="Выберите дату"
              locale="ru"
              required
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              showTimeSelect
              dateFormat="dd.MM.yyyy HH:mm"
              minDate={new Date()}
              onChange={(date) => date !== null && setDeadlineDate(date)}
            />
            <CalendarIcon className="ml-[-44px] mt-[14px] text-current" />
          </div>
        </div>
        <div
          onClick={() => setIsLastTest((prev) => !prev)}
          className="flex flex-row items-center gap-x-4 cursor-pointer"
        >
          <button
            className={`flex items-center justify-center shrink-0 w-6 h-6 rounded ${
              isLastTest ? 'bg-[#58DB66]' : 'bg-white/10 border theme-border--light-black'
            }`}
          >
            <CheckIcon className="w-2.5 h-2.5" />
          </button>
          <span>Заключительный тест?</span>
        </div>
      </div>

      <div className="mt-8 md:mt-12">
        <DragDropContext onDragEnd={onQuestionOrderChange}>
          <Droppable droppableId="questionsList">
            {(outerProvided) => (
              <div {...outerProvided.droppableProps} ref={outerProvided.innerRef}>
                {questions.map((question, index) => (
                  <Draggable key={question.id} draggableId={question.id.toString()} index={index}>
                    {(provided) => (
                      <div {...provided.draggableProps} ref={provided.innerRef}>
                        <TestQuestionCard
                          dragProps={provided.dragHandleProps}
                          number={index + 1}
                          question={question}
                          onDelete={(id) => setQuestions((prev) => prev.filter((q) => q.id !== id))}
                          onCopy={onQuestionCopy}
                          onChange={onQuestionChange}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}

                {outerProvided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      <button
        className="flex items-center justify-center space-x-2 w-full mt-6 py-4 border text-current theme-default-button--active border-white border-dashed rounded-lg"
        onClick={addNewQuestion}
      >
        <span className="text-2xl">+</span>
        <span>ДОБАВИТЬ ВОПРОС</span>
      </button>
    </div>
  );
};

export default TestCreate;
