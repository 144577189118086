import { fetchMe } from 'api/repositories/users.repository';

import { AppDispatch } from '../index';

import { authSlice } from './index';

export const fetchUser = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(authSlice.actions.userFetching());
    const response = await fetchMe();
    dispatch(authSlice.actions.userFetchingSuccess(response));
  } catch (err) {
    dispatch(authSlice.actions.logout());
  }
};
