export default {
  submit: 'Жіберу',
  email: 'Email',
  password: 'Құпия сөз',
  firstName: 'Аты',
  lastName: 'Тек',
  patronymic: 'Әкесінің аты',
  confirmPassword: 'Растау',
  newPassword: 'Жаңа құпия сөз',
  createAccount: 'Аккаунты жасау',
  forgotPassword: 'Құпия сөзді ұмыттым',
  city: 'Қала',
  title: 'Аты',
  description: 'Сипаттама',
  category: 'Санат',
};
