import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as RightIcon } from 'assets/icons/chevron-right.svg';
import { ICoursesGroups } from 'modules/coach/entities/coach-courses/Courses';
import { formatDate } from 'modules/coach/utils/dateUtils';

type Props = {
  startDate: string | Date;
  endDate: string | Date;
  groups: ICoursesGroups[];
};

const CourseGroupTable: FC<Props> = ({ groups, startDate, endDate }) => {
  const navigate = useNavigate();

  const redirectToCourse = (courseId: number, groupId: number) => {
    navigate(`/coach/courses/${courseId}/group/${groupId}`);
  };

  return (
    <table className="table-auto w-full">
      <thead className="bg-white bg-opacity-5 cursor-default">
        <tr className="h-10 theme-text--default text-base gridTemplateColumns">
          <th className="pl-6 text-left">Группа</th>
          <th className="text-left">Начало обучения</th>
          <th className="text-left">Окончание обучения</th>
          <th className="text-left"></th>
        </tr>
      </thead>
      <tbody>
        {groups &&
          groups.map((el, index) => (
            <tr
              key={index}
              className="cursor-default border-b border-opacity-20 border-b-white hover:bg-white hover:bg-opacity-10 last-of-type:border-b-0"
            >
              <td className="px-6 py-6 text-left theme-text--default">{el.groupName}</td>
              <td className="theme-text--default text-lg">
                {formatDate(new Date(startDate), '.')}
              </td>
              <td className="text-left theme-text--default text-lg">
                {formatDate(new Date(endDate), '.')}
              </td>
              <td className="text-right theme-text--default md:pr-[30px]">
                <button
                  onClick={() => redirectToCourse(el.courseId, el.id)}
                  className="px-4 py-1 theme-text--default cursor-pointer md:bg-white md:bg-opacity-30 md:rounded-full"
                >
                  <span className="hidden md:block">Перейти</span>
                  <div className="block md:hidden">
                    <RightIcon />
                  </div>
                </button>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default CourseGroupTable;
