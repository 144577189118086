import React, { useEffect, useState } from 'react';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as ArrowIcon } from 'assets/icons/chevron-right.svg';
import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg';
import LMSButton from 'components/LMSButton';

import { Test } from '../../entities/Test';

interface Props {
  test: Test;
  startTesting: () => void;
}

const TestStart: React.FC<Props> = ({ test, startTesting }) => {
  const [testAttempts, setTestAttempts] = useState(test.attempts_number);
  const [currentAttempt, setCurrentAttempt] = useState(1);

  const badgeClass =
    'flex items-center space-x-2 w-fit mb-2 px-5 py-2 theme-badge text-xs md:text-base rounded-full truncate overflow-hidden';

  const currentDeadline = (date: string) => {
    return `${date.split(' ')[1].slice(0, 5)} ${new Date(date).toLocaleDateString('ru-RU')}`;
  };

  useEffect(() => {
    const tests = window.localStorage.getItem('tests');
    const parsedTests = tests ? JSON.parse(tests) : null;

    if (parsedTests && parsedTests.length) {
      const findTestAttempts = parsedTests.filter((id: number) => id === test.id);
      setCurrentAttempt(findTestAttempts.length + 1);
    }
  }, [testAttempts]);

  return (
    <div className="p-4 md:p-10 rounded-2xl theme-student-card">
      <div className="flex flex-col md:flex-row md:items-center md:justify-between">
        <div className="flex items-center space-x-1.5 flex-wrap">
          {test.timeAmount && (
            <div className={badgeClass}>
              <ClockIcon />
              <p>{test.timeAmount} минут</p>
            </div>
          )}
          {test.questions.length && <p className={badgeClass}>{test.questions.length} вопросов</p>}
          {testAttempts >= currentAttempt ? (
            <p className={badgeClass}>
              попытка {currentAttempt} из {testAttempts}
            </p>
          ) : (
            <p className={badgeClass}>Нет доступных попыток</p>
          )}
        </div>
        <div className={badgeClass}>
          <CalendarIcon />
          <p>до {currentDeadline(test.deadline)}</p>
        </div>
      </div>

      <p className="mt-12 mb-6 text-3xl md:text-4xl font-semibold uppercase">
        пройдите Тест: {test.name}
      </p>

      <div className="flex flex-col md:flex-row md:items-center justify-between">
        <p className="md:max-w-[400px] mb-16 md:mb-0">
          Чтобы пройти тест, нужно набрать {test.minimum_score_to_pass} баллов из 100. В каждом
          вопросе от 1 до 3 правильных вариантов ответа.
        </p>

        {testAttempts >= currentAttempt && (
          <LMSButton suffix={<ArrowIcon />} onClick={startTesting}>
            НАЧАТЬ ТЕСТИРОВАНИЕ
          </LMSButton>
        )}
      </div>
    </div>
  );
};

export default TestStart;
