import React, { useState } from 'react';

import { Faq } from '../../entities/Faq';

interface Props {
  faq: Faq;
}

const FaqAccordion: React.FC<Props> = ({ faq }) => {
  const [isExpanded, setExpanded] = useState(false);

  return (
    <div className="p-4 md:p-8 rounded-2xl md:rounded-3xl bg-studentCardBg transition-all">
      <button
        className={`flex items-start md:items-center justify-between space-x-4 w-full text-left ${
          isExpanded ? 'mb-4' : ''
        }`}
        onClick={() => setExpanded((prev) => !prev)}
      >
        <span className="text-xl md:text-[30px] font-semibold">{faq.title}</span>
        <span
          className={`shrink-0 flex items-center justify-center w-8 md:w-10 h-8 md:h-10 bg-white/20 rounded-full text-xl md:text-2xl origin-center ${
            isExpanded ? 'rotate-0' : 'rotate-45'
          }`}
        >
          &times;
        </span>
      </button>
      <div className={`${isExpanded ? 'block max-h-[500px]' : 'hidden max-h-0'}`}>
        <p className="text-base md:text-2xl">{faq.description}</p>
      </div>
    </div>
  );
};

export default FaqAccordion;
