import { FC, useState } from 'react';
import { ReactComponent as DropdownIcon } from 'assets/icons/chevron-down.svg';
import BaseImage from 'components/BaseImage';

type Props = {
  title: string;
  img: string | null;
  count?: number;
  children?: React.ReactNode;
};

const CourseCard: FC<Props> = (props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleDropdown = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <div className="h-fit w-full rounded-[20px] bg-[#58DB66]">
      <div
        className={`${
          isOpen ? 'mr-0' : 'mr-[4px]'
        } h-full flex flex-col theme-background-homework-card rounded-[18px] cursor-pointer`}
      >
        <div
          onClick={() => toggleDropdown()}
          className="flex flex-row justify-between items-center p-7"
        >
          <div className="w-full flex flex-row items-center justify-between text-white text-lg">
            <div className="flex flex-row gap-y-3 items-center gap-x-5">
              <div className="hidden max-h-[100px] max-w-[160px] sm:block">
                <BaseImage width="160px" height="100px" image={props.img} alt={props.title} />
              </div>
              <span className="theme-text--default max-w-[200px] text-[16px] font-medium sm:max-w-[400px] sm:text-[26px] sm:font-bold">
                {props.title}
              </span>
            </div>
            {props.count && (
              <div className="h-fit flex items-center gap-x-3 px-4 py-1 mr-5 bg-white bg-opacity-20 rounded-full">
                <span className="w-1 h-1 rounded-full shadow bg-[#58DB66]" />
                <span className="theme-text--default">{props.count}</span>
              </div>
            )}
          </div>
          <DropdownIcon
            className={`${
              isOpen ? 'rotate-180 ease-out duration-700' : 'rotate-360 ease-in duration-500'
            } theme-text--default cursor-pointer`}
          />
        </div>
        {isOpen && props.children}
      </div>
    </div>
  );
};

export default CourseCard;
