import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import LMSButton from 'components/LMSButton';
import LMSTitle from 'components/LMSTitle';
import { useNotification } from 'hooks/notification';
import { Option } from 'entities/Option';

import { createSimulation, updateSimulation } from '../../api/repository/simulations';
import { ISimulationDetail } from '../../entities/Simulation';
import DropdownSelect from '../DropdownSelect';

interface Props {
  categories: Option[];
  simulation?: ISimulationDetail | null;
}

const AddNewSimulation: React.FC<Props> = ({ categories, simulation }) => {
  const notify = useNotification();
  const navigate = useNavigate();
  const { control, handleSubmit, setValue } = useForm<ISimulationDetail>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [category, setCategory] = useState<string>('');

  const isEdit = !!simulation;

  const setInitialValues = () => {
    if (isEdit) {
      const initialCategory = categories.find((c) => c.value === simulation.categoryId);
      initialCategory && setCategory(initialCategory.label);
      setValue('categoryId', simulation.categoryId);
      setValue('seconds', simulation.seconds);
      setValue('questions', simulation.questions);
      setValue('target', simulation.target);
      setValue('description', simulation.description);
      setValue('instruction', simulation.instruction);
      setValue('categoryDescription', simulation.categoryDescription);
      setValue('timerDescription', simulation.timerDescription);
      setValue('tryDescription', simulation.tryDescription);
      setValue('resultsDescription', simulation.resultsDescription);
    }
  };

  const simulationDetailEntityToDao = (simulation: ISimulationDetail) => ({
    target: simulation.target,
    description: simulation.description,
    instruction: simulation.instruction,
    category_description: simulation.categoryDescription,
    questions: simulation.questions,
    timer_description: simulation.timerDescription,
    try_description: simulation.tryDescription,
    results_description: simulation.resultsDescription,
    category_id: simulation.categoryId,
    category_name: simulation.categoryName,
    seconds: simulation.seconds,
  });

  const onSubmit = async (formData: ISimulationDetail) => {
    setIsLoading(true);
    Promise.all([
      isEdit
        ? updateSimulation(simulation.id, simulationDetailEntityToDao(formData))
        : createSimulation(simulationDetailEntityToDao(formData)),
    ])
      .then(() => {
        if (isEdit) {
          notify.success('Симуляция было успешно отредактирована');
          setTimeout(() => {
            navigate(-1);
          }, 600);
        } else {
          notify.success('Симуляция было успешно создано');
          setTimeout(() => {
            navigate('/coach/simulations');
          }, 600);
        }
      })
      .catch(() => {
        notify.error(
          `Не удалось ${
            isEdit ? 'отредактировать' : 'создать'
          } симуляцию, повторите еще раз или обратитесь в службу поддержки`
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (isEdit) {
      setInitialValues();
    }
  }, []);

  return (
    <div className="flex flex-col">
      <div className="flex flex-row justify-between items-end">
        <LMSTitle tag="h4" className="text-current text-3xl">
          {isEdit ? 'Редактирование' : 'Создание'} симуляции
        </LMSTitle>
        <LMSButton
          loading={isLoading}
          className="fixed bottom-0 left-0 right-0 py-4 rounded-none sm:static sm:rounded-full"
          onClick={handleSubmit(onSubmit)}
        >
          Сохранить
        </LMSButton>
      </div>

      <div className="w-full h-full flex flex-col gap-y-5 mt-10 mb-20 sm:mb-0">
        <div className="w-full flex flex-col gap-5 p-5 theme-coach-background--gn rounded-3xl sm:p-10">
          <div className="w-full flex flex-col gap-5 sm:flex-row">
            <div className="w-full h-fit rounded-[14px] sm:max-w-[49%]">
              <Controller
                name="categoryId"
                control={control}
                rules={{
                  required: { message: 'Выберите категорию', value: true },
                }}
                render={({ fieldState: { error } }) => (
                  <DropdownSelect
                    notRounded={true}
                    title="Выберите категорию"
                    value={category}
                    list={categories.map((category) => ({
                      id: category.value,
                      name: category.label,
                    }))}
                    error={error?.message}
                    onCourseClick={(id, name) => {
                      setValue('categoryId', id);
                      setCategory(name);
                    }}
                  />
                )}
              />
            </div>
            <div className="w-full rounded-[14px] sm:max-w-[49%]">
              <Controller
                name="seconds"
                control={control}
                rules={{
                  required: { message: 'Это поле обязательно к заполнению', value: true },
                }}
                render={({ field, fieldState: { error } }) => (
                  <div className="w-full rounded-[14px]">
                    <input
                      type="number"
                      placeholder="Общее время на симуляцию в секундах"
                      {...field}
                      className={`w-full max-h-[52px] py-3 px-6 text-current theme-input border border-0.5 border-opacity-50 rounded-[14px] outline-none ${
                        error ? '!border-red-500' : 'border-white'
                      }`}
                    />
                    {error && <p className="text-red-500">{error.message}</p>}
                  </div>
                )}
              />
            </div>
          </div>
          <div className="w-full">
            <Controller
              name="target"
              control={control}
              rules={{
                required: { message: 'Это поле обязательно к заполнению', value: true },
              }}
              render={({ field, fieldState: { error } }) => (
                <div className="w-full">
                  <textarea
                    {...field}
                    className={`w-full h-[225px] py-3 px-6 text-current theme-input border border-0.5 border-opacity-50 rounded-[14px] outline-none ${
                      error ? '!border-red-500' : 'border-white'
                    }`}
                    placeholder="Опишите цель симуляции"
                    onChange={(event) => setValue('target', event.target.value)}
                  ></textarea>
                  {error && <p className="text-red-500">{error.message}</p>}
                </div>
              )}
            />
          </div>
          <div className="w-full">
            <Controller
              name="description"
              control={control}
              rules={{
                required: { message: 'Это поле обязательно к заполнению', value: true },
              }}
              render={({ field, fieldState: { error } }) => (
                <div className="w-full">
                  <textarea
                    {...field}
                    className={`w-full h-[225px] py-3 px-6 text-current theme-input border border-0.5 border-opacity-50 rounded-[14px] outline-none ${
                      error ? '!border-red-500' : 'border-white'
                    }`}
                    placeholder="Опишите описание симуляции"
                    onChange={(event) => setValue('description', event.target.value)}
                  ></textarea>
                  {error && <p className="text-red-500">{error.message}</p>}
                </div>
              )}
            />
          </div>
          <div className="w-full">
            <Controller
              name="instruction"
              control={control}
              rules={{
                required: { message: 'Это поле обязательно к заполнению', value: true },
              }}
              render={({ field, fieldState: { error } }) => (
                <div className="w-full">
                  <textarea
                    {...field}
                    className={`w-full h-[225px] py-3 px-6 text-current theme-input border border-0.5 border-opacity-50 rounded-[14px] outline-none ${
                      error ? '!border-red-500' : 'border-white'
                    }`}
                    placeholder="Опишите инструкцию симуляции"
                    onChange={(event) => setValue('instruction', event.target.value)}
                  ></textarea>
                  {error && <p className="text-red-500">{error.message}</p>}
                </div>
              )}
            />
          </div>
          <div className="w-full">
            <Controller
              name="categoryDescription"
              control={control}
              rules={{
                required: { message: 'Это поле обязательно к заполнению', value: true },
              }}
              render={({ field, fieldState: { error } }) => (
                <div className="w-full">
                  <textarea
                    {...field}
                    className={`w-full h-[225px] py-3 px-6 text-current theme-input border border-0.5 border-opacity-50 rounded-[14px] outline-none ${
                      error ? '!border-red-500' : 'border-white'
                    }`}
                    placeholder="Опишите категории симуляции"
                    onChange={(event) => setValue('categoryDescription', event.target.value)}
                  ></textarea>
                  {error && <p className="text-red-500">{error.message}</p>}
                </div>
              )}
            />
          </div>
          <div className="w-full">
            <Controller
              name="questions"
              control={control}
              rules={{
                required: { message: 'Это поле обязательно к заполнению', value: true },
              }}
              render={({ field, fieldState: { error } }) => (
                <div className="w-full rounded-[14px]">
                  <textarea
                    {...field}
                    className={`w-full h-[225px] py-3 px-6 text-current theme-input border border-0.5 border-opacity-50 rounded-[14px] outline-none ${
                      error ? '!border-red-500' : 'border-white'
                    }`}
                    placeholder="Опишите правила вопросов симуляции"
                    onChange={(event) => setValue('questions', event.target.value)}
                  ></textarea>
                  {error && <p className="text-red-500">{error.message}</p>}
                </div>
              )}
            />
          </div>
          <div className="w-full">
            <Controller
              name="timerDescription"
              control={control}
              rules={{
                required: { message: 'Это поле обязательно к заполнению', value: true },
              }}
              render={({ field, fieldState: { error } }) => (
                <div className="w-full">
                  <textarea
                    {...field}
                    className={`w-full h-[225px] py-3 px-6 text-current theme-input border border-0.5 border-opacity-50 rounded-[14px] outline-none ${
                      error ? '!border-red-500' : 'border-white'
                    }`}
                    placeholder="Опишите работу таймера симуляции"
                    onChange={(event) => setValue('timerDescription', event.target.value)}
                  ></textarea>
                  {error && <p className="text-red-500">{error.message}</p>}
                </div>
              )}
            />
          </div>
          <div className="w-full">
            <Controller
              name="tryDescription"
              control={control}
              rules={{
                required: { message: 'Это поле обязательно к заполнению', value: true },
              }}
              render={({ field, fieldState: { error } }) => (
                <div className="w-full">
                  <textarea
                    {...field}
                    className={`w-full h-[225px] py-3 px-6 text-current theme-input border border-0.5 border-opacity-50 rounded-[14px] outline-none ${
                      error ? '!border-red-500' : 'border-white'
                    }`}
                    placeholder="Опишите количества попыток симуляции"
                    onChange={(event) => setValue('tryDescription', event.target.value)}
                  ></textarea>
                  {error && <p className="text-red-500">{error.message}</p>}
                </div>
              )}
            />
          </div>
          <div className="w-full">
            <Controller
              name="resultsDescription"
              control={control}
              rules={{
                required: { message: 'Это поле обязательно к заполнению', value: true },
              }}
              render={({ field, fieldState: { error } }) => (
                <div className="w-full">
                  <textarea
                    {...field}
                    className={`w-full h-[225px] py-3 px-6 text-current theme-input border border-0.5 border-opacity-50 rounded-[14px] outline-none ${
                      error ? '!border-red-500' : 'border-white'
                    }`}
                    placeholder="Опишите вычисление результатов симуляции"
                    onChange={(event) => setValue('resultsDescription', event.target.value)}
                  ></textarea>
                  {error && <p className="text-red-500">{error.message}</p>}
                </div>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewSimulation;
