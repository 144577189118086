import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as ArrowBack } from 'assets/icons/chevron-left.svg';
import EditHomeWork from 'modules/coach/components/EditHomeWork';

const EditHomework = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const goBack = () => {
    navigate(`/coach/homeworks/homework/${id}`);
  };

  return (
    <div className="w-full h-full flex flex-col mt-24 lg:mt-4">
      <button
        type="button"
        onClick={() => goBack()}
        className="w-fit flex flex-row items-center justify-center gap-x-2 py-3 pl-3 pr-5 text-current text-[14px] font-medium theme-default-button rounded-[36px] border-none outline-none uppercase"
      >
        <ArrowBack />
        назад
      </button>
      <div className="flex flex-col mt-10">
        {id ? (
          <EditHomeWork />
        ) : (
          <div className="flex justify-center items-center text-current">
            Произошла ошибка... обратитесь в тех.поддержку
          </div>
        )}
      </div>
    </div>
  );
};

export default EditHomework;
