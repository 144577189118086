import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as AddIcon } from 'assets/icons/add.svg';
import LMSButton from 'components/LMSButton';
import LMSPagination from 'components/LMSPagination';
import LMSTitle from 'components/LMSTitle';
import Loader from 'components/Loader';
import { fetchSimulations } from 'modules/coach/api/repository/simulations';
import SimulationCard from 'modules/coach/components/SimulationCard';
import { ISimulation } from 'modules/coach/entities/Simulation';
import { useNotification } from 'hooks/notification';

export const emptyIcon = 'assets/images/empty-image.png';

const Simulations = () => {
  const notify = useNotification();
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState<boolean>(true);
  const [simulations, setSimulations] = useState<ISimulation[]>([]);
  const [page, setPage] = useState<number>(1);
  const [lastPage, setLastPage] = useState(1);

  const setCurrentPage = (current: number) => {
    setPage(current);
  };

  useEffect(() => {
    fetchSimulations()
      .then((res) => {
        setSimulations(res.simulations);
        setLastPage(res.lastPage);
      })
      .catch(() => {
        notify.error('Произошла ошибка. Обратитесь в службу поддержки');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [page]);

  return (
    <div className="w-full mt-20 lg:mt-0">
      <div className="flex flex-col pt-6">
        <div className="flex flex-row justify-between items-end">
          <LMSTitle tag="h4" className="mt-auto text-current text-3xl">
            Симуляций
          </LMSTitle>
          <LMSButton prefix={<AddIcon />} onClick={() => navigate('new-simulation')}>
            Новая симуляция
          </LMSButton>
        </div>

        <div className="w-full flex flex-col gap-y-4 pt-9">
          {isLoading && <Loader />}
          {simulations ? (
            simulations?.map((el) => (
              <SimulationCard
                key={el.id}
                title={el.categoryName}
                onClick={() => navigate(`simulation/${el.id}`)}
              />
            ))
          ) : (
            <div className="text-current">Созданные симуляций отстуствуют</div>
          )}
        </div>

        <div className="w-full flex justify-center items-center mt-auto pt-6 md:pt-10">
          <LMSPagination current={page} lastPage={lastPage} onChange={setCurrentPage} />
        </div>
      </div>
    </div>
  );
};

export default Simulations;
