import { useMemo } from 'react';
import { ReactComponent as CheckIcon } from 'assets/icons/check-icon-blue.svg';

interface IProps {
  label: string;
  value: string;
  selectedValue: string;
  disabled?: boolean;
  onChange: CallableFunction;
}

const CustomRadioButton: React.FC<IProps> = ({
  label,
  value,
  selectedValue,
  disabled = false,
  onChange,
}) => {
  const isSelected = selectedValue === value;

  const handleClick = () => {
    if (!disabled && !isSelected) {
      onChange(value);
    }
  };

  const buttonClass = useMemo(() => {
    return `
      flex
      items-center
      space-x-2
      cursor-pointer
      ${isSelected ? 'text-white' : 'text-black'}
      ${disabled ? 'opacity-50 cursor-not-allowed' : ''}
    `;
  }, [isSelected, disabled]);

  return (
    <div className={buttonClass} onClick={handleClick}>
      <div
        className={`w-6 h-6 border-2 ${
          isSelected ? 'border-white' : 'border-gray-400'
        } rounded-full flex items-center justify-center`}
      >
        {isSelected && (
          <div className="w-4 h-4 flex justify-center items-center rounded-full">
            <div className="w-full h-full flex justify-center items-center bg-white rounded-full">
              <CheckIcon />
            </div>
          </div>
        )}
      </div>
      <span>{label}</span>
    </div>
  );
};

export default CustomRadioButton;
