import request from 'api/axios-instance';
import { mapOptionDaoToEntity } from 'api/mappers/option.mappers';
import { Option } from 'entities/Option';

import { ICourseCoach, ICourseDetail } from '../../entities/Course';
import { CourseCoachDao, CourseDetailDao } from '../dao/course-detail.dao';

export const fetchFilterCourses = (params?: Record<string, string>) =>
  request.get('v1/courses-filter', { params }).then((res) => {
    const courses: Option[] = res.data.map(mapOptionDaoToEntity);
    return courses;
  });

export const fetchCourses = (params: Record<string, string | number | null>) =>
  request.get('v1/management/courses', { params }).then((res) => {
    const courses: ICourseDetail[] = res.data.data.map(mapCourseDaoToEntity);
    return {
      courses,
      lastPage: res.data.meta.last_page as number,
    };
  });

export const fetchJournalCourses = (params: Record<string, string | number | null>) =>
  request.get('v2/hr/catalog/courses', { params }).then((res) => {
    return {
      courses: res.data.data,
    };
  });

export const fetchCourseById = (id: number) =>
  request.get(`v1/management/courses/${id}`).then((res) => mapCourseDaoToEntity(res.data.data));

function mapCourseDaoToEntity(course: CourseDetailDao): ICourseDetail {
  return {
    id: course.id,
    name: course.name,
    description: course.description,
    hasCertificate: course.has_certificate,
    durationInHours: course.duration_in_hours,
    programContent: course.program_content,
    courseType: course.course_type,
    studyType: course.study_type,
    currency: course.currency,
    dateFrom: course.date_from,
    dateTo: course.date_to,
    price: course.price,
    direction: course.direction,
    imageUrl: course.image_url,
    modules: course.modules,
    coaches: course.coaches ? course.coaches.map(mapCourseCoachesDaoToEntity) : [],
  };
}

function mapCourseCoachesDaoToEntity(coach: CourseCoachDao): ICourseCoach {
  return {
    id: coach.id,
    fullName: coach.full_name,
    email: coach.email,
  };
}
